import React from 'react';
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core/styles';
import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import { ThreatSurface } from 'app/services/apiThreatSurfaces';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 200,
    },
    listContainer: {},
    list: {
      padding: 0,
    },
    listHeaderContainer: {},
    listHeader: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    listItem: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginTop: theme.spacing(1),
      '&$listItemSelected': {
        background: 'none',
      },
    },
    listItemSelected: {
      boxShadow: `inset 3px 0 0 0 ${theme.palette.primary.main}`,
      '& $listItemTextPrimary': {
        fontWeight: 'bold',
      },
    },
    listItemText: {},
    listItemTextPrimary: {
      fontSize: theme.typography.pxToRem(14),
    },
  });

interface ThreatSurfacesProps extends WithStyles<typeof styles> {
  surfaces: ThreatSurface[] | null;
  activeSurface: ThreatSurface | null;
  handleSurfaceSelection: (surface: ThreatSurface) => void;
}

const NavThreatSurfaces = withStyles(styles)(
  ({
    classes,
    surfaces,
    handleSurfaceSelection,
    activeSurface,
  }: ThreatSurfacesProps) => {
    const handleItemClick = (surface: ThreatSurface) => () => {
      handleSurfaceSelection(surface);
    };

    const renderListItem = (surface: ThreatSurface): JSX.Element => (
      <ListItem
        key={surface.id}
        component="li"
        button
        classes={{
          root: classes.listItem,
          selected: classes.listItemSelected,
        }}
        selected={activeSurface?.id === surface.id}
        onClick={handleItemClick(surface)}
      >
        <ListItemText
          classes={{
            root: classes.listItemText,
            primary: classes.listItemTextPrimary,
          }}
          primary={surface.name}
        />
      </ListItem>
    );

    if (!surfaces || !activeSurface) {
      return null;
    }

    return (
      <div className={classes.root}>
        <Box className={classes.listContainer}>
          <List className={classes.list}>{surfaces.map(renderListItem)}</List>
        </Box>
      </div>
    );
  }
);

export default NavThreatSurfaces;
