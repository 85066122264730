import React, {
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  SyntheticEvent,
  ReactElement,
  forwardRef,
  Ref,
} from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import {
  Paper,
  IconButton,
  Button,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  Fab,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  MenuItem,
  Snackbar,
  Slide,
  Zoom,
  useMediaQuery,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import MuiAlert from '@material-ui/lab/Alert';
import {
  Add,
  Remove,
  Close,
  AddBox,
  IndeterminateCheckBox,
  HelpOutlineRounded,
} from '@material-ui/icons';
import MuiHeaderColor from '@material-ui/core/colors/grey';
import {
  Control,
  getControls,
  TagType,
  getTags,
  saveTagType,
  deleteTagType,
  updateTagType,
  TagHints,
} from 'app/services/apiTags';
import { displayHelp } from 'app/services/help';
import Loader from 'app/common/Loader';
import TreeViewGroup from './TreeViewGroup';
import { isEmpty } from 'lodash';
import { HeadCell } from '../adminControlGroup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      padding: '1.5rem',
      marginTop: '0.5rem',
      marginBottom: '2rem',
    },
    box: {
      height: 500,
      overflow: 'auto',
    },
    tree: {
      height: 500,
      overflow: 'auto',
      padding: '16px',
    },
    treeView: {
      height: 216,
      flexGrow: 1,
      width: '27%',
    },
    treeItem: {
      paddingTop: '6px',
      paddingBottom: '8px',
    },
    column_button: {
      marginTop: '25%',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    dialog: {
      marginTop: theme.spacing(2),
    },
    modal: {
      backgroundColor: MuiHeaderColor[300],
    },
    fab: {
      bottom: theme.spacing(6.35),
      marginRight: theme.spacing(1.25),
      float: 'right',
    },
    margin: {
      margin: theme.spacing(1),
    },
    formControl: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: 550,
    },
    gridTree: {
      width: 550,
      minHeight: 250,
      overflow: 'auto',
      padding: '16px',
      border: '1px solid',
      borderColor: MuiHeaderColor[300],
    },
    gridTitle: {
      fontSize: 12,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      marginLeft: theme.spacing(3),
    },
    controls: {
      width: 270,
      backgroundColor: MuiHeaderColor[300],
      margin: theme.spacing(2),
      '&:hover': {
        color: MuiHeaderColor[900],
      },
    },
    selected: {
      width: 270,
      backgroundColor: MuiHeaderColor[600],
      color: MuiHeaderColor[100],
      margin: theme.spacing(2),
      '&:hover': {
        color: MuiHeaderColor[900],
      },
    },
    table: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      backgroundColor: MuiHeaderColor[400],
    },
    tableTitle: {
      flex: '1 1 100%',
    },
    tableColumn: {
      width: '27%',
      backgroundColor: MuiHeaderColor[300],
    },
    tableBody: {
      backgroundColor: '#FFFFFF',
    },
  })
);

const not = (a: Control[], b: Control[]) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a: Control[], b: Control[]) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

const sortArray = (unsortedArray: any[]) => {
  return unsortedArray.sort((a, b) => a.displayOrder - b.displayOrder);
};

interface EnhancedTableToolbarProps {
  subcomponentName: string;
  subcomponentHelpURI?: string;
}

const EnhancedTableToolbar = ({
  subcomponentName,
  subcomponentHelpURI,
}: EnhancedTableToolbarProps) => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.table}>
      <Typography
        className={classes.tableTitle}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {subcomponentName}
      </Typography>
      {/* Help Button */}
      <IconButton
        disabled={!isEmpty(subcomponentHelpURI)}
        onClick={() =>
          displayHelp({
            context: 'admin',
            resource: subcomponentHelpURI,
          })
        }
      >
        <HelpOutlineRounded />
      </IconButton>
    </Toolbar>
  );
};

interface EnhancedTableProps {
  headCells: HeadCell[];
}

const EnhancedTableHead = ({ headCells }: EnhancedTableProps) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {/* Subtitle */}
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="default"
            className={classes.tableColumn}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface ControlledTreeProps {
  tags: any[];
  treeItems: TagType[];
  selectedTag: TagType | null;
  onHandleSelectedTag: (value: any) => void;
  onCheckTagHint: (
    clickedTag: TagType | null,
    controlledTreeValue: any
  ) => void;
}

const ControlledTreeView = ({
  tags,
  treeItems,
  selectedTag,
  onHandleSelectedTag,
  onCheckTagHint,
}: ControlledTreeProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>(['none']);
  const customizedTags: any[] = treeItems.slice(0);
  customizedTags.unshift({});

  useEffect(() => {
    if (selectedTag) {
      if (selectedTag.parentId) {
        const expandedBranch: string[] = selectedTag.key
          .slice(selectedTag.key.indexOf('.tag.') + 5, selectedTag.key.length)
          .replace(/[^a-zA-Z0-9.]/g, '')
          .toLowerCase()
          .split('.');

        expandTagBranch(treeItems, expandedBranch, 0);
        setExpanded(expandedBranch);
      }

      setSelected([selectedTag.id.toString()]);
      onHandleSelectedTag(
        tags.filter((item) => {
          return item.id === selectedTag.id;
        })[0]
      );
    }
  }, []);

  const expandTagBranch = (
    mainTag: TagType[],
    branchArray: string[],
    index: number
  ) => {
    mainTag.map((tag) => {
      if (
        tag.name.replace(/[^a-zA-Z0-9.]/g, '').toLowerCase() ===
        branchArray[index]
      ) {
        branchArray[index] = String(tag.id);

        if (Array.isArray(tag.subTags)) {
          expandTagBranch(tag.subTags, branchArray, ++index);
        }
      }
    });
  };

  const handleToggle = (event: ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: ChangeEvent<{}>, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  const renderSubTags = (nodes: any, tags: any[]) => {
    const handleClickTagTree = () => {
      onCheckTagHint(
        nodes,
        Object.keys(nodes).length === 0
          ? 'none'
          : tags.filter((item) => {
              return item.id === nodes.id;
            })[0]
      );
    };

    return (
      <TreeItem
        className={classes.treeItem}
        key={Object.keys(nodes).length === 0 ? 'none' : nodes.id}
        nodeId={Object.keys(nodes).length === 0 ? 'none' : String(nodes.id)}
        label={Object.keys(nodes).length === 0 ? 'No Parent' : nodes.name}
        onClick={handleClickTagTree}
      >
        {Object.keys(nodes).length > 0 && Array.isArray(nodes.subTags)
          ? nodes.subTags.map((node: any) => renderSubTags(node, tags))
          : null}
      </TreeItem>
    );
  };

  return (
    <TreeView
      className={classes.treeView}
      defaultCollapseIcon={<IndeterminateCheckBox fontSize="small" />}
      defaultExpandIcon={<AddBox fontSize="small" />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      {customizedTags.map((tag) => renderSubTags(tag, tags))}
    </TreeView>
  );
};

interface AddTagProps {
  tags: any[];
  selectedTag: TagType | null;
  treeItems: TagType[] | null;
  requires: string;
  permissions: string[];
  onHandleSaveTag: (
    tagName: string,
    parentInfo: string,
    tagHint: number
  ) => void;
}

const AddTagDialog = ({
  tags,
  selectedTag,
  treeItems,
  requires,
  permissions,
  onHandleSaveTag,
}: AddTagProps) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [open, setOpen] = useState<{
    dialog: boolean;
    snackbar: boolean;
  }>({
    dialog: false,
    snackbar: false,
  });
  const [state, setState] = useState<{
    tagName: string;
    tagId: any;
    tagHint: boolean;
    tagHintEnabled: boolean;
    tagNotUnique: boolean;
  }>({
    tagName: '',
    tagId: '',
    tagHint: false,
    tagHintEnabled: true,
    tagNotUnique: false,
  });

  const checkUniqueness = () => {
    if (state.tagName && tags) {
      let parentId =
        typeof state.tagId === 'string' &&
        state.tagId.toLocaleLowerCase() === 'none'
          ? null
          : state.tagId.id;

      state.tagNotUnique = false;
      tags.find((item: any) => {
        if (
          String(item.parent) === String(parentId) &&
          state.tagName.toLocaleLowerCase() === item.name.toLocaleLowerCase()
        ) {
          state.tagNotUnique = true;
        }
      });
    }
  };

  const checkTagHint = (
    clickedTag: TagType | null,
    controlledTreeValue: any
  ) => {
    if (clickedTag && treeItems) {
      // No Parent clicked
      if (Object.keys(clickedTag).length === 0 && controlledTreeValue) {
        setState({
          ...state,
          tagId: controlledTreeValue,
          tagHint: false,
          tagHintEnabled: false,
        });
      } else {
        setState(
          controlledTreeValue
            ? {
                ...state,
                tagId: controlledTreeValue,
                tagHint: clickedTag.hint === TagHints.ControlContainer,
                tagHintEnabled: clickedTag.hint === TagHints.ControlContainer,
              }
            : {
                ...state,
                tagHint: clickedTag.hint === TagHints.ControlContainer,
                tagHintEnabled: clickedTag.hint === TagHints.ControlContainer,
              }
        );
      }
    } else {
      // In case the user hasn't selected a control group
      setState({
        ...state,
        tagId: 'none',
        tagHint: false,
        tagHintEnabled: false,
      });
    }
  };

  const handleFormDialog = (name: string, action: boolean) => {
    setOpen({
      ...open,
      [name]: action,
    });
  };

  const handleCloseFormDialog = () => {
    handleFormDialog('dialog', !open.dialog);

    // Clear Form
    setState({
      tagName: '',
      tagId: '',
      tagHint: false,
      tagHintEnabled: true,
      tagNotUnique: false,
    });
  };

  const handleCloseSnackbar = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    handleFormDialog('snackbar', !open.snackbar);
  };

  const handleSelectedTag = (value: any) => {
    setState({
      ...state,
      tagId: value,
    });
  };

  const handleChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value = event.target.value;

    setState({
      ...state,
      [event.target.name as string]: value,
    });
  };

  const handleAddTag = () => {
    if (state.tagName && tags) {
      checkUniqueness();

      if (state.tagNotUnique) {
        handleFormDialog('snackbar', !open.snackbar);
        return;
      }
    }

    // Re-load the data
    const tagHint = state.tagHint
      ? TagHints.ControlContainer
      : TagHints.Default;
    onHandleSaveTag(state.tagName, state.tagId, tagHint);

    // Clear Form
    setState({
      tagName: '',
      tagId: '',
      tagHint: false,
      tagHintEnabled: true,
      tagNotUnique: false,
    });

    handleFormDialog('dialog', !open.dialog);
  };

  return (
    <>
      <Fab
        aria-label="Add"
        className={classes.fab}
        size="small"
        onClick={() => {
          handleFormDialog('dialog', !open.dialog);
          checkTagHint(selectedTag, null);
        }}
        disabled={!permissions.includes(`create:${requires.split(':').pop()}`)}
      >
        <Tooltip
          title="Add Control Group"
          TransitionComponent={Zoom}
          arrow
          placement="top"
        >
          <Add />
        </Tooltip>
      </Fab>
      <Dialog
        fullScreen={fullScreen}
        open={open.dialog}
        onClose={handleCloseFormDialog}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.modal}
          disableTypography
        >
          <Typography variant="h6">New Control Group</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseFormDialog}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          {/* Tag or Sub-tag Name */}
          <TextField
            className={classes.textField}
            required
            autoFocus
            id="name"
            label="Control Group Name"
            variant="outlined"
            name="tagName"
            value={state.tagName}
            onChange={handleChange}
          />

          {/* Associated Tag */}
          <FormControl variant="outlined" className={classes.formControl}>
            {treeItems && tags ? (
              <Grid
                container
                spacing={2}
                justify="flex-start"
                className={classes.root}
              >
                <Typography
                  className={classes.gridTitle}
                  color="textSecondary"
                  gutterBottom
                >
                  Parent Control Group
                </Typography>
                <Grid item>
                  <Paper className={classes.gridTree}>
                    <ControlledTreeView
                      tags={tags}
                      treeItems={treeItems}
                      selectedTag={selectedTag}
                      onHandleSelectedTag={handleSelectedTag}
                      onCheckTagHint={checkTagHint}
                    />
                  </Paper>
                </Grid>
              </Grid>
            ) : (
              <>
                <InputLabel id="select-outlined-label">
                  Parent Control Group
                </InputLabel>
                <Select
                  labelId="select-outlined-label"
                  id="select-outlined"
                  name="tagId"
                  value={() => handleSelectedTag('none')}
                  onChange={handleChange}
                  label="Parent Control Group"
                >
                  <MenuItem value="none" key={'associated-tag-null'}>
                    New
                  </MenuItem>
                </Select>
              </>
            )}
          </FormControl>

          {/* Tag Hint */}
          <FormControlLabel
            className={classes.dialog}
            disabled={state.tagHintEnabled}
            control={
              <Checkbox
                name="tagHint"
                value={state.tagHint}
                checked={state.tagHint}
                onChange={() => setState({ ...state, tagHint: !state.tagHint })}
                // color="primary"
              />
            }
            label="Enable dashboard menu"
          />
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            className={classes.margin}
            onClick={handleAddTag}
            disabled={!state.tagName || !state.tagId}
          >
            Add
          </Button>
          <Snackbar
            open={open.snackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={'error'}
              onClose={handleCloseSnackbar}
            >
              Control Group name must be unique.
            </MuiAlert>
          </Snackbar>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface RemoveTagProps {
  deleteTag: any;
  selectedTag: TagType | null;
  requires: string;
  permissions: string[];
  onHandleRemoveTag: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RemoveTagDialog = ({
  deleteTag,
  selectedTag,
  requires,
  permissions,
  onHandleRemoveTag,
}: RemoveTagProps) => {
  const classes = useStyles();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleCofirmDelete = () => {
    setConfirmDelete(!confirmDelete);
  };

  // handleRemoveTag
  const handleDelete = () => {
    if (confirmDelete) {
      /* Delete Tag */
      handleCofirmDelete();

      onHandleRemoveTag();
    }
  };

  return (
    <Fab
      aria-label="Remove"
      className={classes.fab}
      size="small"
      onClick={handleCofirmDelete}
      disabled={
        !deleteTag.disableButton ||
        !permissions.includes(`delete:${requires.split(':').pop()}`)
      }
    >
      <Tooltip
        title="Remove Control Group"
        TransitionComponent={Zoom}
        arrow
        placement="top"
      >
        <Remove />
      </Tooltip>
      <Dialog
        open={confirmDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCofirmDelete}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" disableTypography>
          <Typography variant="h6">Attention</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure you want to delete ${
              selectedTag ? selectedTag.name : 'this Control Group'
            }?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCofirmDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fab>
  );
};

interface SubcomponentTableProps {
  subcomponentName: string;
  subcomponentHelpURI?: string;
  controls: Control[] | null;
  requires: string;
  permissions: string[];
  filterByCompany: (tags: TagType[]) => TagType[] | null;
  headCells: HeadCell[];
  minDisplayOrder: number;
}

export default function SubcomponentTable({
  subcomponentName,
  subcomponentHelpURI,
  controls,
  requires,
  permissions,
  filterByCompany,
  headCells,
  minDisplayOrder,
}: SubcomponentTableProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [controlGroupTags, setControlGroupTags] = useState<TagType[] | null>(
    null
  );
  // Control(s) NOT associated with selected Tag (Right Box):
  const [controlsNotAssociated, setControlsNotAssociated] = useState<
    Control[] | null
  >(null);
  // Control(s) associated with selected Tag (Middle Box):
  const [controlsAssociated, setControlsAssociated] = useState<
    Control[] | null
  >(null);
  const [controlSelected, setControlSelected] = useState<Control[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const deleteTag = useRef<any>({
    disableButton: false,
    hasFiltered: false,
    hasNestedLevels: false,
    filteredTag: [],
    updateDisplayOrder: [],
  });
  const hintArray = useRef<any[]>([]);
  const refTag = useRef<TagType | null>(null);
  const dialogTags: any[] = [];

  useEffect(() => {
    (async () => {
      await Promise.all([getTags()]).then((pTags) => {
        setControlGroupTags(filterByCompany(pTags[0] as TagType[]));
      });

      setLoading(false);
    })();
    if (controls) {
      handleLoadControl(controls);
    }
  }, [subcomponentName]);

  const selectedAssociated = controlsAssociated
    ? intersection(controlSelected, controlsAssociated)
    : controlSelected;
  const selectedNotAssociated = controlsNotAssociated
    ? intersection(controlSelected, controlsNotAssociated)
    : controlSelected;

  const insertTagByLevel = (
    nodes: TagType,
    newTagName: string,
    tagInfo: any,
    tagHint: number
  ) => {
    if (tagInfo.level > 0 && Array.isArray(nodes.subTags)) {
      --tagInfo.level;
      nodes.subTags.map((node) =>
        insertTagByLevel(node, newTagName, tagInfo, tagHint)
      );
    } else {
      if (nodes.id === tagInfo.id) {
        handlePostRequest({
          parentId: nodes.id,
          name: newTagName,
          displayOrder:
            Array.isArray(nodes.subTags) && nodes.subTags.length > 0
              ? nodes.subTags[nodes.subTags.length - 1].displayOrder + 1
              : minDisplayOrder,
          hint: tagHint,
          companyId: nodes.companyId,
        });
      }
    }
  };

  const removeTagByLevel = (nodes: TagType, removableTag: TagType) => {
    if (!deleteTag.current.hasFiltered && Array.isArray(nodes.subTags)) {
      if (nodes.subTags.includes(removableTag)) {
        deleteTag.current.hasFiltered = true;

        deleteTag.current.filteredTag = {
          ...nodes,
          subTags: nodes.subTags.filter((subTag) => {
            if (removableTag.displayOrder < subTag.displayOrder) {
              subTag.displayOrder -= 1;
              deleteTag.current.updateDisplayOrder.push(subTag);
            }
            return subTag.id !== removableTag.id;
          }),
        };

        return deleteTag.current.filteredTag;
      } else {
        deleteTag.current.hasNestedLevels = true;
        nodes.subTags.map((subTag) => {
          removeTagByLevel(subTag, removableTag);
        });
      }
    } else {
      deleteTag.current.hasNestedLevels = false;
    }

    deleteTag.current.filteredTag = !deleteTag.current.hasNestedLevels
      ? { ...nodes }
      : {
          ...nodes,
          subTags: [deleteTag.current.filteredTag],
        };

    return deleteTag.current.filteredTag;
  };

  const findMainTagHint = (tags: TagType[], tagId: number) => {
    tags.map((tag) => {
      if (tag.id === tagId && Array.isArray(tag.subTags)) {
        getHintSubTagArray(tag.subTags);
      } else if (Array.isArray(tag.subTags)) {
        findMainTagHint(tag.subTags, tagId);
      }
    });
  };

  const getHintSubTagArray = (nodes: TagType[]) => {
    nodes.map((node) => {
      if (node.hint !== TagHints.ControlContainer) {
        hintArray.current.push({
          id: node.id,
          hint: TagHints.ControlContainer,
        });
        if (Array.isArray(node.subTags)) {
          getHintSubTagArray(node.subTags);
        }
      }
    });
  };

  const renderSubTags = (
    nodes: TagType,
    key: number,
    level: number,
    parent: any
  ) => {
    dialogTags.push({
      name: nodes.name,
      id: nodes.id,
      key,
      level,
      parent,
      edit: false,
    });
    if (Array.isArray(nodes.subTags)) {
      ++level;
      nodes.subTags.map((node) =>
        renderSubTags(node, key, level, node.parentId)
      );
    }
  };

  const loadDialogTags = (): any[] => {
    if (controlGroupTags) {
      dialogTags.length = 0;
      controlGroupTags.map((tag) =>
        renderSubTags(tag, tag.id, 0, tag.parentId)
      );
    }
    return dialogTags;
  };

  const handleToggle = (value: Control) => () => {
    if (refTag.current) {
      const currentIndex = controlSelected.indexOf(value);
      const newcontrolSelected = [...controlSelected];

      if (currentIndex === -1) {
        newcontrolSelected.push(value);
      } else {
        newcontrolSelected.splice(currentIndex, 1);
      }

      setControlSelected(newcontrolSelected);
    }
  };

  const handleLoadControl = (controls: Control[], associated?: Control[]) => {
    const controlsNotAssociated: Control[] = !associated
      ? controls
      : controls.filter(
          ({ id: idControl }) =>
            !associated.some(
              ({ id: idAssociated }) => idAssociated === idControl
            )
        );
    setControlsNotAssociated(controlsNotAssociated);

    if (!associated) {
      setControlsAssociated([]);
    }
  };

  const handleAddControlNotAssociated = () => {
    const newAssociated = sortArray(
      controlsAssociated
        ? controlsAssociated.concat(selectedNotAssociated)
        : selectedNotAssociated
    );

    if (refTag.current) {
      const updateTag: any = {
        id: refTag.current.id,
      };
      if (newAssociated.length > 0) {
        updateTag.controlIds = newAssociated.map((control) => {
          return control.id;
        });
      }
      handleUpdateRequest(updateTag, [], true, newAssociated);
    }
  };

  const handleRemoveControlAssociated = () => {
    const remainingAssociated = sortArray(
      controlsAssociated
        ? not(controlsAssociated, selectedAssociated)
        : selectedAssociated
    );

    if (refTag.current) {
      const updateTag: any = {
        id: refTag.current.id,
      };
      updateTag.controlIds = remainingAssociated.map((control) => control.id);

      handleUpdateRequest(updateTag, [], false, remainingAssociated);
    }
  };

  const handleClickTag = (selectedTag: TagType) => {
    if (controls) {
      setControlSelected([]);

      (async () => {
        const tagKey = selectedTag.key;
        const include = 'tags';
        await Promise.all([getControls(tagKey, include)]).then((pControls) => {
          setControlsAssociated(pControls[0] as Control[]);
          handleLoadControl(controls, pControls[0]);
        });

        setLoading(false);
      })().catch((error) => {
        // Error loading promise
        if (error.status === 400) {
          setLoading(false);
          setOpenSnackbar(!openSnackbar);
        }
      });

      deleteTag.current.disableButton =
        !selectedTag.subTags || !selectedTag.subTags.length;
      refTag.current = selectedTag;
    }
  };

  const handleSaveTag = (tagName: string, parentInfo: any, tagHint: number) => {
    const tags = controlGroupTags ? controlGroupTags.slice(0) : [];

    // Adding top-level tag
    if (
      typeof parentInfo === 'string' &&
      parentInfo.toLocaleLowerCase() === 'none'
    ) {
      handlePostRequest({
        parentId: null,
        name: tagName,
        displayOrder:
          tags.length > 0
            ? tags[tags.length - 1].displayOrder + 1
            : minDisplayOrder,
        hint: tagHint,
        companyId: null,
      });
    } else {
      // Editing tag
      if (parentInfo.editName || parentInfo.editHint) {
        const updateTag: any =
          parentInfo.editName && parentInfo.editHint
            ? { id: parentInfo.id, name: tagName, hint: tagHint }
            : parentInfo.editName
            ? { id: parentInfo.id, name: tagName }
            : { id: parentInfo.id, hint: tagHint };

        if (parentInfo.editHint && tagHint === TagHints.ControlContainer) {
          findMainTagHint(tags, parentInfo.id);
        }

        handleUpdateRequest(updateTag, hintArray.current, false);
        hintArray.current.length = 0;
      } else {
        // Adding new Sub-Tag
        const mainTag = tags.filter(
          (tag) => tag.id === Number(parentInfo.key)
        )[0];
        insertTagByLevel(mainTag, tagName, parentInfo, tagHint);
      }
    }
  };

  const handleRemoveTag = () => {
    const removableTag: TagType | null = refTag.current;

    if (controlGroupTags && removableTag) {
      handleDeleteRequest(removableTag);
    }

    refTag.current = null;
    deleteTag.current = {
      hasFiltered: false,
      disableButton: false,
      hasNestedLevels: false,
      filteredTag: [],
      updateDisplayOrder: [],
    };
  };

  const handleLoadData = () => {
    (async () => {
      await Promise.all([getTags()]).then((pTags) => {
        setControlGroupTags(filterByCompany(pTags[0] as TagType[]));
      });

      setLoading(false);
    })();
  };

  const handleResponseDelete = (deleteResponse: any, removableTag: any) => {
    if (
      controlGroupTags &&
      removableTag &&
      Object.keys(deleteResponse.result).length > 0
    ) {
      const newTag = controlGroupTags.slice(0);

      setControlGroupTags(
        !removableTag.parentId
          ? newTag.filter((tag) => {
              if (removableTag.displayOrder < tag.displayOrder) {
                tag.displayOrder -= 1;
                deleteTag.current.updateDisplayOrder.push(tag);
              }
              return tag.id !== removableTag.id;
            })
          : newTag.map((tag) => {
              return removeTagByLevel(tag, removableTag);
            })
      );

      if (deleteTag.current.updateDisplayOrder.length > 0) {
        deleteTag.current.updateDisplayOrder.map((updatedTag: TagType) => {
          const updateTag: any = {
            id: updatedTag.id,
            displayOrder: updatedTag.displayOrder,
          };
          handleUpdateRequest(updateTag, [], false);
        });
      }

      if (controls) {
        handleLoadControl(controls);
      }
    } else {
      console.log(
        `Error ${deleteResponse.result.status}: ${deleteResponse.result.response.message}`
      );
      setLoading(false);
      setOpenSnackbar(!openSnackbar);
    }

    setLoading(false);
  };

  const updateControlBox = (controlArray: any, addControl: boolean) => {
    setControlsAssociated(controlArray);

    if (addControl) {
      setControlsNotAssociated(
        sortArray(
          controlsNotAssociated
            ? not(controlsNotAssociated, selectedNotAssociated)
            : selectedNotAssociated
        )
      );
      // Remove Control selected
      controlSelected.length > selectedNotAssociated.length
        ? setControlSelected(
            controlSelected.filter(
              (control) => !selectedNotAssociated.includes(control)
            )
          )
        : setControlSelected([]);
    } else {
      setControlsNotAssociated(
        sortArray(
          controlsNotAssociated
            ? controlsNotAssociated.concat(selectedAssociated)
            : selectedAssociated
        )
      );

      // Remove Control selected
      controlSelected.length > selectedAssociated.length
        ? setControlSelected(
            controlSelected.filter(
              (control) => !selectedAssociated.includes(control)
            )
          )
        : setControlSelected([]);
    }
  };

  const handlePostRequest = (newTag: any) => {
    // Save new tag
    setLoading(true);

    try {
      const postRequest = async () => {
        return saveTagType(newTag);
      };
      postRequest()
        .then(() => {
          // Re-load the data
          handleLoadData();

          if (controls) {
            handleLoadControl(controls);
          }
        })
        .catch((e) => {
          console.log(
            'There has been a problem with post operation: ',
            e.response.message
          );
          setLoading(false);
          setOpenSnackbar(!openSnackbar);
        });
    } catch (e) {
      console.log('There has been a problem with post operation: ' + e.message);
      setLoading(false);
      setOpenSnackbar(!openSnackbar);
    }
  };

  const handleDeleteRequest = (removeTag: any) => {
    // Delete tag
    const tagContent: any = { id: removeTag.id };

    setLoading(true);

    try {
      const deleteRequest = async () => {
        return deleteTagType(tagContent);
      };
      deleteRequest()
        .then((response) => {
          tagContent.result = response;
          // Re-load the data
          handleResponseDelete(tagContent, removeTag);
        })
        .catch((e) => {
          console.log(
            'There has been a problem with delete operation: ',
            e.response.message
          );
          setLoading(false);
          setOpenSnackbar(!openSnackbar);
        });
    } catch (e) {
      console.log(
        'There has been a problem with delete operation: ' + e.message
      );
      setLoading(false);
      setOpenSnackbar(!openSnackbar);
    }
  };

  const handleUpdateRequest = (
    updateTag: any,
    saveHint: any[],
    addControl: boolean,
    controlArray?: any
  ) => {
    // Update tag
    try {
      const patchRequest = async () => {
        return updateTagType(updateTag);
      };
      patchRequest().then((result: any) => {
        if (typeof result.status !== 'undefined') {
          console.log(`Error ${result.status}: ${result.response.message}`);
          setLoading(false);
          setOpenSnackbar(!openSnackbar);
        }

        if (saveHint.length > 0) {
          let updateError: boolean = false;
          saveHint.map((item) => {
            const patchRequest = async () => {
              return updateTagType(item);
            };
            patchRequest().then((result: any) => {
              if (typeof result.status !== 'undefined') {
                console.log(
                  `Error ${result.status}: ${result.response.message}`
                );
                updateError = true;
              }
            });
          });

          if (updateError) {
            setLoading(false);
            setOpenSnackbar(!openSnackbar);
          }
        }

        // Re-load Controls
        if (controlArray) {
          updateControlBox(controlArray, addControl);
        }

        // Re-load the data
        handleLoadData();
      });
    } catch (e) {
      console.log(
        'There has been a problem with update operation: ' + e.message
      );
      setLoading(false);
      setOpenSnackbar(!openSnackbar);
    }
  };

  if (loading) {
    return <Loader />;
  }

  const customList = (controls: Control[] | null) => (
    <Paper className={classes.box}>
      <List dense component="div" role="list">
        {controls
          ? controls.map((control: Control) => {
              const labelId = `transfer-list-item-${control.id}`;

              return (
                <ListItem
                  key={control.id}
                  role="listitem"
                  button
                  onClick={handleToggle(control)}
                  className={
                    controlSelected.includes(control)
                      ? classes.selected
                      : classes.controls
                  }
                >
                  <ListItemText id={labelId} primary={control.name} />
                </ListItem>
              );
            })
          : null}
      </List>
    </Paper>
  );

  return (
    <Box ml={2} flex={1}>
      <Paper elevation={3} className={classes.paper}>
        <EnhancedTableToolbar
          subcomponentName={subcomponentName}
          subcomponentHelpURI={subcomponentHelpURI}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead headCells={headCells} />

            <TableBody className={classes.tableBody}>
              <TableRow>
                <TableCell padding="default">
                  <Grid item>
                    <Paper className={classes.tree}>
                      {controlGroupTags ? (
                        <TreeViewGroup
                          permissions={permissions}
                          tags={controlGroupTags}
                          dialogTags={loadDialogTags()}
                          onHandleClickTag={handleClickTag}
                          onHandleSaveTag={handleSaveTag}
                        />
                      ) : null}
                    </Paper>

                    <AddTagDialog
                      tags={loadDialogTags()}
                      selectedTag={refTag.current}
                      treeItems={controlGroupTags}
                      requires={requires}
                      permissions={permissions}
                      onHandleSaveTag={handleSaveTag}
                    />
                    <RemoveTagDialog
                      deleteTag={deleteTag.current}
                      selectedTag={refTag.current}
                      requires={requires}
                      permissions={permissions}
                      onHandleRemoveTag={handleRemoveTag}
                    />
                  </Grid>
                </TableCell>
                <TableCell padding="default" align="center">
                  <Grid item>
                    {customList(controlsAssociated)}
                    <Fab
                      aria-label="Remove Parental Control Group"
                      className={classes.fab}
                      size="small"
                      onClick={handleRemoveControlAssociated}
                      disabled={
                        selectedAssociated.length === 0 ||
                        !permissions.includes(
                          `update:${requires.split(':').pop()}`
                        )
                      }
                    >
                      <Tooltip
                        title="Remove Parental Control Group"
                        TransitionComponent={Zoom}
                        arrow
                        placement="top"
                      >
                        <Remove />
                      </Tooltip>
                    </Fab>
                  </Grid>
                </TableCell>
                <TableCell padding="default" align="center">
                  <Grid item>
                    {customList(controlsNotAssociated)}
                    <Fab
                      aria-label="Add Parental Control Group"
                      className={classes.fab}
                      size="small"
                      onClick={handleAddControlNotAssociated}
                      disabled={
                        selectedNotAssociated.length === 0 ||
                        !permissions.includes(
                          `update:${requires.split(':').pop()}`
                        )
                      }
                    >
                      <Tooltip
                        title="Add Parental Control Group"
                        TransitionComponent={Zoom}
                        arrow
                        placement="top"
                      >
                        <Add />
                      </Tooltip>
                    </Fab>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <DialogActions>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(!openSnackbar)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={() => setOpenSnackbar(!openSnackbar)}
          >
            Oops! Something went wrong. Please, try again later.
          </MuiAlert>
        </Snackbar>
      </DialogActions>
    </Box>
  );
}
