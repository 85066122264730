import React, { FunctionComponent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

interface SearchItemProps {
  label: string;
  data: any;
  selectedItem: number;
  onSelectChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const useStyles = makeStyles({
  selectItem: {
    width: 'calc(100% - 100px)',
  },
});

export const SearchItem: FunctionComponent<SearchItemProps> = ({
  label,
  data,
  selectedItem,
  onSelectChange,
}: SearchItemProps) => {
  const classes = useStyles();
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '32px',
        justifyContent: 'space-between',
        marginBottom: '30px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        {label}
      </div>
      <Select
        className={classes.selectItem}
        value={selectedItem}
        defaultValue={0}
        onChange={(e) => onSelectChange(e)}
        displayEmpty
      >
        <MenuItem key={0} value={0}>
          All {label.toLowerCase()}s
        </MenuItem>
        {data.map((item: any) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default SearchItem;
