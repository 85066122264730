import React, { FunctionComponent, useEffect, useState } from 'react';
import { DialogActions, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Loader from 'app/common/Loader';
import { Admin } from 'app/admin/Admin';
import { getControls, TagType, Control } from 'app/services/apiTags';
import SubcomponentTable from './table/ControlGroup';

export interface HeadCell {
  id: string;
  label: string;
}

interface AdminControlGroupProps {
  userPermissions: string[];
  adminName: string;
  requires: string;
  filterByCompany: (tags: TagType[]) => TagType[];
  minDisplayOrder: number;
}

const AdminControlGroup: FunctionComponent<AdminControlGroupProps> = ({
  userPermissions,
  adminName,
  requires,
  filterByCompany,
  minDisplayOrder,
}: AdminControlGroupProps) => {
  const [controls, setControls] = useState<Control[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const headCells: HeadCell[] = [
    {
      id: 'selector',
      label: 'Group Name',
    },
    {
      id: 'selected',
      label: 'Group Controls',
    },
    {
      id: 'available',
      label: 'Available Controls',
    },
  ];

  useEffect(() => {
    (async () => {
      const [pControls] = await Promise.all([getControls()]);
      setControls(pControls as Control[]);

      setLoading(false);
    })().catch((error) => {
      // Error loading promise
      if (error.status === 400) {
        setLoading(false);
        setOpenSnackbar(!openSnackbar);
      }
    });
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <SubcomponentTable
        subcomponentName={adminName}
        controls={controls}
        requires={requires}
        permissions={userPermissions}
        filterByCompany={filterByCompany}
        headCells={headCells}
        minDisplayOrder={minDisplayOrder}
      />
      <DialogActions>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(!openSnackbar)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={() => setOpenSnackbar(!openSnackbar)}
          >
            Oops! Something went wrong. Please, try again later.
          </MuiAlert>
        </Snackbar>
      </DialogActions>
    </>
  );
};

const customGroupImplementation = (userPermissions: string[]): JSX.Element => {
  return (
    <AdminControlGroup
      userPermissions={userPermissions}
      adminName={adminCustomGroup.name}
      requires={adminCustomGroup.requires}
      filterByCompany={(tags: TagType[]): TagType[] =>
        tags.filter((item) => item.companyId !== null)
      }
      minDisplayOrder={100}
    />
  );
};

const globalGroupImplementation = (userPermissions: string[]): JSX.Element => {
  return (
    <AdminControlGroup
      userPermissions={userPermissions}
      adminName={adminGlobalGroup.name}
      requires={adminGlobalGroup.requires}
      filterByCompany={(tags: TagType[]): TagType[] =>
        tags.filter((item) => item.companyId === null)
      }
      minDisplayOrder={1}
    />
  );
};

export const adminCustomGroup: Admin = {
  name: 'Custom Control Groups',
  key: 'com.pharossecurity.com.admin.custom-groups',
  requires: 'read:config',
  implementation: customGroupImplementation,
};

export const adminGlobalGroup: Admin = {
  name: 'Global Control Groups',
  key: 'com.pharossecurity.com.admin.global-groups',
  requires: 'read:global',
  implementation: globalGroupImplementation,
};
