import React from 'react';
import { KPIGroup, KPI, TagType } from 'app/services/api';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { getKpiSubGroupProductivity } from 'app/utils/graph-math';
import { getHelpURN } from 'app/services/help';

const featureName =
  'dashboard/tag-dashboards/common-components/kpi-subgroup-summary';

type KPISubGroupSummary = {
  kpiGroup: KPIGroup;
  title: string;
  controlKpiValues: KPI[][][];
  selectedTag: TagType;
};

const KPISubGroupSummary = ({
  kpiGroup,
  title,
  controlKpiValues,
  selectedTag,
}: KPISubGroupSummary): JSX.Element => {
  const subGroups: KPIGroup[] = kpiGroup.subGroups as KPIGroup[];

  const getBarData = (subGroup: KPIGroup, subGroupIndex: number) => {
    const value = controlKpiValues.reduce(
      (productivity, nextControlKpiValues, index) => {
        const nextSubGroupKpiValues = nextControlKpiValues[subGroupIndex];
        const nextProductivity =
          productivity + getKpiSubGroupProductivity(nextSubGroupKpiValues);

        if (index === controlKpiValues.length - 1) {
          return Math.round(nextProductivity / controlKpiValues.length);
        }

        return nextProductivity;
      },
      0
    );

    const barColors = getBarColorsForValue(value);

    return [
      {
        id: subGroup.name,
        label: subGroup.name,
        value,
        colors: barColors.background,
        labelTextColor: barColors.textColor,
      },
    ];
  };

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading
            colSpan={2}
            helpURI={getHelpURN({
              context: 'dashboard',
              resource: kpiGroup.helpURI,
            })}
          >
            {selectedTag.name} {title}
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            {kpiHeaderTitle}
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {subGroups.map((subGroup, subGroupIndex) => {
            const barData = getBarData(subGroup, subGroupIndex);
            const isLast = subGroupIndex === subGroups.length - 1;

            return (
              <StripedTable.Row key={`${featureName}-${title}-${subGroup.key}`}>
                <StripedTable.GraphLabel
                  isLast={isLast}
                  colSpan={1}
                  style={{ width: '20%' }}
                >
                  {subGroup.name}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast} colSpan={1}>
                  <BarGraph
                    data={barData}
                    colors={barData[0].colors}
                    labelTextColor={barData[0].labelTextColor}
                    axisBottom={isLast ? {} : null}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default KPISubGroupSummary;
