import 'date-fns';
import React, { useState, FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

interface CalendarRangeProps {
  startLabel: string;
  endLabel: string;
  startDateLabel: string | null;
  endDateLabel: string | null;
  onHandleClickDate: (calendarDate: string, nameDate: string) => void;
}

const CalendarRange: FunctionComponent<CalendarRangeProps> = ({
  startLabel,
  endLabel,
  startDateLabel,
  endDateLabel,
  onHandleClickDate,
}: CalendarRangeProps) => {
  const [calendarState, setCalendarState] = useState<{
    startDate: string | number;
    endDate: string | number;
  }>({
    startDate: startDateLabel ? startDateLabel : new Date().toISOString(),
    endDate: endDateLabel ? endDateLabel : new Date().toISOString(),
  });

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const newDate = date.setHours(23, 59, 59, 0);

      setCalendarState({
        ...calendarState,
        startDate: new Date(newDate).toISOString(),
      });

      // Re-load the data
      onHandleClickDate(new Date(newDate).toISOString(), 'startDate');
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const newDate = date.setHours(23, 59, 59, 0);

      setCalendarState({
        ...calendarState,
        endDate: new Date(newDate).toISOString(),
      });

      // Re-load the data
      onHandleClickDate(new Date(newDate).toISOString(), 'endDate');
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          margin="normal"
          variant="inline"
          id="date-picker-start"
          label={startLabel}
          name="startDate"
          format="yyyy/MM/dd"
          value={calendarState.startDate}
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputProps={{ readOnly: true }}
          maxDate={new Date()}
          disableFuture={true}
        />
        <KeyboardDatePicker
          margin="normal"
          variant="inline"
          id="date-picker-end"
          label={endLabel}
          name="endDate"
          format="yyyy/MM/dd"
          value={calendarState.endDate}
          onChange={handleEndDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputProps={{ readOnly: true }}
          minDate={calendarState.startDate}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default CalendarRange;
