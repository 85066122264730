import React, { FunctionComponent } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  InputProps,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import MuiGrey from '@material-ui/core/colors/grey';
import {
  tableContainerStyles,
  headerStyles,
  subHeaderStyles,
} from 'app/kpi/components/tables';
import { StripedTableRow } from 'app/common/StripedTable';
import DescriptionAccordion from 'app/common/DescriptionAccordion';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { ThreatLevel } from 'app/services/apiThreatSurfaces';
import {
  HelpOutlineRounded,
  NoteOutlined,
  NoteTwoTone,
  LinkOutlined,
} from '@material-ui/icons';
import { KPIData } from 'app/kpi/types';
import { getCurrentUser } from 'app/services/auth';
import ValidatedInput from './ValidatedInput';
import { displayHelp } from 'app/services/help';
import { KPIContext } from 'app/services/api';

export interface BudgetColumn {
  text: string;
  type?: string;
  helpTooltip?: string;
  helpURI?: string;
  inputProps?: InputProps;
  wrap?: boolean;
  textAlign?: 'left' | 'right' | 'center';
  style?: {
    width?: string;
  };
}

export interface BudgetItem {
  level?: ThreatLevel;
  text: string;
  description?: string[];
  context?: string;
  kpis: Array<KPIData<any>>;
}

interface KPIZeroBasedTableProps extends BoxProps {
  contextKey: string;
  text: string;
  helpURI?: string;
  columns: BudgetColumn[];
  items: BudgetItem[];
  context?: KPIContext;
  headerTitle: string | null;
  onKPIChange?: (kpi: KPIData<any>, value: string) => void;
  errors: KPIData<any>[];
  onContextDialogOpen: (title: string, context?: KPIContext) => void;
}

const RowHeader = withStyles(() =>
  createStyles({
    root: {
      width: '75%',
    },
  })
)(({ children, ...props }) => (
  <TableCell {...props} component="th" scope="row">
    {children}
  </TableCell>
));

export const KPIZeroBasedTable: FunctionComponent<KPIZeroBasedTableProps> = ({
  contextKey,
  text,
  helpURI,
  columns,
  items,
  context,
  errors,
  headerTitle,
  onContextDialogOpen,
}: KPIZeroBasedTableProps) => {
  const currentUser = getCurrentUser();
  let colSpan = columns.length;
  if (!headerTitle) {
    colSpan--;
  }

  const showCapability = (
    summary: string | undefined, descriptions: string[] | undefined, rowIdx: number
  ) => {
    if (descriptions && descriptions.some((desc: string) => desc.length > 0)) {
      let style: any = {width: '100%'};
      if (rowIdx & 1) {
        style = {
          width: '100%',
          backgroundColor: MuiGrey[100]
        }
      }
      return (
        <DescriptionAccordion
          summary={summary ? summary : ""}
          details={descriptions}
          identifier={rowIdx}
          style={style}
        />
      );
    } else {
      return (
        <Typography variant="body2" component="span" style={{paddingLeft: '1rem'}}>
          {summary ? summary : ""}
        </Typography>
      );
    }
  };

  return (
    <Paper
      elevation={3}
      style={{ padding: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}
    >
      <div style={tableContainerStyles}>
        <Table>
          <TableHead>
            <TableRow style={headerStyles}>
              <TableCell colSpan={colSpan}>
                <Typography variant="h6">{text}</Typography>
              </TableCell>
              <TableCell
                colSpan={1}
                align="right"
                style={{ minWidth: '160px', padding: '0.2rem' }}
              >
                {context ? (
                  <IconButton
                    onClick={() => onContextDialogOpen(contextKey, context)}
                  >
                    <NoteTwoTone />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => onContextDialogOpen(contextKey, context)}
                  >
                    <NoteOutlined />
                  </IconButton>
                )}
                {context && context.contextURI && (
                  <IconButton
                    onClick={() => window.open(context.contextURI!, '_blank')}
                  >
                    <LinkOutlined />
                  </IconButton>
                )}
                <IconButton
                  disabled={!helpURI}
                  onClick={() => displayHelp(helpURI)}
                >
                  <HelpOutlineRounded />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow style={subHeaderStyles}>
              {headerTitle && <RowHeader>{headerTitle}</RowHeader>}
              {columns.map((col, i) => {
                return (
                  <TableCell key={i}>
                    <Box
                      display="flex"
                      justifyContent={col.textAlign ? col.textAlign : 'center'}
                    >
                      <Box mr={1 / 2}>{col.text}</Box>
                      {col.helpURI && (
                        <IconButton
                          size="small"
                          style={{ paddingRight: 0 }}
                          onClick={() => displayHelp(col.helpURI)}
                        >
                          <HelpOutlineRounded fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, i) => {
              let threatLevelCell;

              if (item.level) {
                threatLevelCell = (
                  <TableCell
                    style={{ width: '15%' }}
                  >{`${item.level.severity}. ${item.level.name}`}</TableCell>
                );
              }

              return (
                <StripedTableRow key={i} style={{ whiteSpace: 'pre-line' }}>
                  {threatLevelCell}
                  <RowHeader>
                    <Box display="flex" alignItems="center">
                      {showCapability(item.text, item.description, i)}
                      {item.context && (
                        <Box flex={1}>
                          <Typography component="span" variant="body2">
                            {item.context}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </RowHeader>
                  {item.kpis.map((kpi, i) => {
                    const columnIdx = i + columns.length - item.kpis.length;
                    return (
                      <TableCell align="center" key={i}>
                        <ValidatedInput
                          currentUser={currentUser}
                          column={columns[columnIdx]}
                          error={errors.includes(kpi)}
                          kpi={kpi}
                        />
                      </TableCell>
                    );
                  })}
                </StripedTableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default KPIZeroBasedTable;
