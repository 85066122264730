import { threatSurfaceState } from 'app/common/actions/threat-surfaces';
import { threatLevelState } from 'app/common/actions/threat-levels';
import { KPI, KPIGroup as KpiGroupType, TagType } from 'app/services/api';
import { ThreatLevel, ThreatSurface } from 'app/services/apiThreatSurfaces';
import { getCurrentUser } from 'app/services/auth';
import {
  getSurfaceTargetBudget,
  getLevelEstimatedBudgetSum,
} from 'app/utils/graph-math';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';
import {
  tableContainerWithMarginStyles,
  headerStyles,
  getColWidth,
  firstColStyles,
  lastColStyles,
} from 'app/kpi/components/tables';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { ControlKpiGroupDataType } from '.';
import { displayHelp } from 'app/services/help';
import React from 'react';
import { IconButton } from '@material-ui/core';
import { HelpOutlineRounded } from '@material-ui/icons';

const featureName =
  'dashboard/tag-dashboards/summary/estimated-threat-preparedness-matrix';

type EstimatedThreatPreparednessMatrixType = {
  kpiGroups: KpiGroupType[];
  threatSurfaces: threatSurfaceState;
  threatLevels: threatLevelState;
  selectedTag: TagType;
  budgetEstimators: KPI[][];
  targetBudgets: KPI[];
  controlKpiGroupData: ControlKpiGroupDataType[];
};

const EstimatedThreatPreparednessMatrix = ({
  kpiGroups,
  threatSurfaces,
  threatLevels,
  selectedTag,
  budgetEstimators,
  targetBudgets,
  controlKpiGroupData,
}: EstimatedThreatPreparednessMatrixType) => {
  const kpiGroup = kpiGroups.find(
    (group: KpiGroupType) => group.path === 'investment'
  );

  if (!kpiGroup) {
    return <div>Invalid KPI Group</div>;
  }

  const currency = getCurrentUser()?.currency;
  const locale = currency?.locale;

  let tableHeadings = [
    {
      display: 'Threat Level',
      style: firstColStyles,
    },
  ];

  const numberOfThreatSurfaces = (threatSurfaces.payload as ThreatSurface[])
    .length;
  const coLWidth = getColWidth(numberOfThreatSurfaces);

  const tableData = ((threatLevels.payload as ThreatLevel[]) || []).map(
    (tLevel: ThreatLevel, tLevelIndex: number) => {
      let rowData: any = [`${tLevel.severity}. ${tLevel.name}`];

      rowData = rowData.concat(
        ((threatSurfaces.payload as ThreatSurface[]) || []).map(
          (tSurface: ThreatSurface) => {
            tableHeadings = !tLevelIndex
              ? tableHeadings.concat({
                  display: tSurface.name,
                  style: { width: coLWidth },
                })
              : tableHeadings;

            const budgetSum = controlKpiGroupData.reduce(
              (results, control, index) => {
                const targetBudget = targetBudgets[index];
                const budgetEstimator = budgetEstimators[
                  index
                ].filter((budgetKpi) => budgetKpi.key.includes('cost'));

                if (!targetBudget) {
                  return results;
                }

                const levelEstimatedBudget = getLevelEstimatedBudgetSum(
                  tLevel,
                  budgetEstimator
                );
                const surfaceTargetBudget: any = getSurfaceTargetBudget(
                  tSurface,
                  targetBudget
                );

                const estimatedBudgetSum = Math.round(
                  (levelEstimatedBudget * surfaceTargetBudget) / 100
                );

                return results + estimatedBudgetSum;
              },
              0
            );

            return budgetSum;
          }
        )
      );

      const rowSum = rowData
        .slice(1)
        .reduce((prev: number, next: number) => prev + next, 0);
      rowData = rowData.concat(rowSum);

      rowData = rowData.map((data: string | number, index: number) => {
        let style = {};
        let display = data;

        if (data.constructor === Number) {
          display = data.toLocaleString(locale, currency);
        }

        if (index === rowData.length - 1) {
          style = {
            backgroundColor: MuiLightBlue[900],
            color: 'white',
          };
        }

        return { display, style };
      });

      return rowData;
    }
  );
  tableHeadings = tableHeadings.concat({
    display: 'Row Sum',
    style: lastColStyles,
  });

  const helpURI = '';

  return (
    <Paper
      elevation={3}
      style={{ padding: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}
    >
      <div style={tableContainerWithMarginStyles}>
        <Table size="small">
          <TableHead>
            <TableRow style={headerStyles}>
              <TableCell
                colSpan={
                  ((threatSurfaces.payload as ThreatSurface[]) || []).length + 1
                }
              >
                <Typography variant="h6">{`${selectedTag.name} Estimated Threat Preparedness Costs for Complete Coverage`}</Typography>
              </TableCell>
              <TableCell colSpan={1} align="right" width="60px">
                <IconButton
                  disabled={!helpURI}
                  onClick={() => displayHelp(helpURI)}
                >
                  <HelpOutlineRounded />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              {tableHeadings.map((heading, idx) => (
                <TableCell
                  align={idx ? 'center' : 'left'}
                  key={`${featureName}/thead/${heading.display}`}
                  style={heading.style}
                >
                  {heading.display}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData.map((rowData, rowIndex) => (
              <TableRow key={`${featureName}/tbody/row/${rowIndex}`}>
                {rowData.map((cellData: any, cellIndex: number) => (
                  <TableCell
                    key={`${featureName}/tbody/row/${rowIndex}/cell/${cellIndex}`}
                    style={cellData.style}
                    align={cellIndex ? 'center' : 'left'}
                  >
                    {cellData.display}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default EstimatedThreatPreparednessMatrix;
