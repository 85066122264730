const store = {
  get<T>(key: string): T | null {
    const rawData = localStorage.getItem(key);
    if (!rawData) {
      return null;
    }
    return JSON.parse(rawData) as T;
  },
  set<T>(key: string, data: T): void {
    localStorage.setItem(key, JSON.stringify(data));
  },
  remove(key: string): void {
    localStorage.removeItem(key);
  },
  clear(): void {
    localStorage.clear();
  },
  addEventListener(listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
    window.addEventListener('storage', listener);
  },
  removeEventListener(listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
    window.removeEventListener('storage', listener);
  },
};

export default store;
