import { getInitState } from 'app/utils/async-middleware';
import { actionTypes } from 'app/common/actions/controls';

const initState = getInitState();

export const controlsReducer = (
  state = { ...initState, subControls: { ...initState } },
  action: any
) => {
  switch (action.type) {
    case actionTypes.fetchControls: {
      const { type, ...restAction } = action;

      return {
        ...state,
        ...restAction,
      };
    }

    case actionTypes.resetControls:
      return { ...initState };

    case actionTypes.fetchSubControls: {
      const { type, ...restAction } = action;

      return {
        ...state,
        subControls: {
          ...state.subControls,
          ...restAction,
        },
      };
    }

    default:
      return state;
  }
};

export const controlReducer = (state = { ...initState }, action: any) => {
  switch (action.type) {
    case actionTypes.fetchControlById: {
      const { type, ...restAction } = action;

      return {
        ...state,
        ...restAction,
      };
    }

    default:
      return state;
  }
};
