import React from 'react';
import { threatLevelState } from 'app/common/actions/threat-levels';
import { TagType } from 'app/services/api';
import { ThreatLevel } from 'app/services/apiThreatSurfaces';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  getHighLowColours,
  findMaxProductivityValue,
} from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { AllocationLevel } from 'app/utils/budget';

const featureName =
  'dashboard/tag-dashboards/investment/budget-productivity-by-level';

type BudgetProductivityByLevelType = {
  selectedTag: TagType;
  threatLevels: threatLevelState;
  levelBudgets: AllocationLevel[][];
  secOpsProductivity: number[]
};

const BudgetProductivityByLevel = ({
  selectedTag,
  threatLevels,
  levelBudgets,
  secOpsProductivity
}: BudgetProductivityByLevelType): JSX.Element => {
  const threatLevelPayload = threatLevels.payload as ThreatLevel[];
  if (!threatLevelPayload) {
    return <div />;
  }
  const dataKeys = ['highProductivity', 'lowProductivity'];

  const barData = threatLevelPayload.map((threatLevel) => {
    let totalHighProductivity = 0;
    let totalLowProductivity = 0;
    levelBudgets.forEach((controlBudgets: AllocationLevel[], controlIndex: number) => {
      const levelBudget = controlBudgets.find(bLevel => bLevel.levelId === threatLevel.id);
      const levelBudgetValue = levelBudget ? levelBudget.value : 0;
      const highProductivity = Math.round(
        levelBudgetValue * (Math.round(secOpsProductivity[controlIndex]) / 100)
      );
      const lowProductivity = levelBudgetValue - highProductivity;
      totalHighProductivity += highProductivity;
      totalLowProductivity += lowProductivity;
    });
    return {
      id: threatLevel.id,
      label: `${threatLevel.severity}. ${threatLevel.name}`,
      highProductivity: totalHighProductivity,
      highProductivityColour: green,
      lowProductivity: totalLowProductivity,
      lowProductivityColour: orange,
    };
  });

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2} helpURI="">
            {selectedTag.name} Budget Productivity by Threat Level
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            Threat Level
          </StripedTable.SubHeading>
        </StripedTable.Head>
      </StripedTable>
      <StripedTable>
        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel
                  style={{ width: '20%' }}
                  isLast={isLast}
                  colSpan={1}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast} colSpan={1} style={{ width: '80%' }}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting, tickValues: 7 } : null}
                    maxValue={findMaxProductivityValue(barData)}
                    labelFormat={dollarFormatting}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityByLevel;
