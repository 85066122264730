import 'date-fns';
import React, { useState, FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { CalendarContext } from '../dashboard/MainContent';

interface CalendarProps {
  calendarLabel: string;
  onHandleClickCalendarDate: (calendarDate: string) => void;
}

const Calendar: FunctionComponent<CalendarProps> = ({
  calendarLabel,
  onHandleClickCalendarDate,
}: CalendarProps) => {
  const calendarDate = React.useContext(CalendarContext);
  const [selectedDate, setSelectedDate] = useState<string | number>(
    calendarDate
  );

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const newDate = date.setHours(23, 59, 59, 0);

      setSelectedDate(newDate);
      // Re-load the data
      onHandleClickCalendarDate(new Date(newDate).toISOString());
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="flex-end">
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label={calendarLabel}
          format="yyyy/MM/dd"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputProps={{ readOnly: true }}
          maxDate={new Date()}
          disableFuture={true}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default Calendar;
