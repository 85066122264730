import React from 'react';

import Hint1KpiGroup from './components/Hint1KpiGroup';

const ResourcesDashboard = (): JSX.Element => (
  <Hint1KpiGroup
    title="Resources Summary"
    subGroupTitle="Resources KPI Summary"
  />
);

export default ResourcesDashboard;
