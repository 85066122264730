import { createStyles, makeStyles } from '@material-ui/core/styles';
import MuiGrey from '@material-ui/core/colors/grey';

const iconClickedStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: 14,
      backgroundColor: MuiGrey[400],
    },
  })
);

export default iconClickedStyles;
