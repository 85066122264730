import React from 'react';
import ReactDOM from 'react-dom';
import fetchJson from 'fetch.json';
import URLParse from 'url-parse';
import { getHelpUrl } from './url';
import { processDeauthentication } from 'app/services/auth';

export interface HelpParams {
  context: string;
  resource: string | undefined;
}

export const getHelpURN = (params: string | HelpParams): string => {
  let urn = '';
  if (typeof params === 'string') {
    const str = URLParse(params, {}).pathname;
    const pos = str.lastIndexOf('/');
    if (pos === -1) {
      urn = `urn:help:context::resource:${str}`;
    } else {
      urn = `urn:help:context:${str.slice(0, pos)}:resource:${str.slice(
        pos + 1
      )}`;
    }
  } else if (params.resource) {
    urn = `urn:help:context:${params.context}:resource:${params.resource}`;
  }
  return urn;
};

const getHelpPath = (helpUrn: string) => {
  const parts = helpUrn.toLocaleLowerCase().split(':');
  if (
    parts.length == 6 &&
    parts[0] === 'urn' &&
    parts[1] === 'help' &&
    parts[2] === 'context' &&
    parts[4] === 'resource'
  ) {
    return `${parts[3]}/${parts[5]}`;
  }
};

const getSecureFile = (url: string): Promise<any> | undefined => {
  const headers = new Headers();
  headers.set('Authorization', fetchJson.headers().authorization);
  return httpRequest(getHelpUrl(url), 'GET', null, headers);
};

export const getHelpFile = (urn: string): Promise<any> | undefined => {
  const helpPath = getHelpPath(urn);
  if (helpPath) {
    return getSecureFile(helpPath);
  }
};

export const displayHelp = (
  urn: string | HelpParams | undefined,
  elementId = 'help-hook'
) => {
  if (urn) {
    if (typeof urn === 'object') {
      urn = getHelpURN(urn);
    }
    const el = document.getElementById(elementId);
    if (el) {
      displayHelpInPopup(urn, el);
    }
  }
};

export const displayHelpInPopup = async (helpUrn: string, el: Element) => {
  const helpFile = await getHelpFile(helpUrn);
  if (helpFile) {
    const help = await renderDocument(helpFile);
    const hw = (
      <RenderInHelpWindow
        html={helpFile}
        help={help}
        onClose={() => {
          ReactDOM.unmountComponentAtNode(el);
        }}
        onClick={(urn: string) => {
          displayHelpInPopup(urn, el);
        }}
      ></RenderInHelpWindow>
    );
    ReactDOM.render(hw, el);
  }
};

const renderDocument = async (html: string) => {
  const href = window.location.href;
  const base = href.slice(0, href.lastIndexOf('/') + 1);

  const doc = new DOMParser().parseFromString(html, 'text/html');
  const img = doc.getElementsByTagName('img');
  for (let i = 0; i < img.length; i++) {
    const path = img[i].src;
    if (path.startsWith(base)) {
      const data = await getSecureFile(path.slice(base.length));
      const buff = await data.arrayBuffer();
      const base64String = btoa(String.fromCharCode(...new Uint8Array(buff)));
      img[i].src = `data:${data.type};base64,${base64String}`;
    }
  }

  const anchor = doc.getElementsByTagName('a');
  for (let i = 0; i < anchor.length; i++) {
    const path = anchor[i].href;
    if (path.startsWith(base)) {
      anchor[i].classList.add('pharos-help');
      anchor[i].href = getHelpURN(path.slice(base.length));
    }
  }
  return doc;
};

export const RenderInHelpWindow = (props: any) => {
  const [html, setHtml] = React.useState<string | null>(null);
  const [container, setContainer] = React.useState<Element | null>(null);

  const [popup, setPopup] = React.useState<Window | null>(null);
  if (popup) {
    popup.focus();
  }

  React.useEffect(() => {
    // Stash HTML
    setHtml(props.html);
    // Create container element on client-side
    setContainer(document.createElement('div'));
    // Create window
    if (!popup) {
      const w = window.open('', '', 'width=600,height=400,left=200,top=200');
      const timer = setInterval(() => {
        if (w?.closed) {
          clearInterval(timer);
          if (props.onClose) {
            props.onClose();
          }
        }
      }, 1000);
      setPopup(w);
    }
  }, [props.html]);

  React.useEffect(() => {
    // When container is ready
    if (html && container && popup && props.help) {
      const doc = popup.document;
      // Copy HTML
      doc.head.innerHTML = props.help.head.innerHTML;
      doc.body.innerHTML = props.help.body.innerHTML;
      // Append container
      doc.body.appendChild(container);
      // Attach click event handlers
      if (props.onClick) {
        const anchor = doc.getElementsByTagName('a');
        for (let i = 0; i < anchor.length; i++) {
          if (anchor[i].classList.contains('pharos-help')) {
            const urn = anchor[i].href;
            //anchor[i].href = 'javascript:void(0)';
            anchor[i].addEventListener('click', (e) => {
              e.preventDefault();
              props.onClick(urn);
            });
          }
        }
      }
    }
  }, [html, container, popup]);

  return container && ReactDOM.createPortal(props.children, container);
};

export type UnauthorizedErrorHandler = (
  response: Response
) => Promise<UnauthorizedErrorHandlerResponse>;
export type UnauthorizedErrorHandlerResponse = string | undefined | null;

let unauthorizedErrorHandlerPromise: null | Promise<UnauthorizedErrorHandlerResponse> = null;
let unauthorizedErrorHandler: UnauthorizedErrorHandler;

export const setUnauthorizedHelpErrorHandler = (
  handler: UnauthorizedErrorHandler
): UnauthorizedErrorHandler => (unauthorizedErrorHandler = handler);

const parseHttpResponse = (response: Response): Promise<any> => {
  let data;
  const contentType = response.headers.get('Content-Type') || '';
  if (contentType.startsWith('application/json')) {
    data = response.json();
  } else if (contentType.startsWith('text/html')) {
    data = response.text();
  } else if (contentType.startsWith('image/')) {
    data = response.blob();
  } else {
    return Promise.reject(response);
  }
  return data;
};

export const httpRequest = (
  url: string,
  method: string,
  body: string | null,
  headers: Headers | null
): Promise<any> => {
  const config: RequestInit = {
    method,
    headers: headers || new Headers(),
  };
  if (method.toLowerCase() === 'post' && body && body.length > 0) {
    config.body = JSON.stringify(body);
  }
  return Promise.resolve(unauthorizedErrorHandlerPromise)
    .then(() => fetch(url, config))
    .then((response) => {
      if (response.ok) {
        return parseHttpResponse(response);
      } else if (response.status === 401 && unauthorizedErrorHandler) {
        unauthorizedErrorHandlerPromise =
          unauthorizedErrorHandlerPromise || unauthorizedErrorHandler(response);
        return unauthorizedErrorHandlerPromise
          .then((authHeader: UnauthorizedErrorHandlerResponse) => {
            if (typeof authHeader !== 'string') {
              return Promise.reject(response);
            }
            headers?.set('Authorization', authHeader);
            return fetch(url, config);
          })
          .then((response) => {
            if (response.ok) {
              return parseHttpResponse(response);
            } else if (response.status === 401) {
              // We've got 401 again either during refreshing the token or
              // retrying request, we have no option now but to kick the
              // user to login screen.
              processDeauthentication(window.location.origin);
            }
          })
          .finally(() => (unauthorizedErrorHandlerPromise = null));
      }
    })
    .catch((response) => {
      return Promise.reject(response);
    });
};

export default httpRequest;
