import React, { Fragment } from 'react';
import { Control, KPIGroup, KPI, KPIValue } from 'app/services/api';
import { ThreatLevel, ThreatSurface } from 'app/services/apiThreatSurfaces';
import Loader from 'app/common/Loader';
import { getKPIsAndValuesForGroup } from 'app/utils/helpers';
import { getColWidth } from 'app/kpi/components/tables';
import { DeltaContext } from '../Delta';
import CalendarRange from 'app/common/CalendarRange';
import TableDeltaInput from './TableDeltaInput';
import TableDeltaChange from './TableDeltaChange';


type TableDeltaType = {
  kpiGroups: KPIGroup[];
  controls: Control[];
  selectedControl: Control,
  tableDeltaTitle: string;
  tableDeltaRows: ThreatLevel[];
  tableDeltaColumns: ThreatSurface[];
  tableDeltaState: any;
};

const TableDelta = ({
  kpiGroups,
  controls,
  selectedControl,
  tableDeltaTitle,
  tableDeltaRows,
  tableDeltaColumns,
  tableDeltaState,
}: TableDeltaType) => {
  const selectedControls = selectedControl ? [selectedControl] : controls;
  const numberOfTableColumns = tableDeltaColumns.length;
  const colWidth = getColWidth(numberOfTableColumns);
  const deltaContext = React.useContext(DeltaContext);

  const handleClickDate = (newDate: string, name: string) => {
    if (name === "startDate") {
      deltaContext.startDate = newDate;
      updateCalendarStart();
    } else {
      deltaContext.endDate = newDate;
      updateCalendarEnd();
    }
  };
  const securityKpiGroup = kpiGroups.find(
    (grp: KPIGroup) => grp.path === 'security-coverage'
  );
  const [loading, setLoading] = React.useState<boolean>(true);
  const [startTable, setStartTable] = React.useState<any>([]);
  const [endTable, setEndTable] = React.useState<any>([]);
  const [changeTable, setChangeTable] = React.useState<any>([]);

  const getAllTableData = (securityCoverageData: KPI[][] | null): any => {
    return securityCoverageData?.reduce((result: any, next) => {
      const scopeValues = next.filter(
        (grp) => grp.key === 'com.pharossecurity.security-coverage.scope'
      )[0];
      const coverageValues = next.filter(
        (grp) => grp.key === 'com.pharossecurity.security-coverage.coverage'
      )[0];

      // Starting from the first threat level
      const controlTableData = tableDeltaRows.map((tRow) => {
        const rowData: any = [];
        const scope =
          scopeValues?.kpiValue.filter(
            (kpi: any) =>
              kpi?.levelId === tRow.id &&
              kpi?.kpi.key === 'com.pharossecurity.security-coverage.scope'
          ) || [];
        const coverage =
          coverageValues?.kpiValue.filter(
            (kpi: any) =>
              kpi?.levelId === tRow.id &&
              kpi?.kpi.key === 'com.pharossecurity.security-coverage.coverage'
          ) || [];

        // Add default scope value if the scope hasn't been set
        if (scope.length < numberOfTableColumns) {
          tableDeltaColumns.forEach((surface) => {
            const hasSurface = scope.find(
              (ele: any) => ele.surfaceId === surface.id
            );
            if (!hasSurface) {
              const defaultScope: KPIValue = {} as any;
              defaultScope.surfaceId = surface.id;
              defaultScope.value = '0';
              defaultScope.levelId = tRow.id;
              scope.push(defaultScope);
            }
          });
        }

        scope.sort((a: any, b: any) => a?.surfaceId - b?.surfaceId);

        scope?.forEach((kpi: any) => {
          const surface = tableDeltaColumns.find(
            (surface) => surface.id === kpi?.surfaceId
          );
          if (surface) {
            const coverageLevel: any = coverage?.find(
              (coverageKpi: any) => coverageKpi?.surfaceId === kpi?.surfaceId
            );
            const index = tableDeltaColumns.indexOf(surface);
            const value =
              (Number(kpi?.value) * Number(coverageLevel?.value || 0)) / 100;
            rowData[index] = Math.round(value);
          }
        });

        // Insert threat level label to the beginning of the array
        rowData.splice(0, 0, `${tRow.severity}. ${tRow.name}`);

        return rowData;
      });

      return result.concat([controlTableData]);
    }, []);
  };

  const getTableAverages = (allTableData: any): any => {
    return allTableData.reduce(
      (result: any, controlTableData: any, tableIndex: number) => {
        const newResult = result.slice();

        controlTableData.forEach((row: any, rowIndex: number) => {
          row.forEach((col: any, colIndex: number) => {
            if (newResult[rowIndex]) {
              if (col.constructor === Number) {
                if (newResult[rowIndex][colIndex]) {
                  newResult[rowIndex][colIndex] =
                    newResult[rowIndex][colIndex] + col;
                } else {
                  newResult[rowIndex][colIndex] = col;
                }
                if (tableIndex === allTableData.length - 1) {
                  newResult[rowIndex][colIndex] = Math.round(
                    newResult[rowIndex][colIndex] / allTableData.length
                  );
                }
              }
            } else {
              newResult[rowIndex] = [];
              newResult[rowIndex][colIndex] = col;
            }
          });
        });
        return newResult;
      },
      []
    );
  };

  const getTableDifference = (newResult: any) => {
    startTable.forEach((row: any, rowIndex: number) => {
      row.forEach((col: any, colIndex: number) => {
        if (col.constructor === Number) {
          newResult[rowIndex][colIndex] = Number(newResult[rowIndex][colIndex]) - Number(col)
        }
      });
    });
    return newResult;
  };


  const updateCalendarStart = () => {
    if (!securityKpiGroup) {
      return;
    }

    (async () => {
      // Get security coverage
      const securityCoverage: KPI[][] = await getKPIsAndValuesForGroup(
        selectedControls,
        securityKpiGroup.key,
        deltaContext.startDate
      );

      const allTableDataStart = getAllTableData(securityCoverage);
      const newStartTable = getTableAverages(allTableDataStart);
      deltaContext.startTable = newStartTable;
      deltaContext.preparednessStart = allTableDataStart;
      setStartTable(newStartTable);
      setLoading(false);
    })();
  };
  React.useEffect(() => {
    if (deltaContext.startTable && deltaContext.startTable.length) {
      setStartTable(deltaContext.startTable);
    } else {
      updateCalendarStart();
    }
  }, []);

  const updateCalendarEnd = () => {
    if (!securityKpiGroup) {
      return;
    }

    const endDate =
      new Date(deltaContext.endDate) > new Date()
          ? new Date().toISOString()
          : deltaContext.endDate;
    (async () => {
      // Get security coverage
      const securityCoverage: KPI[][] = await getKPIsAndValuesForGroup(
        selectedControls,
        securityKpiGroup.key,
        endDate
      );

      const allTableDataEnd = getAllTableData(securityCoverage);
      const newEndTable = getTableAverages(allTableDataEnd);
      deltaContext.endTable = newEndTable;
      deltaContext.preparednessEnd = allTableDataEnd;
      setEndTable(newEndTable);
      setLoading(false);
    })();
  };

  React.useEffect(() => {
    if (deltaContext.endTable && deltaContext.endTable.length) {
      setEndTable(deltaContext.endTable);
    } else {
      updateCalendarEnd();
    }
  }, []);

  React.useEffect(() => {
    if (startTable && endTable && startTable.length > 0 && endTable.length > 0) {
      const tableDifference = endTable.map((row: any) => { return [...row] });
      getTableDifference(tableDifference);
      deltaContext.changeTable = tableDifference;
      setChangeTable(tableDifference);
      setLoading(false);
    }
  }, [startTable, endTable]);

  const onHandleChangeData = (tableType: string, idxRow: number, idxCol: number, newValue: number) => {
    if (tableType == 'start') {
      const updatedStartTable = startTable.slice();
      updatedStartTable[idxRow][idxCol] = newValue;
      deltaContext.startTable = updatedStartTable;
      deltaContext.preparednessStart.forEach((controlStart: any) => {
        controlStart[idxRow][idxCol] = newValue;
      });
      setStartTable(updatedStartTable);
    } else {
      const updatedEndTable = endTable.slice();
      updatedEndTable[idxRow][idxCol] = newValue;
      deltaContext.endTable = updatedEndTable;
      deltaContext.preparednessEnd.forEach((controlEnd: any) => {
        controlEnd[idxRow][idxCol] = newValue;
      });

      setEndTable(updatedEndTable);
    }
  };

  if (loading || !startTable || !endTable || !startTable.length || !endTable.length) {
    return <Loader />;
  }

  return (
    <Fragment>
      <CalendarRange
        startLabel="Start Date"
        endLabel="End Date"
        startDateLabel={deltaContext.startDate}
        endDateLabel={deltaContext.endDate}
        onHandleClickDate={handleClickDate}
      />
      <TableDeltaInput
        numberOfTableColumns={numberOfTableColumns}
        colWidth={colWidth}
        tableDeltaTitle={`START - ${tableDeltaTitle}`}
        tableDeltaColumns={tableDeltaColumns}
        tableDeltaState={tableDeltaState}
        tableDeltaType="start"
        onHandleChangeData={onHandleChangeData}
        inputTable={startTable}
      />
      <TableDeltaInput
        numberOfTableColumns={numberOfTableColumns}
        colWidth={colWidth}
        tableDeltaTitle={`END - ${tableDeltaTitle}`}
        tableDeltaColumns={tableDeltaColumns}
        tableDeltaState={tableDeltaState}
        tableDeltaType="end"
        onHandleChangeData={onHandleChangeData}
        inputTable={endTable}
      />
      <TableDeltaChange
        tableDeltaTitle={`CHANGE - ${tableDeltaTitle}`}
        tableDeltaColumns={tableDeltaColumns}
        tableDeltaState={tableDeltaState}
        changeTable={changeTable}
        dataType="percentage"
        useColors={true}
      />
    </Fragment>
  );
};

export default TableDelta;
