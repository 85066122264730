// @ts-nocheck
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import asyncMiddleware from 'app/utils/async-middleware';
import rootReducer from './rootReducer';

const enhancedComposer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState = {};

const store = createStore(
  rootReducer,
  initialState,
  enhancedComposer(applyMiddleware(thunkMiddleware, asyncMiddleware))
);

export default store;
