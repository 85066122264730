import { KPI, KPIGroup } from 'app/services/api';
import { KPIDisplay } from 'app/kpi/types/KPIDisplay';

export class KPIGroupDisplay {
  readonly kpiGroup: KPIGroup;
  readonly kpis: KPIDisplay[];
  readonly _text: string;

  constructor(kpiGroup: KPIGroup, kpis: KPI[], text?: string) {
    this.kpiGroup = kpiGroup;

    // TODO: filter is temporary until deprecated budget split is removed from database
    this.kpis = kpis
      .filter(kpi => kpi.key != 'com.pharossecurity.security-coverage.budget-split')
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map((kpi) => new KPIDisplay(kpi));
    this._text = text || '';
  }

  get key() {
    return this.kpiGroup.key;
  }

  get name() {
    return this.kpiGroup.name;
  }

  get helpURI() {
    return this.kpiGroup.helpURI;
  }

  get hint() {
    return this.kpiGroup.hint;
  }

  get text() {
    return this._text || this.kpiGroup.name;
  }

  findKPI(key: string) {
    return this.kpis.find((kpi) => kpi.key === key);
  }

  static find(groups: KPIGroupDisplay[], key: string) {
    return groups.find((group) => group.key === key);
  }
}

export enum GroupKeys {
  TotalBudget = 'com.pharossecurity.investment.total-budget',
  HumanResources = 'com.pharossecurity.investment.human-resources',
  BudgetAllocation = 'com.pharossecurity.investment.budget-allocation',
  BudgetEstimator = 'com.pharossecurity.investment.budget-estimator',
  TimeAllocation = 'com.pharossecurity.investment.time-allocation',
  TimeAllocationCurrent = 'com.pharossecurity.investment.time-allocation.current',
  TimeAllocationIdeal = 'com.pharossecurity.investment.time-allocation.target',
  BudgetAllocationToSurfaces = 'com.pharossecurity.investment.budget-allocation-to-surfaces',
  BudgetEstimatorPeople = 'com.pharossecurity.investment.budget-estimator.people-quantity',
  BudgetAllocationToThreatLevels = 'com.pharossecurity.zero-based.allocation-to-levels',
  ZeroBasedBudgetAllocationToSurfaces = 'com.pharossecurity.zero-based.allocation-to-surfaces',
}
