import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from 'app/common/Loader';
import { Admin } from 'app/admin/Admin';
import {
  getThreatSurfaces,
  getThreatSurfaceType,
  ThreatSurfaceType,
  ThreatSurface,
} from 'app/services/apiThreatSurfaces';
import SubcomponentTable from './table/ThreatSurfaces';

export interface HeadCell {
  id: string;
  label: string;
}

interface AdminThreatSurfacesProps {
  userPermissions: string[];
}

const AdminThreatSurfaces: FunctionComponent<AdminThreatSurfacesProps> = ({
  userPermissions,
}: AdminThreatSurfacesProps) => {
  const [surfaces, setSurfaces] = useState<ThreatSurface[] | null>(null);
  const [surfaceTypes, setSurfaceTypes] = useState<ThreatSurfaceType[] | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);

  const headCells: HeadCell[] = [
    {
      id: 'name',
      label: 'Threat Surface Name',
    },
    {
      id: 'description',
      label: 'Threat Surface Description',
    },
    {
      id: 'type',
      label: 'Threat Surface Type',
    },
  ];

  useEffect(() => {
    (async () => {
      const [surfaces, types] = await Promise.all([
        getThreatSurfaces(),
        getThreatSurfaceType(),
      ]);
      setSurfaces(surfaces as ThreatSurface[]);
      setSurfaceTypes(types as ThreatSurfaceType[]);

      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <SubcomponentTable
      subcomponentName={adminThreatSurfaces.name}
      surfaces={surfaces}
      threatTypes={surfaceTypes}
      headCells={headCells}
      permissions={userPermissions}
    />
  );
};

const subcomponentImplementation = (userPermissions: string[]): JSX.Element => {
  return <AdminThreatSurfaces userPermissions={userPermissions} />;
};

export const adminThreatSurfaces: Admin = {
  name: 'Threat Surfaces',
  key: 'com.pharossecurity.com.admin.threat-surfaces',
  requires: 'read:config',
  implementation: subcomponentImplementation,
};
