import React, { FunctionComponent, useState, useEffect } from 'react';
import { Container, Box } from '@material-ui/core';
import { getCurrentUser } from 'app/services/auth';
import { Admin } from 'app/admin/Admin';
import NavAdminMenu from 'app/admin/components/NavAdminMenu';
import * as AdminSubcomponentsList from 'app/admin/components/subcomponents';

const MainContent: FunctionComponent = () => {
  const [adminSubcomponents, setAdminSubcomponents] = useState<Admin[] | null>(
    null
  );
  const [
    activeAdminSubcomponent,
    setActiveAdminSubcomponent,
  ] = useState<Admin | null>(null);

  const permissions: string[] = getCurrentUser()?.permissions || [];
  const subcomponents: any = [];
  // Sorting alphabetically and Checking permissions
  AdminSubcomponentsList.List.sort((a, b) => a.name.localeCompare(b.name)).map(
    (adminSubcomp) => {
      if (permissions.includes(adminSubcomp.requires)) {
        subcomponents.push(adminSubcomp);
      }
    }
  );

  useEffect(() => {
    setAdminSubcomponents(subcomponents as Admin[]);
    setActiveAdminSubcomponent(subcomponents ? subcomponents[0] : null);
  }, []);

  const handleAdminSubcomponentSelection = (adminSubcomponent: Admin) => {
    setActiveAdminSubcomponent(adminSubcomponent);
  };

  if (!adminSubcomponents || !activeAdminSubcomponent) {
    return null;
  }

  return (
    <Container maxWidth={false}>
      <Box display="flex" mt={2}>
        {/* Admin Subcomponents Side Menu */}
        <NavAdminMenu
          adminSubcomponents={adminSubcomponents}
          activeAdminSubcomponent={activeAdminSubcomponent}
          handleAdminSubcomponentSelection={handleAdminSubcomponentSelection}
        />
        {/* Subcomponent Content */}
        {activeAdminSubcomponent?.implementation(permissions)}
      </Box>
    </Container>
  );
};

export default MainContent;
