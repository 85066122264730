import React from 'react';
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core/styles';
import { Box, Paper, List, ListItem, ListItemText } from '@material-ui/core';
import { Admin } from 'app/admin/Admin';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 200,
    },
    listContainer: {},
    list: {
      padding: 0,
    },
    listHeaderContainer: {},
    listHeader: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    listItem: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginTop: theme.spacing(1),
      '&$listItemSelected': {
        background: 'none',
      },
    },
    listItemSelected: {
      boxShadow: `inset 3px 0 0 0 ${theme.palette.primary.main}`,
      '& $listItemTextPrimary': {
        fontWeight: 'bold',
      },
    },
    listItemText: {},
    listItemTextPrimary: {
      fontSize: theme.typography.pxToRem(14),
    },
  });

interface AdminProps extends WithStyles<typeof styles> {
  adminSubcomponents: Admin[] | null;
  activeAdminSubcomponent: Admin | null;
  handleAdminSubcomponentSelection: (adminSubcomponent: Admin) => void;
}

const NavAdminMenu = withStyles(styles)(
  ({
    classes,
    adminSubcomponents,
    activeAdminSubcomponent,
    handleAdminSubcomponentSelection,
  }: AdminProps) => {
    const handleItemClick = (adminSubcomponent: Admin) => () => {
      handleAdminSubcomponentSelection(adminSubcomponent);
    };

    const renderListItem = (adminSubcomponent: Admin): JSX.Element => (
      <ListItem
        key={adminSubcomponent.key}
        component="li"
        button
        classes={{
          root: classes.listItem,
          selected: classes.listItemSelected,
        }}
        selected={activeAdminSubcomponent?.key === adminSubcomponent.key}
        onClick={handleItemClick(adminSubcomponent)}
      >
        <ListItemText
          classes={{
            root: classes.listItemText,
            primary: classes.listItemTextPrimary,
          }}
          primary={adminSubcomponent.name}
        />
      </ListItem>
    );

    if (!adminSubcomponents || !activeAdminSubcomponent) {
      return null;
    }

    return (
      <Box>
        <Box component={Paper}>
          <div className={classes.root}>
            <Box className={classes.listContainer}>
              <List className={classes.list}>
                {adminSubcomponents.map(renderListItem)}
              </List>
            </Box>
          </div>
        </Box>
      </Box>
    );
  }
);

export default NavAdminMenu;
