import { History, Listener } from 'history';
import { TagType, Control } from 'app/services/api';
import { tagsState } from 'app/common/actions/tags';
import { recursivelyFind } from 'app/utils/helpers';
import { updateAppSelectionType } from 'app/common/actions';
import { controlsState } from 'app/common/actions/controls';
import { List } from 'lodash';

type HistoryListenerType = {
  selectedTag: TagType;
  selectedControl: Control;
  tags: tagsState;
  controls: controlsState;
  updateApp: (selection: updateAppSelectionType) => void;
};

export const addHistoryListener = (
  history: History<any>,
  module: string,
  args: HistoryListenerType
): (() => void) => {
  const { selectedTag, selectedControl, tags, controls, updateApp } = args;

  const historyListener = (location: any) => {
    if (history.action === 'POP') {
      const path = location.pathname.split('/');
      if (path[1] === module) {
        let newTagId: number | undefined;
        const groupIdx = path.indexOf('groups');
        if (groupIdx !== -1) {
          const tid = Number(path[groupIdx + 1]);
          if (tid) {
            if (tid != selectedTag?.id) {
              newTagId = tid;
            }
          } else {
            if (selectedTag) {
              newTagId = -1;
            }
          }
        }
        let newControlId: number | undefined;
        const controlIdx = path.indexOf('controls');
        if (controlIdx !== -1) {
          const cid = Number(path[controlIdx + 1]);
          if (cid) {
            if (cid != selectedControl?.id) {
              newControlId = cid;
            }
          } else {
            if (selectedControl) {
              newControlId = -1;
            }
          }
        }
        if (newTagId || newControlId) {
          let updatedTag = null;
          if (newTagId && newTagId > 0) {
            updatedTag = recursivelyFind(
              'tags',
              Number(newTagId),
              tags.payload
            );
          }
          let updatedControl = null;
          if (newControlId && newControlId > 0) {
            [updatedControl] = (controls.payload as Control[]).filter(
              (control: Control) => control.id === Number(newControlId)
            );
          }
          updateApp({ tag: updatedTag, control: updatedControl });
        }
      }
    }
  };

  return history.listen(historyListener);
};
