export interface SectionEntryType {
  id: number;
  name: string;
  key: string | null;
  dataType: string;
}

export const sectionEntries: SectionEntryType[] = [
  {
    id: 1,
    name: 'Threat Preparedness',
    key: 'preparedness',
    dataType: 'percentage'
  },
  {
    id: 2,
    name: 'Build Budget',
    key: 'build',
    dataType: 'currency'
  },
  {
    id: 3,
    name: 'Run Budget',
    key: 'run',
    dataType: 'currency'
  },
  {
    id: 4,
    name: 'Capex Budget',
    key: 'capex',
    dataType: 'currency'
  },
  {
    id: 5,
    name: 'Opex Budget',
    key: 'opex',
    dataType: 'currency'
  },
  {
    id: 6,
    name: 'People Budget',
    key: 'people',
    dataType: 'currency'
  },
  {
    id: 7,
    name: 'Technology Budget',
    key: 'technology',
    dataType: 'currency'
  },
  {
    id: 8,
    name: 'Vendor Budget',
    key: 'vendors',
    dataType: 'currency'
  },
  {
    id: 9,
    name: 'Other Budget',
    key: 'other',
    dataType: 'currency'
  },
  {
    id: 10,
    name: 'Head Count',
    key: 'head',
    dataType: 'decimal'
  },
  {
    id: 11,
    name: 'Action Plan',
    key: 'actionPlan',
    dataType: 'plan'
  }
];

