import React from 'react';
import Loader from 'app/common/Loader';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  findMaxProductivityValue,
  getHighLowColours,
} from 'app/common/BarGraph';
import { AllocationLevel } from 'app/utils/budget';
import { threatLevelTypes,  } from './index';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';


const featureName =
  'dashboard/control-dashboards/investment/budget-productivity-by-threat-level';

type BudgetProductivityByThreatLevelType = threatLevelTypes & {
    levelBudgets: AllocationLevel[] | null;
    secOpsProductivity: number;
  };

const BudgetProductivityByThreatLevel = ({
  threatLevels,
  getThreatLevels,
  levelBudgets,
  secOpsProductivity,
}: BudgetProductivityByThreatLevelType) => {
  if (threatLevels.isLoading || !levelBudgets) {
    return <Loader />;
  }

  const barData = threatLevels.payload.map((threatLevel) => {
    const levelBudget = levelBudgets.find(bLevel => bLevel.levelId === threatLevel.id);
    const levelBudgetValue = levelBudget ? levelBudget.value : 0;
    const highProductivity = Math.round(
      levelBudgetValue * (Math.round(secOpsProductivity) / 100)
    );
    const lowProductivity = levelBudgetValue - highProductivity;
    return {
      id: threatLevel.id,
      label: `${threatLevel.severity}. ${threatLevel.name}`,
      lowProductivity,
      lowProductivityColour: orange,
      highProductivity,
      highProductivityColour: green,
    };
  });

  const dataKeys = ['highProductivity', 'lowProductivity'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2} helpURI="">
            Budget Productivity by Threat Level
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            Threat Level
          </StripedTable.SubHeading>
        </StripedTable.Head>
      </StripedTable>
      <StripedTable>
        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel
                  style={{ width: '20%' }}
                  isLast={isLast}
                  colSpan={1}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast} colSpan={1} style={{ width: '80%' }}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting, tickValues: 7 } : null}
                    maxValue={findMaxProductivityValue(barData)}
                    labelFormat={dollarFormatting}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityByThreatLevel;
