import React from 'react';
import Loader from 'app/common/Loader';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  getHighLowColours,
  findMaxProductivityValue,
} from 'app/common/BarGraph';
import { AllocationSurface } from 'app/utils/budget';

import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { surfaceTypes } from './index';

const featureName =
  'dashboard/control-dashboards/investment/budget-productivity-by-surface';

type BudgetProductivityBySurfaceType = surfaceTypes & {
  surfaceBudgets: AllocationSurface[] | null;
  secOpsProductivity: number;
};

const BudgetProductivityBySurface = ({
  getThreatSurfaces,
  threatSurfaces,
  surfaceBudgets,
  secOpsProductivity,
}: BudgetProductivityBySurfaceType) => {
  React.useEffect(() => {
    getThreatSurfaces();
  }, []);

  if (threatSurfaces.isLoading || !surfaceBudgets) {
    return <Loader />;
  }

  const barData = threatSurfaces.payload.map((threatSurface) => {
    const surfaceBudget = surfaceBudgets.find(bSurface => bSurface.surfaceId === threatSurface.id);
    const surfaceBudgetValue = surfaceBudget ? surfaceBudget.value : 0;
    const highProductivity = Math.round(
      surfaceBudgetValue * (Math.round(secOpsProductivity) / 100)
    );
    const lowProductivity = surfaceBudgetValue - highProductivity;
    return {
      id: threatSurface.id,
      label: threatSurface.name,
      lowProductivity,
      lowProductivityColour: orange,
      highProductivity,
      highProductivityColour: green,
    };
  });
  const dataKeys = ['highProductivity', 'lowProductivity'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2} helpURI="">
            Budget Productivity by Threat Surface
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            Threat Surface
          </StripedTable.SubHeading>
        </StripedTable.Head>
      </StripedTable>
      <StripedTable>
        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel
                  style={{ width: '20%' }}
                  isLast={isLast}
                  colSpan={1}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast} colSpan={1} style={{ width: '80%' }}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting, tickValues: 7 } : null}
                    maxValue={findMaxProductivityValue(barData)}
                    labelFormat={dollarFormatting}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityBySurface;
