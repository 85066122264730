import React from 'react';
import { TagType, KPIGroup as KpiGroupType, KPI } from 'app/services/api';
import { ThreatLevel, ThreatSurface } from 'app/services/apiThreatSurfaces';
import { threatLevelState } from 'app/common/actions/threat-levels';
import { threatSurfaceState } from 'app/common/actions/threat-surfaces';
import { Typography, Divider } from '@material-ui/core';
import { ControlKpiGroupDataType } from '../../SummaryDashboard';
import ThreatSurfaceMatrix from './ThreatSurfaceMatrix';
import ThreatSurfaceScopeMatrix from './ThreatSurfaceScopeMatrix';
import ThreatSurfaceCoverageMatrix from './ThreatSurfaceCoverageMatrix';

type ThreatSurfaceMatrixType = {
  kpiGroups: KpiGroupType[];
  selectedTag: TagType;
  threatLevels: threatLevelState;
  threatSurfaces: threatSurfaceState;
  controlKpiGroupData: ControlKpiGroupDataType[];
  securityCoverageData: KPI[][];
};

const ThreatPreparednessByThreatSurfaceMatrix = ({
  kpiGroups,
  selectedTag,
  controlKpiGroupData,
  securityCoverageData,
  threatLevels,
  threatSurfaces,
}: ThreatSurfaceMatrixType) => {
  const tSurfacePayload = threatSurfaces.payload as ThreatSurface[];
  const tLevelPayload = threatLevels.payload as ThreatLevel[];

  if (!tSurfacePayload || !tLevelPayload) {
    return <div />;
  }

  const securityKpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === 'security-coverage'
  );
  const sortedLevelPayload = tLevelPayload.sort((a, b) =>
    a.id < b.id ? -1 : a.id > b.id ? 1 : 0
  );

  return (
    <>
      <Typography variant="h6">
        Threat Preparedness by Threat Surface
      </Typography>
      <Divider style={{ marginBottom: '2rem' }} />

      {tSurfacePayload.map((surface, idx) => (
        <div key={`tp-${surface.name}-${idx}`}>
          <ThreatSurfaceMatrix
            securityKpiGroup={securityKpiGroup}
            controlKpiGroupData={controlKpiGroupData}
            securityCoverageData={securityCoverageData}
            selectedTag={selectedTag}
            levelPayload={sortedLevelPayload}
            surfaceName={surface.name}
            surfaceID={surface.id}
          />
          <ThreatSurfaceScopeMatrix
            securityKpiGroup={securityKpiGroup}
            controlKpiGroupData={controlKpiGroupData}
            securityCoverageData={securityCoverageData}
            selectedTag={selectedTag}
            levelPayload={sortedLevelPayload}
            surfaceName={surface.name}
            surfaceID={surface.id}
          />
          <ThreatSurfaceCoverageMatrix
            securityKpiGroup={securityKpiGroup}
            controlKpiGroupData={controlKpiGroupData}
            securityCoverageData={securityCoverageData}
            selectedTag={selectedTag}
            levelPayload={sortedLevelPayload}
            surfaceName={surface.name}
            surfaceID={surface.id}
          />

          {idx < tSurfacePayload.length - 1 ? (
            <Divider style={{ marginBottom: '2rem' }} />
          ) : null}
        </div>
      ))}
    </>
  );
};

export default ThreatPreparednessByThreatSurfaceMatrix;
