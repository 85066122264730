import React from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import { ExpandMoreSharp } from '@material-ui/icons';

type DescriptionAccordionType = {
  summary: string;
  details: string[];
  identifier: number;
  style?: any;
};

const DescriptionAccordion = ({
  summary,
  details,
  identifier,
  style
}: DescriptionAccordionType): JSX.Element => {

  return (
    <Accordion elevation={0} style={style}>
      <AccordionSummary
        expandIcon={<ExpandMoreSharp />}
        id={`desc-accordion-${identifier}`}
      >
        <Typography variant="body2">{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2">
          {details.map((detail: string, idx: number) => {
            return (
              <div key={`desc-${identifier}-${idx}`}>
                {detail}
                <br/>
              </div>
            );
          })}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default DescriptionAccordion;
