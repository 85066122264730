import React from 'react';
import Paper from '@material-ui/core/Paper';

type SectionType = {
  padding?: boolean;
  children: any;
  elevation?: number;
  style?: any;
  key?: string;
};

const Section = ({
  padding,
  children,
  elevation = 3,
  style,
  ...restProps
}: SectionType): JSX.Element => (
  <Paper
    elevation={elevation}
    style={{
      padding: padding || '2.5rem 1.5rem',
      marginTop: '1.5rem',
      ...style,
    }}
    {...restProps}
  >
    {children}
  </Paper>
);

export default Section;
