import { getKPIMetadata, KPIMetadata } from 'app/services/api';

const featureName = 'app/common/actions';

export type kpiMetadataState = {
  isLoading: boolean;
  hasError: Error | null;
  payload: Promise<KPIMetadata[]> | KPIMetadata[] | null;
};

interface appStore {
  kpiMetadata: kpiMetadataState;
}

type dispatchType = {
  type: string;
  payload: Promise<KPIMetadata[]> | KPIMetadata[] | null;
};

export const actionTypes = {
  fetchKpiMetadata: `${featureName}/FETCH_KPI_METADATA`,
};

export const fetchKpiMetadata = (forceFetch = false) => (
  dispatch: React.Dispatch<dispatchType>,
  getState: () => appStore
): void => {
  const { payload: existingPayload } = getState().kpiMetadata;

  const hasKpiMetadata =
    existingPayload && Array.isArray(existingPayload) && existingPayload.length;

  dispatch({
    type: actionTypes.fetchKpiMetadata,
    payload: !forceFetch && hasKpiMetadata ? existingPayload : getKPIMetadata(),
  });
};
