import MuiBox from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

type BoxWrapperType = {
  width?: string;
  pt?: number;
  display?: string;
  flexWrap?: string;
  justifyContent?: string;
  alignItems?: string;
  style?: any;
  children?: any;
};

const BoxWrapper = ({
  children,
  width,
  pt,
  display,
  flexWrap,
  justifyContent,
  alignItems,
  ...restProps
}: BoxWrapperType): JSX.Element => (
  <MuiBox
    width={width || 'auto'}
    alignItems={alignItems || 'center'}
    display={display || 'flex'}
    pt={pt === undefined ? 3 : pt}
    flexWrap={flexWrap || 'wrap'}
    justifyContent={justifyContent || 'left'}
    {...restProps}
  >
    {children}
  </MuiBox>
);

type BoxType = {
  width?: number | string;
  height?: number | string;
  mb?: number;
  onClick?: any;
  key?: string;
  style?: any;
  children?: any;
  display?: string;
  justifyContent?: string;
  alignItems?: string;
};

const customStyles = makeStyles(() =>
  createStyles({
    card: {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    },
  })
);

const Box = ({
  width,
  height,
  mb,
  onClick,
  children,
  ...restProps
}: BoxType): JSX.Element => {
  const styles = customStyles();

  return (
    <MuiBox
      width={width === undefined ? 275 : width}
      height={height === undefined ? 275 : height}
      mb={mb === undefined ? 2 : mb}
      onClick={onClick}
      style={{ paddingRight: '1.5rem' }}
    >
      <Card className={styles.card} {...restProps}>
        <CardContent>
          <Typography variant="h6" component="h3">
            {children}
          </Typography>
        </CardContent>
      </Card>
    </MuiBox>
  );
};

Box.Wrapper = BoxWrapper;

export default Box;
