import React from 'react';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  getHighLowColours,
} from 'app/common/BarGraph';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import { TagType } from 'app/services/api';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { getTotalProductivity } from 'app/utils/budget';

type BudgetProductivityType = {
  selectedTag: TagType;
  controlBudgets: number[];
  secOpsProductivity: number[];
};

const BudgetProductivity = ({
  selectedTag,
  controlBudgets,
  secOpsProductivity
}: BudgetProductivityType) => {

  const productivity = getTotalProductivity(controlBudgets, secOpsProductivity);
  const barData = [
    {
      id: 1,
      label: 'Budget Productivity',
      highProductivity: productivity.totalHighProductivity,
      highProductivityColour: green,
      lowProductivity: productivity.totalLowProductivity,
      lowProductivityColour: orange
    }
  ];
  const dataKeys = ['highProductivity', 'lowProductivity'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2} helpURI="">
            {selectedTag.name} Budget Productivity
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            {kpiHeaderTitle}
          </StripedTable.SubHeading>
        </StripedTable.Head>
      </StripedTable>
      <StripedTable>
        <StripedTable.Body>
          <StripedTable.Row>
            <StripedTable.GraphLabel
              style={{ width: '20%' }}
              isLast
              colSpan={1}
            >
              Budget Productivity
            </StripedTable.GraphLabel>
            <StripedTable.GraphCell isLast colSpan={1} style={{ width: '80%' }}>
              <BarGraph
                data={barData}
                keys={dataKeys}
                colors={getHighLowColours}
                axisBottom={{ format: dollarFormatting, tickValues: 7 }}
                labelFormat={dollarFormatting}
                maxValue={productivity.totalLowProductivity + productivity.totalHighProductivity}
              />
            </StripedTable.GraphCell>
          </StripedTable.Row>
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivity;
