import React, { FunctionComponent, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from 'app/common/Loader';
import { KPILikertTable } from 'app/kpi/components/tables';
import { KPILikert, LikertValue } from 'app/kpi/types';
import {
  getKPIsAndValues,
  KPI,
  KPIGroup,
  Control,
  getKPIContext,
  KPIContext,
  KPIMetadata,
} from 'app/services/api';
import { KPIGroupDisplay } from 'app/kpi/types/KPIGroupDisplay';
import { kpiGroupsState } from 'app/common/actions/kpi-groups';
import { kpiMetadataState } from 'app/common/actions/kpi-metadata';
import KPIContextDialog from 'app/kpi/components/KPIContextDialog';

interface KPIResourcesProps {
  selectedControl: Control;
  kpiGroups: kpiGroupsState;
  kpiMetadata: kpiMetadataState;
}

type mapStateType = {
  app: {
    control: Control;
  };
  kpiGroups: kpiGroupsState;
  kpiMetadata: kpiMetadataState;
};

const KPIResources: FunctionComponent<KPIResourcesProps> = ({
  selectedControl,
  kpiGroups,
  kpiMetadata,
}: KPIResourcesProps) => {
  const kpiGroupsPayload = kpiGroups.payload as KPIGroup[];
  const kpiGroup = kpiGroupsPayload.find(({ key }) =>
    key.includes('resources')
  );
  const kpiMetadataPayload = kpiMetadata.payload as KPIMetadata[];
  const [kpiData, setKPIData] = useState<KPIGroupDisplay[] | null>(null);
  const [kpiContext, setKpiContext] = useState<KPIContext[] | []>([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [contextKey, setContextKey] = useState('');
  const [
    selectedKPIContext,
    setSelectedKPIContext,
  ] = useState<KPIContext | null>(null);

  const onContextDialogOpen = (contextKey: string, context?: KPIContext) => {
    if (context) {
      setSelectedKPIContext(context);
    } else {
      setSelectedKPIContext(null);
    }
    setContextKey(contextKey);
    setDialogIsOpen(true);
  };

  const handleDialogClose = () => {
    setDialogIsOpen(false);
  };

  useEffect(() => {
    if (!kpiGroup || !selectedControl) {
      return;
    }
    (async () => {
      const kpiData = await Promise.all(
        (kpiGroup.subGroups || []).map(async (group) => {
          let kpis: KPI[];
          try {
            kpis = await getKPIsAndValues({
              groupId: group.id,
              controlId: selectedControl.id,
            });
          } catch (error) {
            kpis = [];
          }
          return new KPIGroupDisplay(group, kpis);
        })
      );

      setKPIData(kpiData);
    })();
  }, []);

  useEffect(() => {
    if (!kpiGroup || !selectedControl) {
      return;
    }

    (async () => {
      const context = await getKPIContext({
        key: kpiGroup.key,
        controlId: selectedControl.id,
        include: 'subKeys',
      });

      setKpiContext(context);
    })();
  }, [dialogIsOpen]);

  const onKPIChange = (kpi: KPILikert, value: LikertValue) => {
    kpi.value = value;
    const newData = kpiData ? kpiData.slice() : [];
    setKPIData(newData);
  };

  if (!kpiGroup || !selectedControl) {
    return null;
  }

  if (!kpiData) {
    return <Loader />;
  }

  const defaultValueProps = { controlId: selectedControl.id };

  return (
    <>
      {kpiData.map(({ name, key, helpURI, kpis }) => (
        <KPILikertTable
          key={key}
          contextKey={key}
          name={name}
          helpURI={helpURI}
          context={kpiContext.find((item) => item.key === key)}
          kpis={
            kpis.map((kpi) =>
              kpi.getValueAt(0, defaultValueProps)
            ) as KPILikert[]
          }
          onKPIChange={onKPIChange}
          onContextDialogOpen={onContextDialogOpen}
        />
      ))}
      {dialogIsOpen && (
        <KPIContextDialog
          isOpen={dialogIsOpen}
          context={selectedKPIContext}
          contextKey={contextKey}
          kpiMetadata={kpiMetadataPayload}
          controlId={selectedControl.id}
          handleClose={handleDialogClose}
        />
      )}
    </>
  );
};

const mapState = ({
  app: { control },
  kpiGroups,
  kpiMetadata,
}: mapStateType) => ({
  selectedControl: control,
  kpiGroups,
  kpiMetadata,
});

export default connect(mapState)(KPIResources);
