import { Admin } from 'app/admin/Admin';
import SubcomponentTable from 'app/admin/components/subcomponents/table';

const subcomponentImplementation = (): JSX.Element => {
  return <SubcomponentTable subcomponentName={adminUser.name} />;
};

export const adminUser: Admin = {
  name: 'Users',
  key: 'com.pharossecurity.com.admin.users',
  requires: 'read:users',
  implementation: subcomponentImplementation,
};
