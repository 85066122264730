import React from 'react';
import { isPromise } from 'app/utils/helpers';

type actionType = {
  type: string;
  payload?: Promise<any> | null;
  isLoading?: boolean | null;
  hasError?: Error | null;
};

type dispatchType = React.Dispatch<any>;

type returnType = void | Promise<any> | actionType;

type initStateReturnType = {
  isLoading: boolean;
  hasError: null;
};

export const getInitState = (actionDetails = {}): initStateReturnType => ({
  isLoading: false,
  hasError: null,
  ...actionDetails,
});

const asyncMiddleware = ({ dispatch }: { dispatch: dispatchType }) => (
  next: dispatchType
) => (action: actionType): returnType => {
  if (!action.payload || !isPromise(action.payload)) {
    return next(action);
  }

  dispatch({ ...action, payload: null, isLoading: true, hasError: null });

  return action.payload
    .then((response: Response) =>
      dispatch({
        ...action,
        payload: response,
        isLoading: false,
        hasError: null,
      })
    )
    .catch((error: Error) =>
      dispatch({
        ...action,
        payload: null,
        isLoading: false,
        hasError: error,
      })
    );
};

export default asyncMiddleware;
