import React, { FunctionComponent } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { LinkOutlined, SwapVert } from '@material-ui/icons';
import MuiGrey from '@material-ui/core/colors/grey';
import { Order } from 'app/search/MainContent';

export interface HeadCell {
  id: string;
  label: string;
}

interface EnhancedTableHeadProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string,
    index: number
  ) => void;
  headCells: HeadCell[];
  order: Order[];
  orderBy: string[];
  activeSort: number;
}

export const EnhancedTableHead: FunctionComponent<EnhancedTableHeadProps> = (
  props: EnhancedTableHeadProps
) => {
  const { order, orderBy, headCells, activeSort, onRequestSort } = props;
  const createSortHandler = (property: string, index: number) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property, index);
  };

  return (
    <TableHead>
      <TableRow style={{ backgroundColor: MuiGrey[400] }}>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'default'}
            sortDirection={orderBy[i] === headCell.id ? order[i] : false}
          >
            {headCell.id !== 'editContext' && (
              <TableSortLabel
                active={orderBy[i] === headCell.id}
                direction={orderBy[i] === headCell.id ? order[i] : 'asc'}
                onClick={createSortHandler(headCell.id, i)}
                IconComponent={i !== activeSort ? SwapVert : undefined}
              >
                {headCell.id === 'contextLink' && (
                  <LinkOutlined fontSize="small" />
                )}
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
