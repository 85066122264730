import React, { ChangeEvent } from 'react';
import { displayHelp } from 'app/services/help';
import { ThreatSurface } from 'app/services/apiThreatSurfaces';
import { StripedTableRow } from 'app/common/StripedTable';
import {
  tableContainerStyles,
  headerStyles,
  subHeaderStyles,
  firstColStyles,
} from 'app/kpi/components/tables';
import {
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
} from '@material-ui/core';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';

type TableDeltaInputType = {
  numberOfTableColumns: number;
  colWidth: string;
  tableDeltaTitle: string;
  tableDeltaColumns: ThreatSurface[];
  tableDeltaState: any;
  tableDeltaType: string;
  onHandleChangeData: (tableType: string, idxRow: number, idxCol: number, newValue: number) => void;
  inputTable: any;
};

const TableDeltaInput = ({
  numberOfTableColumns,
  colWidth,
  tableDeltaTitle,
  tableDeltaColumns,
  tableDeltaState,
  tableDeltaType,
  onHandleChangeData,
  inputTable
}: TableDeltaInputType) => {
  
  const handleOnChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const idxRow = Number(event.target.name?.split('-').shift());
    const idxCol = Number(event.target.name?.split('-').pop());
    const newValue = Number(event.target.value);
    if (Number.isNaN(newValue) || newValue < 0 || newValue > 100) {
      return;
    }
    onHandleChangeData(tableDeltaType, idxRow, idxCol, newValue);
  };

  return (
    <Box ml={2} flex={1} key={`${tableDeltaType}-${tableDeltaTitle}`}>
      <Paper
        elevation={3}
        style={{ padding: '1.5rem', marginTop: '0.5rem', marginBottom: '2rem' }}
      >
        <div style={tableContainerStyles}>
          <Table>
            <TableHead>
              <TableRow style={headerStyles}>
                <TableCell colSpan={numberOfTableColumns}>
                  <Typography variant="h6">{tableDeltaTitle}</Typography>
                </TableCell>
                <TableCell colSpan={1} align="right" width="60px">
                  <IconButton
                    disabled={!tableDeltaState.helpURI}
                    onClick={() => displayHelp(tableDeltaState.helpURI)}
                  >
                    <HelpOutlineRounded />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow key={`header-${tableDeltaType}`} style={subHeaderStyles}>
                <TableCell key={`table-delta-${tableDeltaType}`} style={firstColStyles}>
                  {tableDeltaState.columnName}
                </TableCell>
                {tableDeltaColumns.map((column, idx) => (
                  <TableCell
                    style={{ width: colWidth }}
                    align="center"
                    key={`${column.name}-${idx}`}
                  >
                    {column.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {inputTable.map((row: any, rowIdx: number) => (
                <StripedTableRow
                  key={row[0]}
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {row.map((col: any, colIdx: number) => {
                    return (
                      <TableCell
                        key={`${row[0]}-${colIdx}`}
                        component="th"
                        scope="row"
                        align={colIdx > 0 ? 'center' : 'left'}
                      >
                        {colIdx > 0 ? (
                          <TextField
                            label=""
                            variant="outlined"
                            value={col}
                            name={`${rowIdx}-${colIdx}`}
                            inputProps={{
                              style: { fontSize: '14px', textAlign: 'center' },
                              min: 0,
                              max: 100,
                            }}
                            onChange={handleOnChange}
                            error={Number.isNaN(col) || col < 0 || col > 100}
                          />
                        ) : (
                          col
                        )}
                      </TableCell>
                    );
                  })}
                </StripedTableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </Box>
  );
};

export default TableDeltaInput;
