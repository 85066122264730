import { threatSurfaceState } from 'app/common/actions/threat-surfaces';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { getBarColorsForValue } from 'common/BarGraph';
import { KPIGroup as KpiGroupType, TagType } from 'app/services/api';
import { getKpiGroupProductivity } from 'app/utils/graph-math';
import {
  tableContainerWithMarginStyles,
  headerStyles,
  getColWidth,
  firstColStyles,
  lastColStyles,
} from 'app/kpi/components/tables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ControlKpiGroupDataType } from './index';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';
import { displayHelp } from 'app/services/help';
import React from 'react';
import { HelpOutlineRounded } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

type SecOpsKpiGroupProductivityMatrixType = {
  kpiGroups: KpiGroupType[];
  controlKpiGroupData: ControlKpiGroupDataType[];
  threatSurfaces: threatSurfaceState;
  selectedTag: TagType;
};

const SecOpsKpiGroupProductivityMatrix = ({
  kpiGroups,
  controlKpiGroupData,
  selectedTag,
  threatSurfaces,
}: SecOpsKpiGroupProductivityMatrixType): JSX.Element => {
  const managementKpiGroup = kpiGroups.find((group) =>
    group.key.includes('management')
  );
  const resourcesKpiGroup = kpiGroups.find((group) =>
    group.key.includes('resources')
  );
  const integrationKpiGroup = kpiGroups.find((group) =>
    group.key.includes('integration')
  );

  const productivityData = controlKpiGroupData.reduce(
    (result: any, next: ControlKpiGroupDataType, index: number) => {
      const { management, resources, integration } = next;
      const managementProductivity = getKpiGroupProductivity(
        management,
        managementKpiGroup
      );
      const resourcesProductivity = getKpiGroupProductivity(
        resources,
        resourcesKpiGroup
      );
      const integrationProductivity = getKpiGroupProductivity(
        integration,
        integrationKpiGroup
      );

      const newResult = {
        ...result,
        management: result.management + managementProductivity,
        resources: result.resources + resourcesProductivity,
        integration: result.integration + integrationProductivity,
      };
      const isLast = index === controlKpiGroupData.length - 1;
      if (isLast) {
        newResult.management =
          newResult.management / controlKpiGroupData.length;
        newResult.resources = newResult.resources / controlKpiGroupData.length;
        newResult.integration =
          newResult.integration / controlKpiGroupData.length;
      }

      return newResult;
    },
    { management: 0, integration: 0, resources: 0 }
  );
  const average = Math.round(
    (productivityData.management +
      productivityData.resources +
      productivityData.integration) /
      3
  );
  const tableData = [
    {
      group: managementKpiGroup,
      data: Math.round(productivityData.management),
    },
    {
      group: resourcesKpiGroup,
      data: Math.round(productivityData.resources),
      overall: average,
    },
    {
      group: integrationKpiGroup,
      data: Math.round(productivityData.integration),
    },
  ].sort(
    (prev, next) =>
      (prev.group?.displayOrder || 0) - (next.group?.displayOrder || 0)
  );

  const overallStyle = {
    backgroundColor: MuiLightBlue[900],
    color: 'white',
    border: 0,
  };

  if (!Array.isArray(threatSurfaces.payload)) {
    return <div></div>;
  }

  const numberOfThreatSurfaces = threatSurfaces.payload.length;
  const colWidth = getColWidth(numberOfThreatSurfaces);
  const helpURI = '';

  return (
    <Paper
      elevation={3}
      style={{ padding: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}
    >
      <div style={tableContainerWithMarginStyles}>
        <Table size="small">
          <TableHead>
            <TableRow style={headerStyles}>
              <TableCell colSpan={numberOfThreatSurfaces + 1}>
                <Typography variant="h6">
                  {selectedTag.name} Current SecOps Productivity
                </Typography>
              </TableCell>
              <TableCell colSpan={1} align="right" width="60px">
                <IconButton
                  disabled={!helpURI}
                  onClick={() => displayHelp(helpURI)}
                >
                  <HelpOutlineRounded />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={firstColStyles} key="secops-productivity">
                Key Performance Indicator (KPI)
              </TableCell>
              {threatSurfaces.payload.map((ele, idx) => {
                return (
                  <TableCell
                    style={{ width: colWidth }}
                    key={`header-${idx}`}
                  ></TableCell>
                );
              })}
              <TableCell style={lastColStyles} key="overall" align="center">
                Overall
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, idx) => {
              const barColors = getBarColorsForValue(row.data);
              const cellStyle = {
                backgroundColor: barColors.background,
                color: barColors.textColor,
              };

              let secOpsProductivityColumn;
              if (typeof row.overall !== 'undefined') {
                secOpsProductivityColumn = row.overall;
              }

              return (
                <TableRow key={`${row.group?.name}-${idx}`}>
                  <TableCell align="left">{row.group?.name}</TableCell>
                  <TableCell
                    align="center"
                    style={cellStyle}
                    colSpan={numberOfThreatSurfaces}
                  >
                    {row.data}
                  </TableCell>
                  <TableCell align="center" style={overallStyle}>
                    {secOpsProductivityColumn}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default SecOpsKpiGroupProductivityMatrix;
