import {
  getThreatLevels,
  ThreatLevel as ThreatLevelType,
} from 'app/services/apiThreatSurfaces';

const featureName = 'common/actions';

export type threatLevelState = {
  isLoading: boolean;
  hasError: Error | null;
  payload: Promise<ThreatLevelType[]> | ThreatLevelType[] | null;
};

interface appStore {
  threatLevels: threatLevelState;
}

type dispatchType = {
  type: string;
  payload: Promise<ThreatLevelType[]> | ThreatLevelType[] | null;
};

export const actionTypes = {
  fetchThreatLevels: `${featureName}/FETCH_THREAT_LEVELS`,
};

export const fetchThreatLevels = (forceFetch = false) => (
  dispatch: React.Dispatch<dispatchType>,
  getState: () => appStore
): void => {
  const { payload: existingPayload } = getState().threatLevels;

  const hasThreatLevels =
    existingPayload && Array.isArray(existingPayload) && existingPayload.length;

  dispatch({
    type: actionTypes.fetchThreatLevels,
    payload:
      !forceFetch && hasThreatLevels ? existingPayload : getThreatLevels(),
  });
};
