import PATHS from 'app/utils/paths';
import { getNullableQueryParam } from 'app/services/url';
import { request } from 'app/services/api';

export interface Control {
  id: number;
  parentId: number | null;
  name: string;
  displayOrder: number;
  tagKey?: string;
  tagId?: number;
  tags?: TagType[];
}

export const getControls = (
  tagKey?: string,
  include?: string
): Promise<Control[]> =>
  request
    .get(PATHS.API.CONTROLS, {
      query: {
        ...getNullableQueryParam('tagKey', tagKey),
        ...getNullableQueryParam('include', include),
      },
    })
    .then((controls) =>
      controls.sort(
        (prev: Control, next: Control) => prev.displayOrder - next.displayOrder
      )
    );

export const getControl = (id: string | number): Promise<Control> =>
  request
    .get(`${PATHS.API.CONTROL}/${id}`)
    .then((control: Control | Control[]) =>
      Array.isArray(control) ? control[0] : control
    );

export enum TagHints {
  Default,
  Unused1,
  Unused2,
  ControlContainer,
}

export type TagType = {
  id: number;
  parentId: number | null;
  companyId: number | null;
  key: string;
  name: string;
  displayOrder: number;
  subTags: TagType[];
  hint: TagHints;
};

const sortNestedTags = (tags: TagType[]): TagType[] => {
  const result = tags.sort((a, b) => a.displayOrder - b.displayOrder);
  result.forEach((tag: TagType) => {
    if (tag.subTags) {
      sortNestedTags(tag.subTags);
    }
  });
  return result;
};

export const getTags = (parentId: number | null = null): Promise<TagType[]> =>
  request
    .get(PATHS.API.TAGS, {
      query: {
        ...getNullableQueryParam('parentId', parentId),
        include: 'subTags',
      },
    })
    .then((tags: TagType[]) => sortNestedTags(tags));

export const getTagById = (id: number): Promise<TagType> =>
  request.get(`${PATHS.API.TAG}/${id}`);

export const saveTagType = (tag: TagType[]): Promise<TagType[]> => {
  return request.post(PATHS.API.TAG, tag);
};

export const deleteTagType = (
  TagType: TagType[]
): Promise<TagType[] | Response | Error> => {
  return request
    .delete(PATHS.API.TAG, TagType)
    .then((response: Response) => response)
    .catch((error: Error) => error);
};

export const updateTagType = (
  TagType: TagType[]
): Promise<TagType[] | Response | Error> => {
  return request
    .patch(PATHS.API.TAG, TagType)
    .then((response: Response) => response)
    .catch((error: Error) => error);
};
