import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import PATHS from 'app/utils/paths';
import TagsSelection from 'app/common/NavTagsSelection';
import MainContent from './MainContent';

const NewAnalyze = (): JSX.Element => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${url}${PATHS.GROUPS}/:tagId/controls/:controlId`}
        component={MainContent}
      />
      <Route path={`${url}${PATHS.GROUPS}/:tagId`} component={MainContent} />
      <Route exact path={`${url}${PATHS.GROUPS}`} component={TagsSelection} />

      <Redirect to={`${url}${PATHS.GROUPS}`} />
    </Switch>
  );
};

export default NewAnalyze;
