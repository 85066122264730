import { TagType, Control } from 'services/api';
import { resetControls } from 'common/actions/controls';

const featureName = 'app/common/actions';

export const actionTypes = {
  updateAppSelection: `${featureName}/UPDATE_APP_SELECTION`,
};

export type updateAppSelectionType = {
  tag?: TagType | null;
  control?: Control | null;
};

export const updateAppSelection = (newSelections: updateAppSelectionType) => (
  dispatch: any,
  getState: () => { app: updateAppSelectionType }
): void => {
  const { app } = getState();
  const { tag: existingTag } = app;
  const { tag: newTag, control: newControl } = newSelections;
  const newAppState = { ...app, ...newSelections };

  if (
    (existingTag && newTag && newTag.id !== existingTag.id) ||
    (newControl === null && newTag === null)
  ) {
    dispatch(resetControls());
    newAppState.control = null;
  }

  dispatch({
    type: actionTypes.updateAppSelection,
    newSelections,
  });
};
