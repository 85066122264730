import {
  Control,
  KPI,
  KPIGroup as KpiGroupType,
  KPIValue,
  ControlCapability,
  getKPIValues,
  getKPIsAndValues,
} from 'app/services/api';

export type ProcessedControlCapability = ControlCapability & {
  summary: string;
  description: string[];
}

export const isPromise = (input: any): boolean =>
  Boolean(input) &&
  (typeof input === 'object' || typeof input === 'function') &&
  Boolean(input.then) &&
  typeof input.then === 'function';

export const arrayHasValues = (input: any[]): boolean =>
  input && Array.isArray(input) && input.length ? true : false;

export const filterKpiValues = (
  kpiValues: KPIValue[],
  controlId: number,
  groupId?: number
): KPIValue[] => {
  const values = kpiValues.filter(
    (value: KPIValue) =>
      value.controlId == controlId &&
      (groupId === undefined || value.kpi.groupId === groupId)
  );
  // if (values.length === 0) {
  // }
  return values;
};

export const extractKpisFromValues = (kpiValues: KPIValue[]): KPI[] => {
  const kpis: KPI[] = [];
  kpiValues.forEach((value: KPIValue) => {
    if (value.kpi) {
      const found = kpis.find((kpi: KPI) => kpi.id === value.kpi?.id);
      if (!found) {
        const kpi: KPI = Object.assign({}, value.kpi);
        if (value.kpi.valueType !== undefined) {
          kpi.valueType = value.kpi.valueType;
        }
        kpi.kpiValue = [];
        kpis.push(kpi);
      }
    }
  });
  return kpis.sort((a: KPI, b: KPI) => a?.id - b?.id);
};

export const invertKpiValues = (kpiValues: KPIValue[]): KPI[] => {
  const kpis: KPI[] = [];
  kpiValues.forEach((value: KPIValue) => {
    if (value.kpi) {
      const found = kpis.find((kpi: KPI) => kpi.id === value.kpi?.id);
      if (found) {
        if (value.id) {
          found.kpiValue.push(value);
        }
      } else {
        const kpi: KPI = Object.assign({}, value.kpi);
        if (value.kpi.valueType !== undefined) {
          kpi.valueType = value.kpi.valueType;
        }
        kpi.kpiValue = [];
        if (value.id) {
          kpi.kpiValue.push(value);
        }
        kpis.push(kpi);
      }
    }
  });
  return kpis.sort((a: KPI, b: KPI) => a?.id - b?.id);
};

export const findMissingKpis = (needle: KPI[], haystack: KPI[]): KPI[] => {
  const missing: KPI[] = [];
  if (needle.length < haystack.length) {
    haystack.forEach((straw: KPI) => {
      if (needle.findIndex((kpi: KPI) => kpi.id === straw.id) === -1) {
        missing.push(straw);
      }
    });
  }
  return missing;
};

export const getKPIsAndValuesForGroup = (
  controls: Control[],
  groupKey: string,
  calendarDate?: string
): Promise<KPI[][]> => {
  const kpiValueParams: any = {
    groupKey,
    include: 'kpis,kpis.valueType,nullvalues,unique',
  };
  if (
    calendarDate &&
    new Date(calendarDate).setHours(23, 59, 59, 0) <
      new Date().setHours(23, 59, 59, 0)
  ) {
    kpiValueParams.date = calendarDate;
  }

  return getKPIValues(kpiValueParams).then((kpiValues: KPIValue[]) => {
    const kpis: KPI[] = extractKpisFromValues(kpiValues);

    const result: KPI[][] = controls.map((control: Control) => {
      const controlKpis = invertKpiValues(
        kpiValues.filter((value: KPIValue) => value.controlId == control.id)
      );
      const missing = findMissingKpis(controlKpis, kpis);
      if (missing.length > 0) {
        return controlKpis
          .concat(missing)
          .sort((a: KPI, b: KPI) => a?.id - b?.id);
      }
      return controlKpis;
    });
    return result;
  });
};

export const getKPIsAndValuesForSubgroups = (
  controls: Control[],
  kpiGroups: KpiGroupType[],
  groupName: string,
  calendarDate?: string
): Promise<KPI[][][]> => {
  const kpiGroup = kpiGroups.find((group: KpiGroupType) =>
    group.key.includes(groupName)
  );
  const subGroups = kpiGroup?.subGroups;
  if (!kpiGroup || !subGroups) {
    throw new Error(`Missing subgroups for KPI group ${groupName}`);
  }

  const kpiValueParams: any = {
    groupKey: kpiGroup.key,
    include: 'kpis,kpis.valueType,kpis.subGroups,nullvalues,unique',
  };
  if (
    calendarDate &&
    new Date(calendarDate).setHours(23, 59, 59, 0) <
      new Date().setHours(23, 59, 59, 0)
  ) {
    kpiValueParams.date = calendarDate;
  }

  return getKPIValues(kpiValueParams).then((kpiValues: KPIValue[]) => {
    const kpis: KPI[] = extractKpisFromValues(kpiValues);

    const result: KPI[][][] = controls.map((control: Control) =>
      subGroups.map((subGroup: KpiGroupType) => {
        const values = kpiValues.filter(
          (value: KPIValue) =>
            value.id === undefined ||
            (value.kpi.groupId === subGroup.id && value.controlId == control.id)
        );
        const groupKpis = invertKpiValues(values);
        const missing = findMissingKpis(groupKpis, kpis);
        if (missing.length > 0) {
          return groupKpis
            .concat(missing)
            .sort((a: KPI, b: KPI) => a?.id - b?.id);
        }
        return groupKpis;
      })
    );
    return result;
  });
};

export const kpiGroupPromiseCreator = (
  groupName: string,
  kpiGroups: KpiGroupType[],
  controlId?: number | undefined,
  calendarDate?: string
) => {
  const kpiGroup = kpiGroups.find((group: KpiGroupType) =>
    group.key.includes(groupName)
  );

  if (!kpiGroup) {
    return [];
  }

  return Promise.all(
    (kpiGroup.subGroups || []).map((subGroup: KpiGroupType) => {
      const kpiValueParams: any = {
        groupId: subGroup.id,
        controlId,
      };
      if (
        calendarDate &&
        new Date(calendarDate).setHours(23, 59, 59, 0) <
          new Date().setHours(23, 59, 59, 0)
      ) {
        kpiValueParams.date = calendarDate;
      }
      return getKPIsAndValues(kpiValueParams);
    })
  );
};

export const findSubGroupByKey = (kpiGroup: KpiGroupType, key: string) =>
  (kpiGroup?.subGroups || []).find((group: KpiGroupType) =>
    group.key.includes(key)
  );

export const recursivelyFind = (
  itemName: string,
  id: number,
  inputArray: any
) => {
  for (const i in inputArray) {
    const item = inputArray[i];

    if (item.id === id) {
      return item;
    }

    const subItemName = `sub${itemName
      .charAt(0)
      .toUpperCase()}${itemName.substring(1)}`;
    const subItemArray = item[subItemName];
    if (subItemArray) {
      const parent: any = recursivelyFind(itemName, id, subItemArray);

      if (parent) {
        return parent;
      }
    }
  }
};

export const flattenNestedArrayByName = (itemName: string, inputArray: any) => {
  let foundItems: any = [];

  if (!inputArray || (Array.isArray(inputArray) && !inputArray.length)) {
    return foundItems;
  }

  for (const i in inputArray) {
    const item = inputArray[i];

    if (item) {
      foundItems = foundItems.concat(item);

      const subItemName = `sub${itemName
        .charAt(0)
        .toUpperCase()}${itemName.substring(1)}`;
      const subItemArray = item[subItemName];

      if (subItemArray) {
        foundItems = foundItems.concat(
          flattenNestedArrayByName(itemName, subItemArray)
        );
      }
    }
  }

  return foundItems;
};

export const isValidHttpUrl = (str: string): boolean => {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const processCapabilities = (capabilities: ControlCapability[]): ProcessedControlCapability[] => {
  const processedCapabilities: ProcessedControlCapability[] = [];
  capabilities.forEach((capability: ControlCapability) => {
    const processedCapability: ProcessedControlCapability = {
      id: capability.id,
      name: capability.name,
      controlId: capability.controlId,
      surfaceTypeId: capability.surfaceTypeId,
      levelId: capability.levelId,
      displayOrder: capability.displayOrder,
      summary: capability.name,
      description: []
    }
    if (capability.name && capability.name.length > 0) {
      const capabilityStrings = capability.name.split("|");
      processedCapability.summary = capabilityStrings[0];
      if (capabilityStrings.length > 1) {
        processedCapability.description = capabilityStrings.slice(1);
      }
    }
    processedCapabilities.push(processedCapability);
  });
  return processedCapabilities;
}
