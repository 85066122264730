import React from 'react';
import clsx from 'clsx';
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      marginRight: theme.spacing(4),
      borderRadius: 0,
    },
    label: {
      textTransform: 'none',
      textDecoration: 'none',
    },
    active: {
      color: theme.palette.primary.main,
      backgroundColor: '#fff',
      boxShadow: `inset 0 -3px 0px 0px ${theme.palette.primary.main}`,
    },
  });

interface NavButtonProps extends WithStyles<typeof styles> {
  active?: boolean;
  children: JSX.Element | string;
}

const NavButton = withStyles(styles)(
  ({ classes, children, active = false, ...rest }: NavButtonProps) => {
    const { active: activeCls, ...buttonClasses } = classes;

    return (
      <Button
        {...rest}
        classes={buttonClasses}
        className={clsx(active && activeCls)}
        disableRipple
      >
        {children}
      </Button>
    );
  }
);

export default NavButton;
