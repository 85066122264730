import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import {
  Control,
  KPIGroup,
  KPI,
  getKPIsAndValues,
  getControlCapabilities,
  ControlCapability
} from 'app/services/api';
import {
  getThreatLevels,
  getThreatSurfaces,
  ThreatLevel,
  ThreatSurface,
} from 'app/services/apiThreatSurfaces';
import { controlsState } from 'app/common/actions/controls';
import Loader from 'app/common/Loader';
import NavDelta from './components/NavDelta';
import { connect } from 'react-redux';
import { kpiGroupsState } from 'app/common/actions/kpi-groups';
import { kpiMetadataState } from 'app/common/actions/kpi-metadata';
import { SectionEntryType, sectionEntries } from './components';
import TableDelta from './components/TableDelta';
import TableDeltaChange from './components/TableDeltaChange';
import ActionPlanTable from './components/ActionPlanTable';
import {
  generateTotalTable,
  generateBudgetTable,
  generateDiffTable,
  generateDeltaKpiTables,
  DeltaData,
  DeltaActionPlanValues,
  generateActionPlanTable,
} from './deltaHelpers';


interface AnalyseDeltaProps {
  kpiGroups: kpiGroupsState;
  selectedControl: Control;
  kpiMetadata: kpiMetadataState;
  controls: controlsState;
}

type mapStateType = {
  app: {
    control: Control;
  };
  selectedControl: Control;
  kpiGroups: kpiGroupsState;
  kpiMetadata: kpiMetadataState;
  controls: controlsState;
};

export const DeltaContext = React.createContext<DeltaData>({
  preparednessStart: [],
  preparednessEnd: [],
  startTable: [],
  endTable: [],
  changeTable: [],
  controls: [],
  levels: [],
  surfaces: [],
  totals: [],
  kpiValues: {},
  subTables: {},
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
});

const AnalyseDelta: FunctionComponent<AnalyseDeltaProps> = ({
  kpiGroups,
  selectedControl,
  kpiMetadata,
  controls,
}: AnalyseDeltaProps) => {
  const kpiGroupPayload = kpiGroups.payload as KPIGroup[];
  const [loading, setLoading] = useState<boolean>(true);
  const [tableDeltaColumns, setTableDeltaColumns] = useState<
    ThreatSurface[] | null
  >(null);
  const [tableDeltaRows, setTableDeltaRows] = useState<ThreatLevel[] | null>(
    null
  );
  const [tableDeltaState, setTableDeltaState] = useState<{
    columnName: string;
    helpURI: string;
  }>({
    columnName: '',
    helpURI: '',
  });

  const deltaTablesRef = React.useRef<DeltaData>({
    preparednessStart: [],
    preparednessEnd: [],
    startTable: [],
    endTable: [],
    changeTable: [],
    controls: [],
    levels: [],
    surfaces: [],
    totals: [],
    kpiValues: {},
    subTables: {},
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
  });

  const [
    activeSectionEntry,
    setActiveSectionEntry,
  ] = useState<SectionEntryType | null>(null);

  const handleSectionEntry = (section: SectionEntryType | null) => {
    setActiveSectionEntry(section);
  };

  const generateDeltaTables = () => {
    const tableTypes = Object.keys(deltaTablesRef.current.kpiValues);
    tableTypes.forEach((tableType) => {
      let total = null;
      if (tableType == "head") {
        total = deltaTablesRef.current.totals

      }
      const startTable = generateBudgetTable(
        deltaTablesRef.current.kpiValues[tableType], deltaTablesRef.current.preparednessStart, total
      );
      const endTable = generateBudgetTable(
        deltaTablesRef.current.kpiValues[tableType], deltaTablesRef.current.preparednessEnd, total
      );
      const changeTable = generateDiffTable(startTable, endTable);
      deltaTablesRef.current.subTables[tableType] = {
        start: startTable,
        end: endTable,
        change: changeTable
      };
    });
  };

  const getDeltaKPIs = async (selectedControls: Control[]) => {
    const allData = await Promise.all(
      selectedControls.map(async (selectedControl) => {
        const allocationLevels: KPI[] = await getKPIsAndValues({
          controlId: selectedControl.id,
          groupKey: 'com.pharossecurity.zero-based.allocation-to-levels',
          include: 'kpis,kpis.valueType,nullvalues,unique,kpis.subGroups',
        });
        const allocationSurfaces: KPI[] = await getKPIsAndValues({
          controlId: selectedControl.id,
          groupKey: 'com.pharossecurity.zero-based.allocation-to-surfaces',
          include: 'kpis,kpis.valueType,nullvalues,unique,kpis.subGroups',
        });
        const allocationResources: KPI[] = await getKPIsAndValues({
          controlId: selectedControl.id,
          groupKey: 'com.pharossecurity.zero-based.allocation-to-resources.build',
          include: 'kpis,kpis.valueType,nullvalues,unique,kpis.subGroups',
        });
        const budgetSplit: KPI[] = await getKPIsAndValues({
          controlId: selectedControl.id,
          groupKey: 'com.pharossecurity.zero-based.budget-split.build',
          include: 'kpis,kpis.valueType,nullvalues,unique,kpis.subGroups',
        });
        const humanResources: KPI[] = await getKPIsAndValues({
          controlId: selectedControl.id,
          groupKey: 'com.pharossecurity.zero-based.human-resources.build',
          include: 'kpis,kpis.valueType,nullvalues,unique,kpis.subGroups',
        });
        const capabilities: ControlCapability[] = await getControlCapabilities(selectedControl.id);
        return {
          allocationLevels,
          allocationSurfaces,
          allocationResources,
          budgetSplit,
          humanResources,
          capabilities: capabilities
        };
      })
    );
    return allData;
  };

  const handleThreatPreparedness = async () => {
    deltaTablesRef.current.controls = selectedControl ? [selectedControl] : controls.payload as Control[];
    const [surfaces, levels, deltaKPIs] = await Promise.all([
      getThreatSurfaces(),
      getThreatLevels(),
      getDeltaKPIs(deltaTablesRef.current.controls)
    ]);

    deltaTablesRef.current.levels = levels;
    deltaTablesRef.current.surfaces = surfaces;
    deltaTablesRef.current.kpiValues = generateDeltaKpiTables(surfaces, levels, deltaKPIs);
    deltaTablesRef.current.totals =  generateTotalTable(deltaTablesRef.current.kpiValues.build);
    setTableDeltaColumns(surfaces as ThreatSurface[]);
    setTableDeltaRows(levels as ThreatLevel[]);

    setTableDeltaState({
      ...tableDeltaState,
      columnName: 'Threat Level',
    });

    setLoading(false);
  };

  useEffect(() => {
    // First section to load is Threat Preparedness
    setActiveSectionEntry(sectionEntries ? sectionEntries[0] : null);
    handleThreatPreparedness();
  }, []);

  if (!tableDeltaColumns || !tableDeltaRows || !activeSectionEntry) {
    return null;
  }
  if (loading) {
    return <Loader />;
  }

  const showActionPlanTable = (actionPlanTable: DeltaActionPlanValues[]) => {
    return (
      <ActionPlanTable
        actionPlan={actionPlanTable}
      />
    );
  };

  const showPreparednessTables = () => {
    return (
      <div style={{ flexDirection: 'column' }}>
        <TableDelta
          kpiGroups={kpiGroupPayload}
          controls={controls.payload as Control[]}
          selectedControl={selectedControl}
          tableDeltaTitle={activeSectionEntry.name}
          tableDeltaRows={tableDeltaRows}
          tableDeltaColumns={tableDeltaColumns}
          tableDeltaState={tableDeltaState}
        />
      </div>
    )
  };

  const showKpiTables = () => {
    const sectionKey = activeSectionEntry.key || "";
    return (
      <div style={{ flexDirection: 'column' }}>
        <TableDeltaChange
          tableDeltaTitle={`START - ${activeSectionEntry.name}`}
          tableDeltaColumns={tableDeltaColumns}
          tableDeltaState={tableDeltaState}
          changeTable={deltaTablesRef.current.subTables[sectionKey].start}
          dataType={activeSectionEntry.dataType}
          useColors={false}
        />
        <TableDeltaChange
          tableDeltaTitle={`END - ${activeSectionEntry.name}`}
          tableDeltaColumns={tableDeltaColumns}
          tableDeltaState={tableDeltaState}
          changeTable={deltaTablesRef.current.subTables[sectionKey].end}
          dataType={activeSectionEntry.dataType}
          useColors={false}
        />
        <TableDeltaChange
          tableDeltaTitle={`CHANGE - ${activeSectionEntry.name}`}
          tableDeltaColumns={tableDeltaColumns}
          tableDeltaState={tableDeltaState}
          changeTable={deltaTablesRef.current.subTables[sectionKey].change}
          dataType={activeSectionEntry.dataType}
          useColors={true}
        />
      </div>
    )
  };

  const tableSelector = () => {
    if (activeSectionEntry.key === 'preparedness') {
      return showPreparednessTables();
    } else {
      generateDeltaTables();
      if (activeSectionEntry.key === "actionPlan") {
        const actionPlanTable: DeltaActionPlanValues[] = generateActionPlanTable(deltaTablesRef.current);
        return showActionPlanTable(actionPlanTable);
      } else {
        return showKpiTables();
      }
    }
  };

  return (
    <Box display="flex" mt={2}>
      <Box>
        <Box component={Paper}>
          <NavDelta
            activeSectionEntry={activeSectionEntry}
            sectionEntries={sectionEntries}
            handleSectionEntry={handleSectionEntry}
          />
        </Box>
      </Box>
      <DeltaContext.Provider value={deltaTablesRef.current}>
        {tableSelector()}
      </DeltaContext.Provider>
    </Box>
  );
};

const mapState = ({
  app: { control },
  kpiGroups,
  kpiMetadata,
  controls,
}: mapStateType) => ({
  selectedControl: control,
  kpiGroups,
  kpiMetadata,
  controls,
});

export default connect(mapState)(AnalyseDelta);
