import { connect } from 'react-redux';
import {
  KPIGroup as KpiGroupType,
  getKPIsAndValues,
  KPI,
  Control,
} from 'app/services/api';
import {
  ThreatLevel as ThreatLevelType,
  ThreatSurface as ThreatSurfaceType,
} from 'app/services/apiThreatSurfaces';
import { fetchThreatSurfaces } from 'app/common/actions/threat-surfaces';
import { fetchThreatLevels } from 'app/common/actions/threat-levels';
import { findSubGroupByKey, kpiGroupPromiseCreator } from 'app/utils/helpers';
import { GroupKeys } from 'app/kpi/types/KPIGroupDisplay';
import { getSecOpsProductivity } from 'app/utils/graph-math';
import CurrentBudgetProductivityMatrix from './CurrentBudgetProductivityMatrix';
import CurrentInvestmentMatrix from './CurrentInvestmentMatrix';
import EstimatedThreatPreparednessMatrix from './EstimatedThreatPreparednessMatrix';
import ThreatPreparednessMatrix from './ThreatPreparednessMatrix';
import SecOpsProductivity from './SecOpsProductivity';
import SecOpsProductivityMatrix from './SecOpsProductivityMatrix';
import PeopleSkillMatrix from './PeopleSkillMatrix';
import { CalendarContext } from '../../MainContent';

interface threatSurfacesState {
  isLoading: boolean;
  hasError: Error;
  payload: ThreatSurfaceType[];
}

interface threatLevelState {
  isLoading: boolean;
  hasError: Error;
  payload: ThreatLevelType[];
}

export interface surfaceTypes {
  threatSurfaces: threatSurfacesState;
}

export interface threatLevelTypes {
  threatLevels: threatLevelState;
}

type stateType = {
  kpiGroups: { payload: KpiGroupType[] };
  threatSurfaces: threatSurfacesState;
  threatLevels: threatLevelState;
  app: {
    control: Control;
  };
};

type SummaryDashboardType = surfaceTypes &
  threatLevelTypes & {
    kpiGroups: KpiGroupType[];
    getThreatLevels: () => void;
    getThreatSurfaces: () => void;
    selectedControl: Control;
  };

const SummaryDashboard = ({
  kpiGroups,
  getThreatSurfaces,
  getThreatLevels,
  threatSurfaces,
  threatLevels,
  selectedControl,
}: SummaryDashboardType): JSX.Element => {
  const controlId = selectedControl?.id;
  const controlName = selectedControl?.name;

  const kpiGroup = kpiGroups.find(
    (group: KpiGroupType) => group.path === 'investment'
  );

  if (!kpiGroup) {
    return <div>Invalid KPI Group</div>;
  }

  const totalBudgetGroup = findSubGroupByKey(kpiGroup, 'total-budget');
  const budgetAllocationToSurfaceGroup = findSubGroupByKey(
    kpiGroup,
    'budget-allocation-to-surfaces'
  );

  const securityKpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === 'security-coverage'
  );

  const investmentKpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === 'investment'
  );

  const [budgetEstimator, setBudgetEstimator] = React.useState<KPI[] | null>(
    null
  );
  const [budgetSplit, setBudgetSplit] = React.useState<KPI[]>([]);
  const [totalBudget, setTotalBudget] = React.useState<KPI[] | null>(null);
  const [budgetAllocation, setBudgetAllocation] = React.useState<KPI[] | null>(
    null
  );
  const [loading, setLoading] = React.useState<boolean>(true);
  const [management, setManagement] = React.useState<KPI[][] | null>(null);
  const [resources, setResources] = React.useState<KPI[][] | null>(null);
  const [integration, setIntegration] = React.useState<KPI[][] | null>(null);
  const calendarDate = React.useContext(CalendarContext);

  React.useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    getThreatSurfaces();
    getThreatLevels();

    (async () => {
      if (
        !controlId ||
        !budgetAllocationToSurfaceGroup ||
        !totalBudgetGroup ||
        !securityKpiGroup ||
        !investmentKpiGroup
      ) {
        setLoading(false);
        return;
      }

      const budgetSplitParams: any = {
        groupId: securityKpiGroup.id,
        controlId,
      };
      const budgetEstimatorParams: any = {
        groupKey: GroupKeys.BudgetEstimator,
        controlId,
      };
      const totalBudgetDataParams: any = {
        groupKey: totalBudgetGroup.key,
        controlId,
      };
      const budgetDataParams: any = {
        groupKey: budgetAllocationToSurfaceGroup.key,
        controlId,
      };
      if (
        calendarDate &&
        new Date(calendarDate).setHours(23, 59, 59, 0) <
          new Date().setHours(23, 59, 59, 0)
      ) {
        budgetSplitParams.date = calendarDate;
        budgetEstimatorParams.date = calendarDate;
        totalBudgetDataParams.date = calendarDate;
        budgetDataParams.date = calendarDate;
      }

      const budgetSplitData: KPI[] = await getKPIsAndValues(budgetSplitParams);
      setBudgetSplit(
        budgetSplitData.filter((item) => item.key.includes('budget-split'))
      );

      const budgetEstimator: KPI[] = await getKPIsAndValues(
        budgetEstimatorParams
      );
      setBudgetEstimator(budgetEstimator);

      const totalBudgetData: KPI[] = await getKPIsAndValues(
        totalBudgetDataParams
      );
      setTotalBudget(totalBudgetData);

      const budgetData: KPI[] = await getKPIsAndValues(budgetDataParams);
      setBudgetAllocation(budgetData);

      const managementData = await kpiGroupPromiseCreator(
        'management',
        kpiGroups,
        controlId,
        calendarDate
      );
      setManagement(managementData);
      const resourcesData = await kpiGroupPromiseCreator(
        'resources',
        kpiGroups,
        controlId,
        calendarDate
      );
      setResources(resourcesData);

      const integrationData = await kpiGroupPromiseCreator(
        'integration',
        kpiGroups,
        controlId,
        calendarDate
      );
      setIntegration(integrationData);

      setLoading(false);
    })();
  }, [calendarDate]);

  const secOpsProductivity = getSecOpsProductivity(
    management,
    resources,
    integration,
    kpiGroups
  );

  const currentBudget = budgetAllocation ? budgetAllocation[0] : null;
  const targetBudget = budgetAllocation ? budgetAllocation[1] : null;

  return (
    <div>
      <ThreatPreparednessMatrix
        controlId={controlId as number}
        selectedControl={controlName as string}
        kpiGroups={kpiGroups}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        calendarDate={calendarDate}
      />
      <SecOpsProductivityMatrix
        kpiGroups={kpiGroups}
        management={management}
        resources={resources}
        integration={integration}
        threatSurfaces={threatSurfaces}
        secOpsProductivity={secOpsProductivity}
      />
      <CurrentBudgetProductivityMatrix
        controlId={controlId as number}
        loading={loading}
        kpiGroups={kpiGroups}
        totalBudget={totalBudget}
        currentBudget={currentBudget}
        threatSurfaces={threatSurfaces}
        secOpsProductivity={secOpsProductivity}
      />
      <CurrentInvestmentMatrix
        budgetSplit={budgetSplit}
        controlId={controlId}
        currentBudget={currentBudget}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        totalBudget={totalBudget}
        secOpsProductivity={secOpsProductivity}
      />
      <EstimatedThreatPreparednessMatrix
        budgetEstimator={budgetEstimator}
        controlId={controlId as number}
        kpiGroups={kpiGroups}
        targetBudget={targetBudget}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
      />
      <PeopleSkillMatrix
        budgetEstimator={budgetEstimator}
        controlId={controlId as number}
        kpiGroups={kpiGroups}
        targetBudget={targetBudget}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
      />
      <SecOpsProductivity
        controlId={controlId as number}
        loading={loading}
        kpiGroups={kpiGroups}
        management={management}
        resources={resources}
        integration={integration}
        secOpsProductivity={secOpsProductivity}
      />
    </div>
  );
};

const mapState = ({
  kpiGroups: { payload },
  threatSurfaces,
  threatLevels,
  app: { control },
}: stateType) => ({
  kpiGroups: payload,
  threatLevels,
  threatSurfaces,
  selectedControl: control,
});

const mapDispatch = {
  getThreatLevels: fetchThreatLevels,
  getThreatSurfaces: fetchThreatSurfaces,
};

export default connect(mapState, mapDispatch)(SummaryDashboard);
