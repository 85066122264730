import { ControlCapability, KPI, KPIValue } from 'app/services/api';
import { ThreatSurface } from 'app/services/apiThreatSurfaces';
import { buildKPIData, KPIData } from 'app/kpi/types/KPIData';

export interface KPIValueProps {
  controlId?: number;
  capabilityId?: number;
  levelId?: number;
  surfaceId?: number;
}

export class KPIDisplay {
  readonly kpi: KPI;
  _values: KPIData<any>[];

  constructor(kpi: KPI) {
    this.kpi = kpi;
    this._values = ([] as KPIValue[]).concat(kpi.kpiValue).map(buildKPIData);
  }

  get id() {
    return this.kpi.id;
  }
  get key() {
    return this.kpi.key;
  }
  get text() {
    return this.kpi.name;
  }
  get name() {
    return this.kpi.name;
  }
  get helpURI() {
    return this.kpi.helpURI;
  }
  get valueType() {
    return this.kpi.valueType.key;
  }
  get values() {
    return this._values;
  }

  hasValue() {
    return this._values.length > 0;
  }

  getValueAt(index: number, defaultValueProps?: KPIValueProps) {
    if (!this._values[index] && defaultValueProps) {
      this._values[index] = this.createDefaultValue(defaultValueProps);
    }
    return this._values[index];
  }

  getValueByLevelId(
    levelId: number,
    controlId: number,
    createIfNotExist = false
  ) {
    let value = this._values.find(
      (value) => value.kpiValue.levelId === levelId
    );
    if (!value && createIfNotExist) {
      value = this.createDefaultValue({ levelId, controlId });
      this._values.push(value);
    }
    return value;
  }

  getValueByCapability(
    capability: ControlCapability,
    controlId: number,
    createIfNotExist = false,
    surfaces: ThreatSurface[]
  ) {
    const surface = surfaces.find(
      (surface) => surface.surfaceTypeId === capability.surfaceTypeId
    );
    const surfaceId = surface ? surface.id : 0;
    let value = this._values.find(
      (_value) =>
        _value.kpiValue.levelId === capability.levelId &&
        _value.kpiValue.surfaceId === surfaceId
    );
    if (!value && createIfNotExist) {
      value = this.createDefaultValue({
        controlId,
        surfaceId,
        levelId: capability.levelId,
      });
      this._values.push(value);
    }
    return value;
  }

  getValueBySurfaceId(
    surfaceId: number,
    controlId: number,
    createIfNotExist = false
  ) {
    let value = this._values.find(
      (value) => value.kpiValue.surfaceId === surfaceId
    );
    if (!value && createIfNotExist) {
      value = this.createDefaultValue({ surfaceId, controlId });
      this._values.push(value);
    }
    return value;
  }

  getKpiValue(controlId: number, createIfNotExist = false, surfaceId = 0) {
    let value = this._values[0];
    if (!value && createIfNotExist) {
      value = this.createDefaultValue({ controlId, surfaceId });
      this._values.push(value);
    }
    return value;
  }

  createDefaultValue(options?: KPIValueProps) {
    const { controlId = 0, levelId = 0, surfaceId = 0 } = options || {};
    return buildKPIData({
      id: 0,
      kpiId: this.id,
      controlId,
      levelId,
      surfaceId,
      value: 0,
      kpi: this.kpi,
    });
  }
}
