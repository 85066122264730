import React from 'react';
import { displayHelp } from 'app/services/help';
import { ThreatSurface } from 'app/services/apiThreatSurfaces';
import MuiGreen from '@material-ui/core/colors/green';
import MuiRed from '@material-ui/core/colors/red';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';
import { headerStyles, firstColStyles , getColWidth } from 'app/kpi/components/tables';
import { currencyFormatting } from '../deltaHelpers';

import {
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';

type TableDeltaChangeType = {
  tableDeltaTitle: string;
  tableDeltaColumns: ThreatSurface[];
  tableDeltaState: any;
  changeTable: any;
  dataType: string;
  useColors: boolean;
};

const TableDeltaChange = ({
  tableDeltaTitle,
  tableDeltaColumns,
  tableDeltaState,
  changeTable,
  dataType,
  useColors
}: TableDeltaChangeType) => {
  let numberOfTableColumns = tableDeltaColumns.length || 0;
  if (dataType !== "percentage") {
    numberOfTableColumns += 1;
  }
  const colWidth = getColWidth(numberOfTableColumns);
  let rowTotal = 0;
  const colTotals: number[] = new Array(tableDeltaColumns.length).fill(0);

  const getStyle = (idx: number, value: any, isTotal: boolean) => {
    let style = {};
    if (isTotal) {
      if (idx > 0) {
        style = {
          backgroundColor: MuiLightBlue[900],
          color: 'white',
        };
      } else {
        style = {
          backgroundColor: 'black',
          color: 'white',
        };
      }
    } else if (idx > 0) {
      let background = 'white';
      let textColor = 'black';
      if (useColors) {
        if (value > 0) {
          background = MuiGreen[800];
          textColor = 'white';
        } else if (value < 0) {
          background = MuiRed[800];
          textColor = 'white';
        }
      }
      style = {
        backgroundColor: background,
        color: textColor,
      };
    } else {
      style = {
        whiteSpace: 'nowrap',
      };
    }
    return style;
  };

  const displayColumnTotals = () => {
    let allTotal = 0;
    return (
      <TableRow>
        <TableCell
          key="row-total-label"
          style={getStyle(0, 0, false)}
          component="th"
          scope="row"
          align="left"
        >
          Column Total
        </TableCell>
        {colTotals.map((total: number, idx: number) => {
          allTotal += total;
          let value = total.toString();
          if (dataType === "currency") {
            value = currencyFormatting(total, false);
          } else if (dataType === "decimal") {
            value = total.toFixed(2);
          }
          return (
            <TableCell
              key={`total-col-${idx}`}
              style={getStyle(idx + 1, rowTotal, true)}
              component="th"
              scope="row"
              align="center"
            >
              {value}
            </TableCell>
          );
        })}
        {displayTotal(allTotal, 0, "all-total")}
      </TableRow>
    );
  };

  const displayTotal = (total: number, idx: number, keyPrefix: string) => {
    let value = total.toString();
    if (dataType === "currency") {
      value = currencyFormatting(total, false);
    } else if (dataType === "decimal") {
      value = total.toFixed(2);
    }
    return (
      <TableCell
        key={`${keyPrefix}-${idx}`}
        style={getStyle(idx, total, true)}
        component="th"
        scope="row"
        align="center"
      >
        {value}
      </TableCell>
    );
  };

  const displayTotalHeader = () => {
    return (
      <TableCell
        style={{ width: colWidth }}
        align="center"
        key="row-total-header"
      >
        Row Total
      </TableCell>
    );
  };

  return (
    <Box ml={2} flex={1} key={`change-${tableDeltaTitle}`}>
      <Paper
        elevation={3}
        style={{ padding: '1.5rem', marginTop: '0.5rem', marginBottom: '2rem' }}
      >
        <Table size="small">
          <TableHead>
            <TableRow style={headerStyles}>
              <TableCell colSpan={numberOfTableColumns}>
                <Typography variant="h6">{tableDeltaTitle}</Typography>
              </TableCell>
              <TableCell colSpan={1} align="right" width="60px">
                <IconButton
                  disabled={!tableDeltaState.helpURI}
                  onClick={() => displayHelp(tableDeltaState.helpURI)}
                >
                  <HelpOutlineRounded />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow key="header">
              <TableCell key="table-delta" style={firstColStyles}>
                {tableDeltaState.columnName}
              </TableCell>
              {tableDeltaColumns.map((surface, idx) => (
                <TableCell
                  style={{ width: colWidth }}
                  align="center"
                  key={`${surface.name}-${idx}`}
                >
                  {surface.name}
                </TableCell>
              ))}
              {dataType !== "percentage" ? displayTotalHeader() : ""}
            </TableRow>
          </TableHead>
          <TableBody>
            {changeTable.map((row: any) => (
              <TableRow key={row[0]}>
                {row.map((col: any, idx: number) => {
                  let value = col;
                  const style = getStyle(idx, value, false);
                  if (idx === 0) {
                    rowTotal = 0;
                  } else {
                    colTotals[idx - 1] += value;
                    rowTotal += value;
                    if (dataType === "currency") {
                      value = currencyFormatting(value, false);
                    } else if (dataType === "decimal") {
                      value = value.toFixed(2);
                    }
                  }
                  return (
                    <TableCell
                      key={`${row[0]}-${idx}`}
                      style={style}
                      component="th"
                      scope="row"
                      align={idx > 0 ? 'center' : 'left'}
                    >
                      {value}
                    </TableCell>
                  );
                })}
                {dataType !== "percentage" ? displayTotal(rowTotal, row.length, row[0]) : ""}
              </TableRow>
            ))}
            {dataType !== "percentage" ? displayColumnTotals() : ""}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default TableDeltaChange;
