import { connect } from 'react-redux';
import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  TagType,
  TagHints,
  KPIGroup as KPIGroupType,
  KPIGroupHints,
  Control,
} from 'app/services/api';
import PATHS from 'app/utils/paths';
import { recursivelyFind } from 'app/utils/helpers';
import { updateAppSelection, updateAppSelectionType } from 'app/common/actions';
import { fetchTags, tagsState } from 'app/common/actions/tags';
import { fetchControls, controlsState } from 'app/common/actions/controls';
import { fetchKpiGroups, kpiGroupsState } from 'app/common/actions/kpi-groups';
import {
  fetchKpiMetadata,
  kpiMetadataState,
} from 'app/common/actions/kpi-metadata';
import NavBreadcrumbs, { getBreadcrumbs } from 'app/common/NavBreadcrumbs';
import { addHistoryListener } from 'app/common/NavHistory';
import ElementsSelection from 'app/common/NavElementsSelection';
import ControlsSelection from 'app/common/NavControlsSelection';
import Loader from 'app/common/Loader';
import Box from 'app/common/Box';
import NavKPIGroups from 'app/kpi/components/NavKPIGroups';
import { Container, Typography } from '@material-ui/core';
import Delta from './Delta';

type reduxStateType = {
  tags: tagsState;
  app: {
    tag: TagType;
    control: Control;
  };
  kpiGroups: kpiGroupsState;
  kpiMetadata: kpiMetadataState;
  controls: controlsState;
};

type GroupAnalyzeTypes = {
  selectedTag: TagType;
  getTags: () => void;
  tags: tagsState;
  updateApp: (selection: updateAppSelectionType) => void;
  kpiGroups: kpiGroupsState;
  kpiMetadata: kpiMetadataState;
  getKpiGroups: () => void;
  getKpiMetadata: () => void;
  selectedControl: Control;
  controls: controlsState;
  getControls: (forceFetch: boolean, tagKey?: string, include?: string) => void;
};

const getNavKpiGroups = (
  kpiGroups: KPIGroupType[],
  selectedTag: TagType,
  selectedControl: Control,
  controlsPayload: Control[]
) => {
  let mappedGroups: KPIGroupType[] = [];

  if (
    selectedTag &&
    selectedTag.hint === TagHints.ControlContainer &&
    !isEmpty(controlsPayload)
  ) {
    mappedGroups = mappedGroups.concat({
      id: 12,
      key: PATHS.DELTA,
      name: 'Delta',
      helpURI: '',
      parentId: null,
      hint: KPIGroupHints.Default,
      path: PATHS.DELTA.replace('/', ''),
      weight: 0,
      displayOrder: 0,
    });
  }

  if (
    !selectedControl &&
    (selectedTag.hint === TagHints.ControlContainer ||
      !isEmpty(controlsPayload))
  ) {
    mappedGroups.splice(0, 0, {
      id: 11,
      key: PATHS.CONTROLS,
      name: 'Controls',
      helpURI: 'controls.html',
      parentId: null,
      hint: KPIGroupHints.Default,
      path: PATHS.CONTROLS.replace('/', ''),
      weight: 0,
      displayOrder: 0,
    });
  }

  if (!selectedControl && selectedTag.subTags) {
    mappedGroups.splice(0, 0, {
      id: 10,
      key: PATHS.ELEMENTS,
      name: 'Elements',
      helpURI: 'elements.html',
      parentId: null,
      hint: KPIGroupHints.Default,
      path: PATHS.ELEMENTS.replace('/', ''),
      weight: 0,
      displayOrder: 0,
    });
  }

  return mappedGroups;
};

const GroupAnalyze = ({
  selectedTag,
  getTags,
  tags,
  updateApp,
  kpiGroups,
  getKpiGroups,
  kpiMetadata,
  getKpiMetadata,
  selectedControl,
  controls,
  getControls,
}: GroupAnalyzeTypes): JSX.Element => {
  const {
    url,
    params: { tagId, controlId },
  }: {
    url: string;
    params: {
      tagId: string;
      controlId: string;
    };
  } = useRouteMatch();

  const history = useHistory();
  React.useEffect(() => {
    return addHistoryListener(history, 'analyze', {
      selectedTag,
      selectedControl,
      tags,
      controls,
      updateApp,
    });
  }, [history, updateApp, tags, controls, selectedTag, selectedControl]);

  React.useEffect(() => {
    // fetch tags & setup selectedTag
    if (!tags.isLoading && !('payload' in tags)) {
      getTags();
    }

    if (
      !tags.isLoading &&
      Array.isArray(tags.payload) &&
      ((tagId && !selectedTag) ||
        (tagId && selectedTag && Number(tagId) !== selectedTag.id))
    ) {
      const existingTag = recursivelyFind('tags', Number(tagId), tags.payload);
      updateApp({ tag: existingTag });
    }

    // fetch kpi groups
    if (!kpiGroups.isLoading && !('payload' in kpiGroups)) {
      getKpiGroups();
    }

    // fetch kpi metadata
    if (!kpiMetadata.isLoading && !('payload' in kpiMetadata)) {
      getKpiMetadata();
    }

    // fetch controls & setup selectedControl
    if (selectedTag && !controls.isLoading && !('payload' in controls)) {
      const forceFetch = true;
      const tagKey = selectedTag.key;
      const include = 'tags,subTags';
      getControls(forceFetch, tagKey, include);
    } else if (
      controlId &&
      !selectedControl &&
      !controls.isLoading &&
      Array.isArray(controls.payload)
    ) {
      const [currentControl] = (controls.payload as Control[]).filter(
        (control: Control) => control.id === Number(controlId)
      );

      updateApp({ control: currentControl });
    }
  });

  if (tags.isLoading || kpiGroups.isLoading) {
    return <Loader />;
  }

  if (!tags.payload || !selectedTag || !kpiGroups.payload) {
    return <div />;
  }

  const navKpiGroups = getNavKpiGroups(
    kpiGroups.payload as KPIGroupType[],
    selectedTag,
    selectedControl,
    controls.payload as Control[]
  );

  const navKpiRoute = navKpiGroups.length > 0 ? navKpiGroups[0].path : 'empty';
  const baseUrl = PATHS.ANALYZE;

  return (
    <Container maxWidth={false}>
      <NavBreadcrumbs
        breadcrumbs={getBreadcrumbs({
          baseUrl,
          selectedTag,
          updateApp,
          tags,
          history,
          controlId,
          selectedControl,
        })}
      />

      <NavKPIGroups kpiGroups={navKpiGroups} />

      {navKpiGroups.length > 0 ? (
        <Container maxWidth={false}>
          <Switch>
            <Route
              path={`${url}${PATHS.ELEMENTS}`}
              component={ElementsSelection}
            />
            <Route
              exact
              path={`${url}${PATHS.CONTROLS}`}
              component={ControlsSelection}
            />
            <Route path={`${url}${PATHS.DELTA}`} component={Delta} />

            <Redirect to={`${url}/${navKpiRoute}`} />
          </Switch>
        </Container>
      ) : (
        <Box.Wrapper justifyContent="right">
          <Typography variant="h6">
            The selected control group is empty
          </Typography>
        </Box.Wrapper>
      )}
    </Container>
  );
};

const mapState = ({
  kpiGroups,
  tags,
  app: { tag, control },
  controls,
  kpiMetadata,
}: reduxStateType) => ({
  selectedTag: tag,
  selectedControl: control,
  tags,
  kpiGroups,
  controls,
  kpiMetadata,
});

const mapDispatch = {
  getTags: fetchTags,
  updateApp: updateAppSelection,
  getKpiGroups: fetchKpiGroups,
  getControls: fetchControls,
  getKpiMetadata: fetchKpiMetadata,
};

export default connect(mapState, mapDispatch)(GroupAnalyze);
