import React from 'react';
import { Typography } from '@material-ui/core';
import MuiGrey from '@material-ui/core/colors/grey';
import { KPIGroup, KPI } from 'app/services/api';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import DescriptionAccordion from 'app/common/DescriptionAccordion';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { arrayHasValues } from 'app/utils/helpers';
import { truncate } from 'lodash';
import { getHelpURN } from 'app/services/help';

const featureName =
  'dashboard/control-dashboards/common-components/kpi-summary';

const KPISummary = ({
  kpiValues,
  subGroups,
}: {
  kpiValues: KPI[][];
  subGroups: KPIGroup[];
}): JSX.Element => {
  const getBarData = (
    kpi: KPI
  ): { id: string; label: string; value: number }[] => [
    {
      id: kpi.name,
      label: truncate(kpi.name, {
        length: 50,
        separator: /,? +/,
      }),
      value: arrayHasValues(kpi.kpiValue)
        ? Number(kpi.kpiValue[0].value) * 20
        : 0,
    },
  ];

  const showDescription = (
    text: string, rowIdx: number
  ) => {
    let summary = text;
    const descriptions = text && text.length > 0 ? text.split("|") : [];
    if (descriptions && descriptions.length > 1) {
      summary = descriptions[0];
      const details = descriptions.slice(1);
      if (details.some((detail: string) => detail.length > 0)) {
        let style = {};
        if (rowIdx & 1) {
          style = {
            backgroundColor: MuiGrey[100]
          }
        }
        return (
          <DescriptionAccordion
            summary={summary}
            details={details}
            identifier={rowIdx}
            style={style}
          />
        );
      }
    }
    return (
      <Typography variant="body2" style={{paddingLeft: '1rem'}}>
        {text}
      </Typography>
    );
  };

  let barColors: any;

  return (
    <>
      {subGroups.map((subGroup: KPIGroup, subGrpIdx: number) => (
        <Section key={`${featureName}-${subGroup.key}`}>
          <StripedTable>
            <StripedTable.Head>
              <StripedTable.Heading
                colSpan={2}
                helpURI={getHelpURN({
                  context: 'dashboard',
                  resource: subGroup.helpURI,
                })}
              >
                {subGroup.name}
              </StripedTable.Heading>
              <StripedTable.SubHeading colSpan={2}>
                {kpiHeaderTitle}
              </StripedTable.SubHeading>
            </StripedTable.Head>

            <StripedTable.Body>
              {kpiValues[subGrpIdx].map((kpi, kpiIdx) => {
                const barData = getBarData(kpiValues[subGrpIdx][kpiIdx]);
                const isLast = kpiIdx === kpiValues[subGrpIdx].length - 1;

                if (Array.isArray(barData) && barData.length > 0) {
                  barColors = getBarColorsForValue(barData[0].value);
                }

                return (
                  <StripedTable.Row
                    key={`${featureName}-${subGroup.key}-${kpi.key}`}
                  >
                    <StripedTable.GraphLabel
                      style={{ width: '20%' }}
                      isLast={isLast}
                      colSpan={1}
                    >
                      {showDescription(kpi.name, kpiIdx)}
                    </StripedTable.GraphLabel>
                    <StripedTable.GraphCell isLast={isLast} colSpan={1}>
                      <BarGraph
                        data={barData}
                        colors={barColors.background}
                        labelTextColor={barColors.textColor}
                        axisBottom={isLast ? {} : null}
                      />
                    </StripedTable.GraphCell>
                  </StripedTable.Row>
                );
              })}
            </StripedTable.Body>
          </StripedTable>
        </Section>
      ))}
    </>
  );
};

export default KPISummary;
