import React from 'react';
import {
  KPIGroup as KpiGroupType,
  ControlCapability,
  getKPIsAndValues,
  getControlCapabilities,
  KPI,
} from 'app/services/api';
import {
  ProcessedControlCapability as CapabilityType,
  processCapabilities
} from 'app/utils/helpers';
import { ThreatSurface, ThreatLevel } from 'app/services/apiThreatSurfaces';
import Loader from 'app/common/Loader';
import { Typography, Divider } from '@material-ui/core';
import { threatLevelTypes, surfaceTypes } from '../index';
import ThreatSurfaceMatrix from './ThreatSurfaceMatrix';

type ThreatPreparednessByThreatSurfaceMatrixType = threatLevelTypes &
  surfaceTypes & {
    kpiGroups: KpiGroupType[];
    controlId: number;
    calendarDate: string;
  };

const ThreatPreparednessByThreatSurfaceMatrix = ({
  kpiGroups,
  controlId,
  threatSurfaces,
  threatLevels,
  calendarDate,
}: ThreatPreparednessByThreatSurfaceMatrixType) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [securityGroupValues, setSecurityGroupValues] = React.useState<
    KPI[] | null
  >(null);
  const [capabilities, setCapabilities] = React.useState<CapabilityType[]>([]);

  React.useEffect(() => {
    if (!kpiGroups.length || !controlId) {
      return;
    }

    const securityKpiGroup = kpiGroups.find(
      (grp: KpiGroupType) => grp.path === 'security-coverage'
    );

    (async () => {
      if (!securityKpiGroup) {
        return;
      }

      const controlCaps: ControlCapability[] = await getControlCapabilities(
        controlId
      );
      const processedCapabilities: CapabilityType[] = processCapabilities(controlCaps);
      setCapabilities(processedCapabilities);

      const kpiValueParams: any = {
        controlId,
        groupKey: securityKpiGroup.key,
      };
      if (
        calendarDate &&
        new Date(calendarDate).setHours(23, 59, 59, 0) <
          new Date().setHours(23, 59, 59, 0)
      ) {
        kpiValueParams.date = calendarDate;
      }
      const securityGroupData: KPI[] = await getKPIsAndValues(kpiValueParams);
      setSecurityGroupValues(securityGroupData);

      setLoading(false);
    })();
  }, [calendarDate]);

  if (loading || !Array.isArray(threatLevels.payload)) {
    return (
      <div style={{ minHeight: '500px' }}>
        <Loader />
      </div>
    );
  }

  const tSurfacePayload = threatSurfaces.payload as ThreatSurface[];
  const tLevelsPayload = threatLevels.payload as ThreatLevel[];
  if (!tSurfacePayload || !tLevelsPayload) {
    return <div />;
  }

  return (
    <>
      <Typography variant="h6">
        Threat Preparedness by Threat Surface
      </Typography>
      <Divider style={{ marginBottom: '2rem' }} />

      {tSurfacePayload.map((surface, idx) => (
        <div key={`tp-${surface.name}-${idx}`}>
          <ThreatSurfaceMatrix
            securityGroupValues={securityGroupValues}
            capabilities={capabilities}
            threatSurface={surface}
            threatLevels={tLevelsPayload}
          />
        </div>
      ))}
    </>
  );
};

export default ThreatPreparednessByThreatSurfaceMatrix;
