import React from 'react';
import {
  Container,
  Box,
  Grid,
} from '@material-ui/core';
import { DataGrid, GridToolbar, GridValueFormatterParams } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { DeltaActionPlanValues, currencyFormatting } from 'app/analyze/deltaHelpers';
import { renderCellExpand } from 'app/common/GridCellExpand';

const useStyles = makeStyles({
  gridItem: {
    position: 'relative',
    height: 800,
    minHeight: 100,
  },
  actionPlanTable: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});

type ActionPlanTableType = {
  actionPlan: DeltaActionPlanValues[];
};

const ActionPlanTable = ({
  actionPlan
}: ActionPlanTableType): JSX.Element => {
  const classes = useStyles();
  const columns = [
    { field: 'id', headerName: 'ID', width: 50, hide: true },
    { field: 'controlName', headerName: 'Control', width: 150, renderCell: renderCellExpand },
    { field: 'surfaceName', headerName: 'Threat Surface', width: 160, renderCell: renderCellExpand },
    { field: 'levelName', headerName: 'Threat Level', width: 160, renderCell: renderCellExpand },
    { field: 'capability', headerName: 'Capability', width: 200, renderCell: renderCellExpand },
    { field: 'threatPrepStart', headerName: 'Start TP', width: 120  },
    { field: 'threatPrepEnd', headerName: 'End TP', width: 120  },
    { field: 'threatPrepDelta', headerName: 'Delta TP', width: 120  },
    { field: 'buildBudgetDelta', headerName: 'Build', width: 100,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatting(Number(params.value), false);
      }
    },
    { field: 'runBudgetDelta', headerName: 'Run', width: 100,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatting(Number(params.value), false);
      }
    },
    { field: 'capexBudgetDelta', headerName: 'Capex', width: 100,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatting(Number(params.value), false);
      }
    },
    { field: 'opexBudgetDelta', headerName: 'Opex', width: 100,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatting(Number(params.value), false);
      }
    },
    { field: 'peopleBudgetDelta', headerName: 'People', width: 130,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatting(Number(params.value), false);
      }
    },
    { field: 'technologyBudgetDelta', headerName: 'Technology', width: 140,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatting(Number(params.value), false);
      }
    },
    { field: 'vendorBudgetDelta', headerName: 'Vendor', width: 120,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatting(Number(params.value), false);
      }
    },
    { field: 'otherBudgetDelta', headerName: 'Other', width: 100,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatting(Number(params.value), false);
      }
    },
    { field: 'headcountDelta', headerName: 'Headcount', width: 130,
      valueFormatter: (params: GridValueFormatterParams) => {
        return Number(params.value).toFixed(2);
      }
    },
  ];

  return (
    <Container maxWidth={false}>
      <Box display="flex" mt={2}>
        <Grid container spacing={3} className={classes.gridItem}>
          <DataGrid
            rows={actionPlan}
            columns={columns}
            autoPageSize={true}
            autoHeight={true}
            disableColumnMenu={true}
            hideFooterPagination={true}
            checkboxSelection={true}
            components={{
              Toolbar: GridToolbar
            }}
              />
        </Grid>
      </Box>
    </Container>
  );
};

export default ActionPlanTable;
