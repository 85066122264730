import React, { FunctionComponent } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  InputProps,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  kpiHeaderTitle,
  tableContainerStyles,
  headerStyles,
  subHeaderStyles,
} from 'app/kpi/components/tables';
import { StripedTableRow } from 'app/common/StripedTable';
import {
  withStyles,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { ThreatLevel } from 'app/services/apiThreatSurfaces';
import {
  HelpOutlineRounded,
  NoteOutlined,
  NoteTwoTone,
  LinkOutlined,
} from '@material-ui/icons';
import { KPIData } from 'app/kpi/types';
import { getCurrentUser } from 'app/services/auth';
import ValidatedInput from './ValidatedInput';
import { displayHelp } from 'app/services/help';
import { KPIContext } from 'app/services/api';

export interface InputColumn {
  text: string;
  type?: string;
  helpTooltip?: string;
  helpURI?: string;
  inputProps?: InputProps;
  wrap?: boolean;
  textAlign?: 'left' | 'right' | 'center';
  style?: {
    width?: string;
  };
}

export interface InputItem {
  level?: ThreatLevel;
  text: string;
  context?: string;
  kpis: Array<KPIData<any>>;
}

interface KPIValueInputTableProps extends BoxProps {
  contextKey: string;
  text: string;
  helpURI?: string;
  columns: InputColumn[];
  items: InputItem[];
  context?: KPIContext;
  onKPIChange?: (kpi: KPIData<any>, value: string) => void;
  errors: KPIData<any>[];
  onContextDialogOpen: (title: string, context?: KPIContext) => void;
}

const RowHeader = withStyles(() =>
  createStyles({
    root: {
      width: '75%',
    },
  })
)(({ children, ...props }) => (
  <TableCell {...props} component="th" scope="row">
    {children}
  </TableCell>
));

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(1),
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'uppercase',
      color: theme.palette.text.secondary,
    },
    textField: {
      minWidth: '5rem',
      backgroundColor: '#fff',
      '& input': {
        textAlign: 'center',
      },
    },
    textFieldAlignLeft: {
      backgroundColor: '#fff',
      '& input': {
        textAlign: 'left',
      },
    },
    textFieldAlignRight: {
      backgroundColor: '#fff',
      '& input': {
        textAlign: 'right',
      },
    },
    hiddenTextField: {
      visibility: 'hidden',
    },
  })
);

export const KPIValueInputTable: FunctionComponent<KPIValueInputTableProps> = ({
  contextKey,
  text,
  helpURI,
  columns,
  items,
  context,
  errors,
  onContextDialogOpen,
}: KPIValueInputTableProps) => {
  const currentUser = getCurrentUser();
  let rowHeaderTitle: string | null = kpiHeaderTitle;
  let colSpan = columns.length;
  if (text === 'Budget Estimator') {
    rowHeaderTitle = null;
    colSpan--;
  }
  return (
    <Paper
      elevation={3}
      style={{ padding: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}
    >
      <div style={tableContainerStyles}>
        <Table>
          <TableHead>
            <TableRow style={headerStyles}>
              <TableCell colSpan={colSpan}>
                <Typography variant="h6">{text}</Typography>
              </TableCell>
              <TableCell
                colSpan={1}
                align="right"
                style={{ minWidth: '160px', padding: '0.2rem' }}
              >
                {context ? (
                  <IconButton
                    onClick={() => onContextDialogOpen(contextKey, context)}
                  >
                    <NoteTwoTone />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => onContextDialogOpen(contextKey, context)}
                  >
                    <NoteOutlined />
                  </IconButton>
                )}
                {context && context.contextURI && (
                  <IconButton
                    onClick={() => window.open(context.contextURI!, '_blank')}
                  >
                    <LinkOutlined />
                  </IconButton>
                )}
                <IconButton
                  disabled={!helpURI}
                  onClick={() => displayHelp(helpURI)}
                >
                  <HelpOutlineRounded />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow style={subHeaderStyles}>
              {rowHeaderTitle && <RowHeader>{rowHeaderTitle}</RowHeader>}
              {columns.map((col, i) => {
                if (text === 'Budget Estimator' && col.text === 'Capability') {
                  return (
                    <TableCell key={i} align="left">
                      {col.text}
                    </TableCell>
                  );
                }

                return (
                  <TableCell key={i} align="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box mr={1 / 2}>{col.text}</Box>
                      {col.helpURI && (
                        <IconButton
                          size="small"
                          style={{ paddingRight: 0 }}
                          onClick={() => displayHelp(col.helpURI)}
                        >
                          <HelpOutlineRounded fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, i) => {
              let threatLevelCell;

              if (item.level) {
                threatLevelCell = (
                  <TableCell
                    style={{ minWidth: '130px' }}
                  >{`${item.level.severity}. ${item.level.name}`}</TableCell>
                );
              }

              return (
                <StripedTableRow key={i} style={{ whiteSpace: 'pre-line' }}>
                  {threatLevelCell}
                  <RowHeader>
                    <Box display="flex" alignItems="center">
                      <Box>
                        <Typography component="span" variant="body2">
                          {item.text}
                        </Typography>
                      </Box>
                      {item.context && (
                        <Box flex={1}>
                          <Typography component="span" variant="body2">
                            {item.context}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </RowHeader>
                  {item.kpis.map((kpi, i) => {
                    let columnIdx = i;
                    if (text === 'Budget Estimator') {
                      columnIdx += 2;
                    }
                    return (
                      <TableCell align="center" key={i}>
                        <ValidatedInput
                          currentUser={currentUser}
                          column={columns[columnIdx]}
                          error={errors.includes(kpi)}
                          kpi={kpi}
                        />
                      </TableCell>
                    );
                  })}
                </StripedTableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default KPIValueInputTable;
