import React, { FunctionComponent, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { AddBoxOutlined, Close } from '@material-ui/icons';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { KPIContext, saveKPIContext, KPIMetadata } from 'app/services/api';
import { isValidHttpUrl } from 'app/utils/helpers';

interface KPIContextDialogProps {
  isOpen: boolean;
  contextKey: string;
  kpiMetadata: KPIMetadata[];
  context?: KPIContext | null;
  surfaceId?: number;
  controlId: number;
  searchIndex?: number;
  handleClose: (
    context?: KPIContext,
    searchIndex?: number
  ) => KPIContext | void;
}

const useStyles = makeStyles({
  dialogPaper: {
    height: '70%',
    minHeight: 300,
  },
  tableContainer: {
    height: 370,
    minHeight: 50,
    maxHeight: 'calc(100% - 100px)',
  },
  table: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  tableRow: {
    height: 30,
  },
  selectCell: {
    width: 150,
  },
  contentCell: {
    width: '40%',
    paddingRight: 0,
  },
  uriCell: {
    width: '40%',
    paddingRight: 0,
  },
  closeCell: {
    textAlign: 'right',
    paddingLeft: 0,
    paddingRight: 0,
  },
  tfContextUri: {
    width: '95%',
    position: 'absolute',
    bottom: 50,
  },
  addIconBtn: {
    position: 'absolute',
    bottom: 90,
    right: 15,
  },
});

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const KPIContextDialog: FunctionComponent<KPIContextDialogProps> = ({
  isOpen,
  contextKey,
  kpiMetadata,
  context,
  controlId,
  surfaceId,
  searchIndex,
  handleClose,
}: KPIContextDialogProps) => {
  const classes = useStyles();
  const [data, setData] = useState<Record<string, string>[]>([]);
  const [kpiContextUri, setKpiContextUri] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    if (context && context.contextURI) {
      setKpiContextUri(context.contextURI);
    }

    if (context && context.context) {
      setData([...context.context]);
    } else {
      setData([]);
    }
  }, []);

  useEffect(() => {
    setDisableSave(checkChanges());
  }, [data, kpiContextUri]);

  const checkChanges = (): boolean => {
    const byKey = (a: any, b: any) =>
      a.key > b.key ? 1 : a.key < b.key ? -1 : 0;

    const byUri = (a: any, b: any) =>
      a.uri > b.uri ? 1 : a.uri < b.uri ? -1 : 0;

    if (context) {
      if (context.context && context.contextURI) {
        return (
          JSON.stringify([...context.context].sort(byKey).sort(byUri)) ===
            JSON.stringify([...data].sort(byKey).sort(byUri)) &&
          context.contextURI === kpiContextUri
        );
      } else if (context.context) {
        return (
          JSON.stringify([...context.context].sort(byKey).sort(byUri)) ===
            JSON.stringify([...data].sort(byKey).sort(byUri)) &&
          kpiContextUri.length === 0
        );
      } else if (context.contextURI) {
        return context.contextURI === kpiContextUri && data.length === 0;
      } else {
        return data.length === 0 && kpiContextUri.length === 0;
      }
    } else {
      return data.length === 0 && kpiContextUri.length === 0;
    }
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const newItem = {
      key: event.target.value as string,
      uri: data[index].uri,
      value: data[index].value,
    };

    data[index] = newItem;
    setData([...data]);
  };

  const handleContentChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const newItem = {
      key: data[index].key,
      uri: data[index].uri,
      value: event.target.value as string,
    };

    data[index] = newItem;
    setData([...data]);
  };

  const handleUriChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const newItem = {
      key: data[index].key,
      uri: event.target.value as string,
      value: data[index].value,
    };

    data[index] = newItem;
    setData([...data]);
  };

  const handleDelete = (index: number) => {
    data.splice(index, 1);
    setData([...data]);
  };

  const handleAdd = () => {
    const newItem = { key: kpiMetadata[0].key, uri: '', value: '' };
    setData((oldArray) => [...oldArray, newItem]);
  };

  const handleContextURI = (event: React.ChangeEvent<{ value: string }>) => {
    setKpiContextUri(event?.target.value);
  };

  const handleSnackBarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };

  const handleSave = async () => {
    const param: any = {};
    if (!kpiContextUri || isValidHttpUrl(kpiContextUri)) {
      param.contextURI = kpiContextUri;
    } else {
      setShowAlert(true);
      return;
    }

    let errCount = 0;
    data.forEach((item: any) => {
      if (item.uri) {
        if (!isValidHttpUrl(item.uri)) {
          errCount++;
        }
      }
    });

    if (errCount > 0) {
      setShowAlert(true);
      return;
    }

    const filteredData = data.filter((item) => item.key && item.value);
    param.key = contextKey;
    param.controlId = controlId;
    param.surfaceId = surfaceId;
    param.context = JSON.stringify(filteredData);

    const result = await saveKPIContext(param);
    handleClose(result, searchIndex);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        classes={{ paper: classes.dialogPaper }}
        open={isOpen}
        onClose={() => handleClose()}
        disableBackdropClick
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Context</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table className={classes.table} stickyHeader>
              <TableHead></TableHead>
              <TableBody>
                {data.map((data, i) => {
                  return (
                    <TableRow key={i} className={classes.tableRow}>
                      <TableCell className={classes.selectCell}>
                        <Select
                          style={{ width: '120px' }}
                          value={data.key}
                          defaultValue=""
                          onChange={(e) => handleSelectChange(e, i)}
                          displayEmpty
                        >
                          {kpiMetadata.map((item: KPIMetadata) => {
                            return (
                              <MenuItem key={item.key} value={item.key}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </TableCell>
                      <TableCell className={classes.contentCell}>
                        <TextField
                          margin="dense"
                          label=""
                          placeholder="Name"
                          value={data.value}
                          onChange={(e) => handleContentChange(e, i)}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell className={classes.uriCell}>
                        <TextField
                          margin="dense"
                          label=""
                          placeholder="https://example.com"
                          value={data.uri}
                          onChange={(e) => handleUriChange(e, i)}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell className={classes.closeCell}>
                        <IconButton onClick={() => handleDelete(i)}>
                          <Close />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <IconButton className={classes.addIconBtn} onClick={handleAdd}>
            <AddBoxOutlined />
          </IconButton>
          <TextField
            className={classes.tfContextUri}
            margin="dense"
            id="textUri"
            label="Context URL"
            placeholder="https://example.com"
            value={kpiContextUri}
            onChange={handleContextURI}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={disableSave}>
            Save
          </Button>
        </DialogActions>
        <Snackbar
          open={showAlert}
          autoHideDuration={3000}
          onClose={handleSnackBarClose}
        >
          <Alert onClose={handleSnackBarClose} severity="error">
            Please input valid URL.
          </Alert>
        </Snackbar>
      </Dialog>
    </>
  );
};

export default KPIContextDialog;
