import { KPIGroup as KpiGroupType, TagType } from 'app/services/api';
import { getKpiGroupProductivity } from 'app/utils/graph-math';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import { ControlKpiGroupDataType } from './index';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

const featureName =
  'dashboard/tag-dashboards/summary/sec-ops-kpi-group-productivity';

type SecOpsKpiGroupProductivityType = {
  kpiGroups: KpiGroupType[];
  controlKpiGroupData: ControlKpiGroupDataType[];
  selectedTag: TagType;
};

const SecOpsKpiGroupProductivity = ({
  kpiGroups,
  controlKpiGroupData,
  selectedTag,
}: SecOpsKpiGroupProductivityType): JSX.Element => {
  const managementKpiGroup = kpiGroups.find((group) =>
    group.key.includes('management')
  );
  const resourcesKpiGroup = kpiGroups.find((group) =>
    group.key.includes('resources')
  );
  const integrationKpiGroup = kpiGroups.find((group) =>
    group.key.includes('integration')
  );

  const productivityData = controlKpiGroupData.reduce(
    (result: any, next: ControlKpiGroupDataType, index: number) => {
      const { management, resources, integration } = next;
      const managementProductivity = getKpiGroupProductivity(
        management,
        managementKpiGroup
      );
      const resourcesProductivity = getKpiGroupProductivity(
        resources,
        resourcesKpiGroup
      );
      const integrationProductivity = getKpiGroupProductivity(
        integration,
        integrationKpiGroup
      );

      const newResult = {
        ...result,
        management: result.management + managementProductivity,
        resources: result.resources + resourcesProductivity,
        integration: result.integration + integrationProductivity,
      };
      const isLast = index === controlKpiGroupData.length - 1;
      if (isLast) {
        newResult.management =
          newResult.management / controlKpiGroupData.length;
        newResult.resources = newResult.resources / controlKpiGroupData.length;
        newResult.integration =
          newResult.integration / controlKpiGroupData.length;
      }

      return newResult;
    },
    { management: 0, integration: 0, resources: 0 }
  );

  const barData = [
    { group: managementKpiGroup, data: productivityData.management },
    { group: resourcesKpiGroup, data: productivityData.resources },
    { group: integrationKpiGroup, data: productivityData.integration },
  ]
    .sort(
      (prev, next) =>
        (prev.group?.displayOrder || 0) - (next.group?.displayOrder || 0)
    )
    .map((item) => ({
      id: item.group?.id,
      label: item.group?.name,
      value: Math.round(item.data),
      barColors: getBarColorsForValue(item.data),
    }));

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={6} helpURI="">
            {selectedTag.name} SecOps KPI Group Productivity
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={6} style={{ width: '20%' }}>
            {kpiHeaderTitle}
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.label}`}>
                <StripedTable.GraphLabel
                  isLast={isLast}
                  style={{ width: '20%' }}
                  colSpan={2}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast} colSpan={4}>
                  <BarGraph
                    data={[rowData]}
                    colors={rowData.barColors.background}
                    labelTextColor={rowData.barColors.textColor}
                    axisBottom={isLast ? {} : null}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default SecOpsKpiGroupProductivity;
