import React from 'react';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  getHighLowColours,
  findMaxProductivityValue,
} from 'app/common/BarGraph';
import { TagType } from 'app/services/api';
import { ThreatSurface } from 'app/services/apiThreatSurfaces';
import { threatSurfaceState } from 'app/common/actions/threat-surfaces';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { AllocationSurface } from 'app/utils/budget';

const featureName =
  'dashboard/tag-dashboards/investment/budget-productivity-by-threat-surface';

type BudgetProductivityBySurfaceType = {
  selectedTag: TagType;
  threatSurfaces: threatSurfaceState;
  surfaceBudgets: AllocationSurface[][],
  secOpsProductivity: number[]
};

const BudgetProductivityBySurface = ({
  selectedTag,
  threatSurfaces,
  surfaceBudgets,
  secOpsProductivity
}: BudgetProductivityBySurfaceType): JSX.Element => {
  const dataKeys = ['highProductivity', 'lowProductivity'];
  const threatSurfacePayload = threatSurfaces.payload as ThreatSurface[];

  if (!threatSurfacePayload) {
    return <div />;
  }

  const barData = threatSurfacePayload.map((threatSurface) => {
    let totalHighProductivity = 0;
    let totalLowProductivity = 0;
    surfaceBudgets.forEach((controlBudgets: AllocationSurface[], controlIndex: number) => {
      const surfaceBudget = controlBudgets.find(bSurface => bSurface.surfaceId === threatSurface.id);
      const surfaceBudgetValue = surfaceBudget ? surfaceBudget.value : 0;
      const highProductivity = Math.round(
        surfaceBudgetValue * (Math.round(secOpsProductivity[controlIndex]) / 100)
      );
      const lowProductivity = surfaceBudgetValue - highProductivity;
      totalHighProductivity += highProductivity;
      totalLowProductivity += lowProductivity;
    });
    return {
      id: threatSurface.id,
      label: threatSurface.name,
      highProductivity: totalHighProductivity,
      highProductivityColour: green,
      lowProductivity: totalLowProductivity,
      lowProductivityColour: orange,
    };
  });

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2} helpURI="">
            {selectedTag.name} Budget Productivity by Threat Surface
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            Threat Surface
          </StripedTable.SubHeading>
        </StripedTable.Head>
      </StripedTable>
      <StripedTable>
        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel
                  style={{ width: '20%' }}
                  isLast={isLast}
                  colSpan={1}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast} colSpan={1} style={{ width: '80%' }}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting, tickValues: 7 } : null}
                    maxValue={findMaxProductivityValue(barData)}
                    labelFormat={dollarFormatting}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityBySurface;
