import { useHistory, useLocation } from 'react-router-dom';
import { processAuthentication } from 'app/services/auth';
import PATHS from 'utils/paths';

const Authenticated = () => {
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authorizationCode = params.get('code');

    if (authorizationCode) {
      processAuthentication(authorizationCode)
        .then(() => history.push(PATHS.DASHBOARD))
        .catch(() => history.push(PATHS.SIGNIN));
    } else {
      history.push(PATHS.HOME);
    }
  }, []);

  return null;
};

export default Authenticated;
