import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import MainContent from './MainContent';

const KpiSearch = (): JSX.Element => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={url} component={MainContent} />
      <Redirect to={url} />
    </Switch>
  );
};

export default KpiSearch;
