import { adminCustomGroup, adminGlobalGroup } from './adminControlGroup';
import { adminKpiModels } from './adminKpiModels';
import { adminThreatSurfaces } from './adminThreatSurfaces';
import { adminUser } from './adminUser';
import { adminCrownJewels } from './adminCrownJewels';

export const List: any[] = [
  adminCustomGroup,
  adminGlobalGroup,
  adminKpiModels,
  adminThreatSurfaces,
  adminUser,
  adminCrownJewels,
];
