import fetchJson from 'fetch.json';
import PATHS from 'app/utils/paths';
import { processDeauthentication } from 'app/services/auth';
import { invertKpiValues } from 'app/utils/helpers';
import {
  URLParams,
  getUrl,
  getQueryString,
  getNullableQueryParam,
} from './url';

interface RequestOptions {
  headers: Record<string, string>;
  params?: { include?: string };
}

type RequestFn = (
  url: string,
  body?: any,
  options?: RequestOptions
) => Promise<any>;

type UnauthorizedErrorHandler = (response: Response) => Promise<any>;

let unauthorizedErrorHandler: UnauthorizedErrorHandler;

export const setUnauthorizedErrorHandler = (
  handler: UnauthorizedErrorHandler
) => (unauthorizedErrorHandler = handler);

let unauthorizedErrorHandlerPromise: null | Promise<void> = null;

export const request: Record<string, RequestFn> = [
  'get',
  'post',
  'put',
  'patch',
  'delete',
  'head',
].reduce((request, method) => {
  request[method] = (url: string, body?: any, options?: RequestOptions) => {
    if (method === 'get' && body && !options) {
      options = body;
      body = null;
    }
    if (!/^http/.test(url)) {
      url = getUrl(url, options as URLParams);
    }
    return Promise.resolve(unauthorizedErrorHandlerPromise)
      .then(() => fetchJson[method](url, body, options))
      .catch((response: Response) => {
        if (response.status === 401 && unauthorizedErrorHandler) {
          unauthorizedErrorHandlerPromise =
            unauthorizedErrorHandlerPromise ||
            unauthorizedErrorHandler(response);
          return unauthorizedErrorHandlerPromise
            .then(() => {
              if (options?.headers?.authorization) {
                delete options.headers.authorization;
              }
              return fetchJson[method](url, body, options);
            })
            .catch((response: Response) => {
              if (response.status === 401) {
                // We've got 401 again either during refreshing the token or
                // retrying request, we have no option now but to kick the
                // user to login screen.
                processDeauthentication(window.location.origin);
              }
            })
            .finally(() => (unauthorizedErrorHandlerPromise = null));
        }
        throw response;
      });
  };
  return request;
}, {} as Record<string, RequestFn>);

export interface Company {
  id: number;
  parentId: number | null;
  name: string;
  key: string;
  locale: string;
  currency: string;
}
export interface Control {
  id: number;
  parentId: number | null;
  name: string;
  displayOrder: number;
  tagKey?: string;
  tagId?: number;
  tags?: TagType[];
}

export enum KPIGroupHints {
  Default,
  HasSubgroups,
  HasRepeatSubgroups,
  RepeatKPIsForThreatSurface,
  RepeatKPIsForThreatLevel,
  RepeatKPIsForCapability,
}

export interface KPIGroup {
  id: number;
  parentId: number | null;
  hint: KPIGroupHints;
  key: string;
  path: string;
  name: string;
  helpURI?: string;
  weight: number;
  displayOrder: number;
  subGroups?: KPIGroup[];
}

export interface KPIContext {
  id: number;
  key: string;
  controlId: number;
  levelId: number | null;
  surfaceId: number | null;
  context: Array<Record<string, string>> | null;
  contextURI: string | null;
  userId: number;
  companyId: number;
}

export interface KPIMetadata {
  id: number;
  companyId: number;
  key: string;
  name: string;
  valueTypeId: number;
  displayOrder: number;
  valueType?: KPIValueType;
}

export interface User {
  id: number;
  companyId: number;
  name: string;
  oauth_id: string;
}
export interface ControlCapability {
  id: number;
  name: string;
  controlId: number;
  surfaceTypeId: number;
  levelId: number;
  displayOrder: number;
}

export enum KPIValueTypes {
  Text = 'text',
  Decimal = 'decimal',
  Integer = 'integer',
  Currency = 'currency',
  Percentage = 'percentage',
  Likert = 'likert',
}

export interface KPIValue {
  id: number;
  kpiId: number;
  controlId: number;
  levelId?: number;
  surfaceId?: number;
  userId?: number;
  companyId?: number;
  value: string | number | null;
  kpi: KPI;
  date?: string;
}

export interface KPIValueType {
  id: number;
  key: KPIValueTypes;
  name: string;
}

export interface KPI {
  id: number;
  groupId: number;
  key: string;
  name: string;
  weight: number;
  helpURI?: string;
  valueType: KPIValueType;
  kpiValue: KPIValue[];
  displayOrder: number;
}

// Auth related API calls use `fetchJson` directly to skip 401 handler
export const login = () => fetchJson.get(getUrl(PATHS.API.LOGIN));

export const logout = () => fetchJson.get(getUrl(PATHS.API.LOGOUT));

export const changePassword = () => fetchJson.get(getUrl(PATHS.API.PASSWORD));

export const getAuthToken = (authorizationCode: string) =>
  fetchJson.get(
    getUrl(PATHS.API.TOKEN, {
      query: {
        code: authorizationCode,
      },
    })
  );

export const refreshAuthToken = (refreshToken: string) =>
  fetchJson.get(
    getUrl(PATHS.API.TOKEN, {
      query: {
        grantType: 'refresh_token',
        refreshToken,
      },
    })
  );

export const getCompany = (
  id: string | number,
  options: RequestOptions
): Promise<Company> =>
  request
    .get(`${PATHS.API.COMPANY}/${id}${getQueryString(options.params)}`, options)
    .then((company: Company | Company[]) =>
      Array.isArray(company) ? company[0] : company
    );

export const getCompanies = (
  { parentId }: { parentId: number | string | null },
  options?: RequestOptions
): Promise<Company> =>
  request
    .get(PATHS.API.COMPANIES, {
      query: {
        ...{ parentId },
        ...options?.params,
      },
      ...options?.headers,
    })
    .then((company: Company | Company[]) =>
      Array.isArray(company) ? company[0] : company
    );

export const getUserById = (id: number, options: RequestOptions) =>
  request.get(
    `${PATHS.API.USER}/${id}${getQueryString(options.params)}`,
    options
  );

export const getCurrentUser = (options: RequestOptions): Promise<User> =>
  request.get(`${PATHS.API.USER}${getQueryString(options.params)}`, options);

export const provisionCurrentUser = (options: RequestOptions): Promise<User> =>
  request.post(
    `${PATHS.API.USER}${getQueryString(options.params)}`,
    null,
    options
  );

export const updateCurrentUser = (options: RequestOptions): Promise<User> =>
  request.patch(
    `${PATHS.API.USER}${getQueryString(options.params)}`,
    null,
    options
  );

export const getControls = (
  tagKey?: string,
  include?: string
): Promise<Control[]> =>
  request
    .get(PATHS.API.CONTROLS, {
      query: {
        ...getNullableQueryParam('tagKey', tagKey),
        ...getNullableQueryParam('include', include),
      },
    })
    .then((controls) =>
      controls.sort(
        (prev: Control, next: Control) => prev.displayOrder - next.displayOrder
      )
    );

export const getControl = (id: string | number): Promise<Control> =>
  request
    .get(`${PATHS.API.CONTROL}/${id}`)
    .then((control: Control | Control[]) =>
      Array.isArray(control) ? control[0] : control
    );

const sortNestedGroups = (groups: KPIGroup[]): KPIGroup[] => {
  const result = groups.sort((a, b) => a.displayOrder - b.displayOrder);
  result.forEach((group: KPIGroup) => {
    if (group.subGroups) {
      sortNestedGroups(group.subGroups);
    }
  });
  return result;
};

export const getKPIGroups = (): Promise<KPIGroup[]> =>
  request
    .get(PATHS.API.KPI_GROUPS, {
      query: {
        parentId: null,
        include: 'subGroups',
      },
    })
    .then((groups) =>
      groups.map((grp: KPIGroup) => ({
        ...grp,
        path: grp.key.split('.').pop(),
      }))
    )
    .then((groups) => sortNestedGroups(groups));

export const getKPIGroupsByAttr = (
  parentId?: number | null,
  groupKey?: string | null,
  include?: string
): Promise<KPIGroup[]> =>
  request
    .get(PATHS.API.KPI_GROUPS, {
      query: {
        ...getNullableQueryParam('parentId', parentId),
        ...getNullableQueryParam('groupKey', groupKey),
        ...getNullableQueryParam('include', include),
      },
    })
    .then((groups) =>
      groups.map((grp: KPIGroup) => ({
        ...grp,
        path: grp.key.split('.').pop(),
      }))
    );

export const getControlCapabilities = (
  controlId: number,
  surfaceId?: number
): Promise<ControlCapability[]> =>
  request.get(
    getUrl(PATHS.API.CAPABILITIES, {
      query: {
        controlId,
        ...(surfaceId ? { surfaceId } : {}),
      },
    })
  );

type getKpisAndValuesArgType = {
  groupId?: number;
  groupKey?: string;
  controlId?: number;
  levelId?: number | null;
  surfaceId?: number | null;
  include?: string | undefined;
  date?: string;
};

type getKPIValuesArgType = {
  groupId?: number;
  groupKey?: string;
  controlId?: number;
  levelId?: number | null;
  surfaceId?: number | null;
  include?: string;
  date?: string;
};

export const getKPIValues = ({
  groupId,
  groupKey,
  controlId,
  levelId,
  surfaceId,
  date,
  include,
}: getKPIValuesArgType): Promise<KPIValue[]> => {
  return request
    .get(PATHS.API.KPI_VALUES, {
      query: {
        controlId,
        levelId,
        surfaceId,
        date,
        ...getNullableQueryParam('groupId', groupId),
        ...getNullableQueryParam('groupKey', groupKey),
        ...getNullableQueryParam('include', include),
      },
    })
    .then((kpiValues: KPIValue | KPIValue[]) =>
      ([] as KPIValue[]).concat(kpiValues)
    )
    .catch((response) => {
      if (response.status === 404) {
        return [];
      }
      throw response;
    });
};

export const saveKPIValue = (kpiValue: KPIValue) => {
  const formattedKpiValue = {
    controlId: kpiValue.controlId,
    levelId: kpiValue.levelId,
    surfaceId: kpiValue.surfaceId,
    value:
      kpiValue.value === undefined || kpiValue.value === ''
        ? 0
        : kpiValue.value,
    kpiId: kpiValue.kpiId,
  };

  return request
    .post(PATHS.API.KPI_VALUES, [formattedKpiValue])
    .then(({ data }: { data: KPIValue[] }) => {
      const result = data[0];
      result.value = parseInt(result.value as string);
      return Object.assign(kpiValue, result);
    });
};

export const getKPIsAndValues = async (
  options: getKpisAndValuesArgType
): Promise<KPI[]> => {
  const getKpiValueOptions: getKpisAndValuesArgType = {
    ...options,
  };
  if (options.include === undefined) {
    getKpiValueOptions.include = 'kpis,kpis.valueType,nullvalues,unique';
  }

  return getKPIValues(getKpiValueOptions).then((kpiValues: KPIValue[]) =>
    invertKpiValues(kpiValues)
  );
};

export interface KPISearchFilterType {
  key: string;
  value: string;
  match?: string[];
}

type getKPIContextArgType = {
  key?: string;
  controlId?: number;
  levelId?: number;
  surfaceId?: number;
  include?: string;
  filter?: KPISearchFilterType[];
};

export const getKPIContext = ({
  key,
  controlId,
  levelId,
  surfaceId,
  include,
  filter,
}: getKPIContextArgType): Promise<KPIContext[]> => {
  return request
    .get(PATHS.API.KPI_CONTEXT, {
      query: {
        key,
        controlId,
        ...getNullableQueryParam('levelId', levelId),
        ...getNullableQueryParam('surfaceId', surfaceId),
        ...getNullableQueryParam('include', include),
        filter,
      },
    })
    .then((kpiContext: KPIContext | KPIContext[]) =>
      ([] as KPIContext[]).concat(kpiContext)
    )
    .catch((response) => {
      if (response.status === 404) {
        return [];
      }
      throw response;
    });
};

export const saveKPIContext = (kpiContext: KPIContext) => {
  return request.post(PATHS.API.KPI_CONTEXT, kpiContext);
};

export const getKPIMetadata = (): Promise<KPIMetadata[]> =>
  request
    .get(PATHS.API.KPI_METADATA, {
      query: {
        include: 'valueType',
      },
    })
    .then((metadata: KPIMetadata[]) =>
      metadata.sort((a, b) => a.displayOrder - b.displayOrder)
    );

export enum TagHints {
  Default,
  Unused1,
  Unused2,
  ControlContainer,
}

export type TagType = {
  id: number;
  parentId: number | null;
  companyId: number;
  key: string;
  name: string;
  displayOrder: number;
  subTags: TagType[];
  hint: TagHints;
};

const sortNestedTags = (tags: TagType[]): TagType[] => {
  const result = tags.sort((a, b) => a.displayOrder - b.displayOrder);
  result.forEach((tag: TagType) => {
    if (tag.subTags) {
      sortNestedTags(tag.subTags);
    }
  });
  return result;
};

export const getTags = (parentId: number | null = null): Promise<TagType[]> =>
  request
    .get(PATHS.API.TAGS, {
      query: {
        ...getNullableQueryParam('parentId', parentId),
        include: 'subTags',
      },
    })
    .then((tags: TagType[]) => sortNestedTags(tags));

export const getTagById = (id: number): Promise<TagType> =>
  request.get(`${PATHS.API.TAG}/${id}`);

export default {
  login,
  logout,
  getAuthToken,
  refreshAuthToken,
  getCompanies,
  getCompany,
  getUserById,
  getCurrentUser,
  provisionCurrentUser,
  updateCurrentUser,
  changePassword,
};
