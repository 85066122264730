import { TagType } from 'app/services/api';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

import { ControlKpiGroupDataType } from './index';

type SecOpsProductivityType = {
  selectedTag: TagType;
  controlKpiGroupData: ControlKpiGroupDataType[];
};

const SecOpsProductivity = ({
  controlKpiGroupData,
  selectedTag,
}: SecOpsProductivityType): JSX.Element => {
  const secOpsAverage: number =
    controlKpiGroupData.reduce(
      (result, next) => result + next.secOpsProductivity,
      0
    ) / controlKpiGroupData.length;

  const barData = [
    {
      id: selectedTag.id,
      label: `SecOps Productivity`,
      value: Math.round(secOpsAverage),
    },
  ];

  const barColors = getBarColorsForValue(secOpsAverage);

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={6} helpURI="">
            {selectedTag.name} SecOps Productivity
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={6} style={{ width: '15%' }}>
            {kpiHeaderTitle}
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          <StripedTable.Row>
            <StripedTable.GraphLabel
              isLast
              style={{ width: '15%' }}
              colSpan={2}
            >
              {barData[0].label}
            </StripedTable.GraphLabel>
            <StripedTable.GraphCell isLast colSpan={4}>
              <BarGraph
                data={barData}
                colors={barColors.background}
                labelTextColor={barColors.textColor}
              />
            </StripedTable.GraphCell>
          </StripedTable.Row>
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default SecOpsProductivity;
