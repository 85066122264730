import { KPIGroup as KpiGroupType, KPI } from 'app/services/api';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import { getKpiGroupProductivity } from 'app/utils/graph-math';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

const featureName =
  'dashboard/control-dashboards/summary/sec-ops-kpi-group-productivity';

type SecOpsKpiGroupProductivityType = {
  kpiGroups: KpiGroupType[];
  management: KPI[][] | null;
  resources: KPI[][] | null;
  integration: KPI[][] | null;
};

const SecOpsKpiGroupProductivity = ({
  kpiGroups,
  management,
  resources,
  integration,
}: SecOpsKpiGroupProductivityType): JSX.Element => {
  const managementKpiGroup = kpiGroups.find((group) =>
    group.key.includes('management')
  );
  const resourcesKpiGroup = kpiGroups.find((group) =>
    group.key.includes('resources')
  );
  const integrationKpiGroup = kpiGroups.find((group) =>
    group.key.includes('integration')
  );

  const barData = [
    { kpiGroup: integrationKpiGroup, data: integration },
    { kpiGroup: resourcesKpiGroup, data: resources },
    { kpiGroup: managementKpiGroup, data: management },
  ]
    .sort(
      (prev, next) =>
        (prev.kpiGroup?.displayOrder || 0) - (next.kpiGroup?.displayOrder || 0)
    )
    .map((group) => {
      const value = getKpiGroupProductivity(group.data, group.kpiGroup);

      return {
        id: group.kpiGroup?.id,
        label: group.kpiGroup?.name,
        value,
        barColor: getBarColorsForValue(value),
      };
    });

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={6} helpURI="">
            SecOps KPI Group Productivity
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={6}>
            {kpiHeaderTitle}
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel isLast={isLast} colSpan={2}>
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast} colSpan={4}>
                  <BarGraph
                    data={[rowData]}
                    colors={rowData.barColor.background}
                    labelTextColor={rowData.barColor.textColor}
                    axisBottom={isLast ? {} : null}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default SecOpsKpiGroupProductivity;
