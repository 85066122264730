import React from 'react';
import { TagType, KPIGroup as KpiGroupType, KPI } from 'app/services/api';
import { ThreatLevel, ThreatSurface } from 'app/services/apiThreatSurfaces';
import { threatLevelState } from 'app/common/actions/threat-levels';
import { threatSurfaceState } from 'app/common/actions/threat-surfaces';
import { Typography, Divider } from '@material-ui/core';
import { ControlKpiGroupDataType } from '../../SummaryDashboard';
import ThreatLevelMatrix from './ThreatLevelMatrix';
import ThreatLevelScopeMatrix from './ThreatLevelScopeMatrix';
import ThreatLevelCoverageMatrix from './ThreatLevelCoverageMatrix';

type ThreatLevelMatrixType = {
  kpiGroups: KpiGroupType[];
  selectedTag: TagType;
  threatLevels: threatLevelState;
  threatSurfaces: threatSurfaceState;
  controlKpiGroupData: ControlKpiGroupDataType[];
  securityCoverageData: KPI[][];
};

const ThreatPreparednessByThreatLevelMatrix = ({
  kpiGroups,
  selectedTag,
  controlKpiGroupData,
  securityCoverageData,
  threatLevels,
  threatSurfaces,
}: ThreatLevelMatrixType) => {
  const tSurfacePayload = threatSurfaces.payload as ThreatSurface[];
  const tLevelPayload = threatLevels.payload as ThreatLevel[];

  if (!tSurfacePayload || !tLevelPayload) {
    return <div />;
  }

  const securityKpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === 'security-coverage'
  );
  const sortedLevelPayload = tLevelPayload.sort((a, b) =>
    a.displayOrder < b.displayOrder
      ? -1
      : a.displayOrder > b.displayOrder
      ? 1
      : 0
  );

  return (
    <>
      <Typography variant="h6">Threat Preparedness by Threat Level</Typography>
      <Divider style={{ marginBottom: '2rem' }} />

      {sortedLevelPayload.map((level, idx) => (
        <div key={`tp-${level.name}-${idx}`}>
          <ThreatLevelMatrix
            securityKpiGroup={securityKpiGroup}
            controlKpiGroupData={controlKpiGroupData}
            securityCoverageData={securityCoverageData}
            selectedTag={selectedTag}
            surfacePayload={tSurfacePayload}
            levelName={level.name}
            levelID={level.id}
          />
          <ThreatLevelScopeMatrix
            securityKpiGroup={securityKpiGroup}
            controlKpiGroupData={controlKpiGroupData}
            securityCoverageData={securityCoverageData}
            selectedTag={selectedTag}
            surfacePayload={tSurfacePayload}
            levelName={level.name}
            levelID={level.id}
          />
          <ThreatLevelCoverageMatrix
            securityKpiGroup={securityKpiGroup}
            controlKpiGroupData={controlKpiGroupData}
            securityCoverageData={securityCoverageData}
            selectedTag={selectedTag}
            surfacePayload={tSurfacePayload}
            levelName={level.name}
            levelID={level.id}
          />

          {idx < sortedLevelPayload.length - 1 ? (
            <Divider style={{ marginBottom: '2rem' }} />
          ) : null}
        </div>
      ))}
    </>
  );
};

export default ThreatPreparednessByThreatLevelMatrix;
