import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import MuiGreen from '@material-ui/core/colors/green';
import MuiBlue from '@material-ui/core/colors/blue';
import MuiRed from '@material-ui/core/colors/red';
import MuiOrange from '@material-ui/core/colors/orange';
import MuiAmber from '@material-ui/core/colors/amber';
import MuiYellow from '@material-ui/core/colors/yellow';
import MuiLime from '@material-ui/core/colors/lime';
import MuiLightGreen from '@material-ui/core/colors/lightGreen';

import { getCurrentUser } from 'app/services/auth';

const theme = {
  axis: {
    fontSize: '14px',
    fontWeight: 400,
    tickColor: '#eee',
    ticks: {
      line: {
        stroke: '#555555',
      },
      text: {
        fill: '#000000',
        fontSize: '14px',
        fontWeight: 400,
      },
    },
    legend: {
      text: {
        fontSize: '14px',
        fill: '#aaaaaa',
      },
    },
  },
};

export const getBarColorsForValue = (
  val: number
): {
  background: string;
  textColor: string;
} => {
  let background;
  let textColor = 'black';
  if (val <= 9) {
    background = MuiRed[800];
    textColor = 'white';
  } else if (val <= 19) {
    background = MuiRed[500];
    textColor = 'white';
  } else if (val <= 29) {
    background = MuiOrange[800];
  } else if (val <= 39) {
    background = MuiOrange[500];
  } else if (val <= 49) {
    background = MuiAmber[500];
  } else if (val <= 59) {
    background = MuiYellow[500];
  } else if (val <= 69) {
    background = MuiLime[500];
  } else if (val <= 79) {
    background = MuiLightGreen[400];
  } else if (val <= 89) {
    background = MuiLightGreen[700];
  } else if (val <= 99) {
    background = MuiGreen[500];
  } else {
    background = MuiGreen[800];
    textColor = 'white';
  }

  return { background: background, textColor: textColor };
};

export const getHighLowColours = ({ id, data }: { id: string; data: any }) =>
  data[`${id}Colour`];

export const blue = MuiBlue[500];

export const green = MuiGreen[500];
export const darkGreen = MuiGreen[800];

export const orange = MuiOrange[500];

export const customTooltip = ({
  data,
  id,
  value,
  color,
  indexValue,
}: {
  data: any;
  id: string;
  value: number;
  color: string;
  indexValue: string;
}) => (
  <strong style={{ color }}>
    {data[`${id}BelongsTo`]} - {indexValue}: {value}
  </strong>
);

export const dollarFormatting = (value: string): string => {
  const currency = getCurrentUser()?.currency;

  if (currency) {
    const decimals = 0;
    return currency.currencyFormatter(Number(value), decimals);
  }

  return `$${value}`;
};

export const percentFormatting = (value: string): string => {
  return `${value}%`;
};

export const getMaxCurrentTargetValue = (barData: any) =>
  barData.reduce((result: number, next: any) => {
    let newResult = result;

    if (next.bar1 > newResult) {
      newResult = next.bar1;
    }

    if (next.bar2 > newResult) {
      newResult = next.bar2;
    }

    return newResult;
  }, 0);

export const findMaxProductivityValue = (barData: any) =>
  barData.reduce((result: number, next: any) => {
    let newResult = result;

    if (next.highProductivity + next.lowProductivity > newResult) {
      newResult = next.highProductivity + next.lowProductivity;
    }

    return newResult;
  }, 0);

const BarGraph = (props: any): JSX.Element => (
  <ResponsiveBar
    theme={theme}
    layout="horizontal"
    padding={0.2}
    enableLabel
    margin={{
      top: 5,
      right: 100,
      bottom: 30,
      left: 10,
    }}
    height={90}
    indexBy="label"
    enableGridY={false}
    enableGridX={false}
    minValue={0}
    maxValue={100}
    axisLeft={null}
    {...props}
  />
);

export default BarGraph;
