import { actionTypes } from 'common/actions';

const initState = {
  tag: null,
  control: null,
};

export default (state = { ...initState }, action: any) => {
  switch (action.type) {
    case actionTypes.updateAppSelection:
      return {
        ...state,
        ...action.newSelections,
      };

    default:
      return state;
  }
};
