import React from 'react';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { KPIGroup, KPI, Control } from 'app/services/api';
import { renderCellExpand } from 'app/common/GridCellExpand';

type ControlKPITableValues = {
  id: number;
  controlName: string;
  kpiGroupName: string;
  kpiSubGroupName: string;
  kpiName: string;
  kpiValue: number;
};

type ControlKPITableType = {
  kpiGroup: KPIGroup;
  subGroups: KPIGroup[];
  controls: Control[];
  controlKpiValues: KPI[][][];
};

const ControlKPITable = ({
  kpiGroup,
  subGroups,
  controls,
  controlKpiValues
}: ControlKPITableType): JSX.Element => {

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.2, hide: true },
    { field: 'controlName', headerName: 'Control', flex: 1.5, renderCell: renderCellExpand },
    { field: 'kpiGroupName', headerName: 'KPI Group', flex: 1, renderCell: renderCellExpand },
    { field: 'kpiSubGroupName', headerName: 'KPI Sub-Group', flex: 1, renderCell: renderCellExpand },
    { field: 'kpiName', headerName: 'KPI Name', flex: 2.5, renderCell: renderCellExpand },
    { field: 'kpiValue', headerName: 'KPI Value (%)', flex: 1, renderCell: renderCellExpand }
  ];

  if (
    !kpiGroup ||
    !subGroups ||
    !controlKpiValues ||
    (Array.isArray(controlKpiValues) && !controlKpiValues.length)
  ) {
    return <div>No KPIs found</div>;
  }

  let idx = 0;
  const controlKpis: ControlKPITableValues[] = [];
  controlKpiValues.forEach((controlGroupKpis: KPI[][]) => {
    controlGroupKpis.forEach((controlSubGroupKpis: KPI[]) => {
      controlSubGroupKpis.forEach((kpi: KPI) => {
        if (kpi.kpiValue && kpi.kpiValue.length > 0) {
          const control: Control | undefined = controls.find(c => c.id === kpi.kpiValue[0].controlId);
          const controlName = control ? control.name : "";
          const subGroup: KPIGroup | undefined = subGroups.find(s => s.id === kpi.groupId);
          const subGroupName = subGroup ? subGroup.name : "";
          const controlKpi: ControlKPITableValues = {
            id: idx,
            controlName: controlName,
            kpiGroupName: kpiGroup.name,
            kpiSubGroupName: subGroupName,
            kpiName: kpi.name,
            kpiValue: Number(kpi.kpiValue[0].value) * 20
          };
          controlKpis.push(controlKpi);
          idx += 1;
        }
      });
    });
  });

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading
            colSpan={1}
            helpURI=""
          >
            {kpiGroup.name} KPI Details
          </StripedTable.Heading>
        </StripedTable.Head>
      </StripedTable>
      <DataGrid
        rows={controlKpis}
        columns={columns}
        autoPageSize={true}
        autoHeight={true}
        disableColumnMenu={true}
        hideFooterPagination={true}
        checkboxSelection={true}
        components={{
          Toolbar: GridToolbar
        }}
      />
    </Section>
  );
};

export default ControlKPITable;
