import React from 'react';
import {
  Container,
  Box,
  Grid,
} from '@material-ui/core';

import {
  dollarFormatting,
  getHighLowColours,
  captionColorFormatting
} from 'app/common/BudgetBarGraph';
import StripedTable from 'app/common/StripedTable';
import { ResponsiveBar } from '@nivo/bar';
import Section from 'app/common/Section';
import { makeStyles } from '@material-ui/core/styles';
import { getCurrentUser } from 'app/services/auth';


const chartTooltip = (
  value: number | string,
  color: string,
) => {
  const currency = getCurrentUser()?.currency;
  if (currency) {
    return (
      <strong style={{ color }}>
        {currency.currencyFormatter(Number(value), 0)}
      </strong>
    );
  }
  return (
    <strong style={{ color }}>
      `$${value}`
    </strong>
  );
};

export const chartLabel = (data: any): string => {
  const currency = getCurrentUser()?.currency;

  if (currency) {
    const decimals = 0;
    return currency.currencyFormatter(
      Number(data.value),
      decimals
    );
  }

  return `$${data.value}`;
};

type ControlGroupBudgetChartType = {
  title: string;
  controlGroupBudgets: any;
  maxBudgetValue: number;
};

const ControlGroupBudgetChart = ({
  title,
  controlGroupBudgets,
  maxBudgetValue,
}: ControlGroupBudgetChartType): JSX.Element => {

  controlGroupBudgets.sort((a: any, b: any) => a.budget - b.budget);
  let maxLabelLen = 0;
  controlGroupBudgets.forEach((controlBudget: any) => {
    maxLabelLen = Math.max(controlBudget.label.length, maxLabelLen);
  });
  const chartHeight = controlGroupBudgets.length * 45 + 50;
  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      paddingLeft: 10,
      paddingRight: 10
    },
    gridItem: {
      position: 'relative',
      height: chartHeight + 50
    },
    gridContainerFontSize: {
      fontSize: 18,
    },
  });
  const classes = useStyles();

  const theme = {
    axis: {
      fontSize: '14px',
      fontWeight: 400,
      tickColor: '#eee',
      ticks: {
        line: {
          stroke: '#555555',
        },
        text: {
          fill: '#000000',
          fontSize: '14px',
          fontWeight: 400,
        },
      },
      legend: {
        text: {
          fontSize: '14px',
          fill: '#aaaaaa',
        },
      },
    },
  };
  
  return (
    <>
      <Section>
        <Container className={classes.root} maxWidth={false}>
          <Box display="flex" mt={0.5}>
            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.gridContainerFontSize}>
                <StripedTable>
                  <StripedTable.Head>
                    <StripedTable.Heading colSpan={2} helpURI="">
                      {title}
                    </StripedTable.Heading>
                  </StripedTable.Head>
                </StripedTable>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <ResponsiveBar
                  theme={theme}
                  layout="horizontal"
                  margin={{
                    top: 5,
                    right: 30,
                    bottom: 30,
                    left: maxLabelLen * 7 + 10,
                  }}
                  indexBy="label"
                  data={controlGroupBudgets}
                  keys={["budget"]}
                  colors={getHighLowColours}
                  axisBottom={{ tickValues: 7, format: dollarFormatting }}
                  enableGridY={false}
                  enableGridX={false}
                  enableLabel={true}
                  minValue={0}
                  maxValue={maxBudgetValue}
                  tooltip={tooltipProps => {
                    return chartTooltip(tooltipProps.value, tooltipProps.color);
                  }}
                  padding={0.3}
                  label={(data) => chartLabel(data)}
                  labelTextColor={(data) => captionColorFormatting(data)}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default ControlGroupBudgetChart;
