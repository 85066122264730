import React from 'react';
import { TagType } from 'app/services/api';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  findMaxProductivityValue,
  getHighLowColours,
} from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { AllocationResource } from 'app/utils/budget';

const featureName =
  'dashboard/tag-dashboards/investment/budget-productivity-by-resource';

type BudgetProductivityByResourceType = {
  selectedTag: TagType;
  resourceBudgets: AllocationResource[][]
  secOpsProductivity: number[]
};

type resourceType = {
  label: string;
  highProductivity: number;
  highProductivityColour: string;
  lowProductivity: number;
  lowProductivityColour: string;
};

const BudgetProductivityByResource = ({
  selectedTag,
  resourceBudgets,
  secOpsProductivity
}: BudgetProductivityByResourceType) => {

  const resourceMap: any = {};
  resourceBudgets.forEach((controlBudgets: AllocationResource[], controlIdx: number) => {
    controlBudgets.forEach((resourceBudget: AllocationResource, resourceIdx: number) => {
      const highProductivity = Math.round(
        resourceBudget.value * (Math.round(secOpsProductivity[controlIdx]) / 100)
      );
      const lowProductivity = resourceBudget.value - highProductivity;
      if (resourceBudget.key in resourceMap) {
        resourceMap[resourceBudget.key].highProductivity += highProductivity;
        resourceMap[resourceBudget.key].lowProductivity += lowProductivity;
      } else {
        resourceMap[resourceBudget.key] = {
          id: resourceIdx,
          label: resourceBudget.key,
          highProductivity,
          highProductivityColour: green,
          lowProductivity,
          lowProductivityColour: orange,
        };
      }
    });
  });
  const barData: resourceType[] = Object.values(resourceMap);
  const dataKeys = ['highProductivity', 'lowProductivity'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2} helpURI="">
            {selectedTag.name} Budget Productivity by Resource Type
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            Resource Type
          </StripedTable.SubHeading>
        </StripedTable.Head>
      </StripedTable>
      <StripedTable>
        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.label}`}>
                <StripedTable.GraphLabel
                  style={{ width: '20%' }}
                  isLast={isLast}
                  colSpan={1}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast} colSpan={1} style={{ width: '80%' }}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting, tickValues: 7 } : null}
                    maxValue={findMaxProductivityValue(barData)}
                    labelFormat={dollarFormatting}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityByResource;
