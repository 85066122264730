import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  ReactElement,
  ChangeEvent,
  SyntheticEvent,
  forwardRef,
  Ref,
} from 'react';
import {
  useTheme,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Toolbar,
  Tooltip,
  Typography,
  Paper,
  IconButton,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Zoom,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  MenuItem,
  Button,
  Snackbar,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import MuiAlert from '@material-ui/lab/Alert';
import MuiHeaderColor from '@material-ui/core/colors/grey';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import { InputAdornment } from '@material-ui/core';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import { displayHelp } from 'app/services/help';
import {
  patchAssets,
  saveAsset,
  patchAsset,
  deleteAsset,
  Asset,
  AssetType,
  ImpactScale,
  ImpactType,
} from 'app/services/apiAssets';
import { getCurrentUser } from 'app/services/auth';
import { debounce, isEmpty } from 'lodash';
import { HeadCell } from '../adminCrownJewels';

const useHeadStyles = makeStyles(() =>
  createStyles({
    column: {
      width: '17%',
      backgroundColor: MuiHeaderColor[300],
    },
    features: {
      width: '3%',
      backgroundColor: MuiHeaderColor[300],
    },
  })
);

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  headCells: HeadCell[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const classes = useHeadStyles();
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow>
        {/* Drag and Drop icon */}
        <TableCell padding="default" className={classes.features}></TableCell>

        {/* Subtitle */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="default"
            className={classes.column}
          >
            {headCell.label}
          </TableCell>
        ))}
        {/* Delete / Restore icon */}
        <TableCell padding="default" className={classes.features}></TableCell>
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      backgroundColor: MuiHeaderColor[400],
    },
    title: {
      flex: '1 1 100%',
    },
    formControl: {
      marginTop: theme.spacing(3),
      width: '100%',
    },
    margin: {
      margin: theme.spacing(1),
    },
    dialog: {
      marginTop: theme.spacing(2),
    },
    modal: {
      backgroundColor: MuiHeaderColor[300],
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  })
);

interface EnhancedTableToolbarProps {
  subcomponentName: string;
  subcomponentHelpURI?: string;
  assets: Asset[] | null;
  assetTypes: AssetType[] | null;
  impactScales: ImpactScale[] | null;
  impactTypes: ImpactType[] | null;
  permissions: string[];
  onHandleLoadData: (newAsset: any) => void;
}

const AddAssetDialog = ({
  assets,
  assetTypes,
  impactScales,
  impactTypes,
  permissions,
  onHandleLoadData,
}: any) => {
  const classes = useToolbarStyles();
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [open, setOpen] = useState<{
    dialog: boolean;
    snackbar: boolean;
  }>({
    dialog: false,
    snackbar: false,
  });
  const [state, setState] = useState<{
    assetName: string;
    assetType: string;
    impactScale: string;
    impactType: string[];
    impactCostMin: string;
    impactCostMax: string;
    assetNotUnique: boolean;
  }>({
    assetName: '',
    assetType: '',
    impactScale: '',
    impactType: [],
    impactCostMin: '',
    impactCostMax: '',
    assetNotUnique: false,
  });

  const InputProps = {
    startAdornment: (
      <InputAdornment position="start">
        {getCurrentUser()?.currency.symbol}
      </InputAdornment>
    ),
  };

  const handleFormDialog = (name: string, action: boolean) => {
    setOpen({
      ...open,
      [name]: action,
    });
  };

  const handleChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value =
      event.target.name === 'impactCostMin' ||
      event.target.name === 'impactCostMax'
        ? (event.target.value as string).toString().replace(/\D/g, '')
        : event.target.value;

    setState({
      ...state,
      [event.target.name as string]: value,
    });
  };

  const handleCloseSnackbar = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    handleFormDialog('snackbar', !open.snackbar);
  };

  const handleAddAsset = () => {
    if (state.assetName && assets) {
      state.assetNotUnique = assets.some(
        (item: any) =>
          state.assetName.toLocaleLowerCase() === item.name.toLocaleLowerCase()
      );
    }

    if (
      state.assetNotUnique ||
      !state.assetName ||
      !state.assetType ||
      !state.impactScale
    ) {
      handleFormDialog('snackbar', !open.snackbar);
      return;
    }

    const assetTypeId = assetTypes.find(
      (type: any) =>
        type.name.toLocaleLowerCase() === state.assetType.toLocaleLowerCase()
    ).id;

    const impactScaleId = impactScales.find(
      (type: any) =>
        type.name.toLocaleLowerCase() === state.impactScale.toLocaleLowerCase()
    ).id;

    const impactTypeIds = state.impactType.map((item: any) => {
      return impactTypes.find(
        (type: any) =>
          type.name.toLocaleLowerCase() === item.toLocaleLowerCase()
      ).id;
    });

    // Save new Asset
    try {
      const postRequest = async () => {
        const assetContent: any = {
          name: state.assetName,
          displayOrder:
            assets.length === 0
              ? 1
              : parseInt(assets[assets.length - 1].displayOrder) + 1,
          assetTypeId: parseInt(assetTypeId),
          impactScaleId: parseInt(impactScaleId),
          impactTypeIds: impactTypeIds,
          impactCostMin: state.impactCostMin,
          impactCostMax: state.impactCostMax,
        };

        return saveAsset(assetContent);
      };
      postRequest().then((result: any) => {
        // Clear Form
        setState({
          assetName: '',
          assetType: '',
          impactScale: '',
          impactType: [],
          impactCostMin: '',
          impactCostMax: '',
          assetNotUnique: false,
        });

        handleFormDialog('dialog', !open.dialog);

        // Re-load the data
        const newAsset: any = result;
        newAsset.impactTypes = state.impactType.map((item: any) => {
          return impactTypes.find(
            (type: any) =>
              type.name.toLocaleLowerCase() === item.toLocaleLowerCase()
          );
        });

        onHandleLoadData(newAsset);
      });
    } catch (e) {
      console.log('There has been a problem with post operation: ' + e.message);
    }
  };

  return (
    <>
      <Tooltip title="Add New Asset" TransitionComponent={Zoom} arrow>
        <IconButton
          disabled={!permissions.includes('create:config')}
          onClick={() => handleFormDialog('dialog', !open.dialog)}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={open.dialog}
        onClose={() => handleFormDialog('dialog', !open.dialog)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.modal}
          disableTypography
        >
          <Typography variant="h6">New Asset</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleFormDialog('dialog', !open.dialog)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          {/* Asset Name */}
          <TextField
            required
            autoFocus
            id="assetName"
            label="Asset Name"
            variant="outlined"
            fullWidth
            name="assetName"
            value={state.assetName}
            onChange={handleChange}
          />

          {/* Asset Type */}
          <FormControl
            variant="outlined"
            className={classes.formControl}
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Asset Type
            </InputLabel>
            <Select
              labelId="select-outlined-label"
              id="select-outlined-asset-type"
              name="assetType"
              value={state.assetType}
              onChange={handleChange}
              label="Asset Type *"
            >
              {assets ? (
                assetTypes.map((type: any) => (
                  <MenuItem value={type.name} key={`asset-type-${type.id}`}>
                    {type.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" key={'asset-type-null'}></MenuItem>
              )}
            </Select>
          </FormControl>

          {/* Impact Scale */}
          <FormControl
            variant="outlined"
            className={classes.formControl}
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Impact Scale
            </InputLabel>
            <Select
              labelId="select-outlined-label"
              id="select-outlined-impact-scale"
              name="impactScale"
              value={state.impactScale}
              onChange={handleChange}
              label="Impact Scale *"
            >
              {assets ? (
                impactScales.map((type: any) => (
                  <MenuItem value={type.name} key={`impact-scale-${type.id}`}>
                    {type.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" key={'impact-scale-null'}></MenuItem>
              )}
            </Select>
          </FormControl>

          {/* Impact Types */}
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Impact Types
            </InputLabel>
            <Select
              labelId="select-outlined-label"
              id="select-outlined-impact-scale"
              name="impactType"
              value={state.impactType}
              onChange={handleChange}
              label="Impact Types"
              multiple
            >
              {assets ? (
                impactTypes.map((type: any) => (
                  <MenuItem value={type.name} key={`impact-scale-${type.id}`}>
                    {type.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" key={'impact-scale-null'}></MenuItem>
              )}
            </Select>
          </FormControl>

          {/* Impact Cost Min */}
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              id="impact_cost_min"
              label="Impact Cost Min"
              variant="outlined"
              fullWidth
              name="impactCostMin"
              InputProps={InputProps}
              value={Number(state.impactCostMin).toLocaleString(
                getCurrentUser()?.currency.locale,
                getCurrentUser()?.currency
              )}
              onChange={handleChange}
            />
          </FormControl>

          {/* Impact Cost Max */}
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              id="impact_cost_max"
              label="Impact Cost Max"
              variant="outlined"
              fullWidth
              name="impactCostMax"
              InputProps={InputProps}
              value={Number(state.impactCostMax).toLocaleString(
                getCurrentUser()?.currency.locale,
                getCurrentUser()?.currency
              )}
              onChange={handleChange}
            />
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            className={classes.margin}
            onClick={handleAddAsset}
            disabled={
              !state.assetName || !state.assetType || !state.impactScale
            }
          >
            Add
          </Button>
          <Snackbar
            open={open.snackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={state.assetNotUnique ? 'error' : 'warning'}
              onClose={handleCloseSnackbar}
            >
              {state.assetNotUnique
                ? 'Asset Names must be unique.'
                : 'All fields are required!'}
            </MuiAlert>
          </Snackbar>
        </DialogActions>
      </Dialog>
    </>
  );
};

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const {
    subcomponentName,
    subcomponentHelpURI,
    assets,
    assetTypes,
    impactScales,
    impactTypes,
    permissions,
    onHandleLoadData,
  } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {subcomponentName}
      </Typography>
      {/* Add Row Button */}
      {assets ? (
        <AddAssetDialog
          assets={assets}
          assetTypes={assetTypes}
          impactScales={impactScales}
          impactTypes={impactTypes}
          permissions={permissions}
          onHandleLoadData={onHandleLoadData}
        />
      ) : null}
      {/* Help Button */}
      <IconButton
        disabled={!isEmpty(subcomponentHelpURI)}
        onClick={() =>
          displayHelp({
            context: 'admin',
            resource: subcomponentHelpURI,
          })
        }
      >
        <HelpOutlineRounded />
      </IconButton>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      padding: '1.5rem',
      marginTop: '0.5rem',
      marginBottom: '2rem',
    },
    table: {
      minWidth: 750,
    },
    container: {
      borderBottom: `1px solid ${MuiHeaderColor[400]}`,
    },
    input: {
      marginTop: theme.spacing(0.25),
    },
    // TextField - Asset Name
    textField: {
      margin: theme.spacing(0.15),
      width: '90%',
    },
    // SelectBox - Asset Type
    formControl: {
      margin: theme.spacing(0.15),
      width: '100%',
    },
    impactTypes: { maxWidth: 250, width: '100%' },
    // Drag and Drop Component
    dnd: {
      cursor: 'grab',
    },
  })
);

const AssetRow = ({
  value,
  assetIndex,
  labelId,
  assetTypes,
  impactScales,
  impactTypes,
  permissions,
  onHandleChangeEditRow,
}: any) => {
  const classes = useStyles();

  const InputProps = {
    startAdornment: (
      <InputAdornment position="start">
        {getCurrentUser()?.currency.symbol}
      </InputAdornment>
    ),
  };

  const assetTypesContent = assetTypes
    ? assetTypes.filter((item: any) => value.assetTypeId === item.id)[0]
    : {
        id: 0,
        name: '',
      };

  const impactScalesContent = impactScales
    ? impactScales.filter((item: any) => value.impactScaleId === item.id)[0]
    : {
        id: 0,
        name: '',
      };

  const impactTypesContent = value.impactTypes
    ? value.impactTypes.map((item: any) => {
        return item.name;
      })
    : [];

  const debounceSave = useCallback(
    debounce((value, type) => onHandleChangeEditRow(value, type), 100),
    []
  );

  const handleTextFieldChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const newAsset = { ...value };
    switch (event.target.name) {
      case 'assetName':
        if (String(event.target.value).length > 0) {
          newAsset.name = event.target.value as string;
        }
        break;
      case 'impactCostMin':
        newAsset.impactCostMin = (event.target.value as string)
          .toString()
          .replace(/\D/g, '');
        break;
      case 'impactCostMax':
        newAsset.impactCostMax = (event.target.value as string)
          .toString()
          .replace(/\D/g, '');
        break;
    }

    debounceSave(newAsset, event.target.name);
  };

  const handleAssetTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    const assetTypeId = assetTypes.filter(
      (item: any) => event.target.value === item.name
    )[0].id;
    const newAsset = { ...value };
    newAsset.assetTypeId = assetTypeId;
    onHandleChangeEditRow(newAsset, 'assetType');
  };

  const handleImpactScaleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const impactScaleId = impactScales.filter(
      (item: any) => event.target.value === item.name
    )[0].id;
    const newAsset = { ...value };
    newAsset.impactScaleId = impactScaleId;

    onHandleChangeEditRow(newAsset, 'impactScale');
  };

  const handleImpactTypesChange = (event: ChangeEvent<{ value: unknown }>) => {
    const val: string[] = event.target.value as string[];
    const impactTypeIds = val.map((item: any) => {
      return impactTypes.find(
        (type: any) =>
          type.name.toLocaleLowerCase() === item.toLocaleLowerCase()
      ).id;
    });

    const impactTypesContent = val.map((item: any) => {
      return impactTypes.find(
        (type: any) =>
          type.name.toLocaleLowerCase() === item.toLocaleLowerCase()
      );
    });

    const newAsset = { ...value };
    newAsset.impactTypes = impactTypesContent;
    newAsset.impactTypeIds = impactTypeIds;
    onHandleChangeEditRow(newAsset, 'impactType');
  };

  return (
    <>
      {/* Asset Name */}
      <TableCell padding="none" id={`name-${labelId}`}>
        <form className={classes.textField} noValidate autoComplete="off">
          <div>
            <TextField
              required
              id={`name-required-${assetIndex}`}
              label="Required"
              name="assetName"
              defaultValue={value.name}
              variant="outlined"
              disabled={
                !permissions.includes('update:config') || value.attr !== 0
              }
              onChange={(e) => handleTextFieldChange(e)}
            />
          </div>
        </form>
      </TableCell>

      {/* Asset Type */}
      <TableCell align="left">
        <FormControl
          variant="outlined"
          className={classes.formControl}
          required
          disabled={!permissions.includes('update:config') || value.attr !== 0}
        >
          <InputLabel htmlFor="outlined-name-native-required">
            Required
          </InputLabel>
          <Select
            labelId={`select-outlined-label-${assetTypesContent.id}`}
            id={`select-outlined-id-${assetIndex}`}
            value={assetTypesContent.name}
            onChange={handleAssetTypeChange}
            label="Required *"
          >
            {assetTypes ? (
              assetTypes.map((type: any) => (
                <MenuItem value={type.name} key={`asset-type-${type.id}`}>
                  {type.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" key={'asset-type-null'}></MenuItem>
            )}
          </Select>
        </FormControl>
      </TableCell>

      {/* Impact Scale */}
      <TableCell align="left">
        <FormControl
          variant="outlined"
          className={classes.formControl}
          required
          disabled={!permissions.includes('update:config') || value.attr !== 0}
        >
          <InputLabel htmlFor="outlined-name-native-required">
            Required
          </InputLabel>
          <Select
            labelId={`select-outlined-label-${impactScalesContent.id}`}
            id={`select-outlined-id-${assetIndex}`}
            value={impactScalesContent.name}
            onChange={handleImpactScaleChange}
            label="Required *"
          >
            {impactScales ? (
              impactScales.map((type: any) => (
                <MenuItem value={type.name} key={`impact-scale-${type.id}`}>
                  {type.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" key={'impact-scale-null'}></MenuItem>
            )}
          </Select>
        </FormControl>
      </TableCell>

      {/* Impact Types */}
      <TableCell align="left">
        <FormControl
          variant="outlined"
          className={classes.impactTypes}
          disabled={!permissions.includes('update:config') || value.attr !== 0}
        >
          <Select
            labelId={`select-outlined-label-${assetIndex}`}
            id={`select-outlined-id-${assetIndex}`}
            value={impactTypesContent}
            onChange={handleImpactTypesChange}
            multiple
          >
            {impactTypes ? (
              impactTypes.map((type: any) => (
                <MenuItem value={type.name} key={`impact-type-${type.id}`}>
                  {type.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" key={'impact-type-null'}></MenuItem>
            )}
          </Select>
        </FormControl>
      </TableCell>

      {/* Impact Cost Min */}
      <TableCell padding="none" id={`impact-cost-min-${labelId}`}>
        <form className={classes.textField} noValidate autoComplete="off">
          <div>
            <TextField
              id={`impact-cost-min-${assetIndex}`}
              name="impactCostMin"
              value={Number(value.impactCostMin).toLocaleString(
                getCurrentUser()?.currency.locale,
                getCurrentUser()?.currency
              )}
              variant="outlined"
              className={classes.input}
              InputProps={InputProps}
              disabled={
                !permissions.includes('update:config') || value.attr !== 0
              }
              onChange={(e) => handleTextFieldChange(e)}
            />
          </div>
        </form>
      </TableCell>

      {/* Impact Cost Max */}
      <TableCell padding="none" id={`impact-cost-max-${labelId}`}>
        <form className={classes.textField} noValidate autoComplete="off">
          <div>
            <TextField
              id={`impact-cost-max-${assetIndex}`}
              name="impactCostMax"
              value={Number(value.impactCostMax).toLocaleString(
                getCurrentUser()?.currency.locale,
                getCurrentUser()?.currency
              )}
              variant="outlined"
              className={classes.input}
              InputProps={InputProps}
              disabled={
                !permissions.includes('update:config') || value.attr !== 0
              }
              onChange={(e) => handleTextFieldChange(e)}
            />
          </div>
        </form>
      </TableCell>
    </>
  );
};

const DragHandle = SortableHandle(({ permissions }: any) => {
  const classes = useStyles();

  return (
    /* Drag and Drop icon */
    <TableCell padding="default">
      <Tooltip title="Drag Up/Down" TransitionComponent={Zoom} arrow>
        <IconButton
          className={classes.dnd}
          disabled={!permissions.includes('update:config')}
        >
          <DragHandleIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
});

interface DeleteRestoreProps {
  value: Asset;
  permissions: string[];
  onHandleDeleteAsset: (isDeleted: boolean, arrayAsset: any[]) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DeleteRestoreHandle = ({
  value,
  permissions,
  onHandleDeleteAsset,
}: DeleteRestoreProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDelete = () => {
    if (open) {
      /* Delete Asset */
      handleOpen();

      const delAsset = [{ id: value.id }];
      try {
        delAsset.map(async (asset: any) => {
          await deleteAsset(asset).then((response) => {
            asset.result = response;

            // Re-load the data
            onHandleDeleteAsset(true, delAsset);
          });
        });
      } catch (e) {
        console.log(
          'There has been a problem with delete operation: ' + e.message
        );
      }
    } else {
      /* Restore Asset */
      const restoreAsset = [{ id: value.id }];
      onHandleDeleteAsset(false, restoreAsset);
    }
  };

  return (
    /* Delete / Restore icon */
    <TableCell padding="default">
      <Tooltip
        title={value.attr !== 0 ? 'Restore' : 'Delete'}
        TransitionComponent={Zoom}
        arrow
      >
        {value.attr !== 0 ? (
          <IconButton
            aria-label={value.attr !== 0 ? 'restore' : 'delete'}
            disabled={!permissions.includes('delete:config')}
            onClick={handleDelete}
          >
            <RestoreIcon />
          </IconButton>
        ) : (
          <IconButton
            aria-label={value.attr !== 0 ? 'restore' : 'delete'}
            disabled={!permissions.includes('delete:config')}
            onClick={handleOpen}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOpen}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" disableTypography>
          <Typography variant="h6">Attention</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure you want to delete ${value.name}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </TableCell>
  );
};

interface AssetProps {
  value: Asset;
  assetIndex: number;
  labelId: string;
  assetTypes: AssetType[] | null;
  impactScales: ImpactScale[] | null;
  impactTypes: ImpactType[] | null;
  permissions: string[];
  onHandleDeleteAsset: (isDeleted: boolean, arrayAsset: any[]) => void;
  onHandleChangeEditRow: (assetRow: any, typeRow: string) => void;
}

const AssetContent = (props: AssetProps) => {
  return (
    <TableRow hover tabIndex={-1} key={props.value.name}>
      {/* Drag and Drop icon */}
      <DragHandle permissions={props.permissions} />

      {/* Table Row with Assets */}
      <AssetRow
        value={props.value}
        assetIndex={props.assetIndex}
        labelId={props.labelId}
        assetTypes={props.assetTypes}
        impactScales={props.impactScales}
        impactTypes={props.impactTypes}
        permissions={props.permissions}
        onHandleChangeEditRow={props.onHandleChangeEditRow}
      />

      {/* Delete / Restore icon */}
      <DeleteRestoreHandle
        value={props.value}
        permissions={props.permissions}
        onHandleDeleteAsset={props.onHandleDeleteAsset}
      />
    </TableRow>
  );
};

const AssetSortableContent = SortableElement((props: AssetProps) => (
  <AssetContent
    value={props.value}
    assetIndex={props.assetIndex}
    labelId={props.labelId}
    assetTypes={props.assetTypes}
    impactScales={props.impactScales}
    impactTypes={props.impactTypes}
    permissions={props.permissions}
    onHandleDeleteAsset={props.onHandleDeleteAsset}
    onHandleChangeEditRow={props.onHandleChangeEditRow}
  />
));

const AssetSortableContainer = SortableContainer(({ children }: any) => {
  return <>{children}</>;
});

interface SubcomponentTableProps {
  subcomponentName: string;
  subcomponentHelpURI?: string;
  crownJewels: Asset[] | null;
  assetTypes: AssetType[] | null;
  impactScales: ImpactScale[] | null;
  impactTypes: ImpactType[] | null;
  headCells: HeadCell[];
  permissions: string[];
}

export default function SubcomponentTable({
  subcomponentName,
  subcomponentHelpURI,
  crownJewels,
  assetTypes,
  impactScales,
  impactTypes,
  headCells,
  permissions,
}: SubcomponentTableProps) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [assets, setAssets] = useState<Asset[] | null>(null);
  const nameNotUnique = useRef(false);

  useEffect(() => {
    if (crownJewels) {
      setAssets(crownJewels);
    }
  }, []);

  const handleSnackbar = () => {
    setOpen(!open);
  };

  const handleLoadData = (newAsset: any) => {
    const asset = assets ? assets.slice(0) : [];
    try {
      asset.push(newAsset);
      setAssets(asset as Asset[]);
    } catch (error) {
      console.log(error);
      handleSnackbar();
    }
  };

  const handleUpdateAssets = (
    updatedAsset: any,
    newAsset: any,
    previousIndex: number,
    newIndex: number
  ) => {
    try {
      (async () => {
        await patchAssets(updatedAsset).then((result: any) => {
          if (typeof result.status === 'undefined') {
            // Drag & Drop Assets
            setAssets(arrayMove(newAsset, previousIndex, newIndex));
          } else {
            console.log(`Error ${result.status}: ${result.response.message}`);
            handleSnackbar();
          }
        });
      })();
    } catch (e) {
      console.log(
        'There has been a problem with update operation: ' + e.message
      );
    }
  };

  const handlePatchAsset = (updatedAsset: any, newAsset: any) => {
    try {
      (async () => {
        await patchAsset(updatedAsset).then((result: any) => {
          if (typeof result.status === 'undefined') {
            setAssets(newAsset as Asset[]);
          } else {
            console.log(`Error ${result.status}: ${result.response.message}`);
            handleSnackbar();
          }
        });
      })();
    } catch (e) {
      console.log(
        'There has been a problem with update operation: ' + e.message
      );
    }
  };

  const handleChangeEditRow = (assetRow: any, typeRow: string) => {
    if (assets) {
      const updatedAsset: any = {};

      // Editing Asset name
      if (typeRow === 'assetName') {
        nameNotUnique.current = assets.some(
          (item: any) =>
            assetRow.name.toLocaleLowerCase() === item.name.toLocaleLowerCase()
        );
      }

      if (!nameNotUnique.current) {
        const newAsset = assets.map((item: any) => {
          if (parseInt(assetRow.id) === item.id) {
            item.name = assetRow.name;
            item.assetTypeId = assetRow.assetTypeId;
            item.impactScaleId = assetRow.impactScaleId;
            item.impactTypeIds = assetRow.impactTypeIds;
            item.impactTypes = assetRow.impactTypes;
            item.impactCostMin = assetRow.impactCostMin;
            item.impactCostMax = assetRow.impactCostMax;

            updatedAsset.id = item.id;

            switch (typeRow) {
              case 'assetName':
                updatedAsset.name = item.name;
                break;
              case 'assetType':
                updatedAsset.assetTypeId = item.assetTypeId;
                break;
              case 'impactScale':
                updatedAsset.impactScaleId = item.impactScaleId;
                break;
              case 'impactType':
                updatedAsset.impactTypeIds = item.impactTypeIds;
                break;
              case 'impactCostMin':
                updatedAsset.impactCostMin = item.impactCostMin;
                break;
              case 'impactCostMax':
                updatedAsset.impactCostMax = item.impactCostMax;
                break;
            }
          }
          return item;
        });

        // Patch Asset
        handlePatchAsset(updatedAsset, newAsset);
      } else {
        handleSnackbar();
      }
    }
  };

  const handleDeleteAsset = (isDeleted: boolean, arrayAsset: any[]) => {
    if (assets) {
      const updatedAsset: any = [];

      // Restore Asset
      if (!isDeleted) {
        const newAsset = assets.map((item) => {
          if (parseInt(arrayAsset[0].id) === item.id) {
            item.attr = 0;

            updatedAsset.push({
              id: item.id,
              name: item.name,
              assetTypeId: item.assetTypeId,
              impactScaleId: item.impactScaleId,
              impactCostMin: item.impactCostMin,
              impactCostMax: item.impactCostMax,
              displayOrder: item.displayOrder,
              attr: item.attr,
            });
          }
          return item;
        });

        // Update Asset
        setAssets(newAsset as Asset[]);
      }
      // Delete Asset
      else {
        // Asset Deleted successfully
        let deleteIndex = 0;
        const newAsset = assets.filter((item, index) => {
          if (parseInt(arrayAsset[0].id) === item.id) {
            deleteIndex = index;
          }
          return parseInt(arrayAsset[0].id) !== item.id;
        });

        // Last asset on the table
        if (deleteIndex > newAsset.length - 1) {
          setAssets(newAsset);
        } else {
          fixDisplayOrder(newAsset, deleteIndex, -1, newAsset.length - 1);
          setAssets(newAsset as Asset[]);
        }
      }
    }
  };

  const assetLength = !assets ? 0 : assets.length;
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, assetLength - page * rowsPerPage);

  const handleSortStart = ({ node, helper }: any) => {
    helper = document.getElementsByClassName('SortableHelper')[0];
    node.childNodes.forEach((td: HTMLTableDataCellElement, index: number) => {
      helper.childNodes[index].style.width = `${td.offsetWidth}px`;
    });
  };

  const fixDisplayOrder = (
    arr: Asset[],
    previousIndex: number,
    newIndex: number,
    lastPosition: number
  ) => {
    // Identify a Deleted element and check if it's the last element
    if (newIndex === -1 && previousIndex > lastPosition) {
      return;
    }
    const newArray = arr.slice(0);

    if (previousIndex === lastPosition) {
      newArray[lastPosition].displayOrder =
        newIndex === -1
          ? lastPosition + 1
          : newArray[lastPosition].displayOrder - (previousIndex - newIndex);

      return newArray;
    }

    // Delete
    if (newIndex === -1) {
      newArray[lastPosition].displayOrder = lastPosition + 1;
    } else {
      previousIndex > newIndex
        ? (newArray[lastPosition].displayOrder += 1)
        : (newArray[lastPosition].displayOrder -= 1);
    }
    lastPosition < previousIndex ? (lastPosition += 1) : (lastPosition -= 1);
    fixDisplayOrder(newArray, previousIndex, newIndex, lastPosition);
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (assets && parseInt(oldIndex) !== parseInt(newIndex)) {
      const updatedAssets: any = [];

      fixDisplayOrder(
        assets,
        parseInt(oldIndex),
        parseInt(newIndex),
        parseInt(newIndex)
      );

      assets.map((item, index) => {
        if (
          (index >= parseInt(oldIndex) && index <= parseInt(newIndex)) ||
          (index <= parseInt(oldIndex) && index >= parseInt(newIndex))
        ) {
          updatedAssets.push({
            id: item.id,
            displayOrder: item.displayOrder,
          });
        }
      });

      // Update Asset
      handleUpdateAssets(
        updatedAssets,
        assets,
        parseInt(oldIndex),
        parseInt(newIndex)
      );
    }
  };

  return (
    <Box ml={2} flex={1}>
      <Paper elevation={3} className={classes.paper}>
        <EnhancedTableToolbar
          subcomponentName={subcomponentName}
          subcomponentHelpURI={subcomponentHelpURI}
          assets={assets}
          assetTypes={assetTypes}
          impactScales={impactScales}
          impactTypes={impactTypes}
          permissions={permissions}
          onHandleLoadData={handleLoadData}
        />
        <AssetSortableContainer
          useDragHandle
          helperClass="SortableHelper"
          onSortStart={handleSortStart}
          onSortEnd={onSortEnd}
        >
          <TableContainer className={classes.container}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead classes={classes} headCells={headCells} />

              <TableBody>
                {!assets
                  ? null
                  : assets
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((assetData, index) => {
                        const labelId = `enhanced-table-${index}`;

                        return !permissions.includes('update:config') ? (
                          <AssetContent
                            key={`table-item-${index}`}
                            assetIndex={index}
                            value={assetData}
                            labelId={labelId}
                            assetTypes={assetTypes}
                            impactScales={impactScales}
                            impactTypes={impactTypes}
                            permissions={permissions}
                            onHandleDeleteAsset={handleDeleteAsset}
                            onHandleChangeEditRow={handleChangeEditRow}
                          />
                        ) : (
                          <AssetSortableContent
                            key={`sortable-item-${assetData.name}-${index}`}
                            index={index}
                            assetIndex={index}
                            value={assetData}
                            labelId={labelId}
                            assetTypes={assetTypes}
                            impactScales={impactScales}
                            impactTypes={impactTypes}
                            permissions={permissions}
                            onHandleDeleteAsset={handleDeleteAsset}
                            onHandleChangeEditRow={handleChangeEditRow}
                          />
                        );
                      })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 110 }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AssetSortableContainer>

        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          component="div"
          count={assetLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <DialogActions>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbar}>
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={handleSnackbar}
          >
            {!nameNotUnique.current
              ? 'Oops! Something went wrong. Please, try again later.'
              : 'Asset Names must be unique.'}
          </MuiAlert>
        </Snackbar>
      </DialogActions>
    </Box>
  );
}
