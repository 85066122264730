import React from 'react';
import Loader from 'app/common/Loader';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  getHighLowColours,
  findMaxProductivityValue,
} from 'app/common/BarGraph';
import { AllocationResource } from 'app/utils/budget';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

const featureName =
  'dashboard/control-dashboards/investment/budget-productivity-by-resource';

type BudgetProductivityByResourceType = {
  resourceBudgets: AllocationResource[] | null;
  secOpsProductivity: number;
};

const BudgetProductivityByResource = ({
  resourceBudgets,
  secOpsProductivity,
}: BudgetProductivityByResourceType) => {
  if (!resourceBudgets) {
    return <Loader />;
  }

  const barData = resourceBudgets.map((resourceBudget: AllocationResource, resourceIdx: number) => {
    const highProductivity = Math.round(
      resourceBudget.value * (Math.round(secOpsProductivity) / 100)
    );
    const lowProductivity = resourceBudget.value - highProductivity;
    return {
      id: resourceIdx,
      label: resourceBudget.key,
      lowProductivity,
      lowProductivityColour: orange,
      highProductivity,
      highProductivityColour: green,
    };
  });
  const dataKeys = ['highProductivity', 'lowProductivity'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2} helpURI="">
            Budget Productivity by Resource Type
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            Resource Type
          </StripedTable.SubHeading>
        </StripedTable.Head>
      </StripedTable>

      <StripedTable>
        <StripedTable.Body>
          {barData.map((rowData, index) => {
            const isLast = index === barData.length - 1;

            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel
                  style={{ width: '20%' }}
                  isLast={isLast}
                  colSpan={1}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast} colSpan={1} style={{ width: '80%' }}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    axisBottom={isLast ? { format: dollarFormatting, tickValues: 7 } : null}
                    maxValue={findMaxProductivityValue(barData)}
                    labelFormat={dollarFormatting}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default BudgetProductivityByResource;
