import React from 'react';
import BudgetBarGraph, {
  getHighLowColours,
  customTooltip,
} from 'app/common/BudgetBarGraph';
import StripedTable from 'app/common/StripedTable';
import Section from 'app/common/Section';

type BudgetAllocationTableType = {
  controlName: string;
  totalValue: number;
  totalData: any;
  totalDataKey: string[];
  budgetSplitData: any;
  budgetSplitDataKeys: string[];
  allocationResourcesData: any;
  allocationResourcesDataKeys: string[];
  allocationLevelsData: any;
  allocationLevelsDataKeys: string[];
  allocationSurfacesData: any;
  allocationSurfacesDataKeys: string[];
};

const BudgetAllocationTable = ({
  controlName,
  totalValue,
  totalData,
  totalDataKey,
  budgetSplitData,
  budgetSplitDataKeys,
  allocationResourcesData,
  allocationResourcesDataKeys,
  allocationLevelsData,
  allocationLevelsDataKeys,
  allocationSurfacesData,
  allocationSurfacesDataKeys,
}: BudgetAllocationTableType): JSX.Element => {
  return (
    <>
      <Section>
        <StripedTable>
          <StripedTable.Head>
            <StripedTable.Heading colSpan={2} helpURI="">
              {controlName} Budget Allocation
            </StripedTable.Heading>
          </StripedTable.Head>
        </StripedTable>
        <StripedTable size="medium">
          <StripedTable.Body>
            <StripedTable.Row>
              <StripedTable.GraphLabel style={{ width: '20%' }} colSpan={1}>
                {totalData[0].label}
              </StripedTable.GraphLabel>
              <StripedTable.GraphCell isLast colSpan={1} style={{ width: '80%' }}>
                <BudgetBarGraph
                  data={totalData}
                  keys={totalDataKey}
                  colors={getHighLowColours}
                  tooltip={customTooltip}
                  maxValue={totalValue}
                />
              </StripedTable.GraphCell>
            </StripedTable.Row>
            <StripedTable.Row>
              <StripedTable.GraphLabel
                style={{ width: '20%' }}
                isLast
                colSpan={1}
              >
                {budgetSplitData[0].label}
              </StripedTable.GraphLabel>
              <StripedTable.GraphCell isLast colSpan={1} style={{ width: '80%' }}>
                <BudgetBarGraph
                  data={budgetSplitData}
                  keys={budgetSplitDataKeys}
                  colors={getHighLowColours}
                  tooltip={customTooltip}
                  maxValue={totalValue}
                />
              </StripedTable.GraphCell>
            </StripedTable.Row>
            <StripedTable.Row>
              <StripedTable.GraphLabel
                style={{ width: '20%' }}
                isLast
                colSpan={1}
              >
                {allocationResourcesData[0].label}
              </StripedTable.GraphLabel>
              <StripedTable.GraphCell isLast colSpan={1} style={{ width: '20%' }}>
                <BudgetBarGraph
                  data={allocationResourcesData}
                  keys={allocationResourcesDataKeys}
                  colors={getHighLowColours}
                  tooltip={customTooltip}
                  maxValue={totalValue}
                />
              </StripedTable.GraphCell>
            </StripedTable.Row>
            <StripedTable.Row>
              <StripedTable.GraphLabel
                style={{ width: '20%' }}
                isLast
                colSpan={1}
              >
                {allocationLevelsData[0].label}
              </StripedTable.GraphLabel>
              <StripedTable.GraphCell isLast colSpan={1} style={{ width: '80%' }}>
                <BudgetBarGraph
                  data={allocationLevelsData}
                  keys={allocationLevelsDataKeys}
                  colors={getHighLowColours}
                  tooltip={customTooltip}
                  maxValue={totalValue}
                />
              </StripedTable.GraphCell>
            </StripedTable.Row>
            <StripedTable.Row>
              <StripedTable.GraphLabel
                style={{ width: '20%' }}
                isLast
                colSpan={1}
              >
                {allocationSurfacesData[0].label}
              </StripedTable.GraphLabel>
              <StripedTable.GraphCell isLast colSpan={1} style={{ width: '80%' }}>
                <BudgetBarGraph
                  data={allocationSurfacesData}
                  keys={allocationSurfacesDataKeys}
                  colors={getHighLowColours}
                  tooltip={customTooltip}
                  maxValue={totalValue}
                />
              </StripedTable.GraphCell>
            </StripedTable.Row>
          </StripedTable.Body>
        </StripedTable>
      </Section>
    </>
  );
};

export default BudgetAllocationTable;
