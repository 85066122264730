import { getControls, getControl, Control } from 'app/services/api';

const featureName = 'common/actions';

// TODO: move to somewhere common, maybe storeConfig?
export type controlsState = {
  isLoading: boolean;
  hasError: Error | null;
  payload: Promise<Control[]> | Control[] | null;
};

interface appStore {
  controls: controlsState;
}

type ControlsDispatchType = {
  type: string;
  payload?:
    | Promise<Control[]>
    | Promise<Control[][]>
    | Control[]
    | Control[][]
    | null;
};

type ControlDispatchType = {
  type: string;
  payload: Promise<Control> | Control | null;
};

export const actionTypes = {
  fetchControls: `${featureName}/FETCH_CONTROLS`,
  fetchControlById: `${featureName}/FETCH_CONTROL_BY_ID`,
  resetControls: `${featureName}/RESET_CONTROLS`,
  fetchSubControls: `${featureName}/FETCH_SUB_CONTROLS`,
};

export const fetchControls = (
  forceFetch = false,
  tagKey?: string,
  include?: string
) => (
  dispatch: React.Dispatch<ControlsDispatchType>,
  getState: () => appStore
): void => {
  const { payload: existingPayload } = getState().controls;

  const hasControls =
    existingPayload && Array.isArray(existingPayload) && existingPayload.length;

  dispatch({
    type: actionTypes.fetchControls,
    payload:
      !forceFetch && hasControls
        ? existingPayload
        : getControls(tagKey, include),
  });
};

export const fetchControlById = (id: number) => (
  dispatch: React.Dispatch<ControlDispatchType>
) =>
  dispatch({
    type: actionTypes.fetchControlById,
    payload: getControl(id),
  });

export const resetControls = () => (
  dispatch: React.Dispatch<{ type: string }>
) =>
  dispatch({
    type: actionTypes.resetControls,
  });

export const fetchSubControls = (tagKeys?: string[], include?: string) => (
  dispatch: React.Dispatch<ControlsDispatchType>
): void => {
  dispatch({
    type: actionTypes.fetchSubControls,
    payload: Promise.all(
      (tagKeys || []).map((tagKey) => getControls(tagKey, include))
    ),
  });
};
