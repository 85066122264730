import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      main: '#007eff',
    },
    grey: {
      500: '#888A9B',
    },
    text: {
      primary: '#363740',
      secondary: '#565866',
    },
  },
});
