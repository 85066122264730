import React from 'react';
import { KPIGroup, KPI } from 'app/services/api';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { getKpiGroupProductivity } from 'app/utils/graph-math';
import { getHelpURN } from 'app/services/help';

const KPIGroupSummary = ({
  title,
  kpiGroup,
  kpiValues,
}: {
  title: string;
  kpiGroup: KPIGroup;
  kpiValues: KPI[][];
}): JSX.Element => {
  const barData = [
    {
      id: kpiGroup.name,
      label: kpiGroup.name,
      value: getKpiGroupProductivity(kpiValues, kpiGroup),
    },
  ];

  let barColors: any;
  if (Array.isArray(barData) && barData.length > 0) {
    barColors = getBarColorsForValue(barData[0].value);
  }

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading
            colSpan={2}
            helpURI={getHelpURN({
              context: 'dashboard',
              resource: kpiGroup.helpURI,
            })}
          >
            {title}
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            {kpiHeaderTitle}
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          <StripedTable.Row>
            <StripedTable.GraphLabel
              isLast
              colSpan={1}
              style={{ width: '20%' }}
            >
              {kpiGroup.name}
            </StripedTable.GraphLabel>
            <StripedTable.GraphCell isLast colSpan={1}>
              <BarGraph
                data={barData}
                colors={barColors.background}
                labelTextColor={barColors.textColor}
              />
            </StripedTable.GraphCell>
          </StripedTable.Row>
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default KPIGroupSummary;
