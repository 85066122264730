import PATHS from 'app/utils/paths';
import { request } from 'app/services/api';

export interface ThreatLevel {
  id: number;
  name: string;
  severity: number;
  displayOrder: number;
}

export interface ThreatSurfaceType {
  id: number;
  name: string;
  description: string;
  helpURI: string;
  displayOrder: number;
  attr: number;
}

export interface ThreatSurface {
  id: number;
  name: string;
  description: string;
  displayOrder: number;
  companyId: number;
  attr: number;
  surfaceTypeId: number;
  surfaceType?: ThreatSurfaceType;
}

export const getThreatLevels = (): Promise<ThreatLevel[]> =>
  request
    .get(PATHS.API.THREAT_LEVELS)
    .then((threatLevels: ThreatLevel[]) =>
      threatLevels.sort((a, b) => a.displayOrder - b.displayOrder)
    );

export const getThreatSurfaceType = (): Promise<ThreatSurfaceType[]> =>
  request
    .get(PATHS.API.THREAT_SURFACE_TYPES)
    .then((threatSurfaceType: ThreatSurfaceType[]) =>
      threatSurfaceType.sort((a, b) => a.displayOrder - b.displayOrder)
    );

export const getThreatSurfaces = (): Promise<ThreatSurface[]> =>
  request
    .get(PATHS.API.THREAT_SURFACES)
    .then((threatSurfaces: ThreatSurface[]) =>
      threatSurfaces.sort((a, b) => a.displayOrder - b.displayOrder)
    );

export const saveThreatSurface = (
  threatSurface: ThreatSurface[]
): Promise<ThreatSurface[]> => {
  return request.post(PATHS.API.THREAT_SURFACE, threatSurface);
};

export const deleteThreatSurface = (
  threatSurface: ThreatSurface[]
): Promise<ThreatSurface[] | Response | Error> => {
  return request
    .delete(PATHS.API.THREAT_SURFACE, threatSurface)
    .then((response: Response) => response)
    .catch((error: Error) => error);
};

export const updateThreatSurface = (
  threatSurface: ThreatSurface[]
): Promise<ThreatSurface[] | Response | Error> => {
  return request
    .patch(PATHS.API.THREAT_SURFACES, threatSurface)
    .then((response: Response) => response)
    .catch((error: Error) => error);
};
