import { KPI, Control, ControlCapability } from 'app/services/api';
import {
  ThreatLevel,
  ThreatSurface,
} from 'app/services/apiThreatSurfaces';
import { getCurrentUser } from 'app/services/auth';

export type LevelToSurfaceMatrix = number[][];

export interface DeltaActionPlanValues {
  id: number;
  controlName: string;
  surfaceName: string;
  levelName: string;
  capability: string;
  threatPrepStart: number;
  threatPrepEnd: number;
  threatPrepDelta: number;
  buildBudgetDelta: number;
  runBudgetDelta: number;
  capexBudgetDelta: number;
  opexBudgetDelta: number;
  peopleBudgetDelta: number;
  technologyBudgetDelta: number;
  vendorBudgetDelta: number;
  otherBudgetDelta: number;
  headcountDelta: number;
};

type DeltaKPIType = {
  allocationLevels: KPI[],
  allocationSurfaces: KPI[],
  allocationResources: KPI[],
  budgetSplit: KPI[],
  humanResources: KPI[],
  capabilities: ControlCapability[]
};

export interface DeltaData {
  preparednessStart: any;
  preparednessEnd: any;
  startTable: any;
  endTable: any;
  changeTable: any;
  controls: Control[];
  levels: ThreatLevel[];
  surfaces: ThreatSurface[];
  totals: number[];
  kpiValues: any;
  subTables: any;
  startDate: string;
  endDate: string;
};

const generateCapabilitiesTable = (
  surfaces: ThreatSurface[],
  levels: ThreatLevel[],
  deltaControlGroupKPIs: DeltaKPIType[]
) => {
  return deltaControlGroupKPIs.map((deltaControlKpis: DeltaKPIType) => {
    const controlCapabilitiesTable: string[][] = new Array(levels.length).fill("").map(() => new Array(surfaces.length).fill(""));
    const controlCapabilities = deltaControlKpis.capabilities;
    levels.forEach((level, levelIndex) => {
      surfaces.forEach((surface, surfaceIndex) => {
        const capability = controlCapabilities.find(
          (value: ControlCapability) => (surface.surfaceTypeId === value.surfaceTypeId && level.id === value.levelId)
        );
        if (capability) {
          controlCapabilitiesTable[levelIndex][surfaceIndex] = capability.name;
        }
      });
    });
    return controlCapabilitiesTable;
  });
};

const generateBudgetKpiTable = (
  surfaces: ThreatSurface[],
  levels: ThreatLevel[],
  deltaControlGroupKPIs: DeltaKPIType[],
  key: string
  ) => {
  return deltaControlGroupKPIs.map((deltaControlKpis: DeltaKPIType) => {
    const controlBudgetTable: LevelToSurfaceMatrix = new Array(levels.length).fill(0).map(() => new Array(surfaces.length).fill(0));
    const controlSurfaces = deltaControlKpis.allocationSurfaces;
    const controlLevels = deltaControlKpis.allocationLevels;
    const levelKey = `com.pharossecurity.zero-based.allocation-to-levels.${key}`;
    const surfaceKey = `com.pharossecurity.zero-based.allocation-to-surfaces.${key}`;
    const levelKPIs : KPI | undefined = controlLevels.find((levelKpiValue: KPI) => levelKpiValue.key === levelKey);
    if (levelKPIs) {
      levels.forEach((level, levelIndex) => {
        const levelKPI = levelKPIs.kpiValue.find(value => level.id === value.levelId);
        const levelValue = levelKPI ? Number(levelKPI.value || 0) : 0;
        controlBudgetTable[levelIndex].fill(levelValue);
      });
    }
    const surfaceKPIs : KPI | undefined = controlSurfaces.find((surfaceKPIValue: KPI) => surfaceKPIValue.key === surfaceKey);
    if (surfaceKPIs) {
      surfaces.forEach((surface, surfaceIndex) => {
        const surfaceKPI = surfaceKPIs.kpiValue.find(value => surface.id === value.surfaceId);
        const surfaceValue = surfaceKPI ? Number(surfaceKPI.value || 0) : 0;
        controlBudgetTable.forEach((controlLevel: number[]) => {
          controlLevel[surfaceIndex] *= surfaceValue / 100;
        });
      });
    }
    return controlBudgetTable;
  });
};

const getBudgetKpis = (
  deltaControlGroupKPIs: DeltaKPIType[],
  key: string
) => {
  return deltaControlGroupKPIs.map((deltaControlKpis: DeltaKPIType) => {
    let budgetKpis: KPI[] = [];
    if (key.startsWith('com.pharossecurity.zero-based.budget-split.')) {
      budgetKpis = deltaControlKpis.budgetSplit;
    } else if (key.startsWith('com.pharossecurity.zero-based.allocation-to-resources.')) {
      budgetKpis = deltaControlKpis.allocationResources;
    } else if (key.startsWith('com.pharossecurity.zero-based.human-resources.')) {
      budgetKpis = deltaControlKpis.humanResources;
    }
    const budgetKpi : KPI | undefined = budgetKpis.find((kpiValue: KPI) => kpiValue.key === key);
    let budgetKpiValue = 0;
    if (budgetKpi && budgetKpi.kpiValue && budgetKpi.kpiValue.length) {
      budgetKpiValue = Number(budgetKpi.kpiValue[0].value || 0);
    }
    return budgetKpiValue;
  });
};

const generateBudgetKpiSubTable = (
  deltaControlGroupKPIs: DeltaKPIType[],
  buildBudgetTable: LevelToSurfaceMatrix[],
  kpiKey: string
) => {
  const controlGroupKpis = getBudgetKpis(deltaControlGroupKPIs, kpiKey);
  return buildBudgetTable.map((controlBuildBudget: LevelToSurfaceMatrix, controlIndex: number) => {
    return controlBuildBudget.map((level: number[]) => {
      return level.map((surface: number) => {
        return (surface * controlGroupKpis[controlIndex]) / 100;
      });
    });
  });
};

const generateHeadCountKpiTable = (
  deltaControlGroupKPIs: DeltaKPIType[],
  buildBudgetTable: LevelToSurfaceMatrix[],
  kpiKey: string
) => {
  const controlGroupKpis = getBudgetKpis(deltaControlGroupKPIs, kpiKey);
  return buildBudgetTable.map((controlBuildBudget: LevelToSurfaceMatrix, controlIndex: number) => {
    return controlBuildBudget.map((level: number[]) => {
      return level.map((surface: number) => {
        return (surface * controlGroupKpis[controlIndex]);
      });
    });
  });
};

export const generateDeltaKpiTables = (
  surfaces: ThreatSurface[],
  levels: ThreatLevel[],
  deltaControlGroupKPIs: DeltaKPIType[]) => {
  const buildBudgetTable = generateBudgetKpiTable(surfaces, levels, deltaControlGroupKPIs, 'build');
  return {
    build: buildBudgetTable,
    run: generateBudgetKpiTable(surfaces, levels, deltaControlGroupKPIs, 'run'),
    capex: generateBudgetKpiSubTable(
      deltaControlGroupKPIs, buildBudgetTable, 'com.pharossecurity.zero-based.budget-split.build.capex'
    ),
    opex: generateBudgetKpiSubTable(
      deltaControlGroupKPIs, buildBudgetTable, 'com.pharossecurity.zero-based.budget-split.build.opex'
    ),
    people: generateBudgetKpiSubTable(
      deltaControlGroupKPIs, buildBudgetTable, 'com.pharossecurity.zero-based.allocation-to-resources.build.people'
    ),
    technology: generateBudgetKpiSubTable(
      deltaControlGroupKPIs, buildBudgetTable, 'com.pharossecurity.zero-based.allocation-to-resources.build.technology'
    ),
    vendors: generateBudgetKpiSubTable(
      deltaControlGroupKPIs, buildBudgetTable, 'com.pharossecurity.zero-based.allocation-to-resources.build.vendors'
    ),
    other: generateBudgetKpiSubTable(
      deltaControlGroupKPIs, buildBudgetTable, 'com.pharossecurity.zero-based.allocation-to-resources.build.other'
    ),
    head: generateHeadCountKpiTable(
      deltaControlGroupKPIs, buildBudgetTable, 'com.pharossecurity.zero-based.human-resources.build'
    ),
    capabilities: generateCapabilitiesTable(
      surfaces, levels, deltaControlGroupKPIs
    )
  };
};

export const generateBudgetTable = (
  groupBudgets: LevelToSurfaceMatrix[],
  preparednessTable: any,
  total: number[] | null) => {
  const budgetTable = preparednessTable[0].map((row: any) => { return [...row] });
  budgetTable.forEach((levels: any, levelIndex: number) => {
    levels.forEach((surface: any, surfaceIndex: number) => {
      if (surface.constructor === Number) {
        let result = 0;
        groupBudgets.forEach((controlBudgets: number[][], controlIndex: number) => {
          const threatPreparedness = Number(preparednessTable[controlIndex][levelIndex][surfaceIndex]) / 100;
          const value = controlBudgets[levelIndex][surfaceIndex - 1] * threatPreparedness;
          if (total && total[controlIndex]) {
            result += value / total[controlIndex];
          } else {
            result += value;
          }
        });
        if (total) {
          budgetTable[levelIndex][surfaceIndex] =  Math.round((result + Number.EPSILON) * 100) / 100;
        } else {
          budgetTable[levelIndex][surfaceIndex] = Math.round(result);
        }
      }
    });
  });
  return budgetTable;
};

export const generateDiffTable = (startTable: any, endTable: any) => {
  const tableDifference = endTable.map((row: any) => { return [...row] });
  startTable.forEach((row: any, rowIndex: number) => {
    row.forEach((col: any, colIndex: number) => {
      if (col.constructor === Number) {
        let diff = Number(tableDifference[rowIndex][colIndex]) - Number(col);
        diff = Math.round((diff + Number.EPSILON) * 100) / 100;
        tableDifference[rowIndex][colIndex] = diff;
      }
    });
  });
  return tableDifference;
};

export const generateTotalTable = ((controlsTable: LevelToSurfaceMatrix[]) => {
  return controlsTable.map((controlBudget: number[][]) => {
    let totalControlBudget = 0
    controlBudget.forEach((levels: number[]) => {
      levels.forEach((surface: number) => {
        totalControlBudget += surface;
      });
    });
    return totalControlBudget;
  });
});

export const generateActionPlanTable = (deltaData: DeltaData): DeltaActionPlanValues[] => {
  let idx = 0;
  const actionPlanTable: DeltaActionPlanValues[] = [];
  deltaData.controls.forEach((control: Control, controlIndex: number) => {
    const totalForControl = deltaData.totals[controlIndex];
    deltaData.levels.forEach((level: ThreatLevel, levelIndex: number) => {
      deltaData.surfaces.forEach((surface: ThreatSurface, surfaceIndex: number) => {
        const prepStart = deltaData.preparednessStart[controlIndex][levelIndex][surfaceIndex + 1];
        const prepEnd = deltaData.preparednessEnd[controlIndex][levelIndex][surfaceIndex + 1];
        const prepChange = prepEnd - prepStart;
        if (prepChange !== 0) {
          let headCountDelta = 0;
          if (totalForControl !== 0) {
            headCountDelta = deltaData.kpiValues['head'][controlIndex][levelIndex][surfaceIndex] * (prepChange / 100);
            headCountDelta /= totalForControl;
            headCountDelta = Math.round((headCountDelta + Number.EPSILON) * 100) / 100;
          }
          idx += 1;
          const actionPlanEntry : DeltaActionPlanValues = {
            id: idx,
            controlName: control.name,
            surfaceName: surface.name,
            levelName: level.name,
            capability: deltaData.kpiValues.capabilities[controlIndex][levelIndex][surfaceIndex],
            threatPrepStart: prepStart,
            threatPrepEnd: prepEnd,
            threatPrepDelta: prepChange,
            runBudgetDelta: Math.round(
              deltaData.kpiValues.run[controlIndex][levelIndex][surfaceIndex] * (prepChange / 100)
            ),
            buildBudgetDelta: Math.round(
              deltaData.kpiValues.build[controlIndex][levelIndex][surfaceIndex] * (prepChange / 100)
            ),
            capexBudgetDelta: Math.round(
              deltaData.kpiValues.capex[controlIndex][levelIndex][surfaceIndex ] * (prepChange / 100)
            ),
            opexBudgetDelta: Math.round(
              deltaData.kpiValues.opex[controlIndex][levelIndex][surfaceIndex] * (prepChange / 100)
            ),
            peopleBudgetDelta: Math.round(
              deltaData.kpiValues.people[controlIndex][levelIndex][surfaceIndex] * (prepChange / 100)
            ),
            technologyBudgetDelta: Math.round(
              deltaData.kpiValues.technology[controlIndex][levelIndex][surfaceIndex] * (prepChange / 100)
            ),
            vendorBudgetDelta: Math.round(
              deltaData.kpiValues.vendors[controlIndex][levelIndex][surfaceIndex] * (prepChange / 100)
            ),
            otherBudgetDelta: Math.round(
              deltaData.kpiValues.other[controlIndex][levelIndex][surfaceIndex] * (prepChange / 100)
            ),
            headcountDelta: headCountDelta
          }
          actionPlanTable.push(actionPlanEntry);
        }
      });
    });
  });
  return actionPlanTable;
};

export const currencyFormatting = (value: number, includeSymbol: boolean): string => {
  let formattedValue = includeSymbol ? `$${value.toString()}` : value.toString();
  const currency = getCurrentUser()?.currency;
  if (currency) {
    if (!includeSymbol) {
      const locale = getCurrentUser()?.currency.locale;
      formattedValue = value.toLocaleString(locale, currency);
    } else {
      const decimals = 0;
      return currency.currencyFormatter(Number(value), decimals);
    }
  }
  return formattedValue;
};

