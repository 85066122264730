import React from 'react';
import { displayHelp } from 'app/services/help';
import {
  TagType,
  KPIGroup as KpiGroupType,
  KPI,
  KPIValue,
} from 'app/services/api';
import { ThreatSurface } from 'app/services/apiThreatSurfaces';
import { getBarColorsForValue } from 'app/common/BarGraph';
import Loader from 'app/common/Loader';
import {
  headerStyles,
  getColWidth,
  firstColStyles,
  lastColStyles,
} from 'app/kpi/components/tables';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';
import { ControlKpiGroupDataType } from '../../SummaryDashboard';

type ScopeMatrixType = {
  securityKpiGroup: KpiGroupType | undefined;
  selectedTag: TagType;
  controlKpiGroupData: ControlKpiGroupDataType[];
  securityCoverageData: KPI[][];
  surfacePayload: ThreatSurface[];
  levelName: string;
  levelID: number;
};

const ScopeMatrix = ({
  securityKpiGroup,
  selectedTag,
  controlKpiGroupData,
  securityCoverageData,
  surfacePayload,
  levelName,
  levelID,
}: ScopeMatrixType) => {
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!securityKpiGroup) {
      return;
    }

    (async () => {
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const numberOfThreatSurfaces = surfacePayload.length;
  const allTableData = securityCoverageData?.reduce((result: any, next) => {
    const scopeValues = next.filter(
      (grp) => grp.key === 'com.pharossecurity.security-coverage.scope'
    )[0];

    // Starting from the first control
    const controlTableData = controlKpiGroupData.map((tControl) => {
      const rowData: any = [];
      const scope =
        scopeValues?.kpiValue.filter(
          (kpi: any) =>
            kpi?.levelId === levelID &&
            kpi?.controlId === tControl.control.id &&
            kpi?.kpi.key === 'com.pharossecurity.security-coverage.scope'
        ) || [];

      // Add default scope value if the scope hasn't been set
      if (scope.length < numberOfThreatSurfaces) {
        surfacePayload.forEach((surface) => {
          const hasSurface = scope.find((ele) => ele.surfaceId === surface.id);
          if (!hasSurface) {
            const defaultScope: KPIValue = {} as any;
            defaultScope.surfaceId = surface.id;
            defaultScope.value = '0';
            defaultScope.controlId = tControl.control.id;
            scope.push(defaultScope);
          }
        });
      }

      scope.sort((a: any, b: any) => a?.surfaceId - b?.surfaceId);

      scope?.forEach((kpi: any) => {
        const surface = surfacePayload.find(
          (surface) => surface.id === kpi?.surfaceId
        );
        if (surface) {
          const index = surfacePayload.indexOf(surface);
          rowData[index] = Math.round(Number(kpi?.value));
        }
      });

      const sum = rowData.reduce((acc: number, curr: number) => {
        return acc + curr;
      }, 0);

      const average = Math.round(sum / numberOfThreatSurfaces);

      rowData.push(average);

      // Insert threat level label to the beginning of the array
      rowData.splice(0, 0, tControl.control.name);

      return rowData;
    });

    return result.concat([controlTableData]);
  }, []);

  const tableAverages = allTableData.reduce(
    (result: any, controlTableData: any, tableIndex: number) => {
      const newResult = result.slice();

      controlTableData.forEach((row: any, rowIndex: number) => {
        row.forEach((col: any, colIndex: number) => {
          if (newResult[rowIndex]) {
            if (col.constructor === Number) {
              if (newResult[rowIndex][colIndex]) {
                newResult[rowIndex][colIndex] =
                  newResult[rowIndex][colIndex] + col;
              } else {
                newResult[rowIndex][colIndex] = col;
              }
              if (tableIndex === allTableData.length - 1) {
                newResult[rowIndex][colIndex] = Math.round(
                  newResult[rowIndex][colIndex]
                );
              }
            }
          } else {
            newResult[rowIndex] = [];
            newResult[rowIndex][colIndex] = col;
          }
        });
      });

      return newResult;
    },
    []
  );

  const colWidth = getColWidth(numberOfThreatSurfaces);
  const helpURI = '';

  return (
    <Paper
      elevation={3}
      style={{ padding: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}
    >
      <Table size="small">
        <TableHead>
          <TableRow style={headerStyles}>
            <TableCell colSpan={numberOfThreatSurfaces + 1}>
              <Typography variant="h5" style={{ fontWeight: 600 }}>
                {levelName}
              </Typography>
              <Typography variant="h6">{selectedTag.name} Known</Typography>
            </TableCell>
            <TableCell colSpan={1} align="right" width="60px">
              <IconButton
                disabled={!helpURI}
                onClick={() => displayHelp(helpURI)}
              >
                <HelpOutlineRounded />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow key="header">
            <TableCell
              key="threat-preparedness-surface-scope"
              style={firstColStyles}
            >
              Control
            </TableCell>
            {surfacePayload.map((surface, idx) => (
              <TableCell
                style={{ width: colWidth }}
                align="center"
                key={`${surface.name}-${idx}`}
              >
                {surface.name}
              </TableCell>
            ))}
            <TableCell style={lastColStyles} key="average" align="center">
              Row Average
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableAverages.map((row: any) => (
            <TableRow key={row[0]}>
              {row.map((col: any, idx: number) => {
                let style = {};
                if (idx === row.length - 1) {
                  style = {
                    backgroundColor: MuiLightBlue[900],
                    color: 'white',
                  };
                  // Center algin KPI values
                } else if (idx > 0) {
                  let barColors = getBarColorsForValue(col);
                  style = {
                    backgroundColor: barColors.background,
                    color: barColors.textColor,
                  };
                } else if (idx === 0) {
                  style = {
                    whiteSpace: 'nowrap',
                  };
                }

                return (
                  <TableCell
                    key={`${row[0]}-${idx}`}
                    style={style}
                    component="th"
                    scope="row"
                    align={idx > 0 ? 'center' : 'left'}
                  >
                    {col}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ScopeMatrix;
