import { Admin } from 'app/admin/Admin';
import SubcomponentTable from 'app/admin/components/subcomponents/table';

const subcomponentImplementation = (): JSX.Element => {
  return <SubcomponentTable subcomponentName={adminKpiModels.name} />;
};

export const adminKpiModels: Admin = {
  name: 'KPI Models',
  key: 'com.pharossecurity.com.admin.kpi-models',
  requires: 'read:config',
  implementation: subcomponentImplementation,
};
