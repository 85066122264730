import React from 'react';
import {
  KPIGroup as KpiGroupType,
} from 'app/services/api';
import Loader from 'app/common/Loader';
import BarGraph, {
  green,
  orange,
  dollarFormatting,
  getHighLowColours,
  findMaxProductivityValue,
} from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';

import { kpiHeaderTitle } from 'app/kpi/components/tables';
import {
  BudgetAllocationDataType,
  AllocationLevel,
  AllocationSurface,
  AllocationResource,
  getBudgetForControls,
  getTotalBudgetData,
  getSurfacesBudgetData,
  getResourcesBudgetData,
} from 'app/utils/budget';
import { commonDashboardTypes, threatLevelTypes } from './index';
import BudgetProductivityBySurface from './BudgetProductivityByThreatSurface';
import BudgetProductivityByResource from './BudgetProductivityByResource';
import BudgetProductivityByThreatLevels from './BudgetProductivityByThreatLevel';

type BudgetProductivityTypes = commonDashboardTypes &
  threatLevelTypes & {
    kpiGroups: KpiGroupType[];
    kpiGroup: KpiGroupType;
  };

const BudgetProductivity = ({
  kpiGroups,
  kpiGroup,
  getThreatSurfaces,
  getThreatLevels,
  threatSurfaces,
  threatLevels,
  control,
  calendarDate,
}: BudgetProductivityTypes): JSX.Element => {
  const controlId = control.id;

  const [loading, setLoading] = React.useState<boolean>(true);
  const [secOpsProductivity, setSecOpsProductivity] = React.useState<number | null>(null);
  const [totalBudgetValue, setTotalBudgetValue] = React.useState<number | null>(null);
  const [levelBudgets, setLevelBudgets] = React.useState<AllocationLevel[] | null>(null);
  const [surfaceBudgets, setSurfaceBudgets] = React.useState<AllocationSurface[] | null>(null);
  const [resourceBudgets, setResourceBudgets] = React.useState<AllocationResource[] | null>(null);

  React.useEffect(() => {
    if (!kpiGroup || !controlId) {
      return;
    }

    getThreatSurfaces();
    getThreatLevels();
    (async () => {
      const budgetData: BudgetAllocationDataType[] = await getBudgetForControls(
        [control], kpiGroups, true, calendarDate
      );
      if (budgetData && budgetData.length) {
        const currentTotalObject = getTotalBudgetData(
          budgetData,
          'current'
        );
        if (currentTotalObject) {
          setTotalBudgetValue(currentTotalObject.total);
          setLevelBudgets(currentTotalObject.allocationLevels[0]);
          const currentSurfaceBudgets = getSurfacesBudgetData(
            budgetData,
            'current',
            currentTotalObject.totalsPerControl
          );
          if (currentSurfaceBudgets) {
            setSurfaceBudgets(currentSurfaceBudgets[0]);
          }
          const currentResourceBudgets = getResourcesBudgetData(
            budgetData,
            'current',
            currentTotalObject.totalsPerControl
          );
          if (currentResourceBudgets) {
            setResourceBudgets(currentResourceBudgets[0]);
          }
          setSecOpsProductivity(currentTotalObject.productivityPerControl[0]);
        }
      }
      setLoading(false);
    })();
  }, [calendarDate]);

  if (loading) {
    return (
      <div style={{ minHeight: '500px' }}>
        <Loader />
      </div>
    );
  }

  if (
    !levelBudgets ||
    !surfaceBudgets ||
    !resourceBudgets ||
    secOpsProductivity === null ||
    totalBudgetValue === null
  ) {
    return <div>No Budgets found</div>;
  }

  const highProductivity = Math.round(
    totalBudgetValue* (Math.round(secOpsProductivity) / 100)
  );
  const lowProductivity = totalBudgetValue - highProductivity;

  const barData = [
    {
      id: 1,
      label: 'Budget Productivity',
      highProductivity,
      highProductivityColour: green,
      lowProductivity,
      lowProductivityColour: orange,
    },
  ];
  const dataKeys = ['highProductivity', 'lowProductivity'];

  return (
    <>
      <Section>
        <StripedTable>
          <StripedTable.Head>
            <StripedTable.Heading colSpan={2} helpURI="">
              Budget Productivity
            </StripedTable.Heading>
            <StripedTable.SubHeading colSpan={2}>
              {kpiHeaderTitle}
            </StripedTable.SubHeading>
          </StripedTable.Head>
        </StripedTable>

        <StripedTable>
          <StripedTable.Body>
            <StripedTable.Row>
              <StripedTable.GraphLabel
                style={{ width: '20%' }}
                isLast
                colSpan={1}
              >
                Budget Productivity
              </StripedTable.GraphLabel>
              <StripedTable.GraphCell isLast colSpan={1} style={{ width: '80%' }}>
                <BarGraph
                  data={barData}
                  keys={dataKeys}
                  colors={getHighLowColours}
                  axisBottom={{ format: dollarFormatting, tickValues: 7 }}
                  labelFormat={dollarFormatting}
                  maxValue={findMaxProductivityValue(barData)}
                />
              </StripedTable.GraphCell>
            </StripedTable.Row>
          </StripedTable.Body>
        </StripedTable>
      </Section>

      <BudgetProductivityBySurface
        getThreatSurfaces={getThreatSurfaces}
        threatSurfaces={threatSurfaces}
        surfaceBudgets={surfaceBudgets}
        secOpsProductivity={secOpsProductivity}
      />
      <BudgetProductivityByThreatLevels
        getThreatLevels={getThreatLevels}
        threatLevels={threatLevels}
        levelBudgets={levelBudgets}
        secOpsProductivity={secOpsProductivity}
      />
      <BudgetProductivityByResource
        resourceBudgets={resourceBudgets}
        secOpsProductivity={secOpsProductivity}
      />
    </>
  );
};

export default BudgetProductivity;
