import { threatLevelState } from 'app/common/actions/threat-levels';
import { threatSurfaceState } from 'app/common/actions/threat-surfaces';
import { TagType, KPI, KPIValue } from 'app/services/api';
import { ThreatLevel, ThreatSurface } from 'app/services/apiThreatSurfaces';
import { getCurrentUser } from 'app/services/auth';
import { getSurfaceBudget } from 'app/utils/graph-math';
import {
  tableContainerWithMarginStyles,
  headerStyles,
  getColWidth,
  firstColStyles,
  lastColStyles,
} from 'app/kpi/components/tables';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';

import { ControlKpiGroupDataType } from '.';
import { displayHelp } from 'app/services/help';
import React from 'react';
import { IconButton } from '@material-ui/core';
import { HelpOutlineRounded } from '@material-ui/icons';

const featureName =
  'dashboard/tag-dashboards/summary/current-investment-matrix';

type CurrentInvestmentMatrixType = {
  selectedTag: TagType;
  controlKpiGroupData: ControlKpiGroupDataType[];
  totalBudgets: KPI[][];
  currentBudgets: KPI[];
  threatLevels: threatLevelState;
  threatSurfaces: threatSurfaceState;
  budgetSplits: KPI[][];
};

const CurrentInvestmentMatrix = ({
  selectedTag,
  controlKpiGroupData,
  totalBudgets,
  currentBudgets,
  threatLevels,
  threatSurfaces,
  budgetSplits,
}: CurrentInvestmentMatrixType): JSX.Element => {
  const currency = getCurrentUser()?.currency;
  const locale = currency?.locale;
  const numberOfThreatSurfaces = (threatSurfaces.payload as ThreatSurface[])
    .length;
  const colWidth = getColWidth(numberOfThreatSurfaces);

  const tableHeadings = [
    {
      id: 10101,
      name: 'Threat Level',
      style: firstColStyles,
    },
  ]
    .concat(
      ((threatSurfaces.payload as ThreatSurface[]) || []).map((tSurface) => ({
        id: tSurface.id,
        name: tSurface.name,
        style: { width: colWidth },
      }))
    )
    .concat({
      id: 20202,
      name: 'Row Sum',
      style: lastColStyles,
    });
  const tableData = ((threatLevels.payload as ThreatLevel[]) || []).map(
    (tLevel: ThreatLevel) => {
      let row: any = [
        {
          display: `${tLevel.severity}. ${tLevel.name}`,
          style: { whiteSpace: 'nowrap' },
        },
      ];

      let rowSum = 0;

      row = row.concat(
        ((threatSurfaces.payload as ThreatSurface[]) || []).map(
          (tSurface: ThreatSurface) => {
            const sumData = controlKpiGroupData.reduce(
              (result: any, next: any, controlIndex: number) => {
                const surfaceBudget = getSurfaceBudget(
                  totalBudgets[controlIndex],
                  currentBudgets[controlIndex].kpiValue?.find(
                    (kpiValue: KPIValue) => kpiValue.surfaceId === tSurface.id
                  )
                );
                const budgetSplitsByLevel = budgetSplits[
                  controlIndex
                ][0].kpiValue.filter(
                  (kpi: KPIValue) => kpi.levelId === tLevel.id
                );
                const budgetSplitBySurface = budgetSplitsByLevel.find(
                  (split) => split.surfaceId === tSurface.id
                );

                let answer = 0;
                if (surfaceBudget && budgetSplitBySurface) {
                  answer =
                    (surfaceBudget * Number(budgetSplitBySurface.value)) / 100;
                }

                return result + answer;
              },
              0
            );

            rowSum += sumData;

            return {
              display: Math.round(sumData).toLocaleString(locale, currency),
              style: {},
            };
          }
        )
      );

      row = row.concat({
        display: Math.round(rowSum).toLocaleString(locale, currency),
        style: {
          backgroundColor: MuiLightBlue[900],
          color: 'white',
        },
      });

      return row;
    }
  );

  const helpURI = '';

  return (
    <Paper elevation={3} style={{ padding: '1.5rem', marginBottom: '2rem' }}>
      <div style={tableContainerWithMarginStyles}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow style={headerStyles}>
                <TableCell
                  colSpan={
                    ((threatSurfaces.payload as ThreatSurface[]) || []).length +
                    1
                  }
                >
                  <Typography variant="h6">{`${selectedTag.name} Current Investment`}</Typography>
                </TableCell>
                <TableCell colSpan={1} align="right" width="60px">
                  <IconButton
                    disabled={!helpURI}
                    onClick={() => displayHelp(helpURI)}
                  >
                    <HelpOutlineRounded />
                  </IconButton>
                </TableCell>
              </TableRow>

              <TableRow>
                {tableHeadings.map((heading, index) => (
                  <TableCell
                    key={`${featureName}/table-heading/${heading.name}-${heading.id}`}
                    style={heading.style}
                    align={index > 0 ? 'center' : 'left'}
                  >
                    {heading.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData.map((row, rowIndex) => (
                <TableRow key={`${featureName}/tbody/row/${rowIndex}`}>
                  {row.map((col: any, colIndex: number) => (
                    <TableCell
                      key={`${featureName}/tbody/row/${rowIndex}/col/${colIndex}`}
                      style={col.style}
                      align={colIndex > 0 ? 'center' : 'left'}
                    >
                      {col.display}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  );
};

export default CurrentInvestmentMatrix;
