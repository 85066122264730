import { connect } from 'react-redux';
import { Typography, Divider } from '@material-ui/core';
import {
  KPIGroup as KpiGroupType,
  KPI,
  Control,
  TagType,
} from 'app/services/api';
import {
  fetchThreatSurfaces,
  threatSurfaceState,
} from 'app/common/actions/threat-surfaces';
import {
  fetchThreatLevels,
  threatLevelState,
} from 'app/common/actions/threat-levels';
import { fetchControls, controlsState } from 'app/common/actions/controls';
import {
  getKPIsAndValuesForGroup,
  getKPIsAndValuesForSubgroups,
} from 'app/utils/helpers';
import { getSecOpsProductivity } from 'app/utils/graph-math';
import Loader from 'app/common/Loader';
import { CalendarContext } from '../../MainContent';
import ThreatPreparednessMatrix from '../SummaryDashboard/ThreatPreparednessMatrix';
import ScopeMatrix from './ScopeMatrix';
import CoverageMatrix from './CoverageMatrix';
import ThreatPreparednessByThreatSurfaceMatrix from './ThreatPreparednessByThreatSurfaceMatrix';
import ThreatPreparednessByThreatLevelMatrix from './ThreatPreparednessByThreatLevelMatrix';

type mapStateType = {
  kpiGroups: { payload: KpiGroupType[] };
  threatSurfaces: threatSurfaceState;
  threatLevels: threatLevelState;
  app: {
    tag: TagType;
  };
  controls: controlsState;
};

export type ControlKpiGroupDataType = {
  control: Control;
  management: KPI[][];
  resources: KPI[][];
  integration: KPI[][];
  secOpsProductivity: number;
};

type SecurityDashboardType = {
  kpiGroups: KpiGroupType[];
  getThreatLevels: () => void;
  getThreatSurfaces: () => void;
  selectedTag: TagType;
  threatLevels: threatLevelState;
  threatSurfaces: threatSurfaceState;
  getControls: (forceFetch: boolean, tagKey?: string) => void;
  controls: controlsState;
};

const SecurityDashboard = ({
  kpiGroups,
  getThreatSurfaces,
  getThreatLevels,
  threatSurfaces,
  threatLevels,
  selectedTag,
  getControls,
  controls,
}: SecurityDashboardType): JSX.Element => {
  const controlsPayload = controls.payload as Control[];
  const [loading, setLoading] = React.useState<boolean>(true);
  const [controlKpiGroupData, setControlKpiGroupData] = React.useState<
    ControlKpiGroupDataType[] | null
  >(null);

  const [securityCoverageData, setSecurityCoverageData] = React.useState<
    KPI[][] | null
  >(null);
  const calendarDate = React.useContext(CalendarContext);

  const kpiGroup = kpiGroups.find(
    (group: KpiGroupType) => group.path === 'investment'
  );

  const securityKpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === 'security-coverage'
  );
  if (!kpiGroup || !securityKpiGroup) {
    return <div>Invalid KPI Group</div>;
  }

  React.useEffect(() => {
    if (loading && !threatSurfaces.isLoading && !threatSurfaces.payload) {
      getThreatSurfaces();
    }

    if (loading && !threatLevels.isLoading && !threatLevels.payload) {
      getThreatLevels();
    }

    if (
      !controls.isLoading &&
      Array.isArray(controlsPayload) &&
      controlsPayload.length &&
      !controlKpiGroupData
    ) {
      setTimeout(() => {
        (async () => {
          const managementData = await getKPIsAndValuesForSubgroups(
            controlsPayload,
            kpiGroups,
            'management',
            calendarDate
          );
          const resourcesData = await getKPIsAndValuesForSubgroups(
            controlsPayload,
            kpiGroups,
            'resources',
            calendarDate
          );
          const integrationData = await getKPIsAndValuesForSubgroups(
            controlsPayload,
            kpiGroups,
            'integration',
            calendarDate
          );

          const serializedData: ControlKpiGroupDataType[] = controlsPayload.map(
            (control, index) => {
              const management = managementData[index];
              const resources = resourcesData[index];
              const integration = integrationData[index];

              const secOpsProductivity = getSecOpsProductivity(
                management,
                resources,
                integration,
                kpiGroups
              );

              return {
                control,
                management,
                resources,
                integration,
                secOpsProductivity,
              };
            }
          );
          setControlKpiGroupData(serializedData);

          // Get security coverage
          const securityCoverage: KPI[][] = await getKPIsAndValuesForGroup(
            controlsPayload,
            securityKpiGroup.key,
            calendarDate
          );
          setSecurityCoverageData(securityCoverage);

          setLoading(false);
        })();
      }, 0);
    }
  }, [calendarDate]);

  if (threatSurfaces.isLoading || threatLevels.isLoading || loading) {
    return <Loader />;
  }

  if (!controlKpiGroupData || !securityCoverageData) {
    return <div />;
  }

  return (
    <div>
      <Typography variant="h6">Threat Preparedness Summary</Typography>
      <Divider style={{ marginBottom: '2rem' }} />
      <ThreatPreparednessMatrix
        kpiGroups={kpiGroups}
        controlKpiGroupData={controlKpiGroupData}
        securityCoverageData={securityCoverageData}
        selectedTag={selectedTag}
        threatLevels={threatLevels}
        threatSurfaces={threatSurfaces}
      />
      <ScopeMatrix
        kpiGroups={kpiGroups}
        securityCoverageData={securityCoverageData}
        selectedTag={selectedTag}
        threatLevels={threatLevels}
        threatSurfaces={threatSurfaces}
      />
      <CoverageMatrix
        kpiGroups={kpiGroups}
        securityCoverageData={securityCoverageData}
        selectedTag={selectedTag}
        threatLevels={threatLevels}
        threatSurfaces={threatSurfaces}
      />

      <ThreatPreparednessByThreatSurfaceMatrix
        kpiGroups={kpiGroups}
        controlKpiGroupData={controlKpiGroupData}
        securityCoverageData={securityCoverageData}
        selectedTag={selectedTag}
        threatLevels={threatLevels}
        threatSurfaces={threatSurfaces}
      />

      <ThreatPreparednessByThreatLevelMatrix
        kpiGroups={kpiGroups}
        controlKpiGroupData={controlKpiGroupData}
        securityCoverageData={securityCoverageData}
        selectedTag={selectedTag}
        threatLevels={threatLevels}
        threatSurfaces={threatSurfaces}
      />
    </div>
  );
};

const mapState = ({
  kpiGroups: { payload },
  threatSurfaces,
  threatLevels,
  app: { tag },
  controls,
}: mapStateType) => ({
  kpiGroups: payload,
  threatLevels,
  threatSurfaces,
  selectedTag: tag,
  controls,
});

const mapDispatch = {
  getThreatLevels: fetchThreatLevels,
  getThreatSurfaces: fetchThreatSurfaces,
  getControls: fetchControls,
};

export default connect(mapState, mapDispatch)(SecurityDashboard);
