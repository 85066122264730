import React, { useState, ChangeEvent, SyntheticEvent } from 'react';
import {
  makeStyles,
  withStyles,
  Theme,
  useTheme,
  createStyles,
} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import MuiAlert from '@material-ui/lab/Alert';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  Collapse,
  TextField,
  IconButton,
  Button,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Divider,
  Snackbar,
  Zoom,
  useMediaQuery,
} from '@material-ui/core';
import { Edit, Close, AddBox, IndeterminateCheckBox } from '@material-ui/icons';
import MuiHeaderColor from '@material-ui/core/colors/grey';
import { TagType, TagHints } from 'app/services/apiTags';

const MinusSquare = () => {
  return <IndeterminateCheckBox fontSize="small" />;
};

const PlusSquare = () => {
  return <AddBox fontSize="small" />;
};

const TransitionComponent = (props: TransitionProps) => {
  return <Collapse {...props} />;
};

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
    },
  })
)((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 264,
      flexGrow: 1,
      maxWidth: 400,
    },
    item: {
      paddingTop: '6px',
      paddingBottom: '8px',
    },
    inputInput: {
      padding: '8px',
      cursor: 'default',
    },
    iconButton: {
      marginLeft: theme.spacing(1),
    },
    margin: {
      margin: theme.spacing(1),
    },
    dialog: {
      marginTop: theme.spacing(2),
    },
    modal: {
      backgroundColor: MuiHeaderColor[300],
    },
    formControl: {
      marginTop: theme.spacing(3),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    notdisplay: {
      opacity: 0,
    },
    display: {
      opacity: 1,
    },
  })
);

interface EditTagProps {
  permissions: string[];
  customizedTags: TagType[];
  tags: any[];
  node: TagType;
  onHandleSaveTag: (
    tagName: string,
    parentInfo: string,
    tagHint: number
  ) => void;
  globalTopLevel?: string[] | null;
}

const EditTagDialog = ({
  permissions,
  customizedTags,
  tags,
  node,
  onHandleSaveTag,
  globalTopLevel,
}: EditTagProps) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [open, setOpen] = useState<{
    dialog: boolean;
    snackbar: boolean;
  }>({
    dialog: false,
    snackbar: false,
  });
  const [state, setState] = useState<{
    tagName: string;
    tagInfo: any;
    tagHint: boolean;
    editing: boolean;
    tagNotUnique: boolean;
  }>({
    tagName: node.name,
    tagInfo: tags.filter((tag) => tag.id === node.id)[0],
    tagHint: node.hint === TagHints.ControlContainer ? true : false,
    editing: false,
    tagNotUnique: false,
  });

  const checkUniqueness = () => {
    if (state.tagName && tags) {
      let parentId =
        typeof state.tagInfo === 'string' &&
        state.tagInfo.toLocaleLowerCase() === 'none'
          ? null
          : state.tagInfo.id;

      state.tagNotUnique = false;
      tags.find((item: any) => {
        if (
          String(item.parent) === String(parentId) &&
          state.tagName.toLocaleLowerCase() === item.name.toLocaleLowerCase()
        ) {
          state.tagNotUnique = true;
        }
      });

      // Check if Custom Top Level Tag is different from Global Top Level Tag
      if (!state.tagNotUnique && !parentId && globalTopLevel) {
        globalTopLevel.find((globalName: string) => {
          if (
            state.tagName.toLocaleLowerCase() === globalName.toLocaleLowerCase()
          ) {
            state.tagNotUnique = true;
          }
        });
      }
    }
  };

  const checkTagHintParent = (tagArray: any[]): boolean => {
    let result = true;
    tagArray.map((tag) => {
      if (tag.id === node.parentId) {
        return (result = tag.hint === TagHints.ControlContainer);
      } else if (Array.isArray(tag.subTags)) {
        checkTagHintParent(tag.subTags);
      }
    });

    return result;
  };

  const handleFormDialog = (name: string, action: boolean) => {
    setOpen({
      ...open,
      [name]: action,
    });
  };

  const handleCloseSnackbar = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    handleFormDialog('snackbar', !open.snackbar);
  };

  const handleChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value = event.target.value;

    setState({
      ...state,
      [event.target.name as string]: value,
      editing: true,
    });
  };

  const handleCloseEdit = () => {
    // Clear Form
    setState({
      tagName: node.name,
      tagInfo: tags.filter((tag) => tag.id === node.id)[0],
      tagHint: node.hint === TagHints.ControlContainer ? true : false,
      editing: false,
      tagNotUnique: false,
    });

    handleFormDialog('dialog', !open.dialog);
  };

  const handleUpdateTag = () => {
    if (node.name !== state.tagName && tags) {
      checkUniqueness();

      if (state.tagNotUnique) {
        handleFormDialog('snackbar', !open.snackbar);
        return;
      }
    }

    const updateHint =
      (node.hint === TagHints.ControlContainer && state.tagHint) ||
      (node.hint !== TagHints.ControlContainer && !state.tagHint)
        ? -1
        : state.tagHint
        ? TagHints.ControlContainer
        : TagHints.Default;

    state.tagInfo.editName = node.name !== state.tagName;
    state.tagInfo.editHint = updateHint === -1 ? false : true;

    // Re-load the data
    onHandleSaveTag(state.tagName, state.tagInfo, updateHint);
    handleFormDialog('dialog', !open.dialog);
  };

  return (
    <>
      <Tooltip
        title={'Edit Control Group'}
        TransitionComponent={Zoom}
        arrow
      >
        <IconButton
          className={classes.iconButton}
          aria-label="edit"
          size="small"
          disabled={
            !permissions.includes('update:config') &&
            !permissions.includes('update:global')
          }
        >
          <Edit onClick={() => handleFormDialog('dialog', !open.dialog)} />
        </IconButton>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={open.dialog}
        onClose={() => handleFormDialog('dialog', !open.dialog)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.modal}
          disableTypography
        >
          <Typography variant="h6">Edit Control Group</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseEdit}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          {/* Tag or Sub-tag Name */}
          <TextField
            required
            autoFocus
            id="name"
            label="Control Group Name"
            variant="outlined"
            fullWidth
            name="tagName"
            value={state.tagName}
            onChange={handleChange}
          />

          {/* Tag Hint */}
          <FormControlLabel
            className={classes.dialog}
            disabled={
              node.parentId === null
                ? false
                : node.hint === TagHints.Default
                ? false
                : checkTagHintParent(customizedTags)
            }
            control={
              <Checkbox
                name="tagHint"
                value={state.tagHint}
                checked={state.tagHint}
                onChange={() =>
                  setState({
                    ...state,
                    tagHint: !state.tagHint,
                    editing: true,
                  })
                }
              />
            }
            label="Enable dashboard menu"
          />
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            className={classes.margin}
            onClick={handleUpdateTag}
            disabled={!state.editing}
          >
            Save
          </Button>
          <Snackbar
            open={open.snackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={'error'}
              onClose={handleCloseSnackbar}
            >
              Tag Name must be unique.
            </MuiAlert>
          </Snackbar>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface TreeViewGroupProps {
  permissions: string[];
  tags: TagType[];
  dialogTags: any[];
  onHandleClickTag: (selectedTag: TagType) => void;
  onHandleSaveTag: (
    tagName: string,
    parentInfo: string,
    tagHint: number
  ) => void;
  globalTopLevel?: string[] | null;
}

export default function TreeViewGroup({
  permissions,
  tags,
  dialogTags,
  onHandleClickTag,
  onHandleSaveTag,
  globalTopLevel,
}: TreeViewGroupProps) {
  const classes = useStyles();
  const customizedTags: TagType[] = tags.slice(0);

  const renderSubTags = (nodes: TagType) => {
    return (
      <StyledTreeItem
        className={classes.item}
        key={nodes.id}
        nodeId={String(nodes.id)}
        label={
          <>
            {nodes.name}
            <EditTagDialog
              permissions={permissions}
              customizedTags={customizedTags}
              tags={dialogTags}
              node={nodes}
              onHandleSaveTag={onHandleSaveTag}
              globalTopLevel={globalTopLevel}
            />
          </>
        }
        onClick={() => onHandleClickTag(nodes)}
      >
        {Array.isArray(nodes.subTags)
          ? nodes.subTags.map((node) => renderSubTags(node))
          : null}
      </StyledTreeItem>
    );
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
    >
      {customizedTags.map((tag) => renderSubTags(tag))}
    </TreeView>
  );
}
