import { getInitState } from 'app/utils/async-middleware';
import { actionTypes } from 'app/common/actions/threat-levels';

const initState = getInitState();

export default (state = { ...initState }, action: any) => {
  switch (action.type) {
    case actionTypes.fetchThreatLevels: {
      const { type, ...restAction } = action;

      return {
        ...state,
        ...restAction,
      };
    }

    default:
      return state;
  }
};
