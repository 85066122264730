import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Typography, Divider } from '@material-ui/core';
import MuiGrey from '@material-ui/core/colors/grey';
import { KPIGroup as KpiGroupType, Control } from 'app/services/api';
import {
  ThreatSurface as ThreatSurfaceType,
  ThreatLevel as ThreatLevelType,
} from 'app/services/apiThreatSurfaces';
import { fetchThreatSurfaces } from 'app/common/actions/threat-surfaces';
import { fetchThreatLevels } from 'app/common/actions/threat-levels';
import BudgetAllocation from './BudgetAllocation';
import { CalendarContext } from 'app/dashboard/MainContent';
import { BudgetAllocationDataType, getBudgetForControls } from 'app/utils/budget';

interface threatSurfacesState {
  isLoading: boolean;
  hasError: Error;
  payload: ThreatSurfaceType[];
}

interface threatLevelState {
  isLoading: boolean;
  hasError: Error;
  payload: ThreatLevelType[];
}

type stateType = {
  kpiGroups: { payload: KpiGroupType[] };
  threatSurfaces: threatSurfacesState;
  threatLevels: threatLevelState;
  app: { control: Control };
};

export interface surfaceTypes {
  getThreatSurfaces: () => void;
  threatSurfaces: threatSurfacesState;
}

export interface threatLevelTypes {
  getThreatLevels: () => void;
  threatLevels: threatLevelState;
}

export interface commonDashboardTypes extends surfaceTypes {
  controlId: number;
}

type BudgetDashboardType = surfaceTypes &
  threatLevelTypes & {
    kpiGroups: KpiGroupType[];
    selectedControl: Control;
  };

const BudgetDashboard = ({
  kpiGroups,
  selectedControl,
  getThreatSurfaces,
  getThreatLevels,
  threatSurfaces,
  threatLevels,
}: BudgetDashboardType): JSX.Element => {
  const { url } = useRouteMatch();
  const controlId = selectedControl.id;
  const groupPath = url.split('/').pop();
  const [budgetAllocationData, setBudgetAllocationData] =
    useState<BudgetAllocationDataType | null>(null);
  const [kpiGroup] = kpiGroups.filter(
    (group: KpiGroupType) => group.path === groupPath
  );

  const calendarDate = React.useContext(CalendarContext);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    (async () => {
      const allData = await getBudgetForControls(
        [selectedControl], kpiGroups, false, calendarDate
      );
      if (allData && allData.length) {
        setBudgetAllocationData(allData[0]);
      }
    })();
  }, [calendarDate]);

  if (!kpiGroup) {
    return <div>Invalid KPI Group</div>;
  }

  return (
    <>
      <Typography variant="h6" style={{ marginTop: '2.5rem' }}>
        Overview
      </Typography>
      <Divider
        variant="fullWidth"
        style={{ backgroundColor: MuiGrey[500], marginBottom: '2.5rem' }}
      />
      <BudgetAllocation
        getThreatSurfaces={getThreatSurfaces}
        getThreatLevels={getThreatLevels}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        budgetAllocationData={budgetAllocationData}
        controlId={controlId}
        controlName={selectedControl.name}
      />
    </>
  );
};

const mapState = ({
  app: { control },
  kpiGroups: { payload },
  threatSurfaces,
  threatLevels,
}: stateType) => ({
  kpiGroups: payload,
  threatLevels,
  threatSurfaces,
  selectedControl: control,
});

const mapDispatch = {
  getThreatLevels: fetchThreatLevels,
  getThreatSurfaces: fetchThreatSurfaces,
};

export default connect(mapState, mapDispatch)(BudgetDashboard);
