import React, { FunctionComponent } from 'react';
import {
  IconButton,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import MuiGrey from '@material-ui/core/colors/grey';
import { StripedTableRow } from 'app/common/StripedTable';
import DescriptionAccordion from 'app/common/DescriptionAccordion';
import {
  kpiHeaderTitle,
  tableContainerStyles,
  headerStyles,
  subHeaderStyles,
} from 'app/kpi/components/tables';
import { KPILikert, LikertValue } from 'app/kpi/types/KPIData';
import {
  HelpOutlineRounded,
  NoteOutlined,
  NoteTwoTone,
  LinkOutlined,
} from '@material-ui/icons';
import { displayHelp } from 'app/services/help';
import { KPIContext } from 'app/services/api';

interface Likert {
  label: string;
  value: LikertValue;
}

const likerts: Likert[] = [
  {
    label: 'Agree Strongly',
    value: 5,
  },
  {
    label: 'Agree Moderately',
    value: 4,
  },
  {
    label: 'Agree Slightly',
    value: 3,
  },
  {
    label: 'Disagree Slightly',
    value: 2,
  },
  {
    label: 'Disagree Moderately',
    value: 1,
  },
  {
    label: 'Disagree Strongly',
    value: 0,
  },
];

const showDescription = (
  text: string, rowIdx: number
) => {
  let summary = text;
  const descriptions = text && text.length > 0 ? text.split("|") : [];
  if (descriptions && descriptions.length > 1) {
    summary = descriptions[0];
    const details = descriptions.slice(1);
    if (details.some((detail: string) => detail.length > 0)) {
      let style = {};
      if (rowIdx & 1) {
        style = {
          backgroundColor: MuiGrey[100]
        }
      }
      return (
        <DescriptionAccordion
          summary={summary}
          details={details}
          identifier={rowIdx}
          style={style}
        />
      );
    }
  }
  return (
    <Typography variant="body2" style={{paddingLeft: '1rem'}}>
      {text}
    </Typography>
  );
};

interface KPILikertTableProps {
  contextKey: string;
  className?: string;
  name: string;
  helpURI?: string;
  kpis: KPILikert[];
  context?: KPIContext;
  onKPIChange: (kpi: KPILikert, value: LikertValue) => void;
  onContextDialogOpen: (title: string, context?: KPIContext) => void;
}

export const KPILikertTable: FunctionComponent<KPILikertTableProps> = ({
  contextKey,
  className,
  name,
  helpURI,
  kpis,
  context,
  onKPIChange,
  onContextDialogOpen,
}: KPILikertTableProps) => (
  <Paper
    elevation={3}
    style={{ padding: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}
  >
    <div style={tableContainerStyles}>
      <Table className={className}>
        <TableHead>
          <TableRow style={headerStyles}>
            <TableCell colSpan={likerts.length}>
              <Typography variant="h6">{name}</Typography>
            </TableCell>
            <TableCell
              align="right"
              style={{ minWidth: '160px', padding: '0.2rem' }}
            >
              {context ? (
                <IconButton
                  onClick={() => onContextDialogOpen(contextKey, context)}
                >
                  <NoteTwoTone />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => onContextDialogOpen(contextKey, context)}
                >
                  <NoteOutlined />
                </IconButton>
              )}
              {context && context.contextURI && (
                <IconButton
                  onClick={() => window.open(context.contextURI!, '_blank')}
                >
                  <LinkOutlined />
                </IconButton>
              )}
              <IconButton
                disabled={!helpURI}
                onClick={() =>
                  displayHelp({
                    context: 'kpi',
                    resource: helpURI,
                  })
                }
              >
                <HelpOutlineRounded />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow style={subHeaderStyles}>
            <TableCell style={{ width: '40%' }}>{kpiHeaderTitle}</TableCell>
            {likerts.map(({ label }, index) => (
              <TableCell key={index} align="center">
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {kpis.map((kpi, kpiIndex) => (
            <StripedTableRow key={kpi.text}>
              <TableCell>
                {showDescription(kpi.text, kpiIndex)}
              </TableCell>
              {likerts.map(({ value }, index) => (
                <TableCell key={index} align="center">
                  <Radio
                    color="primary"
                    checked={kpi.value === value}
                    value={value}
                    onChange={() => onKPIChange(kpi, value)}
                  />
                </TableCell>
              ))}
            </StripedTableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  </Paper>
);

export default KPILikertTable;
