import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Typography, Divider } from '@material-ui/core';
import MuiGrey from '@material-ui/core/colors/grey';
import { KPIGroup as KpiGroupType, Control, TagType } from 'app/services/api';
import {
  ThreatSurface as ThreatSurfaceType,
  ThreatLevel as ThreatLevelType,
} from 'app/services/apiThreatSurfaces';
import Loader from 'app/common/Loader';
import { fetchThreatSurfaces } from 'app/common/actions/threat-surfaces';
import { fetchThreatLevels } from 'app/common/actions/threat-levels';
import { fetchControls, controlsState } from 'app/common/actions/controls';
import BudgetAllocation from './BudgetAllocation';
import { CalendarContext } from 'app/dashboard/MainContent';

import { BudgetAllocationDataType, getBudgetForControls} from 'app/utils/budget';

interface threatSurfacesState {
  isLoading: boolean;
  hasError: Error;
  payload: ThreatSurfaceType[];
}

interface threatLevelState {
  isLoading: boolean;
  hasError: Error;
  payload: ThreatLevelType[];
}

type stateType = {
  kpiGroups: { payload: KpiGroupType[] };
  threatSurfaces: threatSurfacesState;
  threatLevels: threatLevelState;
  app: { tag: TagType };
  controls: controlsState;
};

export interface surfaceTypes {
  getThreatSurfaces: () => void;
  threatSurfaces: threatSurfacesState;
}

export interface threatLevelTypes {
  getThreatLevels: () => void;
  threatLevels: threatLevelState;
}

export interface commonDashboardTypes extends surfaceTypes {
  controlId: number;
}

type BudgetDashboardType = surfaceTypes &
  threatLevelTypes & {
    kpiGroups: KpiGroupType[];
    selectedTag: TagType;
    getControls: (forceFetch: boolean, tagKey?: string) => void;
    controls: controlsState;
  };

const BudgetDashboard = ({
  kpiGroups,
  getThreatSurfaces,
  getThreatLevels,
  threatSurfaces,
  threatLevels,
  selectedTag,
  getControls,
  controls,
}: BudgetDashboardType): JSX.Element => {
  const { url } = useRouteMatch();
  const controlsPayload = controls.payload as Control[];
  const groupPath = url.split('/').pop();
  const [budgetAllocationData, setBudgetAllocationData] =
    useState<BudgetAllocationDataType[] | null>(null);
  const [kpiGroup] = kpiGroups.filter(
    (group: KpiGroupType) => group.path === groupPath
  );
  const [investmentKpiGroup] = kpiGroups.filter(
    (group: KpiGroupType) => group.path === 'investment'
  );
  const calendarDate = React.useContext(CalendarContext);

  const securityKpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === 'security-coverage'
  );

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    if (!threatSurfaces.isLoading && !threatSurfaces.payload) {
      getThreatSurfaces();
    }

    if (!threatLevels.isLoading && !threatLevels.payload) {
      getThreatLevels();
    }

    if (!controls.isLoading && !Array.isArray(controlsPayload)) {
      const forceFetch = true;
      getControls(forceFetch, selectedTag.key);
    }

    if (
      !controls.isLoading &&
      Array.isArray(controlsPayload) &&
      controlsPayload.length
    ) {
      (async () => {
        const allData = await getBudgetForControls(controlsPayload, kpiGroups, false, calendarDate);
        setBudgetAllocationData(allData);
      })();
    }
  }, [calendarDate, controlsPayload]);

  if (!kpiGroup || !investmentKpiGroup || !securityKpiGroup) {
    return <div>Invalid KPI Group</div>;
  }

  if (!budgetAllocationData || !controlsPayload) {
    return <Loader />;
  }

  return (
    <>
      <Typography variant="h6" style={{ marginTop: '2.5rem' }}>
        Overview
      </Typography>
      <Divider
        variant="fullWidth"
        style={{ backgroundColor: MuiGrey[500], marginBottom: '2.5rem' }}
      />
      <BudgetAllocation
        getThreatSurfaces={getThreatSurfaces}
        getThreatLevels={getThreatLevels}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        budgetAllocationData={budgetAllocationData}
        selectedTag={selectedTag}
        controls={controlsPayload}
      />
    </>
  );
};

const mapState = ({
  app: { tag },
  kpiGroups: { payload },
  threatSurfaces,
  threatLevels,
  controls,
}: stateType) => ({
  kpiGroups: payload,
  threatLevels,
  threatSurfaces,
  selectedTag: tag,
  controls,
});

const mapDispatch = {
  getThreatLevels: fetchThreatLevels,
  getThreatSurfaces: fetchThreatSurfaces,
  getControls: fetchControls,
};

export default connect(mapState, mapDispatch)(BudgetDashboard);
