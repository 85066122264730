import { getTags, TagType, getTagById } from 'services/api';

const featureName = 'common/actions';

export type tagsState = {
  isLoading: boolean;
  hasError: Error | null;
  payload: Promise<TagType[]> | TagType[] | null;
};

interface appStore {
  tags: tagsState;
}

type tagsDispatchType = {
  type: string;
  payload: Promise<TagType[]> | TagType[] | null;
};

type tagDispatchType = {
  type: string;
  payload: Promise<TagType> | TagType | null;
};

export const actionTypes = {
  fetchTags: `${featureName}/FETCH_TAGS`,
  fetchTagById: `${featureName}/FETCH_TAG_BY_ID`,
};

export const fetchTags = (
  parentId: number | null = null,
  forceFetch = false
) => (
  dispatch: React.Dispatch<tagsDispatchType>,
  getState: () => appStore
): void => {
  const { payload: existingPayload } = getState().tags;

  const hasTags =
    existingPayload && Array.isArray(existingPayload) && existingPayload.length;

  dispatch({
    type: actionTypes.fetchTags,
    payload: !forceFetch && hasTags ? existingPayload : getTags(parentId),
  });
};

export const fetchTagById = (id: number) => (
  dispatch: React.Dispatch<tagDispatchType>
) => {
  dispatch({
    type: actionTypes.fetchTagById,
    payload: getTagById(id),
  });
};
