import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  ReactElement,
  ChangeEvent,
  SyntheticEvent,
  forwardRef,
  Ref,
} from 'react';
import {
  useTheme,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Toolbar,
  Tooltip,
  Typography,
  Paper,
  IconButton,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Zoom,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  MenuItem,
  Button,
  Snackbar,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import MuiAlert from '@material-ui/lab/Alert';
import MuiHeaderColor from '@material-ui/core/colors/grey';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import { displayHelp } from 'app/services/help';
import {
  saveThreatSurface,
  updateThreatSurface,
  deleteThreatSurface,
  ThreatSurfaceType,
  ThreatSurface,
} from 'app/services/apiThreatSurfaces';
import { debounce, isEmpty } from 'lodash';
import { HeadCell } from '../adminThreatSurfaces';

const useHeadStyles = makeStyles(() =>
  createStyles({
    column: {
      width: '27%',
      backgroundColor: MuiHeaderColor[300],
    },
    description: {
      width: '55%',
      backgroundColor: MuiHeaderColor[300],
    },
    features: {
      width: '3%',
      backgroundColor: MuiHeaderColor[300],
    },
  })
);

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  headCells: HeadCell[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const classes = useHeadStyles();
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow>
        {/* Drag and Drop icon */}
        <TableCell padding="default" className={classes.features}></TableCell>

        {/* Subtitle */}
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="default"
            className={index === 1 ? classes.description : classes.column}
          >
            {headCell.label}
          </TableCell>
        ))}
        {/* Delete / Restore icon */}
        <TableCell padding="default" className={classes.features}></TableCell>
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      backgroundColor: MuiHeaderColor[400],
    },
    title: {
      flex: '1 1 100%',
    },
    formControl: {
      marginTop: theme.spacing(3),
      minWidth: 200,
    },
    margin: {
      margin: theme.spacing(1),
    },
    dialog: {
      marginTop: theme.spacing(2),
    },
    modal: {
      backgroundColor: MuiHeaderColor[300],
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  })
);

interface EnhancedTableToolbarProps {
  subcomponentName: string;
  subcomponentHelpURI?: string;
  threatSurfaces: ThreatSurface[] | null;
  threatTypes: ThreatSurfaceType[] | null;
  permissions: string[];
  onHandleLoadData: (newthreatSurface: any) => void;
}

const AddThreatSurfaceDialog = ({
  threatSurfaces,
  threatTypes,
  permissions,
  onHandleLoadData,
}: any) => {
  const classes = useToolbarStyles();
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [open, setOpen] = useState<{
    dialog: boolean;
    snackbar: boolean;
  }>({
    dialog: false,
    snackbar: false,
  });
  const [state, setState] = useState<{
    threatName: string;
    threatDescription: string;
    threatType: string;
    threatNotUnique: boolean;
  }>({
    threatName: '',
    threatDescription: '',
    threatType: '',
    threatNotUnique: false,
  });

  const handleFormDialog = (name: string, action: boolean) => {
    setOpen({
      ...open,
      [name]: action,
    });
  };

  const handleChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name as string]: value,
    });
  };

  const handleCloseSnackbar = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    handleFormDialog('snackbar', !open.snackbar);
  };

  const handleAddThreatSurface = () => {
    if (state.threatName && threatSurfaces) {
      state.threatNotUnique = threatSurfaces.some(
        (item: any) =>
          state.threatName.toLocaleLowerCase() === item.name.toLocaleLowerCase()
      );
    }

    if (
      state.threatNotUnique ||
      !state.threatName ||
      !state.threatDescription ||
      !state.threatType
    ) {
      handleFormDialog('snackbar', !open.snackbar);
      return;
    }

    const indexType = threatTypes.indexOf(
      threatTypes.find(
        (type: any) =>
          type.name.toLocaleLowerCase() === state.threatType.toLocaleLowerCase()
      )
    );

    // Save new Threat Surface
    try {
      const postRequest = async () => {
        const threatContent: any = {
          name: state.threatName,
          description: state.threatDescription,
          displayOrder: !threatSurfaces
            ? 1
            : parseInt(threatSurfaces[threatSurfaces.length - 1].displayOrder) +
              1,
          attr: 0,
          surfaceTypeId: parseInt(threatTypes[indexType].id),
        };

        return saveThreatSurface(threatContent);
      };
      postRequest().then((newSurface: any) => {
        // Clear Form
        setState({
          threatName: '',
          threatDescription: '',
          threatType: '',
          threatNotUnique: false,
        });

        handleFormDialog('dialog', !open.dialog);

        // Re-load the data
        onHandleLoadData(newSurface);
      });
    } catch (e) {
      console.log('There has been a problem with post operation: ' + e.message);
    }
  };

  return (
    <>
      <Tooltip title="Add New Threat" TransitionComponent={Zoom} arrow>
        <IconButton disabled={!permissions.includes('create:config')}>
          <AddIcon onClick={() => handleFormDialog('dialog', !open.dialog)} />
        </IconButton>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={open.dialog}
        onClose={() => handleFormDialog('dialog', !open.dialog)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.modal}
          disableTypography
        >
          <Typography variant="h6">New Threat Surface</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleFormDialog('dialog', !open.dialog)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          {/* Threat Surface Name */}
          <TextField
            required
            autoFocus
            id="name"
            label="Threat Surface Name"
            variant="outlined"
            fullWidth
            name="threatName"
            value={state.threatName}
            onChange={handleChange}
          />

          {/* Threat Surface Description */}
          <TextField
            required
            id="description"
            label="Threat Surface Description"
            variant="outlined"
            fullWidth
            name="threatDescription"
            value={state.threatDescription}
            onChange={handleChange}
            className={classes.dialog}
          />

          {/* Threat Surface Type */}
          <FormControl
            variant="outlined"
            className={classes.formControl}
            required
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Threat Surface Type
            </InputLabel>
            <Select
              labelId="select-outlined-label"
              id="select-outlined"
              name="threatType"
              value={state.threatType}
              onChange={handleChange}
              label="Threat Surface Type *"
            >
              {threatTypes ? (
                threatTypes.map((type: any) => (
                  <MenuItem value={type.name} key={`threat-type-${type.id}`}>
                    {type.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" key={'threat-type-null'}></MenuItem>
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            // color="primary"
            className={classes.margin}
            onClick={handleAddThreatSurface}
            disabled={
              !state.threatName && !state.threatDescription && !state.threatType
            }
          >
            Add
          </Button>
          <Snackbar
            open={open.snackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={state.threatNotUnique ? 'error' : 'warning'}
              onClose={handleCloseSnackbar}
            >
              {state.threatNotUnique
                ? 'Threat Surface Names must be unique.'
                : 'All fields are required!'}
            </MuiAlert>
          </Snackbar>
        </DialogActions>
      </Dialog>
    </>
  );
};

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const {
    subcomponentName,
    subcomponentHelpURI,
    threatSurfaces,
    threatTypes,
    permissions,
    onHandleLoadData,
  } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {subcomponentName}
      </Typography>
      {/* Add Row Button */}
      {threatTypes ? (
        <AddThreatSurfaceDialog
          threatSurfaces={threatSurfaces}
          threatTypes={threatTypes}
          permissions={permissions}
          onHandleLoadData={onHandleLoadData}
        />
      ) : null}
      {/* Help Button */}
      <IconButton
        disabled={!isEmpty(subcomponentHelpURI)}
        onClick={() =>
          displayHelp({
            context: 'admin',
            resource: subcomponentHelpURI,
          })
        }
      >
        <HelpOutlineRounded />
      </IconButton>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      padding: '1.5rem',
      marginTop: '0.5rem',
      marginBottom: '2rem',
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    container: {
      borderBottom: `1px solid ${MuiHeaderColor[400]}`,
    },
    input: {
      marginTop: theme.spacing(0.25),
    },
    // TextField - Threat Name
    textField: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '90%',
      },
    },
    // SelectBox - Threat Type
    formControl: {
      margin: theme.spacing(0.15),
      width: '90%',
    },
    // Drag and Drop Component
    dnd: {
      cursor: 'grab',
    },
  })
);

const ThreatSurfaceRow = ({
  value,
  threatIndex,
  labelId,
  threatTypes,
  permissions,
  onHandleChangeEditRow,
}: any) => {
  const classes = useStyles();
  const surfaceContent = threatTypes
    ? threatTypes.filter((item: any) => value.surfaceTypeId === item.id)[0]
    : {
        id: 0,
        name: '',
      };

  const debounceSave = useCallback(
    debounce((value, type) => onHandleChangeEditRow(value, type), 450),
    []
  );

  const handleTextFieldChange = (
    event: ChangeEvent<{ value: unknown }>,
    textFieldType: number
  ) => {
    if (String(event.target.value).length > 0) {
      const newThreat = { ...value };
      textFieldType === 0
        ? (newThreat.name = event.target.value as string)
        : (newThreat.description = event.target.value as string);

      debounceSave(newThreat, textFieldType);
    }
  };

  const handleTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    const surfaceTypeId = threatTypes.filter(
      (item: any) => event.target.value === item.name
    )[0].id;
    const newThreat = { ...value };
    newThreat.surfaceTypeId = surfaceTypeId;

    onHandleChangeEditRow(newThreat, -1);
  };

  return (
    <>
      {/* Threat Surface Name */}
      <TableCell
        component="th"
        scope="row"
        padding="none"
        id={`name-${labelId}`}
      >
        <form className={classes.textField} noValidate autoComplete="off">
          <div>
            <TextField
              required
              id={`name-required-${threatIndex}`}
              label="Required"
              defaultValue={value.name}
              variant="outlined"
              className={classes.input}
              disabled={
                !permissions.includes('update:config') || value.attr !== 0
              }
              onChange={(e) => handleTextFieldChange(e, 0)}
            />
          </div>
        </form>
      </TableCell>

      {/* Threat Surface Description */}
      <TableCell
        component="th"
        scope="row"
        padding="none"
        id={`description-${labelId}`}
      >
        <form className={classes.textField} noValidate autoComplete="off">
          <div>
            <TextField
              required
              id={`description-required-${threatIndex}`}
              label="Required"
              defaultValue={value.description}
              variant="outlined"
              className={classes.input}
              disabled={
                !permissions.includes('update:config') || value.attr !== 0
              }
              onChange={(e) => handleTextFieldChange(e, 1)}
            />
          </div>
        </form>
      </TableCell>

      {/* Threat Surface Type */}
      <TableCell align="left">
        <FormControl
          variant="outlined"
          className={classes.formControl}
          required
          disabled={!permissions.includes('update:config') || value.attr !== 0}
        >
          <InputLabel htmlFor="outlined-name-native-required">
            Required
          </InputLabel>
          <Select
            labelId={`select-outlined-label-${surfaceContent.id}`}
            id={`select-outlined-id-${threatIndex}`}
            value={surfaceContent.name}
            onChange={handleTypeChange}
            label="Required *"
          >
            {threatTypes ? (
              threatTypes.map((type: any) => (
                <MenuItem value={type.name} key={`threat-type-${type.id}`}>
                  {type.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" key={'threat-type-null'}></MenuItem>
            )}
          </Select>
        </FormControl>
      </TableCell>
    </>
  );
};

const DragHandle = SortableHandle(({ permissions }: any) => {
  const classes = useStyles();

  return (
    /* Drag and Drop icon */
    <TableCell padding="default">
      <Tooltip title="Drag Up/Down" TransitionComponent={Zoom} arrow>
        <IconButton
          className={classes.dnd}
          disabled={!permissions.includes('update:config')}
        >
          <DragHandleIcon />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
});

interface DeleteRestoreProps {
  value: ThreatSurface;
  permissions: string[];
  onHandleDeleteThreat: (isDeleted: boolean, arrayThreat: any[]) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DeleteRestoreHandle = ({
  value,
  permissions,
  onHandleDeleteThreat,
}: DeleteRestoreProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDelete = () => {
    if (open) {
      /* Delete Threat Surface */
      handleOpen();

      const deleteThreat = [{ id: value.id }];

      try {
        deleteThreat.map(async (threat: any) => {
          await deleteThreatSurface(threat).then((response) => {
            threat.result = response;

            // Re-load the data
            onHandleDeleteThreat(true, deleteThreat);
          });
        });
      } catch (e) {
        console.log(
          'There has been a problem with delete operation: ' + e.message
        );
      }
    } else {
      /* Restore Threat Surface */
      const restoreThreat = [{ id: value.id }];
      onHandleDeleteThreat(false, restoreThreat);
    }
  };

  return (
    /* Delete / Restore icon */
    <TableCell padding="default">
      <Tooltip
        title={value.attr !== 0 ? 'Restore' : 'Delete'}
        TransitionComponent={Zoom}
        arrow
      >
        <IconButton
          aria-label={value.attr !== 0 ? 'restore' : 'delete'}
          disabled={!permissions.includes('delete:config')}
        >
          {value.attr !== 0 ? (
            <RestoreIcon onClick={handleDelete} />
          ) : (
            <DeleteIcon onClick={handleOpen} />
          )}
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOpen}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" disableTypography>
          <Typography variant="h6">Attention</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure you want to delete ${value.name}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </TableCell>
  );
};

interface ThreadSurfaceProps {
  value: ThreatSurface;
  threatIndex: number;
  labelId: string;
  threatTypes: ThreatSurfaceType[] | null;
  permissions: string[];
  onHandleDeleteThreat: (isDeleted: boolean, arrayThreat: any[]) => void;
  onHandleChangeEditRow: (threatRow: any, typeRow: number) => void;
}

const ThreadSurfaceContent = (props: ThreadSurfaceProps) => {
  return (
    <TableRow hover tabIndex={-1} key={props.value.name}>
      {/* Drag and Drop icon */}
      <DragHandle permissions={props.permissions} />

      {/* Table Row with Threats Surface */}
      <ThreatSurfaceRow
        value={props.value}
        threatIndex={props.threatIndex}
        labelId={props.labelId}
        threatTypes={props.threatTypes}
        permissions={props.permissions}
        onHandleChangeEditRow={props.onHandleChangeEditRow}
      />

      {/* Delete / Restore icon */}
      <DeleteRestoreHandle
        value={props.value}
        permissions={props.permissions}
        onHandleDeleteThreat={props.onHandleDeleteThreat}
      />
    </TableRow>
  );
};

const ThreadSurfaceSortableContent = SortableElement(
  (props: ThreadSurfaceProps) => (
    <ThreadSurfaceContent
      value={props.value}
      threatIndex={props.threatIndex}
      labelId={props.labelId}
      threatTypes={props.threatTypes}
      permissions={props.permissions}
      onHandleDeleteThreat={props.onHandleDeleteThreat}
      onHandleChangeEditRow={props.onHandleChangeEditRow}
    />
  )
);

const ThreadSurfaceSortableContainer = SortableContainer(
  ({ children }: any) => {
    return <>{children}</>;
  }
);

interface SubcomponentTableProps {
  subcomponentName: string;
  subcomponentHelpURI?: string;
  surfaces: ThreatSurface[] | null;
  threatTypes: ThreatSurfaceType[] | null;
  headCells: HeadCell[];
  permissions: string[];
}

export default function SubcomponentTable({
  subcomponentName,
  subcomponentHelpURI,
  surfaces,
  threatTypes,
  headCells,
  permissions,
}: SubcomponentTableProps) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [threatSurfaces, setThreatSurfaces] = useState<ThreatSurface[] | null>(
    null
  );
  const nameNotUnique = useRef(false);

  useEffect(() => {
    if (surfaces) {
      setThreatSurfaces(surfaces);
    }
  }, []);

  const handleSnackbar = () => {
    setOpen(!open);
  };

  const handleLoadData = (newthreatSurface: any) => {
    const threat = threatSurfaces ? threatSurfaces.slice(0) : [];
    try {
      threat.push(newthreatSurface);
      setThreatSurfaces(threat as ThreatSurface[]);
    } catch (error) {
      console.log(error);
      handleSnackbar();
    }
  };

  const handleUpdateThreatSurface = (
    updatedThreat: any,
    newThreat: any,
    previousIndex: number,
    newIndex: number
  ) => {
    try {
      (async () => {
        await updateThreatSurface(updatedThreat).then((result: any) => {
          if (typeof result.status === 'undefined') {
            // Drag&Drop Threats
            if (previousIndex !== newIndex) {
              setThreatSurfaces(arrayMove(newThreat, previousIndex, newIndex));
            } else {
              setThreatSurfaces(newThreat as ThreatSurface[]);
            }
          } else {
            console.log(`Error ${result.status}: ${result.response.message}`);
            handleSnackbar();
          }
        });
      })();
    } catch (e) {
      console.log(
        'There has been a problem with update operation: ' + e.message
      );
    }
  };

  const handleChangeEditRow = (threatRow: any, typeRow: number) => {
    if (threatSurfaces) {
      const updatedThreat: any = [];

      // Editing Threat name
      if (typeRow === 0) {
        nameNotUnique.current = threatSurfaces.some(
          (item: any) =>
            threatRow.name.toLocaleLowerCase() === item.name.toLocaleLowerCase()
        );
      }

      if (!nameNotUnique.current) {
        const newThreat = threatSurfaces.map((item) => {
          if (parseInt(threatRow.id) === item.id) {
            item.surfaceTypeId = threatRow.surfaceTypeId;
            item.name = threatRow.name;
            item.description = threatRow.description;

            updatedThreat.push({
              id: item.id,
              name: item.name,
              description: item.description,
              displayOrder: item.displayOrder,
              attr: item.attr,
              surfaceTypeId: item.surfaceTypeId,
            });
          }
          return item;
        });

        // Update Threat Surface
        handleUpdateThreatSurface(updatedThreat, newThreat, 0, 0);
      } else {
        handleSnackbar();
      }
    }
  };

  const handleDeleteThreat = (isDeleted: boolean, arrayThreat: any[]) => {
    if (threatSurfaces) {
      const updatedThreat: any = [];

      // Restore Threat Surface
      if (!isDeleted) {
        const newThreat = threatSurfaces.map((item) => {
          if (parseInt(arrayThreat[0].id) === item.id) {
            item.attr = 0;

            updatedThreat.push({
              id: item.id,
              name: item.name,
              description: item.description,
              displayOrder: item.displayOrder,
              attr: item.attr,
              surfaceTypeId: item.surfaceTypeId,
            });
          }
          return item;
        });

        // Update Threat Surface
        handleUpdateThreatSurface(updatedThreat, newThreat, 0, 0);
      }
      // Delete Threat Surface
      else {
        if (Object.keys(arrayThreat[0].result).length > 0) {
          if (typeof arrayThreat[0].result.status === 'undefined') {
            // Disable Threat Surface (Soft Delete)
            const newThreat = threatSurfaces.map((item) => {
              if (parseInt(arrayThreat[0].id) === item.id) {
                item.attr = arrayThreat[0].result[0].attr;

                updatedThreat.push({
                  id: item.id,
                  name: item.name,
                  description: item.description,
                  displayOrder: item.displayOrder,
                  attr: item.attr,
                  surfaceTypeId: item.surfaceTypeId,
                });
              }
              return item;
            });

            // Update Threat Surface
            handleUpdateThreatSurface(updatedThreat, newThreat, 0, 0);
          } else {
            console.log(
              `Error ${arrayThreat[0].result.status}: ${arrayThreat[0].result.response.message}`
            );
            handleSnackbar();
          }
        } else {
          // Threat Deleted successfully
          let deleteIndex = 0;
          const newThreat = threatSurfaces.filter((item, index) => {
            if (parseInt(arrayThreat[0].id) === item.id) {
              deleteIndex = index;
            }
            return parseInt(arrayThreat[0].id) !== item.id;
          });

          // Last threat on the table
          if (deleteIndex > newThreat.length - 1) {
            setThreatSurfaces(newThreat);
          } else {
            fixDisplayOrder(newThreat, deleteIndex, -1, newThreat.length - 1);

            newThreat.map((item, index) => {
              if (index >= deleteIndex) {
                updatedThreat.push({
                  id: item.id,
                  name: item.name,
                  description: item.description,
                  displayOrder: item.displayOrder,
                  attr: item.attr,
                  surfaceTypeId: item.surfaceTypeId,
                });
              }
            });

            // Update Threat Surface
            handleUpdateThreatSurface(updatedThreat, newThreat, 0, 0);
          }
        }
      }
    }
  };

  const threatLength = !threatSurfaces ? 0 : threatSurfaces.length;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, threatLength - page * rowsPerPage);

  const handleSortStart = ({ node, helper }: any) => {
    helper = document.getElementsByClassName('SortableHelper')[0];
    node.childNodes.forEach((td: HTMLTableDataCellElement, index: number) => {
      helper.childNodes[index].style.width = `${td.offsetWidth}px`;
    });
  };

  const fixDisplayOrder = (
    arr: ThreatSurface[],
    previousIndex: number,
    newIndex: number,
    lastPosition: number
  ) => {
    // Identify a Deleted element and check if it's the last element
    if (newIndex === -1 && previousIndex > lastPosition) {
      return;
    }
    const newArray = arr.slice(0);

    if (previousIndex === lastPosition) {
      newArray[lastPosition].displayOrder =
        newIndex === -1
          ? lastPosition + 1
          : newArray[lastPosition].displayOrder - (previousIndex - newIndex);

      return newArray;
    }

    // Delete
    if (newIndex === -1) {
      newArray[lastPosition].displayOrder = lastPosition + 1;
    } else {
      previousIndex > newIndex
        ? (newArray[lastPosition].displayOrder += 1)
        : (newArray[lastPosition].displayOrder -= 1);
    }
    lastPosition < previousIndex ? (lastPosition += 1) : (lastPosition -= 1);
    fixDisplayOrder(newArray, previousIndex, newIndex, lastPosition);
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (threatSurfaces && parseInt(oldIndex) !== parseInt(newIndex)) {
      const updatedThreat: any = [];

      fixDisplayOrder(
        threatSurfaces,
        parseInt(oldIndex),
        parseInt(newIndex),
        parseInt(newIndex)
      );

      threatSurfaces.map((item, index) => {
        if (
          (index >= parseInt(oldIndex) && index <= parseInt(newIndex)) ||
          (index <= parseInt(oldIndex) && index >= parseInt(newIndex))
        ) {
          updatedThreat.push({
            id: item.id,
            name: item.name,
            description: item.description,
            displayOrder: item.displayOrder,
            attr: item.attr,
            surfaceTypeId: item.surfaceTypeId,
          });
        }
      });

      // Update Threat Surface
      handleUpdateThreatSurface(
        updatedThreat,
        threatSurfaces,
        parseInt(oldIndex),
        parseInt(newIndex)
      );
    }
  };

  return (
    <Box ml={2} flex={1}>
      <Paper elevation={3} className={classes.paper}>
        <EnhancedTableToolbar
          subcomponentName={subcomponentName}
          subcomponentHelpURI={subcomponentHelpURI}
          threatSurfaces={threatSurfaces}
          threatTypes={threatTypes}
          permissions={permissions}
          onHandleLoadData={handleLoadData}
        />
        <ThreadSurfaceSortableContainer
          useDragHandle
          helperClass="SortableHelper"
          onSortStart={handleSortStart}
          onSortEnd={onSortEnd}
        >
          <TableContainer className={classes.container}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead classes={classes} headCells={headCells} />

              <TableBody>
                {!threatSurfaces
                  ? null
                  : threatSurfaces
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((threatData, index) => {
                        const labelId = `enhanced-table-${index}`;

                        return !permissions.includes('update:config') ? (
                          <ThreadSurfaceContent
                            key={`table-item-${index}`}
                            threatIndex={index}
                            value={threatData}
                            labelId={labelId}
                            threatTypes={threatTypes}
                            permissions={permissions}
                            onHandleDeleteThreat={handleDeleteThreat}
                            onHandleChangeEditRow={handleChangeEditRow}
                          />
                        ) : (
                          <ThreadSurfaceSortableContent
                            key={`sortable-item-${threatData.name}-${index}`}
                            index={index}
                            threatIndex={index}
                            value={threatData}
                            labelId={labelId}
                            threatTypes={threatTypes}
                            permissions={permissions}
                            onHandleDeleteThreat={handleDeleteThreat}
                            onHandleChangeEditRow={handleChangeEditRow}
                          />
                        );
                      })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 110 }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ThreadSurfaceSortableContainer>

        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          component="div"
          count={threatLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <DialogActions>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackbar}>
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="error"
            onClose={handleSnackbar}
          >
            {!nameNotUnique.current
              ? 'Oops! Something went wrong. Please, try again later.'
              : 'Threat Surface Names must be unique.'}
          </MuiAlert>
        </Snackbar>
      </DialogActions>
    </Box>
  );
}
