import { connect } from 'react-redux';
import { Typography, Divider } from '@material-ui/core';
import { KPIGroup as KpiGroupType, Control } from 'app/services/api';
import {
  ThreatLevel as ThreatLevelType,
  ThreatSurface as ThreatSurfaceType,
} from 'app/services/apiThreatSurfaces';
import { fetchThreatSurfaces } from 'app/common/actions/threat-surfaces';
import { fetchThreatLevels } from 'app/common/actions/threat-levels';
import { CalendarContext } from '../../MainContent';
import ThreatPreparednessMatrix from '../SummaryDashboard/ThreatPreparednessMatrix';
import ScopeMatrix from './ScopeMatrix';
import CoverageMatrix from './CoverageMatrix';
import ThreatPreparednessByThreatSurfaceMatrix from './ThreatPreparednessByThreatSurfaceMatrix';

interface threatSurfacesState {
  isLoading: boolean;
  hasError: Error;
  payload: ThreatSurfaceType[];
}

interface threatLevelState {
  isLoading: boolean;
  hasError: Error;
  payload: ThreatLevelType[];
}

export interface surfaceTypes {
  threatSurfaces: threatSurfacesState;
}

export interface threatLevelTypes {
  threatLevels: threatLevelState;
}

type stateType = {
  kpiGroups: { payload: KpiGroupType[] };
  threatSurfaces: threatSurfacesState;
  threatLevels: threatLevelState;
  app: {
    control: Control;
  };
};

type SecurityDashboardType = surfaceTypes &
  threatLevelTypes & {
    kpiGroups: KpiGroupType[];
    getThreatLevels: () => void;
    getThreatSurfaces: () => void;
    selectedControl: Control;
  };

const SecurityDashboard = ({
  kpiGroups,
  getThreatSurfaces,
  getThreatLevels,
  threatSurfaces,
  threatLevels,
  selectedControl,
}: SecurityDashboardType): JSX.Element => {
  const controlId = selectedControl?.id;
  const controlName = selectedControl?.name;

  const kpiGroup = kpiGroups.find(
    (group: KpiGroupType) => group.path === 'investment'
  );

  if (!kpiGroup) {
    return <div>Invalid KPI Group</div>;
  }

  const securityKpiGroup = kpiGroups.find(
    (grp: KpiGroupType) => grp.path === 'security-coverage'
  );

  const [loading, setLoading] = React.useState<boolean>(true);
  const calendarDate = React.useContext(CalendarContext);

  React.useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    getThreatSurfaces();
    getThreatLevels();

    (async () => {
      if (!controlId || !securityKpiGroup) {
        setLoading(false);
        return;
      }

      setLoading(false);
    })();
  }, [calendarDate]);

  return (
    <div>
      <Typography variant="h6">Threat Preparedness Summary</Typography>
      <Divider style={{ marginBottom: '2rem' }} />
      <ThreatPreparednessMatrix
        controlId={controlId as number}
        selectedControl={controlName as string}
        kpiGroups={kpiGroups}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        calendarDate={calendarDate}
      />
      <ScopeMatrix
        controlId={controlId as number}
        selectedControl={controlName as string}
        kpiGroups={kpiGroups}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        calendarDate={calendarDate}
      />
      <CoverageMatrix
        controlId={controlId as number}
        selectedControl={controlName as string}
        kpiGroups={kpiGroups}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        calendarDate={calendarDate}
      />

      <ThreatPreparednessByThreatSurfaceMatrix
        controlId={controlId as number}
        kpiGroups={kpiGroups}
        threatSurfaces={threatSurfaces}
        threatLevels={threatLevels}
        calendarDate={calendarDate}
      />
    </div>
  );
};

const mapState = ({
  kpiGroups: { payload },
  threatSurfaces,
  threatLevels,
  app: { control },
}: stateType) => ({
  kpiGroups: payload,
  threatLevels,
  threatSurfaces,
  selectedControl: control,
});

const mapDispatch = {
  getThreatLevels: fetchThreatLevels,
  getThreatSurfaces: fetchThreatSurfaces,
};

export default connect(mapState, mapDispatch)(SecurityDashboard);
