import React, { useEffect } from 'react';
import MuiBlue from '@material-ui/core/colors/blue';
import Loader from 'app/common/Loader';
import BudgetAllocationTable from './BudgetAllocationTable';
import { KPI, KPIValue } from 'app/services/api';
import {
  ThreatSurface as ThreatSurfaceType,
  ThreatLevel as ThreatLevelType,
} from 'app/services/apiThreatSurfaces';
import { BudgetAllocationDataType } from 'app/utils/budget';
import {
  commonDashboardTypes,
  threatLevelTypes,
} from './index';

type BudgetAllocationType = commonDashboardTypes &
  threatLevelTypes & {
    budgetAllocationData: BudgetAllocationDataType | null;
    controlName: string;
  };

const getDollarFromPercent = (totalValue: number, value: number): string => {
  const val = Number((totalValue / 100) * value);
  return val.toFixed(2);
};

const getValueFromObject = (obj: KPI | undefined): number => {
  return obj && obj.kpiValue.length > 0 ? Number(obj.kpiValue[0].value) : 0;
};

const getNameFromObject = (obj: KPI | undefined): string => {
  return obj ? obj.name : '';
};

const getSum = (obj: number[] | undefined): number => {
  return obj ? obj.reduce((a, b) => a + b, 0) : 0;
};

const getTotalObject = (
  budgetAllocationData: BudgetAllocationDataType,
  key: string
): any => {
  const totalAllocationLevels = budgetAllocationData.totalAllocationLevels.find(
    (item) => item.key === key
  );
  const totalAllocationLevelsValues = totalAllocationLevels?.kpiValue.map(
    (item) => Number(item.value)
  );
  const totalValue = getSum(totalAllocationLevelsValues);
  const totalData = [
    {
      id: 1,
      label: 'Total',
      Total: totalValue,
      TotalColour: MuiBlue[900],
    },
  ];
  const totalDataKey = ['Total'];
  return {
    totalAllocationLevels,
    totalAllocationLevelsValues,
    totalValue,
    totalData,
    totalDataKey,
  };
};

const getBudgetSplitObject = (
  budgetAllocationData: BudgetAllocationDataType,
  key: string,
  capExKey: string,
  opExKey: string,
  totalValue: number
): any => {
  const budgetSplit = budgetAllocationData.budgetSplit.filter((item) =>
    item.key.includes(key)
  );
  const capEx = budgetSplit.find((item) => item.key === capExKey);
  const opEx = budgetSplit.find((item) => item.key === opExKey);
  const opExValue = getValueFromObject(opEx);
  const capExValue = getValueFromObject(capEx);
  const budgetSplitData = [
    {
      id: 1,
      label: 'Budget Split',
      'OPEX %': getDollarFromPercent(totalValue, opExValue),
      'OPEX %Colour': capExValue > opExValue ? MuiBlue[800] : MuiBlue[900],
      'CAPEX %': getDollarFromPercent(totalValue, capExValue),
      'CAPEX %Colour': capExValue > opExValue ? MuiBlue[900] : MuiBlue[800],
    },
  ];
  let budgetSplitDataKeys =
    capExValue > opExValue
      ? [getNameFromObject(capEx), getNameFromObject(opEx)]
      : [getNameFromObject(opEx), getNameFromObject(capEx)];
  const budgetSplitDataLength =
    opExValue && capExValue ? 2 : opExValue === 0 || capExValue === 0 ? 1 : 0;
  budgetSplitDataKeys = budgetSplitDataKeys.slice(0, budgetSplitDataLength);
  const budgetSplitDataMaxValue = getDollarFromPercent(
    totalValue,
    opExValue + capExValue
  );
  return {
    budgetSplitData,
    budgetSplitDataKeys,
    budgetSplitDataMaxValue,
  };
};

const getAllocationResourcesObject = (
  budgetAllocationData: BudgetAllocationDataType,
  key: string,
  totalValue: number
): any => {
  const allocationResources =
    budgetAllocationData.budgetAllocationResources.filter((item) =>
      item.key.includes(key)
    );
  let allocationResourcesData = [
    {
      id: 1,
      label: 'Allocation to Resources',
    },
  ];
  const allocationResourcesPairedData: { name: string; value: number }[] = [];
  allocationResources.map((element) => {
    const key = element.name;
    const value = element.kpiValue.length > 0 ? element.kpiValue[0].value : 0;
    allocationResourcesPairedData.push({
      name: key ? key : '',
      value: value ? Number(value) : 0,
    });
  });

  const allocationResourcesDataKeys: string[] = [];
  allocationResourcesPairedData.sort(function (a, b) {
    return b.value - a.value;
  });
  allocationResourcesPairedData.map((item, index: number) => {
    const key = item.name;
    const value = item.value;
    const colorKey = `${key}` + 'Colour';
    const colorIndex = 9 - index >= 1 ? (9 - index) * 100 : 50;
    allocationResourcesData = allocationResourcesData.map((data: any) => {
      const newObj = { ...data };
      newObj[key] = getDollarFromPercent(totalValue, Number(value));
      newObj[colorKey] = MuiBlue[colorIndex as keyof ColorIndexArray];
      return newObj;
    });
  });
  allocationResourcesPairedData.map(
    (item) => item.value && allocationResourcesDataKeys.push(item.name)
  );
  const allocationResourcesValues = allocationResourcesPairedData.map(
    (item) => item.value && Number(item.value)
  );
  const allocationResourcsDataMaxValue = getDollarFromPercent(
    totalValue,
    getSum(allocationResourcesValues)
  );

  return {
    allocationResourcesData,
    allocationResourcesDataKeys,
    allocationResourcsDataMaxValue,
  };
};

const getAllocationLevelsObject = (
  budgetAllocationData: BudgetAllocationDataType,
  totalAllocationLevelsValues: number[],
  totalAllocationLevels: KPI,
  threatLevelsPayload: ThreatLevelType[]
): any => {
  totalAllocationLevelsValues &&
    totalAllocationLevelsValues.sort((a: number, b: number) => b - a);
  let allocationLevelsData = [
    {
      id: 1,
      label: 'Allocation to Levels',
    },
  ];

  const allocationLevelsPairedData: { name: string; value: number }[] = [];
  totalAllocationLevels?.kpiValue.map((element: KPIValue) => {
    const levelId = element.levelId ? element.levelId : '';
    const payload = threatLevelsPayload.find((o) => o.id === levelId);
    const key = payload?.name;
    const value = element.value;
    allocationLevelsPairedData.push({
      name: key ? key : '',
      value: value ? Number(value) : 0,
    });
  });

  let allocationLevelsDataKeys: string[] = [];
  allocationLevelsPairedData.sort(function (a, b) {
    return b.value - a.value;
  });
  allocationLevelsPairedData.map((item, index: number) => {
    const key = item.name;
    const value = item.value;
    const colorKey = `${key}` + 'Colour';
    const colorIndex = 9 - index >= 1 ? (9 - index) * 100 : 50;
    allocationLevelsData = allocationLevelsData.map((data: any) => {
      const newObj = { ...data };
      newObj[key] = value;
      newObj[colorKey] = MuiBlue[colorIndex as keyof ColorIndexArray];
      return newObj;
    });
  });
  allocationLevelsPairedData.map((item) =>
    allocationLevelsDataKeys.push(item.name)
  );
  let allocationLevelsDataKeysLength = 0;
  totalAllocationLevelsValues?.map(
    (item: number) => item && allocationLevelsDataKeysLength++
  );
  allocationLevelsDataKeys = allocationLevelsDataKeys.slice(
    0,
    allocationLevelsDataKeysLength
  );

  return {
    allocationLevelsData,
    allocationLevelsDataKeys,
  };
};

const getAllocationSurfacesObject = (
  budgetAllocationData: BudgetAllocationDataType,
  key: string,
  totalValue: number,
  threatSurfacesPayload: ThreatSurfaceType[]
): any => {
  const allocationSurfaces = budgetAllocationData.budgetAllocationSurfaces.find(
    (item) => item.key === key
  );
  const allocationSurfacesValues = allocationSurfaces?.kpiValue.map((item) =>
    Number(item.value)
  );
  const allocationSurfacesDataTotalValue = getDollarFromPercent(
    totalValue,
    getSum(allocationSurfacesValues)
  );
  allocationSurfacesValues && allocationSurfacesValues.sort((a, b) => b - a);
  let allocationSurfacesData = [
    {
      id: 1,
      label: 'Allocation to Surfaces',
    },
  ];
  const allocationSurfacesPairedData: { name: string; value: number }[] = [];
  allocationSurfaces?.kpiValue.map((element) => {
    const surfaceId = element.surfaceId ? element.surfaceId : '';
    const payload = threatSurfacesPayload.find((o) => o.id === surfaceId);
    const key = payload?.name;
    const value = element.value;
    allocationSurfacesPairedData.push({
      name: key ? key : '',
      value: value ? Number(value) : 0,
    });
  });

  let allocationSurfacesDataKeys: string[] = [];
  allocationSurfacesPairedData.sort(function (a, b) {
    return b.value - a.value;
  });
  allocationSurfacesPairedData.map((item, index: number) => {
    const key = item.name;
    const value = item.value;
    const colorKey = `${key}` + 'Colour';
    const colorIndex = 9 - index >= 1 ? (9 - index) * 100 : 50;
    allocationSurfacesData = allocationSurfacesData.map((data: any) => {
      const newObj = { ...data };
      newObj[key] = getDollarFromPercent(totalValue, Number(value));
      newObj[colorKey] = MuiBlue[colorIndex as keyof ColorIndexArray];
      return newObj;
    });
  });
  allocationSurfacesPairedData.map((item) =>
    allocationSurfacesDataKeys.push(item.name)
  );
  let allocationSurfacesDataKeysLength = 0;
  allocationSurfacesValues?.map(
    (item) => item && allocationSurfacesDataKeysLength++
  );
  allocationSurfacesDataKeys = allocationSurfacesDataKeys.slice(
    0,
    allocationSurfacesDataKeysLength
  );

  return {
    allocationSurfacesData,
    allocationSurfacesDataKeys,
    allocationSurfacesDataTotalValue,
  };
};

interface ColorIndexArray {
  900: string;
  800: string;
  700: string;
  600: string;
  500: string;
  400: string;
  300: string;
  200: string;
  100: string;
  50: string;
}

const BudgetAllocation = ({
  budgetAllocationData,
  getThreatSurfaces,
  getThreatLevels,
  threatSurfaces,
  threatLevels,
  controlName,
}: BudgetAllocationType): JSX.Element => {
  useEffect(() => {
    getThreatSurfaces();
    getThreatLevels();
  }, []);

  if (
    !budgetAllocationData ||
    threatSurfaces.isLoading ||
    threatLevels.isLoading
  ) {
    return <Loader />;
  }

  const threatSurfacesPayload = threatSurfaces.payload;
  const threatLevelsPayload = threatLevels.payload;

  // total allocation to level data for current
  const currentTotalObject = getTotalObject(
    budgetAllocationData,
    'com.pharossecurity.zero-based.allocation-to-levels.current'
  );
  const currentTotalAllocationLevels = currentTotalObject.totalAllocationLevels;
  const currentTotalAllocationLevelsValues =
    currentTotalObject.totalAllocationLevelsValues;
  const currentTotalValue = currentTotalObject.totalValue;
  const currentTotalData = currentTotalObject.totalData;
  const currentTotalDataKey = currentTotalObject.totalDataKey;

  // budget split data for current
  const currentBudgetSplitObject = getBudgetSplitObject(
    budgetAllocationData,
    'budget-split.current',
    'com.pharossecurity.zero-based.budget-split.current.capex',
    'com.pharossecurity.zero-based.budget-split.current.opex',
    currentTotalValue
  );
  const currentBudgetSplitData = currentBudgetSplitObject.budgetSplitData;
  const currentBudgetSplitDataKeys =
    currentBudgetSplitObject.budgetSplitDataKeys;

  // allocation to resources data for current
  const currentAllocationResourcesObject = getAllocationResourcesObject(
    budgetAllocationData,
    'allocation-to-resources.current',
    currentTotalValue
  );
  const currentAllocationResourcesData =
    currentAllocationResourcesObject.allocationResourcesData;
  const currentAllocationResourcesDataKeys =
    currentAllocationResourcesObject.allocationResourcesDataKeys;

  // allocation to level data for current
  const currentAllocationLevelsObject = getAllocationLevelsObject(
    budgetAllocationData,
    currentTotalAllocationLevelsValues,
    currentTotalAllocationLevels,
    threatLevelsPayload
  );
  const currentAllocationLevelsData =
    currentAllocationLevelsObject.allocationLevelsData;
  const currentAllocationLevelsDataKeys =
    currentAllocationLevelsObject.allocationLevelsDataKeys;

  // allocation to surfaces data for current
  const currentAllocationSurfacesObject = getAllocationSurfacesObject(
    budgetAllocationData,
    'com.pharossecurity.zero-based.allocation-to-surfaces.current',
    currentTotalValue,
    threatSurfacesPayload
  );
  const currentAllocationSurfacesData =
    currentAllocationSurfacesObject.allocationSurfacesData;
  const currentAllocationSurfacesDataKeys =
    currentAllocationSurfacesObject.allocationSurfacesDataKeys;

  // total allocation to level data for build
  const buildTotalObject = getTotalObject(
    budgetAllocationData,
    'com.pharossecurity.zero-based.allocation-to-levels.build'
  );
  const buildTotalAllocationLevels = buildTotalObject.totalAllocationLevels;
  const buildTotalAllocationLevelsValues =
    buildTotalObject.totalAllocationLevelsValues;
  const buildTotalValue = buildTotalObject.totalValue;
  const buildTotalData = buildTotalObject.totalData;
  const buildTotalDataKey = buildTotalObject.totalDataKey;

  // budget split data for build
  const buildBudgetSplitObject = getBudgetSplitObject(
    budgetAllocationData,
    'budget-split.build',
    'com.pharossecurity.zero-based.budget-split.build.capex',
    'com.pharossecurity.zero-based.budget-split.build.opex',
    buildTotalValue
  );
  const buildBudgetSplitData = buildBudgetSplitObject.budgetSplitData;
  const buildBudgetSplitDataKeys = buildBudgetSplitObject.budgetSplitDataKeys;

  // allocation to resources data for build
  const buildAllocationResourcesObject = getAllocationResourcesObject(
    budgetAllocationData,
    'allocation-to-resources.build',
    buildTotalValue
  );
  const buildAllocationResourcesData =
    buildAllocationResourcesObject.allocationResourcesData;
  const buildAllocationResourcesDataKeys =
    buildAllocationResourcesObject.allocationResourcesDataKeys;

  // allocation to level data for build
  const buildAllocationLevelsObject = getAllocationLevelsObject(
    budgetAllocationData,
    buildTotalAllocationLevelsValues,
    buildTotalAllocationLevels,
    threatLevelsPayload
  );
  const buildAllocationLevelsData =
    buildAllocationLevelsObject.allocationLevelsData;
  const buildAllocationLevelsDataKeys =
    buildAllocationLevelsObject.allocationLevelsDataKeys;

  // allocation to surfaces data for build
  const buildAllocationSurfacesObject = getAllocationSurfacesObject(
    budgetAllocationData,
    'com.pharossecurity.zero-based.allocation-to-surfaces.build',
    buildTotalValue,
    threatSurfacesPayload
  );
  const buildAllocationSurfacesData =
    buildAllocationSurfacesObject.allocationSurfacesData;
  const buildAllocationSurfacesDataKeys =
    buildAllocationSurfacesObject.allocationSurfacesDataKeys;

  // total allocation to level data for run
  const runTotalObject = getTotalObject(
    budgetAllocationData,
    'com.pharossecurity.zero-based.allocation-to-levels.run'
  );
  const runTotalAllocationLevels = runTotalObject.totalAllocationLevels;
  const runTotalAllocationLevelsValues =
    runTotalObject.totalAllocationLevelsValues;
  const runTotalValue = runTotalObject.totalValue;
  const runTotalData = runTotalObject.totalData;
  const runTotalDataKey = runTotalObject.totalDataKey;

  // budget split data for run
  const runBudgetSplitObject = getBudgetSplitObject(
    budgetAllocationData,
    'budget-split.run',
    'com.pharossecurity.zero-based.budget-split.run.capex',
    'com.pharossecurity.zero-based.budget-split.run.opex',
    runTotalValue
  );
  const runBudgetSplitData = runBudgetSplitObject.budgetSplitData;
  const runBudgetSplitDataKeys = runBudgetSplitObject.budgetSplitDataKeys;

  // allocation to resources data for run
  const runAllocationResourcesObject = getAllocationResourcesObject(
    budgetAllocationData,
    'allocation-to-resources.run',
    runTotalValue
  );
  const runAllocationResourcesData =
    runAllocationResourcesObject.allocationResourcesData;
  const runAllocationResourcesDataKeys =
    runAllocationResourcesObject.allocationResourcesDataKeys;

  // allocation to level data for run
  const runAllocationLevelsObject = getAllocationLevelsObject(
    budgetAllocationData,
    runTotalAllocationLevelsValues,
    runTotalAllocationLevels,
    threatLevelsPayload
  );
  const runAllocationLevelsData =
    runAllocationLevelsObject.allocationLevelsData;
  const runAllocationLevelsDataKeys =
    runAllocationLevelsObject.allocationLevelsDataKeys;

  // allocation to surfaces data for run
  const runAllocationSurfacesObject = getAllocationSurfacesObject(
    budgetAllocationData,
    'com.pharossecurity.zero-based.allocation-to-surfaces.run',
    runTotalValue,
    threatSurfacesPayload
  );
  const runAllocationSurfacesData =
    runAllocationSurfacesObject.allocationSurfacesData;
  const runAllocationSurfacesDataKeys =
    runAllocationSurfacesObject.allocationSurfacesDataKeys;

  return (
    <>
      <BudgetAllocationTable
        controlName={controlName + ' Current'}
        totalValue={currentTotalValue}
        totalData={currentTotalData}
        totalDataKey={currentTotalDataKey}
        budgetSplitData={currentBudgetSplitData}
        budgetSplitDataKeys={currentBudgetSplitDataKeys}
        allocationResourcesData={currentAllocationResourcesData}
        allocationResourcesDataKeys={currentAllocationResourcesDataKeys}
        allocationLevelsData={currentAllocationLevelsData}
        allocationLevelsDataKeys={currentAllocationLevelsDataKeys}
        allocationSurfacesData={currentAllocationSurfacesData}
        allocationSurfacesDataKeys={currentAllocationSurfacesDataKeys}
      />
      <BudgetAllocationTable
        controlName={controlName + ' Full Build'}
        totalValue={buildTotalValue}
        totalData={buildTotalData}
        totalDataKey={buildTotalDataKey}
        budgetSplitData={buildBudgetSplitData}
        budgetSplitDataKeys={buildBudgetSplitDataKeys}
        allocationResourcesData={buildAllocationResourcesData}
        allocationResourcesDataKeys={buildAllocationResourcesDataKeys}
        allocationLevelsData={buildAllocationLevelsData}
        allocationLevelsDataKeys={buildAllocationLevelsDataKeys}
        allocationSurfacesData={buildAllocationSurfacesData}
        allocationSurfacesDataKeys={buildAllocationSurfacesDataKeys}
      />
      <BudgetAllocationTable
        controlName={controlName + ' Full Run'}
        totalValue={runTotalValue}
        totalData={runTotalData}
        totalDataKey={runTotalDataKey}
        budgetSplitData={runBudgetSplitData}
        budgetSplitDataKeys={runBudgetSplitDataKeys}
        allocationResourcesData={runAllocationResourcesData}
        allocationResourcesDataKeys={runAllocationResourcesDataKeys}
        allocationLevelsData={runAllocationLevelsData}
        allocationLevelsDataKeys={runAllocationLevelsDataKeys}
        allocationSurfacesData={runAllocationSurfacesData}
        allocationSurfacesDataKeys={runAllocationSurfacesDataKeys}
      />
    </>
  );
};

export default BudgetAllocation;
