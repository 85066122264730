import React from 'react';

import Hint1KpiGroup from './components/Hint1KpiGroup';

const ManagementDashboard = (): JSX.Element => (
  <Hint1KpiGroup
    title="Management Summary"
    subGroupTitle="Management KPI Summary"
  />
);

export default ManagementDashboard;
