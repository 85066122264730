import PATHS from 'app/utils/paths';
import { getNullableQueryParam } from 'app/services/url';
import { request } from 'app/services/api';

export interface Asset {
  id: number;
  name: string;
  displayOrder: number;
  assetTypeId: number;
  impactScaleId: number;
  impactCostMin: number | null;
  impactCostMax: number | null;
  impactTypeIds: number[] | null;
  attr: number;
}

export interface AssetType {
  id: number;
  name: string;
  description: string;
  helpURI: string;
  displayOrder: number;
  attr: number;
}

export interface ImpactType {
  id: number;
  name: string;
  displayOrder: number;
  attr: number;
}

export interface ImpactScale {
  id: number;
  name: string;
  displayOrder: number;
}

type getAssetsType = {
  include?: string;
};

export const getAssets = ({ include }: getAssetsType): Promise<Asset[]> =>
  request
    .get(PATHS.API.ASSETS, {
      query: {
        ...getNullableQueryParam('include', include),
      },
    })
    .then((assets: Asset[]) =>
      assets.sort((a, b) => a.displayOrder - b.displayOrder)
    );

export const patchAssets = (
  asset: Asset[]
): Promise<Asset[] | Response | Error> => {
  return request
    .patch(PATHS.API.ASSETS, asset)
    .then((response: Response) => response)
    .catch((error: Error) => error);
};

type getAssetByIdType = {
  id: number;
  include?: string;
};

export const getAssetById = ({
  id,
  include,
}: getAssetByIdType): Promise<Asset> =>
  request
    .get(PATHS.API.ASSET, {
      query: {
        id,
        ...getNullableQueryParam('include', include),
      },
    })
    .then((asset: Asset) => asset);

export const saveAsset = (asset: Asset[]): Promise<Asset[]> => {
  return request.post(PATHS.API.ASSET, asset);
};

export const patchAsset = (
  asset: Asset
): Promise<Asset[] | Response | Error> => {
  return request
    .patch(PATHS.API.ASSET, asset)
    .then((response: Response) => response)
    .catch((error: Error) => error);
};

export const deleteAsset = (
  asset: Asset[]
): Promise<Asset[] | Response | Error> => {
  return request
    .delete(PATHS.API.ASSET, asset)
    .then((response: Response) => response)
    .catch((error: Error) => error);
};

export const getAssetTypes = (): Promise<AssetType[]> =>
  request
    .get(PATHS.API.ASSET_TYPES)
    .then((assetTypes: AssetType[]) =>
      assetTypes.sort((a, b) => a.displayOrder - b.displayOrder)
    );

export const getImpactTypes = (): Promise<ImpactType[]> =>
  request
    .get(PATHS.API.IMPACT_TYPES)
    .then((impactTypes: ImpactType[]) =>
      impactTypes.sort((a, b) => a.displayOrder - b.displayOrder)
    );

export const getImpactScales = (): Promise<ImpactScale[]> =>
  request
    .get(PATHS.API.IMPACT_SCALES)
    .then((impactScales: ImpactScale[]) =>
      impactScales.sort((a, b) => a.displayOrder - b.displayOrder)
    );
