import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Checkbox,
  Container,
  Divider,
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TextField,
  Paper,
  Tooltip,
  Zoom,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddBoxOutlined, Close, CreateOutlined } from '@material-ui/icons';
import {
  SearchItem,
  KPIGroupTreeItem,
  EnhancedTableHead,
} from 'app/search/components';
import {
  KPIGroup as KPIGroupType,
  Control,
  KPIMetadata,
  getKPIContext,
  KPISearchFilterType,
  KPIContext,
} from 'app/services/api';
import { ThreatSurface } from 'app/services/apiThreatSurfaces';
import {
  fetchKpiMetadata,
  kpiMetadataState,
} from 'app/common/actions/kpi-metadata';
import { fetchControls, controlsState } from 'app/common/actions/controls';
import { fetchKpiGroups, kpiGroupsState } from 'app/common/actions/kpi-groups';
import {
  fetchThreatSurfaces,
  threatSurfaceState,
} from 'app/common/actions/threat-surfaces';
import MuiGrey from '@material-ui/core/colors/grey';
import IconCaseSensitive from 'app/common/IconCaseSensitive';
import IconCaseSensitiveClicked from 'app/common/IconCaseSensitiveClicked';
import IconRegex from 'app/common/IconRegex';
import IconRegexClicked from 'app/common/IconRegexClicked';
import { HeadCell } from 'app/search/components/EnhancedTableHead';
import KPIContextDialog from 'app/kpi/components/KPIContextDialog';

type reduxStateType = {
  kpiMetadata: kpiMetadataState;
  controls: controlsState;
  kpiGroups: kpiGroupsState;
  threatSurfaces: threatSurfaceState;
};

type SearchKpiContextTypes = {
  kpiMetadata: kpiMetadataState;
  getKpiMetadata: () => void;
  kpiGroups: kpiGroupsState;
  getKpiGroups: () => void;
  controls: controlsState;
  getControls: () => void;
  threatSurfaces: threatSurfaceState;
  getThreatSurfaces: () => void;
};

export type Order = 'asc' | 'desc';

const useStyles = makeStyles({
  gridItem: {
    position: 'relative',
  },
  gridContainerFontSize: {
    fontSize: 18,
  },
  searchContextContainer: {
    height: 370,
    minHeight: 50,
    maxHeight: 'calc(100% - 100px)',
  },
  searchResultContainer: {
    height: 570,
    minHeight: 50,
    maxHeight: 'calc(100% - 100px)',
  },
  searchTable: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  tableRow: {
    height: 30,
  },
  selectCell: {
    width: 120,
  },
  contentCell: {
    width: '80%',
    paddingRight: 0,
  },
  deleteCell: {
    textAlign: 'right',
    paddingLeft: 0,
    paddingRight: 0,
  },
  addIconBtn: {
    position: 'absolute',
    right: 0,
  },
  regexIconBtn: {
    position: 'absolute',
    right: 0,
  },
  caseSensitiveIconBtn: {
    position: 'absolute',
    right: 22,
  },
  tableCellContextUri: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '-webkit-link',
  },
  divider: {
    backgroundColor: MuiGrey[500],
    marginTop: -25,
  },
  btnSearch: {
    position: 'absolute',
    right: '25px',
    marginTop: '95px',
  },
  btnReset: {
    position: 'absolute',
    right: '125px',
    marginTop: '95px',
  },
  cbDisable: {
    position: 'absolute',
    right: 'calc(100% - 292px)',
    marginTop: '85px',
  },
  editIconBtn: {
    right: 0,
  },
});

const MainContent = ({
  kpiMetadata,
  getKpiMetadata,
  kpiGroups,
  getKpiGroups,
  controls,
  getControls,
  threatSurfaces,
  getThreatSurfaces,
}: SearchKpiContextTypes): JSX.Element => {
  const classes = useStyles();
  const [metaData, setMetaData] = useState([
    { key: '', value: '', case: false, regex: false },
  ]);
  const [copyMetaData, setCopyMetaData] = useState([
    { key: '', value: '', case: false, regex: false },
  ]);
  const [searchResult, setSearchResult] = useState<KPIContext[]>([]);
  const [selectedControl, setSelectedControl] = useState(0);
  const [selectedKPIGroup, setSelectedKPIGroup] = useState('');
  const [selectedSurface, setSelectedSurface] = useState(0);
  const [order, setOrder] = useState<Order[]>(['asc', 'asc', 'asc', 'asc']);
  const [expandedTreeItem, setExpandedTreeItem] = useState<string[]>([]);
  const [selectedTreeItem, setSelectedTreeItem] = useState<string[]>([]);
  const [includeSubKeys, setIncludeSubKeys] = useState(false);
  const [disableCheckbox, setDisableCheckBox] = useState(true);
  const [orderBy, setOrderBy] = useState<string[]>([
    'control',
    'surface',
    'kpiGroup',
    'contextLink',
  ]);
  const defaultHeadCells: HeadCell[] = [
    { id: 'control', label: 'Control' },
    { id: 'surface', label: 'Surface' },
    { id: 'kpiGroup', label: 'KPI Group' },
    { id: 'contextLink', label: 'Context Link' },
    { id: 'editContext', label: '' },
  ];
  const [headCells, setHeadCells] = useState<HeadCell[]>(defaultHeadCells);
  const [activeSort, setActiveSort] = useState(-1);
  const [isFilterAll, setIsFilterAll] = useState(false);
  const [removeItems, setRemoveItems] = useState<number[]>([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [searchIndex, setSearchIndex] = useState(0);
  const [context, setContext] = useState<KPIContext | null>(null);
  const [contextKey, setContextKey] = useState('');
  const [controlId, setControlId] = useState(0);
  const [showEditButton, setShowEditButton] = useState<boolean[]>([]);
  const kpiMetadataPayload: KPIMetadata[] = kpiMetadata.payload as KPIMetadata[];
  const controlsPayload: Control[] = controls.payload as Control[];
  const kpiGroupsPayload: KPIGroupType[] = kpiGroups.payload as KPIGroupType[];
  const threatSurfacesPayload: ThreatSurface[] = threatSurfaces.payload as ThreatSurface[];

  useEffect(() => {
    // fetch kpi metadata
    if (!kpiMetadata.isLoading && !('payload' in kpiMetadata)) {
      getKpiMetadata();
    }

    // fetch kpi groups
    if (!kpiGroups.isLoading && !('payload' in kpiGroups)) {
      getKpiGroups();
    }

    // fetch controls
    if (!controls.isLoading && !('payload' in controls)) {
      getControls();
    }

    // fetch threat surfaces
    if (!threatSurfaces.isLoading && !('payload' in threatSurfaces)) {
      getThreatSurfaces();
    }
  }, []);

  useEffect(() => {
    if (isFilterAll) {
      setHeadCells(defaultHeadCells);
      setOrder(['asc', 'asc', 'asc', 'asc']);
      setOrderBy(['control', 'surface', 'kpiGroup', 'contextLink']);
      const arrCount: number[] = [];

      kpiMetadataPayload.forEach(() => {
        arrCount.push(0);
      });

      kpiMetadataPayload
        .slice(0)
        .reverse()
        .map((payload) => {
          searchResult.forEach((result: KPIContext) => {
            getMetadataValue(result).map((r: any, i: number) => {
              r.length !== 0 && arrCount[i]++;
            });
          });

          setHeadCells((oldArray) => [
            {
              id: payload.key,
              label: payload.name,
            },
            ...oldArray,
          ]);
          setOrder((oldArray) => [...oldArray, 'asc']);
          setOrderBy((oldArray) => [payload.key, ...oldArray]);
        });
      const removed: number[] = [];
      arrCount.map((item, index) => {
        if (item === 0) {
          removed.push(index);
          setRemoveItems((oldArray) => [...oldArray, index]);
        }
      });
      setHeadCells([
        ...headCells.filter((item, index) => !removed.includes(index)),
      ]);
      setOrder([...order.filter((item, index) => !removed.includes(index))]);
      setOrderBy([
        ...orderBy.filter((item, index) => !removed.includes(index)),
      ]);
    }
  }, [searchResult]);

  const onControlChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedControl(event.target.value as number);
  };

  const onSurfaceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedSurface(event.target.value as number);
  };

  const handleMetaSelect = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const newItem = {
      key: event.target.value as string,
      value: metaData[index].value,
      case: metaData[index].case,
      regex: metaData[index].regex,
    };
    metaData[index] = newItem;
    setMetaData([...metaData]);
  };

  const handleMetaContentChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const newItem = {
      key: metaData[index].key,
      value: event.target.value as string,
      case: metaData[index].case,
      regex: metaData[index].regex,
    };

    metaData[index] = newItem;
    setMetaData([...metaData]);
  };

  const handleCaseSensitive = (index: number) => {
    const newItem = {
      key: metaData[index].key,
      value: metaData[index].value,
      case: !metaData[index].case,
      regex: metaData[index].regex,
    };

    metaData[index] = newItem;
    setMetaData([...metaData]);
  };

  const handleRegex = (index: number) => {
    const newItem = {
      key: metaData[index].key,
      value: metaData[index].value,
      case: metaData[index].case,
      regex: !metaData[index].regex,
    };

    metaData[index] = newItem;
    setMetaData([...metaData]);
  };

  const handleAdd = () => {
    const newItem = { key: '', value: '', case: false, regex: false };
    setMetaData((oldArray) => [...oldArray, newItem]);
  };

  const handleDelete = (index: number) => {
    metaData.splice(index, 1);
    setMetaData([...metaData]);
  };

  const handleReset = () => {
    metaData.length = 0;
    const newItem = { key: '', value: '', case: false, regex: false };
    setMetaData((oldArray) => [...oldArray, newItem]);
    setSelectedControl(0);
    setSelectedSurface(0);
    setSelectedKPIGroup('');
    setSelectedTreeItem([]);
    setExpandedTreeItem([]);
    setIncludeSubKeys(false);
  };

  const handleTreeItemToggle = (
    event: React.ChangeEvent<unknown>,
    nodeIds: string[]
  ) => {
    setExpandedTreeItem(nodeIds);
  };

  const selectKPIGroup = (item: KPIGroupType, nodeId: string) => {
    if (nodeId == 'group' + item.id.toString()) {
      setSelectedKPIGroup(item.key);
      if (item.subGroups) {
        setDisableCheckBox(false);
      } else {
        setDisableCheckBox(true);
      }
    }
    if (item.subGroups) {
      item.subGroups.forEach((subItem: KPIGroupType) => {
        selectKPIGroup(subItem, nodeId);
      });
    }
  };

  const handleTreeItemSelect = (
    event: React.ChangeEvent<unknown>,
    nodeIds: string[]
  ) => {
    const nodeId = nodeIds.toString();
    kpiGroupsPayload.forEach((item: KPIGroupType) => {
      selectKPIGroup(item, nodeId);
    });

    setSelectedTreeItem(nodeIds);
  };

  const handleSearch = async (): Promise<void> => {
    const param = handlePreSetSearchResult();
    const context = await getKPIContext(param);
    context.forEach(() => {
      showEditButton.push(false);
    });
    setSearchResult(context);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
    index: number
  ) => {
    const isAsc = orderBy[index] === property && order[index] === 'asc';
    order[index] = isAsc ? 'desc' : 'asc';
    setOrder([...order]);
    setActiveSort(index);
    switch (property) {
      case 'control':
        searchResult.sort((a, b) =>
          getControlName(a.controlId) >= getControlName(b.controlId)
            ? order[index] === 'asc'
              ? 1
              : -1
            : order[index] === 'asc'
            ? -1
            : 1
        );
        break;
      case 'surface':
        searchResult.sort((a, b) =>
          getSurfaceName(a) >= getSurfaceName(b)
            ? order[index] === 'asc'
              ? 1
              : -1
            : order[index] === 'asc'
            ? -1
            : 1
        );
        break;
      case 'kpiGroup':
        searchResult.sort((a, b) =>
          getKPIGroupName(a.key) >= getKPIGroupName(b.key)
            ? order[index] === 'asc'
              ? 1
              : -1
            : order[index] === 'asc'
            ? -1
            : 1
        );
        break;
      case 'contextLink':
        searchResult.sort((a, b) =>
          getContextURI(a) >= getContextURI(b)
            ? order[index] === 'asc'
              ? 1
              : -1
            : order[index] === 'asc'
            ? -1
            : 1
        );
        break;
      default:
        searchResult.sort((a, b) => {
          const val1 = getMetadataValue(a)[index];
          const val2 = getMetadataValue(b)[index];
          return (val1.length > 0 ? val1[0].value : '') >=
            (val2.length > 0 ? val2[0].value : '')
            ? order[index] === 'asc'
              ? 1
              : -1
            : order[index] === 'asc'
            ? -1
            : 1;
        });
    }
  };

  const getKPIGroupName = (key: string) => {
    for (let i = 0; i < kpiGroupsPayload.length; i++) {
      if (kpiGroupsPayload[i].key === key) {
        return kpiGroupsPayload[i].name;
      }

      const sg = kpiGroupsPayload[i].subGroups || [];
      for (let j = 0; j < sg.length; j++) {
        if (sg[j].key === key) {
          return sg[j].name;
        }
      }
    }
    return '';
  };

  const getControlName = (id: number) => {
    const result = controlsPayload.find((item: Control) => item.id === id);
    if (result) {
      return result.name;
    }
    return '';
  };

  const getSurfaceName = (item: KPIContext) => {
    if (item.surfaceId) {
      const result = threatSurfacesPayload.find(
        (element: ThreatSurface) => element.id === item.surfaceId
      );
      if (result) {
        return result.name;
      }
    }
    return '';
  };

  const getContextURI = (item: KPIContext) => {
    return item.contextURI ? item.contextURI : '';
  };

  const getMetadataValue = (item: KPIContext) => {
    const arr: any = [];
    let cn: any = [];

    if (isFilterAll) {
      kpiMetadataPayload.map((meta: KPIMetadata, index) => {
        if (item.context && !removeItems.includes(index)) {
          item.context.map((rc: any) => {
            return meta.key === rc.key
              ? cn.push({ value: rc.value, uri: rc.uri })
              : null;
          });
          arr.push(cn);
          cn = [];
        }
      });
    } else {
      copyMetaData.map((meta: any) => {
        if (item.context) {
          item.context.map((rc: any) => {
            return meta.key === rc.key
              ? cn.push({ value: rc.value, uri: rc.uri })
              : null;
          });
          arr.push(cn);
          cn = [];
        }
      });
    }

    return arr;
  };

  const handleEdit = (
    event: React.MouseEvent<{ value: unknown }>,
    index: number
  ) => {
    setContext(searchResult[index]);
    setContextKey(searchResult[index].key);
    setControlId(searchResult[index].controlId);
    setSearchIndex(index);
    setDialogIsOpen(true);
  };

  const handleDialogClose = (context?: KPIContext, searchIndex?: number) => {
    setDialogIsOpen(false);
    if (context) {
      handlePreSetSearchResult();
      searchResult[searchIndex!] = context;
      setSearchResult([...searchResult]);
      searchResult.forEach(() => {
        showEditButton.push(false);
      });
    }
  };

  const handlePreSetSearchResult = () => {
    const filter: KPISearchFilterType[] = [];
    let isAll = false;
    metaData.forEach((data) => {
      const item: KPISearchFilterType = {
        key: data.key,
        value: data.value,
        match: [],
      };

      if (data.case && item.match) {
        item.match.push('case');
      }
      if (data.regex && item.match) {
        item.match.push('regex');
      }

      filter.push(item);
    });

    const param: any = {};
    param.key = selectedKPIGroup !== '' ? selectedKPIGroup : undefined;
    param.controlId =
      selectedControl > 0 ? selectedControl : undefined;
    param.surfaceId =
      selectedSurface > 0
        ? threatSurfacesPayload[selectedSurface - 1].id
        : undefined;
    param.filter = JSON.stringify(filter);
    setIsFilterAll(false);

    // check filter to set param.filter
    let t = 0;
    let t1 = 0;
    for (let i = 0; i < filter.length; i++) {
      if (
        filter[i].key === '' &&
        filter[i].value === '' &&
        filter[i].match?.length === 0
      ) {
        t++;
      }
      if (filter[i].key === '') {
        t1++;
      }
    }
    if (t === filter.length) {
      param.filter = JSON.stringify([]);
      setIsFilterAll(true);
      isAll = true;
    }
    if (t1 > 0) {
      setIsFilterAll(true);
      isAll = true;
    }

    param.include = includeSubKeys ? 'subKeys' : undefined;

    setHeadCells(defaultHeadCells);
    setOrder(['asc', 'asc', 'asc', 'asc']);
    setOrderBy(['control', 'surface', 'kpiGroup', 'contextLink']);

    if (isAll) {
      kpiMetadataPayload
        .slice(0)
        .reverse()
        .map((payload) => {
          setHeadCells((oldArray) => [
            {
              id: payload.key,
              label: payload.name,
            },
            ...oldArray,
          ]);
          setOrder((oldArray) => [...oldArray, 'asc']);
          setOrderBy((oldArray) => [payload.key, ...oldArray]);
        });
    } else {
      metaData
        .slice(0)
        .reverse()
        .map((item) => {
          kpiMetadataPayload.map((payload) => {
            if (item.key === payload.key) {
              setHeadCells((oldArray) => [
                {
                  id: payload.key,
                  label: payload.name,
                },
                ...oldArray,
              ]);
              setOrder((oldArray) => [...oldArray, 'asc']);
              setOrderBy((oldArray) => [payload.key, ...oldArray]);
            }
          });
        });
    }

    setRemoveItems([]);
    setCopyMetaData([...metaData]);

    return param;
  };

  const getGroupHierarchy = (key: string) => {
    const data = kpiGroupsPayload;
    const queue: Array<{
      data: KPIGroupType | undefined;
      parents: string[];
    }> = [];
    data.map((item) => queue.push({ data: item, parents: [] }));

    let top:
      | { data: KPIGroupType | undefined; parents: string[] }
      | undefined = {
      data: undefined,
      parents: [],
    };
    while (queue.length) {
      top = queue.shift();
      if (top) {
        if (top.data && top.data.key === key) {
          break;
        }
        if (top.data && top.data.subGroups) {
          top.data.subGroups.map((item) => {
            const newParents = [...top!.parents];
            newParents.push(top!.data!.name);
            queue.push({ data: item, parents: newParents });
          });
        }
      }
    }

    top!.parents.push(top!.data!.name);
    return top!.parents.join(' > ');
  };

  if (
    !kpiMetadata.payload ||
    !kpiGroups.payload ||
    !controls.payload ||
    !threatSurfaces.payload
  ) {
    return <div />;
  }

  return (
    <Container maxWidth={false}>
      <Box display="flex" mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.gridContainerFontSize}>
            Search Context
          </Grid>
          <Grid item xs={6} className={classes.gridItem}>
            <TableContainer
              className={classes.searchContextContainer}
              component={Paper}
            >
              <Table className={classes.searchTable} stickyHeader>
                <TableHead></TableHead>
                <TableBody>
                  {metaData.map((item, i) => {
                    return (
                      <TableRow key={i} className={classes.tableRow}>
                        <TableCell>
                          <Select
                            className={classes.selectCell}
                            value={item.key === '' ? '' : item.key}
                            defaultValue={''}
                            onChange={(e) => handleMetaSelect(e, i)}
                            displayEmpty
                          >
                            <MenuItem key={''} value={''}>
                              {'All Context'}
                            </MenuItem>
                            {kpiMetadataPayload.map((item: KPIMetadata) => {
                              return (
                                <MenuItem key={item.key} value={item.key}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </TableCell>
                        <TableCell className={classes.contentCell}>
                          <TextField
                            margin="dense"
                            label=""
                            value={item.value}
                            onChange={(e) => handleMetaContentChange(e, i)}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="Use Regular Expression">
                                    <IconButton
                                      className={classes.regexIconBtn}
                                      onClick={() => handleRegex(i)}
                                      size="small"
                                      disableRipple={true}
                                    >
                                      {item.regex ? (
                                        <IconRegexClicked />
                                      ) : (
                                        <IconRegex />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Match Case">
                                    <IconButton
                                      className={classes.caseSensitiveIconBtn}
                                      onClick={() => handleCaseSensitive(i)}
                                      size="small"
                                      disableRipple={true}
                                    >
                                      {item.case ? (
                                        <IconCaseSensitiveClicked />
                                      ) : (
                                        <IconCaseSensitive />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                        <TableCell className={classes.deleteCell}>
                          <IconButton
                            onClick={() => handleDelete(i)}
                            size="small"
                          >
                            <Close />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <IconButton className={classes.addIconBtn} onClick={handleAdd}>
              <AddBoxOutlined />
            </IconButton>
          </Grid>
          <Grid item xs={6} className={classes.gridItem}>
            <SearchItem
              label="Control"
              data={controlsPayload}
              selectedItem={selectedControl}
              onSelectChange={onControlChange}
            />
            <SearchItem
              label="Surface"
              data={threatSurfacesPayload}
              selectedItem={selectedSurface}
              onSelectChange={onSurfaceChange}
            />
            <KPIGroupTreeItem
              label="KPI Group"
              data={kpiGroupsPayload}
              expanded={expandedTreeItem}
              selectedItem={selectedTreeItem}
              handleToggle={handleTreeItemToggle}
              handleSelect={handleTreeItemSelect}
            />
            <FormControlLabel
              className={classes.cbDisable}
              disabled={disableCheckbox}
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={includeSubKeys}
                  onChange={() => {
                    setIncludeSubKeys(!includeSubKeys);
                  }}
                />
              }
              label="Include subgroups"
              labelPlacement="end"
            />
            <Button
              className={classes.btnSearch}
              onClick={handleSearch}
              color="primary"
              variant="contained"
            >
              Search
            </Button>
            <Button
              className={classes.btnReset}
              onClick={handleReset}
              variant="outlined"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider variant="fullWidth" className={classes.divider} />
      <Box display="flex" mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.gridContainerFontSize}>
            Search Results
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <TableContainer
              className={classes.searchResultContainer}
              component={Paper}
            >
              <Table className={classes.searchTable} stickyHeader>
                <EnhancedTableHead
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  activeSort={activeSort}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {searchResult.map((result: KPIContext, index: number) => {
                    return (
                      <TableRow
                        key={result.id}
                        className={classes.tableRow}
                        hover
                        onMouseEnter={() => {
                          showEditButton[index] = true;
                          setShowEditButton([...showEditButton]);
                        }}
                        onMouseLeave={() => {
                          showEditButton[index] = false;
                          setShowEditButton([...showEditButton]);
                        }}
                      >
                        {getMetadataValue(result).map((r: any, i: number) => {
                          return (
                            <TableCell key={i}>
                              {r.map((item: any, idx: number) => {
                                return item.uri ? (
                                  <span key={idx}>
                                    <Tooltip
                                      title={item.uri}
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <span
                                        key={idx}
                                        className={classes.tableCellContextUri}
                                        onClick={() => {
                                          window.open(item.uri, '_blank');
                                        }}
                                      >
                                        {item.value}
                                      </span>
                                    </Tooltip>
                                    {r.length === idx + 1 ? '' : ', '}
                                  </span>
                                ) : (
                                  item.value +
                                    (r.length === idx + 1 ? '' : ', ')
                                );
                              })}
                            </TableCell>
                          );
                        })}
                        <TableCell>
                          {getControlName(result.controlId)}
                        </TableCell>
                        <TableCell>{getSurfaceName(result)}</TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              getGroupHierarchy(result.key)
                                ? getGroupHierarchy(result.key)
                                : ''
                            }
                            TransitionComponent={Zoom}
                            arrow
                          >
                            <span>{getKPIGroupName(result.key)}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellContextUri}
                          onClick={() =>
                            result.contextURI &&
                            window.open(result.contextURI, '_blank')
                          }
                        >
                          {getContextURI(result)}
                        </TableCell>
                        {
                          <TableCell style={{ width: 70 }} size="small">
                            {showEditButton[index] && (
                              <IconButton
                                className={classes.editIconBtn}
                                size="small"
                                onClick={(e) => handleEdit(e, index)}
                              >
                                <CreateOutlined />
                              </IconButton>
                            )}
                          </TableCell>
                        }
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {dialogIsOpen && (
              <KPIContextDialog
                isOpen={dialogIsOpen}
                context={context}
                contextKey={contextKey}
                kpiMetadata={kpiMetadataPayload}
                controlId={controlId}
                searchIndex={searchIndex}
                handleClose={handleDialogClose}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const mapState = ({
  kpiMetadata,
  kpiGroups,
  controls,
  threatSurfaces,
}: reduxStateType) => ({
  kpiMetadata,
  kpiGroups,
  controls,
  threatSurfaces,
});

const mapDispatch = {
  getKpiMetadata: fetchKpiMetadata,
  getKpiGroups: fetchKpiGroups,
  getControls: fetchControls,
  getThreatSurfaces: fetchThreatSurfaces,
};

export default connect(mapState, mapDispatch)(MainContent);
