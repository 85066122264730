import React from 'react';
import { Typography } from '@material-ui/core';
import MuiGrey from '@material-ui/core/colors/grey';
import { KPIGroup, KPI, TagType } from 'app/services/api';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import DescriptionAccordion from 'app/common/DescriptionAccordion';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { arrayHasValues } from 'app/utils/helpers';
import { truncate } from 'lodash';
import { getHelpURN } from 'app/services/help';

const featureName = 'dashboard/tag-dashboards/common-components/kpi-summary';

type KPISummaryType = {
  subGroups: KPIGroup[];
  controlKpiValues: KPI[][][];
  selectedTag: TagType;
};

const KPISummary = ({
  subGroups,
  controlKpiValues,
  selectedTag,
}: KPISummaryType): JSX.Element => {
  const getBarData = (subGroupIndex: number) =>
    controlKpiValues.reduce(
      (kpiSum: any, nextCtrlKpiValues: any, controlIndex: number) => {
        const subGroupKpis = nextCtrlKpiValues[subGroupIndex];

        return subGroupKpis.map((nextKpi: any, kpiIndex: number) => {
          const nextKpiValue = arrayHasValues(nextKpi.kpiValue)
            ? Number(nextKpi.kpiValue[0].value)
            : 0;
          const currentKpi = kpiSum[kpiIndex]
            ? Number(kpiSum[kpiIndex].value)
            : 0;

          let value = nextKpiValue + currentKpi;

          if (controlIndex === controlKpiValues.length - 1) {
            value = (value * 20) / controlKpiValues.length;
          }

          const barColors = getBarColorsForValue(value);

          return {
            ...nextKpi,
            label: truncate(nextKpi.name, {
              length: 50,
              separator: /,? +/,
            }),
            value: Math.round(value),
            colors: barColors.background,
            labelTextColor: barColors.textColor,
          };
        });
      },
      []
    );

  const showDescription = (
    text: string, rowIdx: number
  ) => {
    let summary = text;
    const descriptions = text && text.length > 0 ? text.split("|") : [];
    if (descriptions && descriptions.length > 1) {
      summary = descriptions[0];
      const details = descriptions.slice(1);
      if (details.some((detail: string) => detail.length > 0)) {
        let style = {};
        if (rowIdx & 1) {
          style = {
            backgroundColor: MuiGrey[100]
          }
        }
        return (
          <DescriptionAccordion
            summary={summary}
            details={details}
            identifier={rowIdx}
            style={style}
          />
        );
      }
    }
    return (
      <Typography variant="body2" style={{paddingLeft: '1rem'}}>
        {text}
      </Typography>
    );
  };

  return (
    <>
      {subGroups.map((subGroup: KPIGroup, subGrpIdx: number) => (
        <Section key={`${featureName}-${subGroup.key}`}>
          <StripedTable>
            <StripedTable.Head>
              <StripedTable.Heading
                colSpan={2}
                helpURI={getHelpURN({
                  context: 'dashboard',
                  resource: subGroup.helpURI,
                })}
              >
                {selectedTag.name} {subGroup.name}
              </StripedTable.Heading>
              <StripedTable.SubHeading colSpan={2}>
                {kpiHeaderTitle}
              </StripedTable.SubHeading>
            </StripedTable.Head>

            <StripedTable.Body>
              {getBarData(subGrpIdx).map(
                (barData: any, kpiIndex: number, self: any) => {
                  const isLast = kpiIndex === self.length - 1;

                  return (
                    <StripedTable.Row
                      key={`${featureName}-${subGroup.key}-${barData.key}-${kpiIndex}`}
                    >
                      <StripedTable.GraphLabel
                        style={{ width: '20%' }}
                        isLast={isLast}
                        colSpan={1}
                      >
                        {showDescription(barData.name, kpiIndex)}
                      </StripedTable.GraphLabel>
                      <StripedTable.GraphCell isLast={isLast} colSpan={1}>
                        <BarGraph
                          data={[barData]}
                          colors={barData.colors}
                          labelTextColor={barData.labelTextColor}
                          axisBottom={isLast ? {} : null}
                        />
                      </StripedTable.GraphCell>
                    </StripedTable.Row>
                  );
                }
              )}
            </StripedTable.Body>
          </StripedTable>
        </Section>
      ))}
    </>
  );
};

export default KPISummary;
