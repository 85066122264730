import React, { FunctionComponent, MouseEventHandler } from 'react';
import { Box, Breadcrumbs, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';
import { recursivelyFind } from 'utils/helpers';
import { TagType, Control } from 'app/services/api';
import { updateAppSelectionType } from 'app/common/actions';
import { tagsState } from 'app/common/actions/tags';
import PATHS from 'app/utils/paths';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    li: {
      '& a': {
        textDecoration: 'none',
        color: theme.palette.text.secondary,
      },
    },
  })
);

interface Breadcrumb {
  text: string;
  url?: string;
  onClick?: MouseEventHandler;
}

interface NavBreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

export const getBreadcrumbs = ({
  baseUrl,
  updateApp,
  selectedTag,
  tags,
  history,
  controlId,
  selectedControl,
}: {
  baseUrl: string;
  selectedTag: TagType;
  updateApp: (selection: updateAppSelectionType) => void;
  tags: tagsState;
  history: any;
  controlId: string | number | undefined;
  selectedControl: Control;
}) => {
  const parentTag: TagType = recursivelyFind(
    'tags',
    Number(selectedTag.parentId),
    tags.payload
  );
  const grandParentTag: TagType | null = parentTag
    ? recursivelyFind('tags', Number(parentTag.parentId), tags.payload)
    : null;

  const breadcrumbs = [
    {
      text: 'Groups',
      onClick: (event: any): void => {
        event.preventDefault();

        updateApp({ tag: null, control: null });
        history.push(baseUrl);
      },
    },
    { text: selectedTag.name },
  ];

  if (controlId) {
    breadcrumbs.splice(1, 1, {
      text: selectedTag.name,
      onClick: (event: any): void => {
        event.preventDefault();

        updateApp({ tag: selectedTag, control: null });

        const destination = `${baseUrl}${PATHS.GROUPS}/${selectedTag.id}${PATHS.CONTROLS}`;
        history.push(destination);
      },
    });
  }

  if (parentTag) {
    breadcrumbs.splice(1, 0, {
      text: parentTag.name,
      onClick: (event: any): void => {
        event.preventDefault();

        updateApp({ tag: parentTag, control: null });

        const destination = `${baseUrl}${PATHS.GROUPS}/${parentTag.id}${
          parentTag.subTags ? PATHS.ELEMENTS : ''
        }`;
        history.push(destination);
      },
    });
  }

  if (grandParentTag) {
    breadcrumbs.splice(1, 0, {
      text: grandParentTag.name,
      onClick: (event: any): void => {
        event.preventDefault();

        updateApp({ tag: grandParentTag, control: null });

        const destination = `${baseUrl}${PATHS.GROUPS}/${grandParentTag.id}${
          grandParentTag.subTags ? PATHS.ELEMENTS : ''
        }`;
        history.push(destination);
      },
    });
  }

  if (controlId && selectedControl) {
    breadcrumbs.push({
      text: selectedControl.name,
    });
  }

  return breadcrumbs;
};

const NavBreadcrumbs: FunctionComponent<NavBreadcrumbsProps> = ({
  breadcrumbs,
}: NavBreadcrumbsProps) => {
  const classes = useStyles();

  return (
    <Box my={2}>
      <Breadcrumbs
        classes={classes}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs.map(({ text, url, onClick }, i) => {
          if (onClick) {
            return (
              <a key={i} onClick={onClick} style={{ cursor: 'pointer' }}>
                {text}
              </a>
            );
          }
          if (url) {
            return (
              <Link key={i} to={url}>
                {text}
              </Link>
            );
          }

          return (
            <Typography key={i} color="textPrimary">
              {text}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default NavBreadcrumbs;
