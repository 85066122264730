import React from 'react';
import {
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  Typography,
  IconButton,
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import MuiGrey from '@material-ui/core/colors/grey';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import { displayHelp } from 'app/services/help';

const StripedTable = (props: any) => (
  <MuiTable {...props} size={props.size || 'small'} />
);

const StripedTableHead = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.grey[100],
      },
      margin: '1rem 0',
      borderBottom: `1px solid ${MuiGrey[400]}`,
    },
  })
)(MuiTableHead);

export const StripedTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.grey[100],
      },
      width: '100%',
    },
  })
)(MuiTableRow);

const Heading = ({
  style,
  children,
  colSpan,
  variant,
  helpURI,
}: {
  style?: any;
  children: any;
  colSpan: number;
  variant?: any;
  helpURI?: string;
}) => {
  let iconTableCell;
  if (helpURI !== undefined) {
    iconTableCell = (
      <MuiTableCell
        style={{ width: '60px', ...style }}
        colSpan={1}
        align="right"
      >
        <IconButton disabled={!helpURI} onClick={() => displayHelp(helpURI)}>
          <HelpOutlineRounded />
        </IconButton>
      </MuiTableCell>
    );
    colSpan--;
  }
  return (
    <StripedTableRow style={{ backgroundColor: MuiGrey[400] }}>
      <MuiTableCell
        style={{
          padding: '16px',
          minWidth: '500px',
          ...style,
        }}
        colSpan={colSpan}
      >
        <Typography variant={variant || 'h6'}>{children}</Typography>
      </MuiTableCell>
      {iconTableCell}
    </StripedTableRow>
  );
};

const SubHeading = ({
  style,
  children,
  colSpan,
  variant,
}: {
  style?: any;
  children: any;
  colSpan: number;
  variant?: any;
}) => (
  <StripedTableRow style={{ backgroundColor: MuiGrey[300] }}>
    <MuiTableCell style={{ padding: '16px', ...style }} colSpan={colSpan}>
      <Typography variant={variant || 'subtitle2'}>{children}</Typography>
    </MuiTableCell>
  </StripedTableRow>
);

const GraphLabel = (props: any) => (
  <MuiTableCell style={props.style} align="left" colSpan={props.colSpan}>
    <Typography
      style={props.isLast ? { marginBottom: '1.25rem' } : {}}
      variant="body2"
    >
      {props.children}
    </Typography>
  </MuiTableCell>
);

const GraphCell = (props: any) => {
  let styles: any = {
    height: '50px',
    width: '100%',
    minWidth: 0,
    maxWidth: '98%',
    marginTop: '-15px',
    whiteSpace: 'nowrap'
  };

  if (props.isLast) {
    styles = {
      ...styles,
      height: '90px',
      marginTop: '0',
    };
  }

  return (
    <MuiTableCell
      style={{ whiteSpace: 'nowrap', width: '100%' }}
      colSpan={props.colSpan}
    >
      <div style={styles}>{props.children}</div>
    </MuiTableCell>
  );
};

const LargeGraphCell = (props: any) => {
  let styles: any = {
    height: '150px',
    width: '100%',
    minWidth: 0,
    maxWidth: '98%',
    marginTop: '-15px',
    whiteSpace: 'nowrap'
  };

  if (props.isLast) {
    styles = {
      ...styles,
      height: '190px',
      marginTop: '0',
    };
  }

  return (
    <MuiTableCell
      style={{ whiteSpace: 'nowrap', width: '100%' }}
      colSpan={props.colSpan}
    >
      <div style={styles}>{props.children}</div>
    </MuiTableCell>
  );
};

StripedTable.Head = StripedTableHead;
StripedTable.Body = MuiTableBody;
StripedTable.Row = StripedTableRow;
StripedTable.Cell = MuiTableCell;
StripedTable.GraphLabel = GraphLabel;
StripedTable.GraphCell = GraphCell;
StripedTable.LargeGraphCell = LargeGraphCell;

StripedTable.Heading = Heading;
StripedTable.SubHeading = SubHeading;
// StripedTable.GraphCell = GraphCell;

export default StripedTable;
