import React from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  KPIGroup as KpiGroupType,
  KPI,
  Control,
  TagType,
} from 'app/services/api';
import {
  fetchThreatSurfaces,
  threatSurfaceState,
} from 'app/common/actions/threat-surfaces';
import {
  fetchThreatLevels,
  threatLevelState,
} from 'app/common/actions/threat-levels';
import { fetchControls, controlsState } from 'app/common/actions/controls';
import Loader from 'app/common/Loader';
import {
  getBudgetForControls,
  getTotalBudgetData,
  getSurfacesBudgetData,
  getResourcesBudgetData,
  BudgetAllocationDataType,
  AllocationLevel,
  AllocationSurface,
  AllocationResource
} from 'app/utils/budget';
import { CalendarContext } from '../../MainContent';

import BudgetProductivity from './BudgetProductivity';
import BudgetProductivityBySurface from './BudgetProductivityByThreatSurface';
import BudgetProductivityByLevel from './BudgetProductivityByThreatLevel';
import BudgetProductivityByControl from './BudgetProductivityByControl';
import BudgetProductivityByResource from './BudgetProductivityByResource';

type mapStateType = {
  kpiGroups: { payload: KpiGroupType[] };
  threatSurfaces: threatSurfaceState;
  threatLevels: threatLevelState;
  app: { tag: TagType };
  controls: controlsState;
};

export type InvestmentDataType = {
  totalBudget: KPI[];
  budgetSplit: KPI[];
  budgetAllocation: KPI[];
  currentBudget: KPI;
  management: KPI[][];
  resources: KPI[][];
  integration: KPI[][];
  secOpsProductivity: number;
  control: Control;
};

type InvestmentDashboardType = {
  kpiGroups: KpiGroupType[];
  selectedControl: Control;
  getThreatSurfaces: () => void;
  threatSurfaces: threatSurfaceState;
  getThreatLevels: () => void;
  threatLevels: threatLevelState;
  selectedTag: TagType;
  getControls: (forceFetch: boolean, tagKey?: string) => void;
  controls: controlsState;
};

const InvestmentDashboard = ({
  kpiGroups,
  getThreatLevels,
  threatLevels,
  getThreatSurfaces,
  threatSurfaces,
  selectedTag,
  getControls,
  controls,
}: InvestmentDashboardType): JSX.Element => {
  const controlsPayload = controls.payload as Control[];

  const [loading, setLoading] = React.useState<boolean>(true);
  const [secOpsProductivity, setSecOpsProductivity] = React.useState<number[] | null>(null);
  const [controlBudgets, setControlBudgets] = React.useState<number[] | null>(null);
  const [levelBudgets, setLevelBudgets] = React.useState<AllocationLevel[][] | null>(null);
  const [surfaceBudgets, setSurfaceBudgets] = React.useState<AllocationSurface[][] | null>(null);
  const [resourceBudgets, setResourceBudgets] = React.useState<AllocationResource[][] | null>(null);

  const calendarDate = React.useContext(CalendarContext);
  const { url } = useRouteMatch();
  const groupPath = url.split('/').pop();
  const [kpiGroup] = kpiGroups.filter(
    (group: KpiGroupType) => group.path === groupPath
  );

  React.useEffect(() => {
    if (!kpiGroup) {
      return;
    }

    setLoading(true);

    if (!threatSurfaces.isLoading && !threatSurfaces.payload) {
      getThreatSurfaces();
    }

    if (!threatLevels.isLoading && !threatLevels.payload) {
      getThreatLevels();
    }

    if (!controls.isLoading && !Array.isArray(controlsPayload)) {
      const forceFetch = true;
      getControls(forceFetch, selectedTag.key);
    }

    if (
      !controls.isLoading &&
      Array.isArray(controlsPayload) &&
      controlsPayload.length
    ) {
      setTimeout(() => {
        (async () => {
          const budgetData: BudgetAllocationDataType[] = await getBudgetForControls(
            controlsPayload, kpiGroups, true, calendarDate
          );
          if (budgetData && budgetData.length) {
            const currentTotalObject = getTotalBudgetData(
              budgetData,
              'current'
            );
            if (currentTotalObject) {
              setControlBudgets(currentTotalObject.totalsPerControl);
              setLevelBudgets(currentTotalObject.allocationLevels);
              const currentSurfaceBudgets = getSurfacesBudgetData(
                budgetData,
                'current',
                currentTotalObject.totalsPerControl
              );
              if (currentSurfaceBudgets) {
                setSurfaceBudgets(currentSurfaceBudgets);
              }
              const currentResourceBudgets = getResourcesBudgetData(
                budgetData,
                'current',
                currentTotalObject.totalsPerControl
              );
              if (currentResourceBudgets) {
                setResourceBudgets(currentResourceBudgets);
              }
              setSecOpsProductivity(currentTotalObject.productivityPerControl);
            }
          }
          setLoading(false);
        })();
      }, 0);
    }
  }, [calendarDate]);

  if (!kpiGroup) {
    return <div>Invalid KPI Group</div>;
  }

  if (
    threatSurfaces.isLoading ||
    threatLevels.isLoading ||
    loading ||
    controls.isLoading
  ) {
    return <Loader />;
  }

  if (
    !controlBudgets ||
    !controlsPayload ||
    !secOpsProductivity ||
    !surfaceBudgets ||
    !levelBudgets ||
    !resourceBudgets
  ) {
    return <div />;
  }

  return (
    <div>
      <BudgetProductivity
        selectedTag={selectedTag}
        controlBudgets={controlBudgets}
        secOpsProductivity={secOpsProductivity}
      />
      <BudgetProductivityBySurface
        selectedTag={selectedTag}
        threatSurfaces={threatSurfaces}
        surfaceBudgets={surfaceBudgets}
        secOpsProductivity={secOpsProductivity}
      />
      <BudgetProductivityByLevel
        selectedTag={selectedTag}
        threatLevels={threatLevels}
        levelBudgets={levelBudgets}
        secOpsProductivity={secOpsProductivity}
      />
      <BudgetProductivityByResource
        selectedTag={selectedTag}
        resourceBudgets={resourceBudgets}
        secOpsProductivity={secOpsProductivity}
      />

      <BudgetProductivityByControl
        selectedTag={selectedTag}
        controls={controlsPayload}
        controlBudgets={controlBudgets}
        secOpsProductivity={secOpsProductivity}
      />
    </div>
  );
};

const mapState = ({
  app: { tag },
  kpiGroups: { payload },
  threatSurfaces,
  threatLevels,
  controls,
}: mapStateType) => ({
  kpiGroups: payload,
  threatLevels,
  threatSurfaces,
  selectedTag: tag,
  controls,
});

const mapDispatch = {
  getThreatLevels: fetchThreatLevels,
  getThreatSurfaces: fetchThreatSurfaces,
  getControls: fetchControls,
};

export default connect(mapState, mapDispatch)(InvestmentDashboard);
