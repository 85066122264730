import { connect } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { TagType } from 'app/services/api';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { updateAppSelection, updateAppSelectionType } from 'app/common/actions';

const featureName = 'common/element-selector';

const elementSelectionStyles = makeStyles(() =>
  createStyles({
    card: {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    },
  })
);

type reduxMapState = {
  app: updateAppSelectionType;
};

type ElementsSelectionType = {
  selectedTag: TagType;
  updateApp: (selection: updateAppSelectionType) => void;
};

const ElementsSelection = ({
  selectedTag,
  updateApp,
}: ElementsSelectionType): JSX.Element => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const styles = elementSelectionStyles();

  return (
    <>
      <Box
        width="auto"
        pt={3}
        display="flex"
        flexWrap="wrap"
        justifyContent="left"
      >
        {(selectedTag.subTags || []).map((subTag: TagType) => (
          <Box
            width={275}
            height={225}
            mb={2}
            key={`${featureName}/grandchild-groups-selector/${subTag.id}`}
            onClick={(event): void => {
              event.preventDefault();

              const urlArray = url.split('groups/');
              let destination = `${urlArray[0]}groups/`;

              if (subTag.subTags) {
                destination += `${subTag.id}/element`;
              } else {
                destination += `${subTag.id}/controls`;
              }

              updateApp({ tag: subTag });
              history.push(destination);
            }}
            style={{ paddingRight: '1.5rem' }}
          >
            <Card className={styles.card}>
              <CardContent>
                <Typography variant="h6" component="h3">
                  {subTag.name}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>
    </>
  );
};

const mapState = ({ app }: reduxMapState) => ({
  selectedTag: app.tag as TagType,
});

const mapDispatch = {
  updateApp: updateAppSelection,
};

export default connect(mapState, mapDispatch)(ElementsSelection);
