import { connect } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { TagType, Control } from 'app/services/api';
import Loader from 'app/common/Loader';
import Typography from '@material-ui/core/Typography';
import { updateAppSelection, updateAppSelectionType } from 'app/common/actions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import isEmpty from 'lodash/isEmpty';
import { flattenNestedArrayByName } from 'app/utils/helpers';
import Divider from '@material-ui/core/Divider';
import Box from 'app/common/Box';

const featureName = 'common/controls-selection';

type tagReduxType = {
  isLoading: boolean;
  hasError: Error | null;
  payload: Promise<TagType> | TagType | null;
};

type tagsReduxType = {
  isLoading: boolean;
  hasError: Error | null;
  payload: Promise<TagType[]> | TagType[] | null;
};

type controlReduxType = {
  isLoading: boolean;
  hasError: Error | null;
  payload: Promise<Control[]> | Control[] | null;
};

type reduxStateType = {
  app: { tag: TagType };
  tag: tagReduxType;
  controls: controlReduxType;
  tags: tagsReduxType;
};

type ControlsSelectionType = {
  selectedTag: TagType;
  getTagById: (id: number) => void;
  tag: tagReduxType;
  controls: controlReduxType;
  updateApp: (selection: updateAppSelectionType) => void;
  tagId: number;
  tags: tagsReduxType;
  getTags: () => void;
};

const UngroupedControls = ({
  controls,
  handleControlClick,
}: {
  controls: Control[];
  handleControlClick: (event: any, control: Control) => void;
}) => {
  return (
    <Box.Wrapper>
      {controls.map((control: Control) => (
        <Box
          key={`${featureName}-${control.id}`}
          onClick={(event: any) => handleControlClick(event, control)}
        >
          {control.name}
        </Box>
      ))}
    </Box.Wrapper>
  );
};

const GroupedControls = ({
  controls,
  selectedTag,
  handleControlClick,
}: {
  controls: Control[];
  selectedTag: TagType;
  handleControlClick: (event: any, control: Control) => void;
}) => {
  const descendantTags = flattenNestedArrayByName('tags', selectedTag.subTags);

  return (
    <>
      <Box.Wrapper>
        {controls
          .filter((control) =>
            (control.tags || []).some((e) => e.key === selectedTag.key)
          )
          .map((control) => (
            <Box
              key={`${featureName}-${control.id}`}
              onClick={(event: any) => handleControlClick(event, control)}
            >
              {control.name}
            </Box>
          ))}
      </Box.Wrapper>

      {descendantTags.map((tag: TagType) => {
        const tagControls = controls.filter((control) =>
          (control.tags || []).some((e) => e.id === tag.id)
        );
        const pathNameForTag = (tag: TagType) => {
          let tagName = tag.name;
          if (tag.parentId) {
            const parentTag = descendantTags.find(
              (t: TagType) => t.id === tag.parentId
            );
            if (parentTag) {
              tagName = [pathNameForTag(parentTag), tagName].join(' > ');
            }
          }
          return tagName;
        };

        return (
          <div key={`${featureName}/grouped-controls/${tag.id}`}>
            <Typography variant="h6">{pathNameForTag(tag)}</Typography>

            <Divider style={{ marginBottom: '2rem' }} />

            <div>
              <Box.Wrapper>
                {tagControls.map((control) => (
                  <Box
                    key={`${featureName}-${control.id}`}
                    onClick={(event: any) => handleControlClick(event, control)}
                  >
                    {control.name}
                  </Box>
                ))}
              </Box.Wrapper>
            </div>
          </div>
        );
      })}
    </>
  );
};

const ControlsSelection = ({
  selectedTag,
  tag,
  tagId,
  tags,
  controls,
  updateApp,
}: ControlsSelectionType) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const [showGroupControls, setGroupControls] = React.useState(true);

  if (tag.isLoading || controls.isLoading) {
    return <Loader />;
  }

  if (
    !selectedTag ||
    !controls.payload ||
    !tags.payload ||
    (controls.payload && !Array.isArray(controls.payload)) ||
    (tags.payload && !Array.isArray(tags.payload))
  ) {
    return <div />;
  }

  if (Array.isArray(controls.payload) && isEmpty(controls.payload)) {
    return (
      <Box.Wrapper justifyContent="right">
        <Typography variant="h6">No controls in group</Typography>
      </Box.Wrapper>
    );
  }

  const handleGroupControlSelect = () => setGroupControls(!showGroupControls);

  const handleControlClick = (event: any, control: Control): void => {
    event.preventDefault();

    const newSelection: updateAppSelectionType = { control };
    if (selectedTag && Number(tagId) === selectedTag.id) {
      newSelection.tag = selectedTag;
    }

    updateApp(newSelection);
    history.push(`${url}/${control.id}`);
  };

  return (
    <>
      {selectedTag.subTags ? (
        <Box.Wrapper justifyContent="right">
          <FormControlLabel
            control={
              <Checkbox
                checked={showGroupControls}
                onChange={handleGroupControlSelect}
                name="grp_controls"
              />
            }
            label="Group Controls"
          />
        </Box.Wrapper>
      ) : (
        <div />
      )}

      {selectedTag.subTags && showGroupControls ? (
        <GroupedControls
          controls={controls.payload as Control[]}
          selectedTag={selectedTag}
          handleControlClick={handleControlClick}
        />
      ) : (
        <UngroupedControls
          controls={controls.payload as Control[]}
          handleControlClick={handleControlClick}
        />
      )}
    </>
  );
};

const mapState = ({ app, tag, controls, tags }: reduxStateType) => ({
  selectedTag: app.tag,
  tag,
  controls,
  tags,
});

const mapDispatch = {
  updateApp: updateAppSelection,
};

export default connect(mapState, mapDispatch)(ControlsSelection);
