import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Toolbar, Typography, Paper, IconButton, Box } from '@material-ui/core';
import MuiHeaderColor from '@material-ui/core/colors/grey';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import { displayHelp } from 'app/services/help';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      backgroundColor: MuiHeaderColor[400],
    },
    title: {
      flex: '1 1 100%',
    },
  })
);

interface EnhancedTableToolbarProps {
  subcomponentName: string;
  subcomponentHelpURI?: string;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { subcomponentName, subcomponentHelpURI } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {subcomponentName}
      </Typography>
      {/* Help Button */}
      <IconButton
        disabled={
          typeof subcomponentHelpURI === 'undefined' ||
          subcomponentHelpURI?.length === 0
        }
        onClick={() =>
          displayHelp({
            context: 'admin',
            resource: subcomponentHelpURI,
          })
        }
      >
        <HelpOutlineRounded />
      </IconButton>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '1.5rem',
      marginTop: '0.5rem',
      marginBottom: '2rem',
    },
  })
);

interface SubcomponentTableProps {
  subcomponentName: string;
  subcomponentHelpURI?: string;
}

export default function subcomponentTable(props: SubcomponentTableProps) {
  const classes = useStyles();
  const { subcomponentName, subcomponentHelpURI } = props;

  return (
    <Box ml={2} flex={1}>
      <Paper elevation={3} className={classes.paper}>
        <EnhancedTableToolbar
          subcomponentName={subcomponentName}
          subcomponentHelpURI={subcomponentHelpURI}
        />
      </Paper>
    </Box>
  );
}
