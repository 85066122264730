import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from 'app/common/Loader';
import { Admin } from 'app/admin/Admin';
import {
  getAssets,
  getAssetTypes,
  getImpactScales,
  getImpactTypes,
  Asset,
  AssetType,
  ImpactType,
  ImpactScale,
} from 'app/services/apiAssets';
import SubcomponentTable from './table/CrownJewels';

export interface HeadCell {
  id: string;
  label: string;
}

interface AdminCrownJewelsProps {
  userPermissions: string[];
}

const AdminCrownJewels: FunctionComponent<AdminCrownJewelsProps> = ({
  userPermissions,
}: AdminCrownJewelsProps) => {
  const [assets, setAssets] = useState<Asset[] | null>(null);
  const [assetTypes, setAssetTypes] = useState<AssetType[] | null>(null);
  const [impactTypes, setImpactTypes] = useState<ImpactType[] | null>(null);
  const [impactScales, setImpactScales] = useState<ImpactScale[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const headCells: HeadCell[] = [
    {
      id: 'name',
      label: 'Asset Name',
    },
    {
      id: 'asset_type',
      label: 'Asset Type',
    },
    {
      id: 'impact_scale',
      label: 'Impact Scale',
    },
    {
      id: 'impact_types',
      label: 'Impact Types',
    },
    {
      id: 'impact_cost_min',
      label: 'Impact Cost Min',
    },
    {
      id: 'impact_cost_max',
      label: 'Impact Cost Max',
    },
  ];

  useEffect(() => {
    (async () => {
      const [
        assets,
        assetTypes,
        impactScales,
        impactTypes,
      ] = await Promise.all([
        getAssets({ include: 'impactTypes' }),
        getAssetTypes(),
        getImpactScales(),
        getImpactTypes(),
      ]);
      setAssets(assets as Asset[]);
      setAssetTypes(assetTypes as AssetType[]);
      setImpactScales(impactScales as ImpactScale[]);
      setImpactTypes(impactTypes as ImpactType[]);

      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <SubcomponentTable
      subcomponentName={adminCrownJewels.name}
      crownJewels={assets}
      assetTypes={assetTypes}
      impactScales={impactScales}
      impactTypes={impactTypes}
      headCells={headCells}
      permissions={userPermissions}
    />
  );
};

const subcomponentImplementation = (userPermissions: string[]): JSX.Element => {
  return <AdminCrownJewels userPermissions={userPermissions} />;
};

export const adminCrownJewels: Admin = {
  name: 'Crown Jewels',
  key: 'com.pharossecurity.com.admin.crown-jewels',
  requires: 'read:config',
  implementation: subcomponentImplementation,
};
