import { KPI, Control, getKPIsAndValues, KPIGroup as KPIGroupType } from 'app/services/api';
import { findSubGroupByKey, kpiGroupPromiseCreator } from 'app/utils/helpers';
import { getSecOpsProductivity } from 'app/utils/graph-math';


export type BudgetAllocationDataType = {
  totalAllocationLevels: KPI[];
  budgetSplit: KPI[];
  budgetAllocationResources: KPI[];
  budgetAllocationSurfaces: KPI[];
  secOpsProductivity: number;
};

export type AllocationLevel = {
  value: number;
  levelId: number;
};

export type AllocationSurface = {
  value: number;
  surfaceId: number;
};

export type AllocationResource = {
  key: string;
  value: number;
};

export const getBudgetForControls = (
  controls: Control[],
  kpiGroups: KPIGroupType[],
  includeProductivity: boolean,
  calendarDate?: string,
): Promise<BudgetAllocationDataType[]> => {
  
  const [kpiZeroBasedGroup] = kpiGroups.filter(
    (group: KPIGroupType) => group.path === "zero-based"
  );
  const totalAllocationLevelsGroup = findSubGroupByKey(
    kpiZeroBasedGroup,
    'allocation-to-levels'
  );
  const budgetSplitGroup = findSubGroupByKey(kpiZeroBasedGroup, 'budget-split');
  const budgetAllocationResourcesGroup = findSubGroupByKey(
    kpiZeroBasedGroup,
    'allocation-to-resources'
  );
  const budgetAllocationSurfacesGroup = findSubGroupByKey(
    kpiZeroBasedGroup,
    'allocation-to-surfaces'
  );
  return Promise.all(
    controls.map(async (control) => {
      const totalAllocationLevelsParams: any = {
        controlId: control.id,
        groupKey: totalAllocationLevelsGroup?.key,
      };
      const budgetSplitParams: any = {
        controlId: control.id,
        groupKey: budgetSplitGroup?.key,
        include: 'kpis,kpis.valueType,nullvalues,unique,kpis.subGroups',
      };
      const budgetAllocationResourcesParams: any = {
        controlId: control.id,
        groupKey: budgetAllocationResourcesGroup?.key,
        include: 'kpis,kpis.valueType,nullvalues,unique,kpis.subGroups',
      };
      const budgetAllocationSurfacesParams: any = {
        controlId: control.id,
        groupKey: budgetAllocationSurfacesGroup?.key,
      };
      if (
        calendarDate &&
        new Date(calendarDate).setHours(23, 59, 59, 0) < new Date().setHours(23, 59, 59, 0)
      ) {
        totalAllocationLevelsParams.date = calendarDate;
        budgetSplitParams.date = calendarDate;
        budgetAllocationResourcesParams.date = calendarDate;
        budgetAllocationSurfacesParams.date = calendarDate;
      }

      const totalAllocationLevels: KPI[] = await getKPIsAndValues(
        totalAllocationLevelsParams
      );
      const budgetSplit: any = await getKPIsAndValues(budgetSplitParams);
      const budgetAllocationResources: KPI[] = await getKPIsAndValues(
        budgetAllocationResourcesParams
      );
      const budgetAllocationSurfaces: KPI[] = await getKPIsAndValues(
        budgetAllocationSurfacesParams
      );

      let secOpsProductivity = 0;
      if (includeProductivity) {
        // get sec ops productivity
        const management = await kpiGroupPromiseCreator(
          'management',
          kpiGroups,
          control.id,
          calendarDate
        );
        const resources = await kpiGroupPromiseCreator(
          'resources',
          kpiGroups,
          control.id,
          calendarDate
        );
        const integration = await kpiGroupPromiseCreator(
          'integration',
          kpiGroups,
          control.id,
          calendarDate
        );

        secOpsProductivity = getSecOpsProductivity(
          management,
          resources,
          integration,
          kpiGroups
        );
      }

      return {
        totalAllocationLevels,
        budgetSplit,
        budgetAllocationResources,
        budgetAllocationSurfaces,
        secOpsProductivity
      };
    })
  );
};

export const getTotalBudgetData = (budgetData: BudgetAllocationDataType[], budgetType: string) => {
  let total = 0;
  const key = `com.pharossecurity.zero-based.allocation-to-levels.${budgetType}`;
  const totalsPerControl = new Array(budgetData.length).fill(0);
  const productivityPerControl = new Array(budgetData.length).fill(0);
  const allocationLevels: AllocationLevel[][] = budgetData.map(
    (data: BudgetAllocationDataType, controlIndex: number) => {
      productivityPerControl[controlIndex] = data.secOpsProductivity;
      let totalForControl = 0;
      const levels: (KPI | undefined) = data.totalAllocationLevels.find((item) => item.key === key);
      let levelBudgets = [{ value: 0, levelId: 0 }];
      if (levels && levels?.kpiValue.length > 0) {
        levelBudgets = levels?.kpiValue.map((item) => {
          total += Number(item.value || 0);
          totalForControl += Number(item.value || 0);
          return {
            value: Number(item.value || 0),
            levelId: item.levelId || 0,
          };
        });
      }
      totalsPerControl[controlIndex] = totalForControl;
      return levelBudgets;
    }
  );

  return {
    total,
    totalsPerControl,
    productivityPerControl,
    allocationLevels
  };
};

export const getSurfacesBudgetData = (
  budgetData: BudgetAllocationDataType[],
  budgetType: string,
  totalsPerControl: number[]
): AllocationSurface[][] => {
  const key = `com.pharossecurity.zero-based.allocation-to-surfaces.${budgetType}`;
  const allocationSurfaces: AllocationSurface[][] = budgetData.map(
    (data: BudgetAllocationDataType, controlIndex: number) => {
      const surfaces: (KPI | undefined) = data.budgetAllocationSurfaces.find((item) => item.key === key);
      return surfaces && surfaces?.kpiValue.length > 0
        ? surfaces?.kpiValue.map((item) => {
          return {
            value: totalsPerControl[controlIndex] * (Number(item.value || 0) / 100),
            surfaceId: item.surfaceId || 0,
          };
        })
        : [{ value: 0, surfaceId: 0 }];
    }
  );

  return allocationSurfaces;
};

export const getResourcesBudgetData = (
  budgetData: BudgetAllocationDataType[],
  budgetType: string,
  totalsPerControl: number[]
): AllocationResource[][] => {
  const key = `com.pharossecurity.zero-based.allocation-to-resources.${budgetType}`;
  const allocationResources: AllocationResource[][] = budgetData.map(
    (data: BudgetAllocationDataType, controlIndex: number) => {
    const resources = data.budgetAllocationResources.filter((item) => item.key.includes(key));
    return resources.map((resource) => {
      const key = resource.name;
      const allocationPercent: number = resource.kpiValue.length > 0 ? Number(resource.kpiValue[0].value) : 0;
      const value = totalsPerControl[controlIndex] * (allocationPercent / 100);
      return {
        key,
        value
      }
    });
  });
  return allocationResources;
}

export const getTotalProductivity = (
  controlBudgets: number[],
  secOpsProductivity: number[]
): {totalHighProductivity: number, totalLowProductivity: number} => {
  let totalHighProductivity = 0;
  let totalLowProductivity = 0;
  controlBudgets.forEach((controlBudget: number, controlIndex: number) => {
    const highProductivity = Math.round(
      controlBudget * (Math.round(secOpsProductivity[controlIndex]) / 100)
    );
    const lowProductivity = controlBudget - highProductivity;
    totalHighProductivity += highProductivity;
    totalLowProductivity += lowProductivity;
  });
  return {
    totalHighProductivity,
    totalLowProductivity
  };
};

