import React from 'react';
import { render } from 'react-dom';
import capitalize from 'lodash/capitalize';

import App from 'app/App';
import { init } from 'app/services/auth';

import './index.css';

const packageJson = require('../package.json');

console.info(`Pharos Control Dashboard v${packageJson.version}`);

document.title = `Pharos ${capitalize(
  window.location.href.split('://')[1].split('/')[0].split('.')[0]
)}`;

const main = async (): Promise<void> => {
  try {
    await init();
  } catch (err) {
    // do nothing
    void err;
  }
  render(<App />, document.querySelector('#app'));
};

main();
