import React, { useEffect, useState } from 'react';
import {
  KPIGroup as KpiGroupType,
  getKPIsAndValues,
  KPI,
} from 'app/services/api';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import Loader from 'app/common/Loader';
import BarGraph, {
  blue,
  green,
  darkGreen,
  getHighLowColours,
  customTooltip,
  percentFormatting,
} from 'app/common/BarGraph';
import StripedTable from 'app/common/StripedTable';
import Section from 'app/common/Section';

const featureName =
  'dashboard/control-dashboards/investment/time-allocation-graph';

type TimeAllocationType = {
  kpiGroup: KpiGroupType;
  controlId: number;
  calendarDate: string;
};

const extractKpiValue = (kpi: KPI) => {
  if (!kpi || !kpi?.kpiValue || !kpi?.kpiValue.length) {
    return 0;
  }

  return Number(kpi.kpiValue[0].value);
};

const formatBarData = ((currentKpis: KPI[], buildKpis: KPI[], runKpis: KPI[]) => {
  let maxValue = 0;
  const barData = currentKpis.map((currentKpi: KPI, index: number) => {
    const currentValue = extractKpiValue(currentKpi);
    const buildValue = extractKpiValue(buildKpis[index]);
    const runValue = extractKpiValue(runKpis[index]);
    maxValue = Math.max(maxValue, currentValue, buildValue, runValue);
    const data = {
      id: currentKpi.id,
      label: currentKpi.name,
      bar1: currentValue,
      bar1Colour: blue,
      bar2: buildValue,
      bar2Colour: darkGreen,
      bar3: runValue,
      bar3Colour: green,
      bar1BelongsTo: 'Current',
      bar2BelongsTo: 'Build',
      bar3BelongsTo: 'Run',
    };

    return data;
  });
  return {barData, maxValue};
});

const TimeAllocation = ({
  kpiGroup,
  controlId,
  calendarDate,
}: TimeAllocationType): JSX.Element => {
  const currentGroup = (kpiGroup?.subGroups || []).find((group: KpiGroupType) =>
    group.key.includes('time-allocation')
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [timeAllocData, setTimeAllocData] = useState<any | null>(null);

  useEffect(() => {
    if (!kpiGroup || !controlId || !currentGroup) {
      return;
    }

    (async () => {
      const data: KPI[][] = await Promise.all(
        (currentGroup?.subGroups || []).map((group) => {
          const kpiValueParams: any = {
            groupKey: group.key,
            controlId,
          };
          if (
            calendarDate &&
            new Date(calendarDate).setHours(23, 59, 59, 0) <
              new Date().setHours(23, 59, 59, 0)
          ) {
            kpiValueParams.date = calendarDate;
          }
          return getKPIsAndValues(kpiValueParams);
        })
      );

      setTimeAllocData(data);

      setLoading(false);
    })();
  }, [calendarDate]);

  if (loading) {
    return <Loader />;
  }

  const {barData, maxValue} = formatBarData(timeAllocData[0], timeAllocData[1], timeAllocData[2]);
  const dataKeys = ['bar1', 'bar2', 'bar3'];

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={2} helpURI="">
            Time Allocation
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            {kpiHeaderTitle}
          </StripedTable.SubHeading>
        </StripedTable.Head>
      </StripedTable>
      <StripedTable>
        <StripedTable.Body>
          {barData.map((rowData) => {
            return (
              <StripedTable.Row key={`${featureName}/${rowData.id}`}>
                <StripedTable.GraphLabel
                  style={{ width: '20%' }}
                  isLast={false}
                  colSpan={1}
                >
                  {rowData.label}
                </StripedTable.GraphLabel>
                <StripedTable.LargeGraphCell isLast={false} colSpan={1} style={{ width: '80%'}}>
                  <BarGraph
                    data={[rowData]}
                    keys={dataKeys}
                    colors={getHighLowColours}
                    tooltip={customTooltip}
                    axisBottom={null}
                    groupMode="grouped"
                    maxValue={maxValue}
                    height={150}
                    padding={0.1}
                    innerPadding={1}
                    margin={{
                      top: 10,
                      right: 100,
                      bottom: 5,
                      left: 10,
                    }}
                    enableLabel={true}
                    labelFormat={percentFormatting}
                  />
                </StripedTable.LargeGraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default TimeAllocation;
