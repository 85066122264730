import React, { FunctionComponent, useState, useCallback } from 'react';
import { InputAdornment } from '@material-ui/core';
import _debounce from 'lodash/debounce';
import { TextField } from '@material-ui/core';
import { useStyles } from './ValueInput';
import { InputColumn } from 'app/kpi/components/tables';
import { KPIData } from 'app/kpi/types/KPIData';
import { UserDataType } from 'app/services/auth';
import clsx from 'clsx';

type ValidatedInputProps = {
  column: InputColumn;
  error?: boolean;
  kpi: KPIData<any>;
  currentUser: UserDataType;
};

const ValidatedInput: FunctionComponent<ValidatedInputProps> = ({
  column,
  error,
  kpi,
  currentUser,
}: ValidatedInputProps) => {
  const classes = useStyles();
  const locale = currentUser?.currency.locale;
  let defaultValue: number | string = '0';

  if (kpi.value) {
    defaultValue = kpi.value;
  }

  if (kpi.type === 'decimal' && defaultValue === '0') {
    defaultValue = '0.00';
  }

  const [kpiValue, setKpiValue] = useState<number | string | null>(
    defaultValue
  );

  const debounceSave = useCallback(
    _debounce((value) => kpi.setValue(value), 450),
    []
  );

  const handleOnChange = (value: string) => {
    let validatedValue: string | number = value.replace('-', '');
    if (kpi.type === 'percentage') {
      validatedValue = parseInt(validatedValue) || 0;
      if (validatedValue > 100) {
        validatedValue = 100;
      }
      // Remove non-numeric
    } else if (kpi.type === 'currency') {
      validatedValue = value.toString().replace(/\D/g, '');
      // Limit 2 decimal places
    } else if (kpi.type === 'decimal') {
      // Replace non-numeric characters
      validatedValue = validatedValue.replace(/[^0-9\.]+/g, '');

      const regex = /^0+\d+(\.\d*)?$/;
      if (regex.test(validatedValue)) {
        // Replace leading zeros
        validatedValue = validatedValue.replace(/^0+(?=\d)/, '') || '0';
      }

      if (validatedValue.indexOf('.') > -1) {
        const splitValue = validatedValue
          .split('.')
          .filter((ele) => ele.length > 0);
        // Handle case where the value is missing a leading 0 (.23)
        const number = splitValue[0] || '0';
        if (validatedValue.split('.')[1].length > 2 || splitValue.length > 2) {
          validatedValue = parseFloat(
            `${number}.${splitValue[1].substring(0, 2)}`
          ).toFixed(2);
        }
      }
    }
    setKpiValue(validatedValue);
    debounceSave(validatedValue);
  };

  const handleOnBlur = () => {
    let value = kpiValue || 0;
    if (kpi.type === 'decimal') {
      value = parseFloat(value.toString()).toFixed(2);
      setKpiValue(value);
      debounceSave(value);
    }
  };

  let displayValue: string | number = kpiValue?.toString() || '';
  let InputProps = {
    ...column.inputProps,
  };

  if (kpi.type === 'currency') {
    displayValue = Number(kpiValue).toLocaleString(
      locale,
      currentUser?.currency
    );

    InputProps = {
      ...InputProps,
      startAdornment: (
        <InputAdornment position="start">
          {currentUser?.currency.symbol || '$'}
        </InputAdornment>
      ),
    };
  }

  return (
    <TextField
      classes={{
        root: clsx(
          classes.textField,
          !column.text && classes.hiddenTextField,
          column.textAlign === 'left' && classes.textFieldAlignLeft,
          column.textAlign === 'right' && classes.textFieldAlignRight
        ),
      }}
      error={error}
      label=""
      variant="outlined"
      value={displayValue}
      InputProps={InputProps}
      inputProps={{ style: { fontSize: '14px' } }}
      onBlur={handleOnBlur}
      onChange={(event) => handleOnChange(event.target.value)}
      style={column.style}
    />
  );
};

export default ValidatedInput;
