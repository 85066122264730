import React, { FunctionComponent } from 'react';
import { Box, Grid, IconButton, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link, useRouteMatch } from 'react-router-dom';
import NavButton from 'app/common/NavButton';
import { KPIGroup } from 'app/services/api';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import { displayHelp } from 'app/services/help';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navButtonLink: {
      textDecoration: 'none',
    },
    navButtonLabel: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
    },
  })
);

interface NavKPIGroupsProps {
  kpiGroups: KPIGroup[] | null;
}

const NavKPIGroups: FunctionComponent<NavKPIGroupsProps> = ({
  kpiGroups,
}: NavKPIGroupsProps) => {
  const classes = useStyles();
  const { url } = useRouteMatch();

  if (!kpiGroups) {
    return null;
  }

  return (
    <Box component={Paper} height="60px" px={2}>
      <Grid container justify="space-between">
        <div style={{ height: '60px' }}>
          {kpiGroups.map((kpiGroup) => (
            <Link
              key={kpiGroup.key}
              to={`${url}/${kpiGroup.path}`}
              className={classes.navButtonLink}
            >
              <NavButton
                classes={{ label: classes.navButtonLabel }}
                active={location.pathname === `${url}/${kpiGroup.path}`}
              >
                {kpiGroup.name}
              </NavButton>
            </Link>
          ))}
        </div>
        <div style={{ height: '60px' }}>
          <IconButton
            style={{ marginTop: '5px' }}
            disabled={kpiGroups.length === 0}
            onClick={() => {
              const kpiGroup = kpiGroups.find(
                (group) => location.pathname === `${url}/${group.path}`
              );
              if (kpiGroup) {
                displayHelp({ context: 'nav', resource: kpiGroup.helpURI });
              }
            }}
          >
            <HelpOutlineRounded />
          </IconButton>
        </div>
      </Grid>
    </Box>
  );
};

export default NavKPIGroups;
