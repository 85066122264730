import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  buildValueInputTableConfig,
  ValueInputTableConfig,
  KPIZeroBasedTable,
} from 'app/kpi/components/tables';
import { KPIData } from 'app/kpi/types';
import {
  getControlCapabilities,
  getKPIsAndValues,
  KPIGroup,
  Control,
  getKPIContext,
  KPIContext,
  KPIMetadata,
  ControlCapability
} from 'app/services/api';
import {
  ProcessedControlCapability as CapabilityType,
  processCapabilities
} from 'app/utils/helpers';
import {
  getThreatLevels,
  getThreatSurfaces,
} from 'app/services/apiThreatSurfaces';
import Loader from 'app/common/Loader';
import { KPIGroupDisplay, GroupKeys } from 'app/kpi/types/KPIGroupDisplay';
import { KPIDisplay } from 'app/kpi/types/KPIDisplay';
import { kpiGroupsState } from 'app/common/actions/kpi-groups';
import { kpiMetadataState } from 'app/common/actions/kpi-metadata';
import { getHelpURN } from 'app/services/help';
import KPIContextDialog from 'app/kpi/components/KPIContextDialog';

const getColumnConfigs: Record<string, any> = {
  [GroupKeys.BudgetAllocationToThreatLevels](kpi: KPIDisplay) {
    return {
      text: kpi.text,
      helpURI: getHelpURN({ context: 'kpi', resource: kpi.helpURI }),
    };
  },
  [GroupKeys.ZeroBasedBudgetAllocationToSurfaces](kpi: KPIDisplay) {
    return {
      text: kpi.text,
      helpURI: getHelpURN({ context: 'kpi', resource: kpi.helpURI }),
    };
  },
};

type mapStateType = {
  app: {
    control: Control;
  };
  kpiGroups: kpiGroupsState;
  kpiMetadata: kpiMetadataState;
};

interface KPIBudgetProps {
  selectedControl: Control;
  kpiGroups: kpiGroupsState;
  kpiMetadata: kpiMetadataState;
}

const KPIBudget: FunctionComponent<KPIBudgetProps> = ({
  selectedControl,
  kpiGroups,
  kpiMetadata,
}: KPIBudgetProps) => {
  const kpiGroupPayload = kpiGroups.payload as KPIGroup[];
  const kpiGroup = kpiGroupPayload.find(({ key }) =>
    key.includes('zero-based')
  );
  const kpiMetadataPayload = kpiMetadata.payload as KPIMetadata[];
  const [tableConfigs, setTableConfigs] = useState<
    ValueInputTableConfig[] | null
  >(null);
  const [errors] = useState<KPIData<any>[]>([]);
  const [kpiContext, setKpiContext] = useState<KPIContext[] | []>([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [contextKey, setContextKey] = useState('');
  const [
    selectedKPIContext,
    setSelectedKPIContext,
  ] = useState<KPIContext | null>(null);

  const onContextDialogOpen = (contextKey: string, context?: KPIContext) => {
    if (context) {
      setSelectedKPIContext(context);
    } else {
      setSelectedKPIContext(null);
    }
    setContextKey(contextKey);
    setDialogIsOpen(true);
  };

  const handleDialogClose = () => {
    setDialogIsOpen(false);
  };

  useEffect(() => {
    if (!kpiGroup || !selectedControl) {
      return;
    }
    (async () => {
      try {
        const [surfaces, levels] = await Promise.all([
          getThreatSurfaces(),
          getThreatLevels(),
        ]);
        const caps: ControlCapability[] = await getControlCapabilities(
          selectedControl.id,
          surfaces[0].id
        );
        const capabilities: CapabilityType[] = processCapabilities(caps);

        const tableConfigs = await Promise.all(
          (kpiGroup.subGroups || []).map(async (group) => {
            let kpis;
            let displayGroup;
            if (group.subGroups) {
              const subGroupKPIs = await Promise.all(
                (group.subGroups || []).map((grp) =>
                  getKPIsAndValues({
                    groupId: grp.id,
                    controlId: selectedControl.id,
                  })
                )
              );

              kpis = subGroupKPIs.reduce((prev, next) => prev.concat(next), []);
              displayGroup = new KPIGroupDisplay(group, kpis);
            } else {
              kpis = await getKPIsAndValues({
                groupId: group.id,
                controlId: selectedControl.id,
              });
              displayGroup = new KPIGroupDisplay(group, kpis);
            }

            return buildValueInputTableConfig(
              displayGroup,
              selectedControl,
              capabilities,
              surfaces,
              levels,
              getColumnConfigs[displayGroup.key],
              null
            );
          })
        );

        setTableConfigs(tableConfigs);

        // eslint-disable-next-line no-empty
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    if (!kpiGroup || !selectedControl) {
      return;
    }

    (async () => {
      const context = await getKPIContext({
        key: kpiGroup.key,
        controlId: selectedControl.id,
        include: 'subKeys',
      });

      setKpiContext(context);
    })();
  }, [dialogIsOpen]);

  if (!kpiGroup || !selectedControl) {
    return null;
  }

  if (!tableConfigs) {
    return <Loader />;
  }

  return (
    <>
      {tableConfigs.map(
        ({ text, key, helpURI, columns, items, headerTitle }) => {
          return (
            <KPIZeroBasedTable
              key={key}
              contextKey={key}
              text={text}
              helpURI={helpURI}
              columns={columns}
              headerTitle={headerTitle}
              context={kpiContext.find((item) => item.key === key)}
              items={items}
              errors={errors}
              onContextDialogOpen={onContextDialogOpen}
            />
          );
        }
      )}
      {dialogIsOpen && (
        <KPIContextDialog
          isOpen={dialogIsOpen}
          context={selectedKPIContext}
          contextKey={contextKey}
          kpiMetadata={kpiMetadataPayload}
          controlId={selectedControl.id}
          handleClose={handleDialogClose}
        />
      )}
    </>
  );
};

const mapState = ({
  app: { control },
  kpiGroups,
  kpiMetadata,
}: mapStateType) => ({
  selectedControl: control,
  kpiGroups,
  kpiMetadata,
});

export default connect(mapState)(KPIBudget);
