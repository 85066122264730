import { getKPIGroups, KPIGroup } from 'app/services/api';

const featureName = 'common/actions';

export type kpiGroupsState = {
  isLoading: boolean;
  hasError: Error | null;
  payload: Promise<KPIGroup[]> | KPIGroup[] | null;
};

interface appStore {
  kpiGroups: kpiGroupsState;
}

type dispatchType = {
  type: string;
  payload: Promise<KPIGroup[]> | KPIGroup[] | null;
};

export const actionTypes = {
  fetchKpiGroups: `${featureName}/FETCH_KPI_GROUPS`,
};

export const fetchKpiGroups = (forceFetch = false) => (
  dispatch: React.Dispatch<dispatchType>,
  getState: () => appStore
): void => {
  const { payload: existingPayload } = getState().kpiGroups;

  const hasKpiGroups =
    existingPayload && Array.isArray(existingPayload) && existingPayload.length;

  dispatch({
    type: actionTypes.fetchKpiGroups,
    payload: !forceFetch && hasKpiGroups ? existingPayload : getKPIGroups(),
  });
};
