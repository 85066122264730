import { KPIGroup as KpiGroupType, KPI } from 'services/api';
import { getBarColorsForValue } from 'common/BarGraph';
import {
  tableContainerWithMarginStyles,
  headerStyles,
  getColWidth,
  firstColStyles,
  lastColStyles,
} from 'app/kpi/components/tables';
import { surfaceTypes } from './index';
import Loader from 'app/common/Loader';
import MuiLightBlue from '@material-ui/core/colors/lightBlue';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getKpiGroupProductivity } from 'utils/graph-math';
import { displayHelp } from 'app/services/help';
import React from 'react';
import { IconButton } from '@material-ui/core';
import { HelpOutlineRounded } from '@material-ui/icons';

type SecOpsKpiGroupProductivityType = surfaceTypes & {
  kpiGroups: KpiGroupType[];
  management: KPI[][] | null;
  resources: KPI[][] | null;
  integration: KPI[][] | null;
  secOpsProductivity: number;
};

const SecOpsProductivityMatrix = ({
  kpiGroups,
  management,
  resources,
  integration,
  secOpsProductivity,
  threatSurfaces,
}: SecOpsKpiGroupProductivityType): JSX.Element => {
  const managementKpiGroup = kpiGroups.find((group) =>
    group.key.includes('management')
  );
  const resourcesKpiGroup = kpiGroups.find((group) =>
    group.key.includes('resources')
  );
  const integrationKpiGroup = kpiGroups.find((group) =>
    group.key.includes('integration')
  );

  const managementKpiGroupProductivity = getKpiGroupProductivity(
    management,
    managementKpiGroup
  );
  const resourcesKpiGroupProductivity = getKpiGroupProductivity(
    resources,
    resourcesKpiGroup
  );
  const integrationKpiGroupProductivity = getKpiGroupProductivity(
    integration,
    integrationKpiGroup
  );

  const data = [
    { kpiGroup: managementKpiGroup, data: managementKpiGroupProductivity },
    {
      kpiGroup: resourcesKpiGroup,
      data: resourcesKpiGroupProductivity,
      secOpsProductivity: secOpsProductivity,
    },
    { kpiGroup: integrationKpiGroup, data: integrationKpiGroupProductivity },
  ].sort(
    (prev, next) =>
      (prev.kpiGroup?.displayOrder || 0) - (next.kpiGroup?.displayOrder || 0)
  );

  const overallStyle = {
    backgroundColor: MuiLightBlue[900],
    color: 'white',
    border: 0,
  };

  if (!Array.isArray(threatSurfaces.payload)) {
    return (
      <div style={{ minHeight: '500px' }}>
        <Loader />
      </div>
    );
  }
  const numberOfThreatSurfaces = threatSurfaces.payload.length;
  const colWidth = getColWidth(numberOfThreatSurfaces);
  const helpURI = '';

  return (
    <>
      <Paper elevation={3} style={{ padding: '1.5rem', marginBottom: '2rem' }}>
        <div style={tableContainerWithMarginStyles}>
          <Table size="small">
            <TableHead>
              <TableRow style={headerStyles}>
                <TableCell colSpan={numberOfThreatSurfaces + 1}>
                  <Typography variant="h6">
                    Current SecOps Productivity
                  </Typography>
                </TableCell>
                <TableCell colSpan={1} align="right" width="60px">
                  <IconButton
                    disabled={!helpURI}
                    onClick={() => displayHelp(helpURI)}
                  >
                    <HelpOutlineRounded />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={firstColStyles} key="secops-productivity">
                  Key Performance Indicator (KPI)
                </TableCell>
                {threatSurfaces.payload.map((ele, idx) => {
                  return (
                    <TableCell
                      style={{ width: colWidth }}
                      key={`header-${idx}`}
                    ></TableCell>
                  );
                })}
                <TableCell style={lastColStyles} key="overall" align="center">
                  Overall
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((group, idx) => {
                let barColors = getBarColorsForValue(group.data);
                const cellStyle = {
                  backgroundColor: barColors.background,
                  color: barColors.textColor,
                };

                let secOpsProductivityColumn;
                if (typeof group.secOpsProductivity !== 'undefined') {
                  secOpsProductivityColumn = Math.round(
                    group.secOpsProductivity
                  );
                }

                return (
                  <TableRow key={`${group.kpiGroup?.name}-${idx}`}>
                    <TableCell align="left">{group.kpiGroup?.name}</TableCell>
                    <TableCell
                      align="center"
                      style={cellStyle}
                      colSpan={numberOfThreatSurfaces}
                    >
                      {group.data}
                    </TableCell>
                    <TableCell align="center" style={overallStyle}>
                      {secOpsProductivityColumn}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </>
  );
};

export default SecOpsProductivityMatrix;
