import queryString from 'query-string';

export interface URLParams {
  query?: Record<string, string | number | boolean>;
}

export const getNullableQueryParam = (
  field: string,
  inputParam: number | string | null | undefined
) => ({
  ...(inputParam ? { [field]: inputParam } : {}),
});

export const getQueryString = (params: any): string => {
  let query = queryString.stringify(params);
  if (query) {
    query = `?${query}`;
  }
  return query;
};

export const getUrl = (path: string, params?: URLParams): string => {
  const apiUrl = API_URL.replace(/\/$/, '');
  path = path.replace(/^\//, '');
  params = params || {};
  if (params?.query) {
    path += getQueryString(params.query);
  }
  return `${apiUrl}/${path}`;
};

export const getHelpUrl = (path: string, params?: URLParams): string => {
  const helpUrl = HELP_URL.replace(/\/$/, '');
  path = path.replace(/^\//, '');
  params = params || {};
  if (params?.query) {
    path += getQueryString(params.query);
  }
  return `${helpUrl}/help/${path}`;
};

export const getTutorialUrl = (): string => {
  const helpUrl = HELP_URL.replace(/\/$/, '');
  return `${helpUrl}/tutorial`;
};
