import React, { useEffect } from 'react';
import MuiBlue from '@material-ui/core/colors/blue';
import Loader from 'app/common/Loader';
import BudgetAllocationTable from './BudgetAllocationTable';
import ControlGroupBudgetChart from './ControlGroupBudgetChart';
import { KPI, KPIValue, TagType, Control } from 'app/services/api';
import {
  ThreatSurface as ThreatSurfaceType,
  ThreatLevel as ThreatLevelType,
} from 'app/services/apiThreatSurfaces';
import { BudgetAllocationDataType } from 'app/utils/budget';
import {
  surfaceTypes,
  threatLevelTypes,
} from './index';

type BudgetAllocationType = surfaceTypes &
  threatLevelTypes & {
    budgetAllocationData: BudgetAllocationDataType[] | null;
    selectedTag: TagType;
    controls: Control[]
  };

const getValueFromObject = (obj: KPI | undefined): number => {
  return obj && obj.kpiValue.length > 0 ? Number(obj.kpiValue[0].value) : 0;
};

const getNameFromObject = (obj: KPI | undefined): string => {
  return obj ? obj.name : '';
};

const getTotalObject = (
  budgetAllocationData: BudgetAllocationDataType[],
  key: string,
  controls: Control[]
): any => {
  const totalAllocationLevelsArray = budgetAllocationData.map(
    (data: BudgetAllocationDataType) => {
      return data.totalAllocationLevels.find((item) => item.key === key);
    }
  );
  const totalAllocationLevelsValuesArray = totalAllocationLevelsArray.map(
    (levels: KPI | undefined) => {
      return levels && levels?.kpiValue.length > 0
        ? levels?.kpiValue.map((item) => {
            return {
              value: Number(item.value),
              levelId: item.levelId,
            };
          })
        : [{ value: 0, levelId: 0 }];
    }
  );

  let totalValue = 0;
  let maxControlSum = 0;
  const totalsPerControl = controls.map((control: Control, controlIndex: number) => {
    let controlSum = 0;
    totalAllocationLevelsValuesArray[controlIndex].forEach((valueObject) => {
      controlSum += valueObject.value;
    });
    totalValue += controlSum;
    maxControlSum = Math.max(maxControlSum, controlSum);
    return {
      id: control.id,
      label: control.name,
      budget: controlSum,
      budgetColour: MuiBlue[900]
    }
  });

  const totalData = [
    {
      id: 1,
      label: 'Total',
      Total: totalValue,
      TotalColour: MuiBlue[900],
    },
  ];
  const totalDataKey = ['Total'];

  return {
    totalAllocationLevelsArray,
    totalValue,
    totalData,
    totalDataKey,
    totalsPerControl,
    maxControlSum
  };
};

const getBudgetSplitObject = (
  budgetAllocationData: BudgetAllocationDataType[],
  key: string,
  capExKey: string,
  opExKey: string,
  controlBudgets: any
): any => {
  const budgetSplitArray = budgetAllocationData.map(
    (data: BudgetAllocationDataType) => {
      return data.budgetSplit.filter((item) => item.key.includes(key));
    }
  );
  const capExArray = budgetSplitArray.map((budgetSplit: KPI[]) =>
    budgetSplit.find((item) => item.key === capExKey)
  );
  const opExArray = budgetSplitArray.map((budgetSplit: KPI[]) =>
    budgetSplit.find((item) => item.key === opExKey)
  );
  const capExValuesArray = capExArray.map((capEx: KPI | undefined) =>
    getValueFromObject(capEx)
  );
  const opExValuesArray = opExArray.map((opEx: KPI | undefined) =>
    getValueFromObject(opEx)
  );

  let capExTotal = 0;
  let opExTotal = 0;
  controlBudgets.forEach((controlBudget: any, controlIndex: number) => {
    opExTotal += controlBudget.budget * (opExValuesArray[controlIndex] / 100);
    capExTotal += controlBudget.budget * (capExValuesArray[controlIndex] / 100);
  });
  const budgetSplitData = [
    {
      id: 1,
      label: 'Budget Split',
      'OPEX %': Math.round(opExTotal),
      'OPEX %Colour': capExTotal > opExTotal ? MuiBlue[800] : MuiBlue[900],
      'CAPEX %': Math.round(capExTotal),
      'CAPEX %Colour': capExTotal > opExTotal ? MuiBlue[900] : MuiBlue[800],
    },
  ];
  let budgetSplitDataKeys =
    capExTotal > opExTotal
      ? [getNameFromObject(capExArray[0]), getNameFromObject(opExArray[0])]
      : [getNameFromObject(opExArray[0]), getNameFromObject(capExArray[0])];
  const budgetSplitDataLength =
    opExTotal && capExTotal ? 2 : opExTotal === 0 || capExTotal === 0 ? 1 : 0;
  budgetSplitDataKeys = budgetSplitDataKeys.slice(0, budgetSplitDataLength);
  const budgetSplitDataMaxValue = opExTotal + capExTotal;
  return {
    budgetSplitData,
    budgetSplitDataKeys,
    budgetSplitDataMaxValue,
  };
};

const getAllocationResourcesObject = (
  budgetAllocationData: BudgetAllocationDataType[],
  key: string,
  totalsPerControl: any
): any => {
  const allocationResourcesArray = budgetAllocationData.map(
    (data: BudgetAllocationDataType) => {
      return data.budgetAllocationResources.filter((item) =>
        item.key.includes(key)
      );
    }
  );

  let total = 0;
  const resourceMap: any = {};
  allocationResourcesArray.forEach((allocationResources: KPI[], controlIndex: number) => {
    allocationResources.forEach((element) => {
      const key = element.name;
      const allocationPercent: number = element.kpiValue.length > 0 ? Number(element.kpiValue[0].value) : 0;
      const value = Math.round(totalsPerControl[controlIndex].budget * (allocationPercent / 100));
      total += value;
      if (key in resourceMap) {
        resourceMap[key].value += value;
      } else {
        resourceMap[key] = {
          key,
          value
        }
      }
    });
  });
  const allocationResourcesPairedData: { key: string; value: number }[] = Object.values(resourceMap);
  allocationResourcesPairedData.sort(function (a, b) {
    return b.value - a.value;
  });
  const allocationResourcesDataKeys: string[] = [];
  const allocationResourcesData: any = [
    {
      id: 1,
      label: 'Allocation to Resources',
    },
  ];
  allocationResourcesPairedData.forEach((item, index: number) => {
    const key = item.key;
    const value = item.value;
    const colorKey = `${key}` + 'Colour';
    const colorIndex = 9 - index >= 1 ? (9 - index) * 100 : 50;
    allocationResourcesData[0][key] = value;
    allocationResourcesData[0][colorKey] = MuiBlue[colorIndex as keyof ColorIndexArray];
    allocationResourcesDataKeys.push(key);
  });
  const allocationResourcesDataMaxValue = total;
  return {
    allocationResourcesData,
    allocationResourcesDataKeys,
    allocationResourcesDataMaxValue,
  };
};

const getAllocationLevelsObject = (
  totalAllocationLevelsArray: KPI[],
  threatLevelsPayload: ThreatLevelType[]
): any => {
  let allocationLevelsData = [
    {
      id: 1,
      label: 'Allocation to Levels',
    },
  ];
  const allocationLevelsPairedDataArray: { name: string; value: number }[] = [];
  totalAllocationLevelsArray.map((totalAllocationLevels: KPI) => {
    totalAllocationLevels?.kpiValue.map((element: KPIValue) => {
      const levelId = element.levelId ? element.levelId : '';
      const payload = threatLevelsPayload.find((o) => o.id === levelId);
      const key = payload?.name;
      const value = element.value;
      allocationLevelsPairedDataArray.push({
        name: key ? key : '',
        value: value ? Number(value) : 0,
      });
    });
  });
  const allocationLevelsPairedData: { name: string; value: number }[] = [];
  allocationLevelsPairedDataArray.map((item) => {
    const temp = allocationLevelsPairedData.filter(
      (data) => data.name === item.name
    );
    if (temp.length === 0) {
      allocationLevelsPairedData.push({
        name: item.name,
        value: item.value,
      });
    } else {
      const index = allocationLevelsPairedData.findIndex(
        (data) => data.name === item.name
      );
      allocationLevelsPairedData[index].value += item.value;
    }
  });
  let allocationLevelsDataKeys: string[] = [];
  allocationLevelsPairedData.sort(function (a, b) {
    return b.value - a.value;
  });
  allocationLevelsPairedData.map((item, index: number) => {
    const key = item.name;
    const value = item.value;
    const colorKey = `${key}` + 'Colour';
    const colorIndex = 9 - index >= 1 ? (9 - index) * 100 : 50;
    allocationLevelsData = allocationLevelsData.map((data: any) => {
      const newObj = { ...data };
      newObj[key] = value;
      newObj[colorKey] = MuiBlue[colorIndex as keyof ColorIndexArray];
      return newObj;
    });
  });
  allocationLevelsPairedData.map((item) =>
    allocationLevelsDataKeys.push(item.name)
  );
  let allocationLevelsDataKeysLength = 0;
  allocationLevelsPairedData.map(
    (item) => item.value && allocationLevelsDataKeysLength++
  );
  allocationLevelsDataKeys = allocationLevelsDataKeys.slice(
    0,
    allocationLevelsDataKeysLength
  );

  return {
    allocationLevelsData,
    allocationLevelsDataKeys,
  };
};

const getAllocationSurfacesObject = (
  budgetAllocationData: BudgetAllocationDataType[],
  key: string,
  threatSurfacesPayload: ThreatSurfaceType[],
  totalsPerControl: any
): any => {
  const allocationSurfacesArray = budgetAllocationData.map(
    (data: BudgetAllocationDataType) => {
      return data.budgetAllocationSurfaces.filter((item) =>
        item.key.includes(key)
      );
    }
  );

  let total = 0;
  const allocationSurfacesPairedData: { name: string; value: number }[] = [];
  threatSurfacesPayload.forEach((threatSurface: ThreatSurfaceType) => {
    let totalForSurface = 0;
    allocationSurfacesArray.forEach((allocationSurfacesForControl: KPI[], controlIndex: number) => {
      const allocationSurface = allocationSurfacesForControl[0].kpiValue.find((kpi) => kpi.surfaceId === threatSurface.id);
      const surfacePercent = allocationSurface ? Number(allocationSurface.value) : 0;
      totalForSurface += Math.round(totalsPerControl[controlIndex].budget * (surfacePercent / 100));
    });
    total += totalForSurface;
    if (totalForSurface) {
      allocationSurfacesPairedData.push({
        name: threatSurface.name,
        value: totalForSurface
      });
    } 
  });

  allocationSurfacesPairedData.sort(function (a, b) {
    return b.value - a.value;
  });
  const allocationSurfacesData: any = [
    {
      id: 1,
      label: 'Allocation to Surfaces',
    },
  ];
  const allocationSurfacesDataKeys: string[] = [];
  allocationSurfacesPairedData.forEach((item, index: number) => {
    const key = item.name;
    const value = item.value;
    const colorKey = `${key}` + 'Colour';
    const colorIndex = 9 - index >= 1 ? (9 - index) * 100 : 50;
    allocationSurfacesData[0][key] = value;
    allocationSurfacesData[0][colorKey] = MuiBlue[colorIndex as keyof ColorIndexArray];
    allocationSurfacesDataKeys.push(key);
  });

  const allocationSurfacesDataMaxValue = total;
  return {
    allocationSurfacesData,
    allocationSurfacesDataKeys,
    allocationSurfacesDataMaxValue,
  };
};

interface ColorIndexArray {
  900: string;
  800: string;
  700: string;
  600: string;
  500: string;
  400: string;
  300: string;
  200: string;
  100: string;
  50: string;
}

const BudgetAllocation = ({
  budgetAllocationData,
  getThreatSurfaces,
  getThreatLevels,
  threatSurfaces,
  threatLevels,
  selectedTag,
  controls,
}: BudgetAllocationType): JSX.Element => {
  useEffect(() => {
    getThreatSurfaces();
    getThreatLevels();
  }, []);

  if (
    !budgetAllocationData ||
    threatSurfaces.isLoading ||
    threatLevels.isLoading
  ) {
    return <Loader />;
  }

  const threatSurfacesPayload = threatSurfaces.payload;
  const threatLevelsPayload = threatLevels.payload;

  // total allocation to level data for current
  const currentTotalObject = getTotalObject(
    budgetAllocationData,
    'com.pharossecurity.zero-based.allocation-to-levels.current',
    controls
  );
  const currentTotalAllocationLevelsArray =
    currentTotalObject.totalAllocationLevelsArray;
  const currentTotalValue = currentTotalObject.totalValue;
  const currentTotalData = currentTotalObject.totalData;
  const currentTotalDataKey = currentTotalObject.totalDataKey;
  const currentTotalsPerControl = currentTotalObject.totalsPerControl;
  const currentMaxPerControl = currentTotalObject.maxControlSum;

  // budget split data for current
  const currentBudgetSplitObject = getBudgetSplitObject(
    budgetAllocationData,
    'budget-split.current',
    'com.pharossecurity.zero-based.budget-split.current.capex',
    'com.pharossecurity.zero-based.budget-split.current.opex',
    currentTotalsPerControl
  );
  const currentBudgetSplitData = currentBudgetSplitObject.budgetSplitData;
  const currentBudgetSplitDataKeys =
    currentBudgetSplitObject.budgetSplitDataKeys;

  // allocation to resources data for current
  const currentAllocationResourcesObject = getAllocationResourcesObject(
    budgetAllocationData,
    'allocation-to-resources.current',
    currentTotalsPerControl
  );
  const currentAllocationResourcesData =
    currentAllocationResourcesObject.allocationResourcesData;
  const currentAllocationResourcesDataKeys =
    currentAllocationResourcesObject.allocationResourcesDataKeys;

  // allocation to level data for current
  const currentAllocationLevelsObject = getAllocationLevelsObject(
    currentTotalAllocationLevelsArray,
    threatLevelsPayload,
  );
  const currentAllocationLevelsData =
    currentAllocationLevelsObject.allocationLevelsData;
  const currentAllocationLevelsDataKeys =
    currentAllocationLevelsObject.allocationLevelsDataKeys;

  // allocation to surfaces data for current
  const currentAllocationSurfacesObject = getAllocationSurfacesObject(
    budgetAllocationData,
    'com.pharossecurity.zero-based.allocation-to-surfaces.current',
    threatSurfacesPayload,
    currentTotalsPerControl
  );
  const currentAllocationSurfacesData =
    currentAllocationSurfacesObject.allocationSurfacesData;
  const currentAllocationSurfacesDataKeys =
    currentAllocationSurfacesObject.allocationSurfacesDataKeys;

  // total allocation to level data for build
  const buildTotalObject = getTotalObject(
    budgetAllocationData,
    'com.pharossecurity.zero-based.allocation-to-levels.build',
    controls
  );
  const buildTotalAllocationLevelsArray =
    buildTotalObject.totalAllocationLevelsArray;
  const buildTotalValue = buildTotalObject.totalValue;
  const buildTotalData = buildTotalObject.totalData;
  const buildTotalDataKey = buildTotalObject.totalDataKey;
  const buildTotalsPerControl = buildTotalObject.totalsPerControl;
  const buildMaxPerControl = buildTotalObject.maxControlSum;

  // budget split data for build
  const buildBudgetSplitObject = getBudgetSplitObject(
    budgetAllocationData,
    'budget-split.build',
    'com.pharossecurity.zero-based.budget-split.build.capex',
    'com.pharossecurity.zero-based.budget-split.build.opex',
    buildTotalsPerControl
  );
  const buildBudgetSplitData = buildBudgetSplitObject.budgetSplitData;
  const buildBudgetSplitDataKeys = buildBudgetSplitObject.budgetSplitDataKeys;

  // allocation to resources data for build
  const buildAllocationResourcesObject = getAllocationResourcesObject(
    budgetAllocationData,
    'allocation-to-resources.build',
    buildTotalsPerControl
  );
  const buildAllocationResourcesData =
    buildAllocationResourcesObject.allocationResourcesData;
  const buildAllocationResourcesDataKeys =
    buildAllocationResourcesObject.allocationResourcesDataKeys;

  // allocation to level data for build
  const buildAllocationLevelsObject = getAllocationLevelsObject(
    buildTotalAllocationLevelsArray,
    threatLevelsPayload,
  );
  const buildAllocationLevelsData =
    buildAllocationLevelsObject.allocationLevelsData;
  const buildAllocationLevelsDataKeys =
    buildAllocationLevelsObject.allocationLevelsDataKeys;

  // allocation to surfaces data for build
  const buildAllocationSurfacesObject = getAllocationSurfacesObject(
    budgetAllocationData,
    'com.pharossecurity.zero-based.allocation-to-surfaces.build',
    threatSurfacesPayload,
    buildTotalsPerControl
  );
  const buildAllocationSurfacesData =
    buildAllocationSurfacesObject.allocationSurfacesData;
  const buildAllocationSurfacesDataKeys =
    buildAllocationSurfacesObject.allocationSurfacesDataKeys;

  // total allocation to level data for run
  const runTotalObject = getTotalObject(
    budgetAllocationData,
    'com.pharossecurity.zero-based.allocation-to-levels.run',
    controls
  );
  const runTotalAllocationLevelsArray =
    runTotalObject.totalAllocationLevelsArray;
  const runTotalValue = runTotalObject.totalValue;
  const runTotalData = runTotalObject.totalData;
  const runTotalDataKey = runTotalObject.totalDataKey;
  const runTotalsPerControl = runTotalObject.totalsPerControl;
  const runMaxPerControl = runTotalObject.maxControlSum;

  // budget split data for run
  const runBudgetSplitObject = getBudgetSplitObject(
    budgetAllocationData,
    'budget-split.run',
    'com.pharossecurity.zero-based.budget-split.run.capex',
    'com.pharossecurity.zero-based.budget-split.run.opex',
    runTotalsPerControl
  );
  const runBudgetSplitData = runBudgetSplitObject.budgetSplitData;
  const runBudgetSplitDataKeys = runBudgetSplitObject.budgetSplitDataKeys;

  // allocation to resources data for run
  const runAllocationResourcesObject = getAllocationResourcesObject(
    budgetAllocationData,
    'allocation-to-resources.run',
    runTotalsPerControl
  );
  const runAllocationResourcesData =
    runAllocationResourcesObject.allocationResourcesData;
  const runAllocationResourcesDataKeys =
    runAllocationResourcesObject.allocationResourcesDataKeys;

  // allocation to level data for run
  const runAllocationLevelsObject = getAllocationLevelsObject(
    runTotalAllocationLevelsArray,
    threatLevelsPayload
  );
  const runAllocationLevelsData =
    runAllocationLevelsObject.allocationLevelsData;
  const runAllocationLevelsDataKeys =
    runAllocationLevelsObject.allocationLevelsDataKeys;

  // allocation to surfaces data for run
  const runAllocationSurfacesObject = getAllocationSurfacesObject(
    budgetAllocationData,
    'com.pharossecurity.zero-based.allocation-to-surfaces.run',
    threatSurfacesPayload,
    runTotalsPerControl
  );
  const runAllocationSurfacesData =
    runAllocationSurfacesObject.allocationSurfacesData;
  const runAllocationSurfacesDataKeys =
    runAllocationSurfacesObject.allocationSurfacesDataKeys;

  return (
    <>
      <BudgetAllocationTable
        controlName={selectedTag.name + ' Current'}
        totalValue={currentTotalValue}
        totalData={currentTotalData}
        totalDataKey={currentTotalDataKey}
        budgetSplitData={currentBudgetSplitData}
        budgetSplitDataKeys={currentBudgetSplitDataKeys}
        allocationResourcesData={currentAllocationResourcesData}
        allocationResourcesDataKeys={currentAllocationResourcesDataKeys}
        allocationLevelsData={currentAllocationLevelsData}
        allocationLevelsDataKeys={currentAllocationLevelsDataKeys}
        allocationSurfacesData={currentAllocationSurfacesData}
        allocationSurfacesDataKeys={currentAllocationSurfacesDataKeys}
      />
      <BudgetAllocationTable
        controlName={selectedTag.name + ' Full Build'}
        totalValue={buildTotalValue}
        totalData={buildTotalData}
        totalDataKey={buildTotalDataKey}
        budgetSplitData={buildBudgetSplitData}
        budgetSplitDataKeys={buildBudgetSplitDataKeys}
        allocationResourcesData={buildAllocationResourcesData}
        allocationResourcesDataKeys={buildAllocationResourcesDataKeys}
        allocationLevelsData={buildAllocationLevelsData}
        allocationLevelsDataKeys={buildAllocationLevelsDataKeys}
        allocationSurfacesData={buildAllocationSurfacesData}
        allocationSurfacesDataKeys={buildAllocationSurfacesDataKeys}
      />

      <BudgetAllocationTable
        controlName={selectedTag.name + ' Full Run'}
        totalValue={runTotalValue}
        totalData={runTotalData}
        totalDataKey={runTotalDataKey}
        budgetSplitData={runBudgetSplitData}
        budgetSplitDataKeys={runBudgetSplitDataKeys}
        allocationResourcesData={runAllocationResourcesData}
        allocationResourcesDataKeys={runAllocationResourcesDataKeys}
        allocationLevelsData={runAllocationLevelsData}
        allocationLevelsDataKeys={runAllocationLevelsDataKeys}
        allocationSurfacesData={runAllocationSurfacesData}
        allocationSurfacesDataKeys={runAllocationSurfacesDataKeys}
      />

      <ControlGroupBudgetChart
        title={`${selectedTag.name} Current Budget Allocation By Control`}
        controlGroupBudgets={currentTotalsPerControl}
        maxBudgetValue={currentMaxPerControl}
      />

      <ControlGroupBudgetChart
        title={`${selectedTag.name} Build Budget Allocation By Control`}
        controlGroupBudgets={buildTotalsPerControl}
        maxBudgetValue={buildMaxPerControl}
      />

      <ControlGroupBudgetChart
        title={`${selectedTag.name} Run Budget Allocation By Control`}
        controlGroupBudgets={runTotalsPerControl}
        maxBudgetValue={runMaxPerControl}
      />
    </>
  );
};

export default BudgetAllocation;
