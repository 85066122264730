import React from 'react';
import { KPIGroup, KPI } from 'app/services/api';
import { kpiHeaderTitle } from 'app/kpi/components/tables';
import BarGraph, { getBarColorsForValue } from 'app/common/BarGraph';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import { getKpiSubGroupProductivity } from 'app/utils/graph-math';
import { getHelpURN } from 'app/services/help';

const featureName =
  'dashboard/control-dashboards/common-components/kpi-subgroup-summary';

const KPISubGroupSummary = ({
  kpiGroup,
  title,
  kpiValues,
}: {
  kpiGroup: KPIGroup;
  title: string;
  kpiValues: KPI[][];
}): JSX.Element => {
  const getBarData = (subGroup: KPIGroup, kpiData: KPI[]) => [
    {
      id: subGroup.name,
      label: subGroup.name,
      value: getKpiSubGroupProductivity(kpiData),
    },
  ];

  const subGroups: KPIGroup[] = kpiGroup.subGroups as KPIGroup[];
  let barColors: any;

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading
            colSpan={2}
            helpURI={getHelpURN({
              context: 'dashboard',
              resource: kpiGroup.helpURI,
            })}
          >
            {title}
          </StripedTable.Heading>
          <StripedTable.SubHeading colSpan={2}>
            {kpiHeaderTitle}
          </StripedTable.SubHeading>
        </StripedTable.Head>

        <StripedTable.Body>
          {subGroups.map((subGroup, subGroupIndex) => {
            const barData = getBarData(subGroup, kpiValues[subGroupIndex]);
            const isLast = subGroupIndex === subGroups.length - 1;

            if (Array.isArray(barData) && barData.length > 0) {
              barColors = getBarColorsForValue(barData[0].value);
            }

            return (
              <StripedTable.Row key={`${featureName}-${title}-${subGroup.key}`}>
                <StripedTable.GraphLabel
                  isLast={isLast}
                  colSpan={1}
                  style={{ width: '20%' }}
                >
                  {subGroup.name}
                </StripedTable.GraphLabel>
                <StripedTable.GraphCell isLast={isLast} colSpan={1}>
                  <BarGraph
                    data={barData}
                    colors={barColors.background}
                    labelTextColor={barColors.textColor}
                    axisBottom={isLast ? {} : null}
                  />
                </StripedTable.GraphCell>
              </StripedTable.Row>
            );
          })}
        </StripedTable.Body>
      </StripedTable>
    </Section>
  );
};

export default KPISubGroupSummary;
