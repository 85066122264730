import React, { FunctionComponent } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMoreOutlined, ChevronRightOutlined } from '@material-ui/icons';
import { KPIGroup as KPIGroupType } from 'app/services/api';

interface KPIGroupTreeItemProps {
  label: string;
  data: any;
  expanded: string[];
  selectedItem: string[];
  handleToggle: (event: React.ChangeEvent<unknown>, nodeIds: string[]) => void;
  handleSelect: (event: React.ChangeEvent<unknown>, nodeIds: string[]) => void;
}

const useStyles = makeStyles({
  treeViewContainer: {
    flexGrow: 1,
    maxWidth: 'calc(100% - 100px)',
    height: 150,
    border: 'inset',
    borderWidth: '1px',
    overflow: 'auto',
  },
});

export const KPIGroupTreeItem: FunctionComponent<KPIGroupTreeItemProps> = ({
  label,
  data,
  expanded,
  selectedItem,
  handleToggle,
  handleSelect,
}: KPIGroupTreeItemProps) => {
  const classes = useStyles();

  const renderSubTreeItem = (item: any) => {
    return item.subGroups.map((subItem: any) => {
      return (
        <TreeItem
          key={'group' + subItem.id}
          nodeId={'group' + subItem.id}
          label={subItem.name}
        >
          {subItem.subGroups && renderSubTreeItem(subItem)}
        </TreeItem>
      );
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '32px',
        justifyContent: 'space-between',
        marginBottom: '30px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        {label}
      </div>
      <TreeView
        className={classes.treeViewContainer}
        defaultCollapseIcon={<ExpandMoreOutlined />}
        defaultExpandIcon={<ChevronRightOutlined />}
        expanded={expanded}
        selected={selectedItem}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        {data.map((item: KPIGroupType) => {
          return (
            <TreeItem
              key={'group' + item.id}
              nodeId={'group' + item.id}
              label={item.name}
            >
              {item.subGroups && renderSubTreeItem(item)}
            </TreeItem>
          );
        })}
      </TreeView>
    </div>
  );
};

export default KPIGroupTreeItem;
