export const getCurrencySymbol = (inputLocale: string | null | undefined, inputCurrency: string | null | undefined): string => {
  const locale = inputLocale || 'en-US';
  const currency = inputCurrency || 'USD';

  return new Intl.NumberFormat(locale, { style: 'currency', currency })
    .formatToParts()
    .find((part) => part.type === 'currency')?.value || '';
};

const getCurrencyFormatter = (locale = 'en-US', currency = 'USD', amount = 0, decimals = 2) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(amount);

export type CurrencyType = {
  locale: string;
  currency: string;
  symbol: string;
  currencyFormatter: (amount: number, decimals: number) => string;
  valueFormatter: (amount: string) => number;
};

export const getCurrencyFromLocale = (
  inputLocale: string | null | undefined,
  inputCurrency: string | null | undefined
): CurrencyType => {
  const locale = inputLocale || 'en-US';
  const currency = inputCurrency || 'USD';

  return {
    locale,
    currency,
    symbol: getCurrencySymbol(locale, currency),
    currencyFormatter: (amount: number, decimals = 2) =>
      getCurrencyFormatter(locale, currency, amount, decimals),
    valueFormatter: (currencyString: string) =>
      Number(currencyString.replace(/[^0-9\.-]+/g, '')),
  };
};
