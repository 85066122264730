import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PATHS from 'utils/paths';

import { isAuthenticated } from 'app/services/auth';

interface RouteProps {
  redirectTo?: string;
  children: JSX.Element;
  [prop: string]: any;
}

export const PrivateRoute = ({
  redirectTo = PATHS.HOME,
  children,
  ...props
}: RouteProps) => (
  <Route
    {...props}
    render={({ location }) =>
      isAuthenticated() ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: redirectTo,
            state: { from: location },
          }}
        />
      )
    }
  />
);

export const PublicRoute = ({
  redirectTo = PATHS.HOME,
  children,
  ...props
}: RouteProps) => (
  <Route
    {...props}
    render={() => (isAuthenticated() ? <Redirect to={redirectTo} /> : children)}
  />
);
