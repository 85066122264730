import React from 'react';
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core/styles';
import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import { SectionEntryType } from '.';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 200,
    },
    listContainer: {},
    list: {
      padding: 0,
    },
    listHeaderContainer: {},
    listHeader: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    listItem: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      marginTop: theme.spacing(1),
      '&$listItemSelected': {
        background: 'none',
      },
    },
    listItemSelected: {
      boxShadow: `inset 3px 0 0 0 ${theme.palette.primary.main}`,
      '& $listItemTextPrimary': {
        fontWeight: 'bold',
      },
    },
    listItemText: {},
    listItemTextPrimary: {
      fontSize: theme.typography.pxToRem(14),
    },
  });

interface NavDeltaProps extends WithStyles<typeof styles> {
  sectionEntries: SectionEntryType[] | null;
  activeSectionEntry: SectionEntryType | null;
  handleSectionEntry: (section: SectionEntryType) => void;
}

const NavDelta = withStyles(styles)(
  ({
    classes,
    sectionEntries,
    activeSectionEntry,
    handleSectionEntry,
  }: NavDeltaProps) => {
    const handleItemClick = (section: SectionEntryType) => () => {
      handleSectionEntry(section);
    };

    const renderListItem = (section: SectionEntryType): JSX.Element => (
      <ListItem
        key={section.id}
        component="li"
        button
        classes={{
          root: classes.listItem,
          selected: classes.listItemSelected,
        }}
        selected={activeSectionEntry?.id === section.id}
        onClick={handleItemClick(section)}
      >
        <ListItemText
          classes={{
            root: classes.listItemText,
            primary: classes.listItemTextPrimary,
          }}
          primary={section.name}
        />
      </ListItem>
    );

    if (!sectionEntries || !activeSectionEntry) {
      return null;
    }

    return (
      <div className={classes.root}>
        <Box className={classes.listContainer}>
          <List className={classes.list}>
            {sectionEntries.map(renderListItem)}
          </List>
        </Box>
      </div>
    );
  }
);

export default NavDelta;
